<template>
  <div class="avata-warper">
    <div class="container">
      <div class="outer">
        <div class="text" v-if="image.length == 0" color="primary">
          <span class="white--text">
            {{ firstName?.charAt(0) }}{{ lastName?.charAt(0) }}</span
          >
        </div>
        <v-img v-else class="image" :src="image"></v-img>
        <div class="inner">
          <input
            class="inputfile"
            type="file"
            name="pic"
            accept="image/*"
            @change="readUrl"
          />
          <label><v-icon color="primary">mdi-pencil-outline</v-icon></label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// ref https://jsfiddle.net/etfd1hvc/
// ref https://codepen.io/gdev/pen/bKdjpa
export default {
  props: {
    value: {
      type: FileList,
      default: () => []
    },
    src: {
      type: String,
      default: ''
    },
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      image: ''
    }
  },
  computed: {
    files: {
      get () {
        // console.log('get')
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        return val
      }
    }
  },
  watch: {
    src: {
      handler: function (n) {
        this.image = n
      },
      deep: true
    }
  },
  // created () {
  //   this.image = this.src
  // },
  methods: {
    async readUrl (files) {
      const f = files.target.files

      if (f.length > 0) {
        this.files = f
        const x = new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(f[0])
          reader.onerror = reject
          reader.onload = () => {
            resolve(reader.result)
          }
        })
        this.image = await x
        this.$emit('update:src', this.image)
        this.$emit('change', f)
      } else console.error('file not found')
    }
  }
}
</script>

<style lang="scss" scoped>
.avata-warper {
  // background: yellow;
  height: 100%;
  width: 100%;
}

.container {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  display: block;
  margin: 0 auto;
  position: relative;
  padding: 0px;
  // max-width: 200px !important; /* any size */
  max-height: 200px !important; /* any size */
}

.outer {
  width: 100% !important;
  height: 100% !important;
  max-width: 200px !important; /* any size */
  max-height: 200px !important; /* any size */
  margin: auto;
  background-color: var(--v-primary-base);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.outer .image {
  width: inherit;
  height: inherit;
  border-radius: inherit;
  // background: red;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.outer .text {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background: red;
  border-radius: inherit;
  font-size: 3.5rem;
  font-weight: 500;
}

.inner {
  background-color: var(--v-secondary-base);
  width: 20%;
  height: 20%;
  border: solid 2px var(--v-bgPrimary-base);
  border-radius: 100%;
  position: absolute;
  bottom: 2%;
  right: 5%;
  z-index: 3;
}

.inner:hover {
  background-color: var(--v-secondary-darken1);
}

.inputfile {
  top: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  font-size: 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;

  width: 100%;
  height: 100%;
  // pointer-events: none;
  cursor: pointer;
  text-align: center;
  background: pink;
}
label {
  // font-size: 10px;
  text-overflow: ellipsis;
  cursor: pointer;
  position: absolute;
  fill: #fff;
  color: #fff;
  // background: green;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

label i {
  font-size: 1.5em !important;
}
</style>
