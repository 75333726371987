<template>
  <div>
    <div>
      <span v-if="required" class="required">*</span><span>{{ title }}</span>
    </div>
    <v-textarea hide-details outlined v-model="data" :rules="rules"></v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    required: {
      type: Boolean,
      default: false
    },
    json: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    data: {
      get () {
        if (this.json) return JSON.stringify(this.value, undefined, 2)
        else return this.value
      },
      set (val) {
        if (this.json) {
          if (this.isJsonString(val)) {
            this.$emit('input', JSON.parse(val))
          }
        } else this.$emit('input', val)
        return val
      }
    }
  },
  methods: {
    isJsonString (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped></style>
