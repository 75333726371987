<style lang="css" scoped>

/* .size-btn{
  width: 50%;
  height: 300px;
} */

.size-btn{
  width: 50%;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.square-box {
  position: relative;
  width: 32px;
  overflow: hidden;
}
.square-box:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.square-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

.btn-text {
  width: calc(100% - 30px);
  font-weight: 400;
  text-transform: none;
}
::v-deep .img {
  /* max-width: 100%;
  max-height: 100%; */
  max-height: 32px;
  max-width: 32px;
  width: 100%;
  height: 100%;

  /* background: yellow; */
}
::v-deep .v-image__image--cover {
  background-size: contain;
}
::v-deep .v-btn__content {
  padding: 3px;
  height: 100%;
}

/* ::v-deep .img {
  max-width: 100%;
  max-height: 100%;
}

::v-deep .v-image__image--cover {
  background-size: contain;
}

::v-deep .v-btn__content {
  padding: 3px;
  height: 100%;
} */
</style>
<template>
  <!-- <v-btn
    :rounded="rounded"
    :color="color"
    :loading="loading"
    :block="block"
    :disabled="disabled"
    @click="$emit('click', {})"
    class="pa-0 ma-0 white--text size-btn"
  >
    <div class="content red">
      <div class="image square-box">
        <div class="square-content">
          <v-img class="img" :src="image"></v-img>
        </div>
      </div>
      <div class="btn-text">{{ text }}</div>
    </div>
  </v-btn> -->
  <v-btn
    :rounded="rounded"
    :color="color"
    :loading="loading"
    :disabled="disabled"
    outlined
    @click="$emit('click', {})"
    style="height: 64px; width: 64px; box-shadow: 1.49665px 2.9933px 8.9799px rgba(100, 52, 248, 0.15); border-radius: 16px; background: #FFFFFF border: 1px solid #50A7DC;
;
"
  >
    <div class="content">
      <div class="image square-box">
        <div class="square-content">
          <v-img class="img" :src="image"></v-img>
        </div>
      </div>
      <!-- <div class="btn-text">{{ text }}</div> -->
    </div>
  </v-btn>

</template>
<script>
export default {
  props: {
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
}
</script>
