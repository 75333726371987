<template>
  <v-navigation-drawer v-model="drawer" clipped app bottom>
    <slot name="prepend"></slot>
    <v-list class="navigation">
      <router-link
        v-for="item in menus"
        :key="item.text"
        custom
        :to="item.to"
        v-slot="{ isActive, isExactActive }"
        :id="`menu-name-${item.text}`"
      >
        <!-- เมื่อไม่มี Sub items -->
        <v-list-item
          v-if="item.items.length == 0"
          :to="item.to"
          :class="[
            isActive && 'active',
            isExactActive && 'router-link-exact-active',
          ]"
          active-class="active-item"
          @click="setMenuName(item.text)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="menu-text">
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>

        <!-- เมื่อมี sub items -->
        <v-list-group
          v-else
          :value="false"
          no-action
          :prepend-icon="item.icon"
          :class="[
            isActive && 'active-no-action',
            isExactActive && 'router-link-exact-active-no-action',
          ]"
        >
          <template v-slot:activator>
            <v-list-item-content
              :class="[
                isActive && 'active-no-action',
                isExactActive && 'router-link-exact-active-no-action',
              ]"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <router-link
            v-for="item in item.items"
            :key="item.text"
            custom
            :to="item.to"
            v-slot="{ isActive, isExactActive }"
            :id="`menu-name-${item.text}`"
          >
            <v-list-item
              style="padding-left: 42px"
              :to="item.to"
              :class="[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]"
              active-class="active-item"
              @click="setMenuName(item.text)"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-group>
      </router-link>
    </v-list>
    <!-- <div class="px-2">
      <v-btn outlined block color="error" @click="logout">
        {{ $vuetify.lang.t("$vuetify.logout") }}
      </v-btn>
    </div> -->

    <template v-slot:append>
      <div class="px-1 py-1">
        <v-btn outlined block color="error" @click="logout">
          {{ $vuetify.lang.t("$vuetify.logout") }}
        </v-btn>
      </div>
      <!-- <div class="version-text">
        <span>Copyright &#174;&nbsp; </span>
        {{ new Date().getFullYear() }}
        <span>&nbsp;ManageAI</span><span>&nbsp;{{ version }}</span>
      </div> -->
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    menus: {
      type: Array,
      default: () => []
    },
    version: {
      type: String,
      default: process.env.VUE_APP_VERSION
    }
  },
  computed: {
    drawer: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  methods: {
    logout () {
      this.$websocket.Disconnect()
      localStorage.clear()
      this.$vuetify.theme.dark = false
      this.$router.push({ name: 'loginPage' }).catch(() => {})
    },
    setMenuName (val) {
      this.$store.commit('setProjectID', '')
      this.$store.commit('setProjectNmae', '')
      this.$store.commit('setMenuName', val)
    },
    toggle () {
      console.log('click')
    }
  }
}
</script>

<style lang="css" scoped>
.active-item {
  border-right-style: solid;
  border-right-color: var(--v-primary-base);
  background: linear-gradient(
    90deg,
    var(--v-gradient3-base) 0%,
    var(--v-gradient4-base) 100%
  );
}
.navigation .menu-text {
  font-size: 16px;
  font-weight: 400;
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0 !important;
}

>>> .navigation .active-no-action {
  color: var(--v-primary-base) !important;
}

::v-deep .active-no-action .v-list-group__header .v-icon {
  color: var(--v-primary-base) !important;
}
.v-list-item--active .menu-text,
.v-list-item--active .v-icon {
  color: var(--v-primary-base) !important;
}

.version-text {
  display: flex;
  font-size: 11px;
  font-weight: 300;
  justify-content: center;
  align-content: center;
}
</style>
