<template>
  <div class="pa-0 ma-0">
    <v-card-title class="pa-0">
      <div class="card-form-title">
        <v-icon v-if="icon != ''" class="card-form-title-icon">
          {{ icon }}
        </v-icon>
        <span class="card-form-title-text">{{ title }}</span>
      </div>
    </v-card-title>
    <v-card-subtitle class="pa-0 pt-2" v-if="subtitle != ''">{{ subtitle }}</v-card-subtitle>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.title-header-line {
  color: var(--v-primary-base);
}
>>> .card-form-title {
  /* margin: 1em; */
  width: 100%;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.card-form-title-text {
  font-size: 24px;
  font-weight: 300;
  color: var(--v-primary-base);
  margin-bottom: 5px;
  padding-bottom: 0px;
  text-transform: capitalize !important;
  /* padding-top: 7px !important; */
}

.card-form-title-icon {
  font-size: 20px;
  padding-right: 3px;
  margin-top: -3px;
  color: var(--v-primary-base);
}

.v-card .v-card__subtitle {
  /* padding-left: 50px !important; */
  font-weight: 200;
  text-transform: capitalize !important;
  padding-bottom: 7px !important;
}
</style>
