export default {
  methods: {
    async signInProvidAuthorizationCode (payload) {
      // NOTE this function signin
      const p = {
        ...{ authorization_code: '', redirect_url: '' },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/signin/providerid`,
        method: 'post',
        data: p
      }
      return await this.awaitAxios(options)
    },
    async getProviderIDCredential () {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/signin/providerid/credential`,
        method: 'get'
      }
      return await this.awaitAxios(options)
    },

    async ConnectProviderIDAccount (payload) {
      const p = {
        ...{ authorization_code: '', redirect_uri: '' },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/profile/connect/providerid`,
        method: 'post',
        data: p
      }
      return await this.awaitAxios(options)
    },
    // NOTE Page Notification ------------------------------------
    async getNotificationForTable (payload) {
      // DTO
      const p = {
        ...{
          page: 1,
          row: 10,
          keyword: '',
          read: ''
        },
        ...payload
      }
      // NOTE this function get data for table
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/notifications`,
        method: 'get',
        params: p
      }
      return await this.awaitAxios(options)
    }

  }
}
