<template>
  <canvas :id="myID" class="mychart"></canvas>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    indexAxis: {
      type: String,
      default: 'x'
    },
    labels: {
      type: Array,
      default: () => []
    },
    datasets: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    // TODO เมื่อมีการ Update Labels จะ Rerender Chart
    labels: {
      handler: function () {
        this.chart.data.labels = this.labels
        this.chart.update()
      },
      deep: true
    },
    // TODO เมื่อมีการ Update Datasets จะ Rerender Chart
    datasets: {
      handler: function () {
        this.chart.data.datasets = this.datasets
        this.chart.update()
      },
      deep: true
    }
  },
  data () {
    return {
      myID: 'my-id',
      chart: null,

      // TODO Config Chart.js
      DefaultOptions: {
        indexAxis: this.indexAxis,
        plugins: {
          legend: {
            display: false,
            labels: { font: { family: "'Kanit', sans-serif" } }
          },
          tooltip: {
            position: 'custom'

          }
        },
        interaction: {
          // REF https://www.youtube.com/watch?v=OSWIefHdago เรื่อง show tooltip เมื่อไม่ตรง pointer
          // REF https://www.youtube.com/watch?v=24FVvn8IHQk เรื่อง Vertical line
          intersect: false,
          mode: 'index'
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false
            },
            ticks: {
              font: {
                size: 10,
                weight: 500,
                family: "'Kanit', sans-serif"
              }
            }
          },
          x: {
            grid: {
              display: false
            },
            ticks: {
              font: {
                size: 10,
                weight: 500,
                family: "'Kanit', sans-serif"
              }

              // autoSkip: true
              // maxTicksLimit: 3,
              // maxRotation: 0,
              // stepSize: Math.round((Math.max.apply(Math, this.datasets.length) / 10) / 5) * 5,
              // beginAtZero: true,
              // precision: 0
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
        // aspectRatio: 1 | 1
      }
    }
  },
  created () {
    // TODO Create Unique id of component chart
    this.myID = 'my-id-' + this._uid
  },

  mounted () {
    // TODO initial Chart js
    const ctx = document.getElementById(this.myID)
    this.chart = new this.Chart(ctx, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: this.DefaultOptions
    })
  }
}
</script>

<style lang="scss" scoped>
.mychart {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: contain !important;
}
</style>
