<template>
  <div
    style="
      position: relative;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      /* background-color: orange; */
    "
  >
    <div class="gauge__box">
      <div class="gauge__percent">
        <svg>
          <circle cx="70" cy="70" r="70"></circle>
          <circle
            cx="70"
            cy="70"
            r="70"
            :style="`stroke-dashoffset: ${
              440 - (value * 440) / 100
            }; stroke :${color}`"
          ></circle>
        </svg>
        <div class="gauge__number">
          <h2>{{ value }} <span>%</span></h2>
        </div>
      </div>
      <div class="gauge__text">{{ title }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
      required: false
    },
    title: {
      type: String,
      default: 'Untitle',
      required: false
    },
    color: {
      type: String,
      default: '#03a9f4'
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.gauge__percent {
  //   background-color: red;
  padding: 0;
}

.gauge__box {
  position: relative;
  //   width: 300px;
  //   height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  //   background: red;
  //   box-shadow: 0 30px 60px rgba(0, 0, 0, 0.2);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gauge__box .gauge__percent {
  position: relative;
  width: 150px;
  height: 150px;
}

.gauge__box .gauge__percent svg {
  position: relative;
  width: 150px;
  height: 150px;
}

.gauge__box .gauge__percent svg circle {
  fill: none;
  width: 150px;
  height: 150px;
  stroke-width: 10;
  stroke: #000;
  transform: translate(5px, 5px);
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
}

.gauge__box .gauge__percent svg circle:nth-child(1) {
  stroke-dashoffset: 0;
  stroke: #f3f3f3;
}

// .gauge__box .gauge__percent svg circle:nth-child(2) {
//   stroke-dashoffset: calc(440 - (0 * 440 / 100));
//   stroke: #03a9f4;
// }
.gauge__box .gauge__percent .gauge__number {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gauge__box .gauge__text {
  padding: 10px 0 0;
  color: #999;
  font-weight: 500;
  font-size: 26px;
  letter-spacing: 1px;
}
</style>
