import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // name when click settting Project
    ProjectNmae: '',
    ProjectID: '',
    ProjectLimitDate: 0,
    MenuName: '',
    userInfo: {
      user_id: '',
      first_name: '',
      last_name: '',
      phone: '',
      gender: '',
      image: '',
      date_of_birth: '',
      permissions: [
        // {
        //   permission_id: '',
        //   name: '',
        //   description: '',
        //   priority: 0
        // }
      ],
      verify: 0,
      street: '',
      zip_code: '',
      city: '',
      country_code: '',
      country: '',
      description: '',
      methods: [
        // {
        //   provider: 'local',
        //   method_id: '',
        //   email: '',
        //   username: ''
        // }
      ],
      name_title: ''
    },
    currentTag: [],
    oneauthenHost: 'inet-log.one.th',
    // oneauthenHost: '127.0.0.1'

    curentDatasourceId: '',
    curentDateSourceIndex: '',

    curentProjectMenus: [] // NOTE สำหรับเก็บข้อมูล Menu ทั้งหมดของ Project เมื่อกดเข้า Project
  },
  getters: {
    storeGetUserInfo (state) {
      // TODO สำหรับ Get User Info
      return state.userInfo
    },
    storeGetCurrentTag (state) {
      return state.currentTag
    },
    storeGetOneauthHost (state) {
      return state.oneauthenHost
    },
    storeGetCurentDatasourceId (state) {
      return state.curentDatasourceId
    },
    storeGetCurentDatasourceIndex (state) {
      return state.curentDateSourceIndex
    },
    storeGetProjectID (state) {
      return state.ProjectID
    },
    storeGetCurProjectMenus (state) {
      return state.curentProjectMenus
    },
    storeGetProjectLimitDate (state) {
      return state.ProjectLimitDate
    }
  },
  mutations: {
    setProjectNmae (state, newData) {
      state.ProjectNmae = newData
    },
    setMenuName (state, newData) {
      state.MenuName = newData
    },
    setProjectID (state, newData) {
      state.ProjectID = newData
    },
    storeSetUserinfo (state, newData) {
      // TODO สำหรับ Set User Info
      state.userInfo = { ...state.userInfo, ...newData }
    },
    storeSetCurrentTag (state, newData) {
      state.currentTag = newData
    },
    storeSetCurentDatasourceId (state, newData) {
      state.curentDatasourceId = newData
    },
    storeSetCurentDatasourceIndex (state, newData) {
      state.curentDateSourceIndex = newData
    },
    storeSetProjectMenus (state, newData) {
      state.curentProjectMenus = newData
    },
    storeSetProjectLimitDate (state, newData) {
      state.ProjectLimitDate = newData
    }
  },
  actions: {
    // storeSetCurentDatasourceId (context, val) {
    //   context.commit('SetCurentDatasourceId', val)
    // },
    // storeSetCurentDatasourceIndex (context, val) {
    //   context.commit('storeGetCurentDatasourceIndex', val)
    // }
  },
  modules: {}
})
