<template>
  <div style="height: 100%">
    <transition name="fade" mode="out-in">
      <router-view style="height: 100%"></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="css" scoped>
/* Router Transition https://markus.oberlehner.net/blog/vue-router-page-transitions/ */
.fade-enter-active,
.fade-leave-active {
  /* transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease; */
  transition: all 0.5s ease-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
  transition: 0.5s;
  transform: translateX(800px);
  animation: 0.5s infinite;
}
</style>
