<template>
  <canvas :id="myID" class="mychart"></canvas>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    indexAxis: {
      type: String,
      default: 'x'
    },
    labels: {
      type: Array,
      default: () => []
    },
    datasets: {
      type: Array,
      default: () => []
    },
    dataField: {
      type: String,
      default: ''
    },
    dataType: {
      type: String,
      default: ''
    }
  },
  watch: {
    // TODO เมื่อมีการ Update Labels จะ Rerender Chart
    labels: {
      handler: function () {
        this.chart.data.labels = this.labels
        this.chart.update()
      },
      deep: true
    },
    // TODO เมื่อมีการ Update Datasets จะ Rerender Chart
    datasets: {
      handler: function () {
        this.chart.data.datasets = this.datasets
        this.chart.update()
      },
      deep: true
    }
  },
  data () {
    return {
      myID: 'my-id',
      chart: null,

      // TODO Config Chart.js
      DefaultOptions: {
        indexAxis: this.indexAxis,
        plugins: {
          legend: { display: false },
          tooltip: {
            position: 'custom'
          }
        },
        interaction: {
          // REF https://www.youtube.com/watch?v=OSWIefHdago เรื่อง show tooltip เมื่อไม่ตรง pointer
          // REF https://www.youtube.com/watch?v=24FVvn8IHQk เรื่อง Vertical line
          intersect: false,
          mode: this.indexAxis
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false
            },
            ticks: {
              font: {
                size: 10,
                weight: 500,
                family: "'Kanit', sans-serif"
              }
            }
          },
          x: {
            grid: {
              display: false
            },
            ticks: {
              font: {
                size: 10,
                weight: 500,
                family: "'Kanit', sans-serif"
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
        // aspectRatio: 1 | 1
      }
    }
  },
  created () {
    // TODO Create Unique id of component chart
    this.myID = 'my-id-' + this._uid
  },

  mounted () {
    // TODO initial Chart js
    const ctx = document.getElementById(this.myID)
    const that = this
    this.chart = new this.Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      // options: this.DefaultOptions
      options: {
        indexAxis: this.indexAxis,
        plugins: {
          legend: { display: false },
          tooltip: {
            position: 'custom'
          }
        },
        interaction: {
          // REF https://www.youtube.com/watch?v=OSWIefHdago เรื่อง show tooltip เมื่อไม่ตรง pointer
          // REF https://www.youtube.com/watch?v=24FVvn8IHQk เรื่อง Vertical line
          intersect: false,
          mode: this.indexAxis
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false
            },
            ticks: {
              font: {
                size: 10,
                weight: 500,
                family: "'Kanit', sans-serif"
              }
            }
          },
          x: {
            grid: {
              display: false
            },
            ticks: {
              font: {
                size: 10,
                weight: 500,
                family: "'Kanit', sans-serif"
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        // aspectRatio: 1 | 1
        onClick: function (event, elements) {
          // console.log(that.dataType)
          if (elements?.length > 0 && that.dataType === 'bar') {
            const clickedElement = elements[0]
            const datasetIndex = clickedElement.index
            // const label = chart.data.labels[datasetIndex]
            const label = this.data.labels[datasetIndex]
            // const labelValue = this.data.datasets[0].data[datasetIndex]
            // ส่งค่า label และ labelvalue ไป clickWidgetPie()
            that.clickWidgetBar(label)
            // that.clickWidgetPie(label)
          }
        },
        onHover: (event, activeElements) => {
          if (activeElements?.length > 0 && that.dataType === 'bar') {
            event.native.target.style.cursor = 'pointer'
          } else {
            event.native.target.style.cursor = 'auto'
          }
        }
      }
    })
  },
  methods: {
    clickWidgetBar (label) {
      // console.log('=== dataType ===')
      // console.log(this.dataType)
      // if (this.dataType === 'bar') {
      const dataBarChartPatter = this.dataField + ':' + '"' + label + '"'
      this.$EventBus.$emit('clickWidgetBarChart', dataBarChartPatter)
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.mychart {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: contain !important;
}
</style>
