<template>
  <v-app-bar app clipped-left color="bgPrimary" elevation="3">
    <v-app-bar-nav-icon @click="drawer = !drawer" class="color-icon">
    </v-app-bar-nav-icon>
    <div class="mr-2" v-if="logo.length > 0">
      <v-img width="30" :src="require(`@/assets/images/${logo}`)">
      </v-img>
    </div>
    <v-toolbar-title class="title">{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <u-notification-popup />
    <u-profile-popup
      :image="image"
      :firstName="firstName"
      :lastName="lastName"
      :subtitle="subtitle"
    ></u-profile-popup>
  </v-app-bar>
</template>

<script>

export default {
  data () {
    return {
      items: [...Array(0)].map((_, i) => {
        return { text: `Real-Time-${i}`, icon: 'mdi-clock' }
      })
    }
  },
  props: {
    notifications: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Untitled'
    },
    value: {
      type: Boolean,
      default: false
    },
    firstName: {
      type: String,
      default: 'Untitled'
    },
    lastName: {
      type: String,
      default: 'Untitled'
    },
    image: {
      type: String,
      default: 'Untitled'
    },
    subtitle: {
      type: String,
      default: ''
    },
    logo: {
      type: String,
      default: ''
    }
  },
  computed: {
    drawer: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  }
}
</script>

<style lang="css" scoped>
.title {
  font-family: "Kanit", sans-serif !important;
  font-size: 25px !important;
  font-weight: 300 !important;
  color: var(--v-tprimary-base);
}
.title::first-letter {
  font-weight: 700;
  /* color: #55cfe5; */
  color: var(--v-primary-base);
}
.color-icon {
  color: var(--v-tprimary-base) !important;
}
</style>
