<template>
  <div class="main-layout">
    <div class="layout-title" v-if="$router.currentRoute.name === 'projectList'">
      <u-title-page title="โปรเจค" :detail="$store.state.ProjectNmae" />
    </div>
    <div class="layout-content">
      <transition name="fade" mode="out-in">
        <router-view style="height: 100%"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  async created () {}
}
</script>

<style lang="css" scoped>
/* // TODO สำหรับจัดการหน้าเพจทั้งหมด */
.main-layout {
  height: 100%;
}

/* // TODO สำหรับจัดการ Content ของเพจ */
.layout-content {
  height: calc(100% - 58px);
  padding: 0;
  margin: 0;
}

/* Router Transition */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s ease-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
  transition: 0.4s;
  transform: translateX(800px);
  animation: 0.5s infinite;
}
</style>
