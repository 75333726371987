<template>
<v-dialog v-model="dialog" class="main-style-card" persistent>
    <v-card class="style-card">
        <slot name="actionsCloseBtn"></slot>
        <v-img class="img-header mx-auto" :src="imageDialog" />
        <div class="text-for-Dialog">
            <div class="text-header" :style="`color: ${colorForMainTitle};`">{{ title }}</div>
            <div class="text-subtitle">{{subtitle}}</div>
        </div>
        <v-card-actions>
            <!-- <slot name="actions" :valid="valid"> </slot> -->
            <slot name="actions"></slot>
        </v-card-actions>
    </v-card>
</v-dialog>
<!-- <DialogConfirm :value="dialogForSave" :title="titleDialogConfirm.formTitle" :subtitle="titleDialogConfirm.subtitle" :colorForMainTitle="titleDialogConfirm.colorForTitle" :imageDialog="require('../../public/img/createAccountSuccess.png')">
    <template v-slot:actionsCloseBtn>
        <div class="btn-close">
            <v-icon @click="(dialogForSave = false),reset()">mdi-close</v-icon>
        </div>
    </template>
</DialogConfirm> -->
</template>

<script>
// import DialogConfirm from '@/components/Forms/DialogConfirm.vue'

export default {
  components: {
    // DialogAnnounce,
    // DialogForgetPassword,
    // OauthBtn
  },
  props: {
    // value สำหรับ dialog เปิด-ปิด
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'untitled'
    },
    subtitle: {
      type: String,
      default: 'undefined Subtitle'
    },
    icon: {
      type: String,
      default: 'mdi-cog'
    },
    widthDialog: {
      type: String,
      default: '500'
    },
    imageDialog: {
      type: String,
      default: ''
    },
    colorForMainTitle: {
      type: String,
      default: '#333333'
    }
  },
  // data: () => ({
  //   valid: false
  // }),
  data () {
    return {
      dialogForSave: false,
      valid: false,
      titleForConfirm: {
        formTitle: 'สร้างบัญชีผู้ใช้',
        subtitle: 'คุณต้องการสร้างบัญชีผู้ใช้นี้ใช่หรือไม่ ?',
        colorForTitle: 'green'
      },
      titleDialogConfirm: {
        formTitle: 'สำเร็จ',
        subtitle: 'ลงทะเบียนบัญชีผู้ใช้งานสำเร็จรหัสผ่านของคุณจะถูกส่งไปที่ อีเมลที่ ทำการสมัครหรือกรุณาติดต่อเจ้าหน้าที่ ',
        colorForTitle: 'green'
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    validate () {
      // console.log('this.$refs.form.validate()', this.$refs.form.validate())
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    }
  }
}
</script>

<style lang="css" scoped>
.main-style-card {
    width: 100%;
    max-width: 600px;

}

.style-card {
    height: 100%;
    width: 100%;
    /* max-height: calc(100vh - 439px); */
    max-height: 400px;
    /* background: yellow; */
    position: absolute;
    max-width: 472px;
    /* max-height: 469px; */
    left: calc(50% - 472px/2);
    top: calc(50% - 400px/2 - 0.5px);

    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 4px 8px -2px rgba(27, 46, 94, 0.04);
    border-radius: 32px;
}

.btn-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
    color: #989898;
}

.text-for-Dialog {
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #333333;
    margin-top: 16px;
    margin-bottom: 16px;
}

.text-header {
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    /* color: #333333; */
    /* colorForTitle */
    /* color: v-bind('colorForTitle'); */

}

.text-subtitle {
    margin-top: 10px;
    /* background: yellow; */
    /* margin-right: 13px;
    margin-left: 13px; */
    padding-right: 13px;
    padding-left: 13px;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-weight: 300;
    font-size: 18px;
}

.img-header {
    max-width: 30%;
    margin-top: 16px;
    margin-bottom: 16px;
}

.title-header-line {
    color: var(--v-primary-base);
}

>>>.card-form-title {
    /* margin: 1em; */
    width: 100%;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
}

.card-form-title-text {
    font-size: 24px;
    font-weight: 300;
    color: var(--v-primary-base);
    margin-bottom: 5px;
    padding-bottom: 0px;
    text-transform: capitalize !important;
    /* padding-top: 7px !important; */
}

.card-form-title-icon {
    font-size: 20px;
    padding-right: 3px;
    margin-top: -3px;
    color: var(--v-primary-base);
}

.v-card .v-card__subtitle {
    /* padding-left: 50px !important; */
    font-weight: 200;
    text-transform: capitalize !important;
    padding-bottom: 7px !important;
}

/* >>> .v-list-item .v-list-item__title {
  text-transform: capitalize !important;
}

.v-input {
  text-transform: capitalize !important;
} */

.v-card__actions {
    /* padding-top: 16px !important;
  padding-bottom: 16px !important; */
    /* background: red; */
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
}

.main-card {
    max-height: calc(80vh - 68px - 37px - 16px);
    overflow-y: scroll;
    padding-bottom: 0 !important;
    -ms-overflow-style: thin;
    /* IE and Edge */
    scrollbar-width: thin;
    /* Firefox */
}

@media screen and (max-width: 500px) {
    .style-card {
        width: 100%;
        max-width: 350px;
        left: calc(50% - 350px/2);
        /* background: RED; */

    }
}
</style>
