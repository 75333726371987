<template>
  <div class="main-layout">
    <div class="layout-title">
      <u-title-page :title="NameComponent" />
    </div>
    <div class="layout-content">
      <u-table
        :headers="headers"
        :items="items"
        :mobileBreakpoint="900"
        :showSelect="false"
        itemKey="package_id"
        :pagination="pagination"
        @onChangePagination="onChangePagination"
        :loading="loading"
      >
        <template v-slot:header>
          <v-row
            justify="space-between"
            align="center"
            class="header-custom-table"
          >
            <!-- // # Action Group -->
            <v-col cols="12" md="4">
              <v-row justify="start" align="center">
                <v-col cols="12" md="6">
                  <v-btn color="primary" block dark @click="dialog = !dialog">
                    <v-icon>mdi-home-plus</v-icon>เพิ่ม{{ NameComponent }}
                  </v-btn></v-col
                >
              </v-row>
            </v-col>
            <!-- // # Search Group -->
            <v-col cols="12" md="4">
              <v-row justify="center" align="center">
                <v-col
                  ><v-text-field
                    v-model="keyword"
                    name="name"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    placeholder="ค้นหา"
                    label="ค้นหา"
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col cols="4">
                  <v-btn color="primary" block dark @click="init"
                    >ค้นหา</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </template>
        <!-- // # Items Table  -->
        <template v-slot:[`item.number`]="{ item }">
              {{
                pagination.row * pagination.page -
                pagination.row +
                (items.indexOf(item) + 1)
              }}
            </template>
        <!-- <template v-slot:[`item.description`]="{ item }">
          {{
            item.description !== "" && item.description !== null
              ? item.description
              : "-"
          }}
        </template> -->

        <template v-slot:[`item.start_date`]="{ item }">
          {{ item.start_date | BE_DD_MM_YYYY_HH_MM }}
        </template>

        <template v-slot:[`item.end_date`]="{ item }">
          {{ item.end_date | BE_DD_MM_YYYY_HH_MM }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="action-nowrap">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  dark
                  color="primary"
                  @click="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>แก้ไขข้อมูล</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  dark
                  color="error"
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </template>
              <span>ลบ</span>
            </v-tooltip>
          </div>
        </template>
      </u-table>
    </div>

    <!-- // # Form Add/Edit -->
    <u-card-form
      v-model="dialog"
      :title="formTitle"
      :subtitle="formSubTitle"
      widthDialog="1000"
      ref="form"
    >
      <template v-slot:form>
        <v-row>
          <v-col cols="12" class="pb-0">
            <div>
              <span class="required">*</span
              ><span>ชื่อ{{ NameComponent }}</span>
            </div>
            <v-text-field
              v-model="editedItem.title"
              :rules="[Rule.noInPut]"
              required
              outlined
              dense
              :placeholder="`กรุณากรอกชื่อ${NameComponent}`"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="custom-col">
            <div><span class="required">*</span><span>เริ่ม</span></div>
            <u-date-picker
              format="DD-MM-YYYY"
              isBE
              format_output="YYYY-MM-DD"
              v-model="editedItem.start_date"
              placeholder="เริ่ม"
              :max="editedItem.end_date"
              :rules="[Rule.noInPut]"
            ></u-date-picker>
          </v-col>

          <v-col cols="12" md="6" class="custom-col">
            <div><span class="required">*</span><span>สิ้นสุด</span></div>
            <u-date-picker
              format="DD-MM-YYYY"
              isBE
              format_output="YYYY-MM-DD"
              v-model="editedItem.end_date"
              placeholder="สิ้นสุด"
              :min="editedItem.start_date"
              :rules="[Rule.noInPut]"
            ></u-date-picker>
          </v-col>

          <!-- <v-col cols="12">
            <div>Preview</div>
            <div class="preview">
              <u-render-announcer :data="editedItem"></u-render-announcer>
            </div>
          </v-col> -->

          <!-- <v-col cols="12" md="6" class="custom-col">
            <div><span>รูปภาพ</span></div>
            <v-text-field
              v-model="editedItem.image"
              :rules="[Rule.noInPut]"
              outlined
              dense
              placeholder="รูปภาพ"
            ></v-text-field>
          </v-col> -->

          <!-- <v-col cols="12" md="6" class="custom-col">
            <div><span>ลิงก์(สำหรับคลิก)</span></div>
            <v-text-field
              v-model="editedItem.url"
              :rules="[Rule.noInPut]"
              outlined
              dense
              placeholder="ลิงก์"
            ></v-text-field>
          </v-col> -->

          <!-- <v-col cols="12" md="6" class="custom-col">
            <div><span>ไอคอน</span></div>
            <v-text-field
              v-model="editedItem.icon"
              :rules="[Rule.noInPut]"
              outlined
              dense
              placeholder="ไอคอน"
            ></v-text-field>
          </v-col> -->

          <!-- <v-col cols="12" md="6" class="custom-col">
            <div><span>รูปแบบ</span></div>
            <v-select
              v-model="editedItem.type"
              :items="type_items"
              placeholder="รูปแบบ"
              outlined
              dense
            ></v-select>
          </v-col> -->

          <v-col cols="12" class="custom-col">
            <div><span>รายละเอียด</span></div>
            <div class="content">
              <u-text-deitor v-model="editedItem.description"></u-text-deitor>
            </div>

            <!-- <v-textarea
              v-model="editedItem.description"
              outlined
              placeholder="รายละเอียด"
              value=""
            ></v-textarea> -->
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions="{ valid }">
        <v-row>
          <v-col>
            <v-btn
              :disabled="!valid"
              @click="save"
              block
              color="primary"
              :loading="loadingSave"
            >
              บันทึก
            </v-btn>
          </v-col>
          <v-col
            ><v-btn block color="error" @click="close">ยกเลิก</v-btn></v-col
          >
        </v-row>
      </template>
    </u-card-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      keyword: '',
      start_date_status: false,
      end_date_status: false,
      loading: false,
      loadingSave: false,
      NameComponent: 'ประกาศ',
      dialog: false, // สำหรับใช้เปิดปิด dialog add/edit
      IdEdit: '',
      editedIndex: -1,
      editedItem: {
        announcer_id: '',
        start_date: '',
        end_date: '',
        title: '',
        description: '',
        image: '',
        url: '',
        icon: '',
        type: ''
      },
      defaultItem: {
        announcer_id: '',
        start_date: '',
        end_date: '',
        title: '',
        description: '',
        image: '',
        url: '',
        icon: '',
        type: ''
      },
      pagination: {
        total: 0,
        page: 1,
        row: 10
      },
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number'
        },
        {
          text: 'หัวข้อ',
          align: 'start',
          value: 'title',
          sortable: false
        },
        {
          text: 'เริ่ม',
          value: 'start_date',
          align: 'center',
          sortable: false
        },
        {
          text: 'สิ้นสุด',
          value: 'end_date',
          align: 'center',
          sortable: false
        },
        { text: 'จัดการ', value: 'actions', sortable: false, align: 'center' }
      ],
      items: [],
      type_items: ['Type_A', 'Type_B']
    }
  },
  watch: {},
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? `เพิ่ม${this.NameComponent}`
        : `แก้ไข${this.NameComponent}`
    },
    formSubTitle () {
      return this.editedIndex === -1
        ? `กรอกข้อมูลเพื่อสร้าง${this.NameComponent}`
        : `กรอกข้อมูลเพื่อแก้ไข${this.NameComponent}`
    },
    returnFormateDate: {
      set (val) {
        return val
      },
      get () {
        return this.editedItem.date_of_birth !== ''
          ? this.$moment(this.editedItem.date_of_birth)
            .add(543, 'year')
            .format('YYYY-MM-DD')
          : ''
      }
    },
    editor () {
      return this.$refs.myTextEditor.quill
    }
  },
  created () {
    this.init()
  },

  methods: {
    async init () {
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        page: this.pagination.page,
        row: this.pagination.row,
        keyword: this.keyword
      }
      const res = await this.getAnnouncerForTable(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination = res.data.pagination
      } else {
        this.antmessage.error(res.message)
      }
    },
    onChangePagination (val) {
      this.pagination.page = val.page
      this.pagination.row = val.row
      this.init()
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.$swal({
        allowEnterKey: false,
        title: `ลบ${this.NameComponent}`,
        text: `คุณต้องการลบ${this.NameComponent}นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = { announcer_id: item.announcer_id }
          const res = await this.deleteItemAnnouncer(payload)
          this.antmessage.destroy()
          if (res.status) {
            this.init()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `ลบ${this.NameComponent}สำเร็จ`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },
    close () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        // TODO Update Data
        this.$swal({
          allowEnterKey: false,
          title: `แก้ไข${this.NameComponent}`,
          text: `คุณต้องการแก้ไข${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              announcer_id: this.editedItem.announcer_id,
              start_date: this.editedItem.start_date,
              end_date: this.editedItem.end_date,
              title: this.editedItem.title,
              description: this.editedItem.description,
              image: this.editedItem.image,
              url: this.editedItem.url,
              icon: this.editedItem.icon,
              type: this.editedItem.type
            }
            const res = await this.updateAnnouncer(payload)
            this.loadingSave = false
            if (res.status) {
              this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `แก้ไข${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else {
        // TODO Create Data
        this.$swal({
          allowEnterKey: false,
          title: `เพิ่ม${this.NameComponent}`,
          text: `คุณต้องการเพิ่ม${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = this.editedItem

            const res = await this.createAnnounucer(payload)
            this.loadingSave = false
            if (res.status) {
              this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `เพิ่ม${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      }
    }
    // onEditorChange ({ html, text }) {
    //   console.debug('editor change!', html, text)
    //   this.content = html
    // }
  }
}
</script>

<style lang="css" scoped>
/* สำหรับจัดการหน้าเพจทั้งหมด */
.main-layout {
  height: 100%;
}

/* สำหรับ จัดการ title ของเพจ */
.layout-title {
  height: 58px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* สำหรับจัดการ Content ของเพจ */
.layout-content {
  height: calc(100% - 58px);
  padding: 0;
  margin: 0;
}

.header-custom-table .header-custom-search {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.header-custom-table .header-custom-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
  min-height: 70px;
}

.custom-col {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
/* .preview {
  width: 100%;
  border: solid 1px grey;
  aspect-ratio: 16 / 9;
  position: relative;
  border-radius: 5px;
} */

::v-deep .content {
  width: 100%;
  border: solid 1px grey;
  aspect-ratio: 16 / 9;
  border-radius: 5px;
}
</style>
