<template>
  <div class="main-page">
    <Toolbar
      v-model="drawer"
      :title="host == storeGetOneauthHost ? 'Centralized Log Management' : 'Dashboard'"
      :firstName="user.first_name"
      :lastName="user.last_name"
      :image="user.image"
      :subtitle="user.phone"
      :notifications="notifications"
      v-if="$router.currentRoute.name !== 'projectDashboard'"
      :logo="host == storeGetOneauthHost ? 'logo-oneauthen2.jpg' : ''"
    />
    <Navigation v-model="drawer" :menus="menus">
      <template v-slot:prepend>
        <div class="pa-5 name-menu">
          <div
            class="mb-3"
            v-if="$router.currentRoute.name === 'projectDashboard'"
          >
            <div style="display: flex">
              <div v-if="host == storeGetOneauthHost" class="mr-2">
                <v-img
                  width="40"
                  :src="require(`@/assets/images/logo-oneauthen.png`)"

                >
                </v-img>
              </div>
              <div class="title">
                {{ host == storeGetOneauthHost ? "INET LOG" : "Dashboard" }}
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-start justify-lg-center pl-8 pt-6"
            style="height: 90px"
          >
            <u-profile-popup
              size="75"
              :image="user.image"
              :firstName="user.first_name"
              :lastName="user.last_name"
              :subtitle="user.phone"
            ></u-profile-popup>
            <div class="ml-5 mt-7"><u-notification-popup /></div>
          </div>
          <div class="mt-3">
            {{ $store.state.MenuName }}
            <span v-if="$store.state.ProjectNmae !== ''">
              /{{ $store.state.ProjectNmae }}
            </span>
          </div>
        </div>
        <v-divider></v-divider>
      </template>
    </Navigation>
    <v-main class="main-content">
      <div class="main-container pa-4">
        <transition name="fade" mode="out-in">
          <router-view style="height: 100%"></router-view>
        </transition>
      </div>
    </v-main>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
import Toolbar from '../components/Layout/ToolBar.vue'
import Navigation from '../components/Layout/Navigation.vue'
export default {
  components: {
    Toolbar,
    Navigation
  },
  created () {
    // TODO ใช้สำหรับการดึงค่า hostname เพื่อใช้ในการเลือกเปิด หน้า Login
    this.host = window.location.hostname

    this.initUserInfo()
    this.initMenuNavigation()

    // NOTE สำหรับแก้ปัญหา Event ไม่เคลียเมื่อมีการ Refresh Page
    this.$websocket.Disconnect()

    // NOTE สำหรับ Connect Websocket
    this.$websocket.Connect(localStorage.getItem('Accesstoken'))

    // close Navigation when click tab in project
    this.$EventBus.$on('close-nav', () => {
      this.drawer = false
    })
    this.$EventBus.$on('set-nav', () => {
      this.drawer = !this.drawer
    })
  },

  data () {
    return {
      drawer: true,
      menus: [],
      notifications: [],
      user: {
        user_id: '',
        first_name: '',
        last_name: '',
        phone: '',
        image: ''
      },
      host: '',
      oneauthHost: 'inet-log.one.th'
      // oneauthHost: '127.0.0.1'
    }
  },
  computed: {
    ...mapGetters(['storeGetUserInfo', 'storeGetOneauthHost'])
  },

  methods: {
    ...mapMutations(['storeSetUserinfo']),

    setNameMenu (currentRoute) {
      let name = currentRoute.name
      if (
        currentRoute.matched.length === 3 &&
        currentRoute.matched[1].name === 'projectPage'
      ) {
        name = currentRoute.matched[1].name
      }
      for (let index = 0; index < this.menus.length; index++) {
        if (this.menus[index].items.length !== 0) {
          for (
            let index2 = 0;
            index2 < this.menus[index].items.length;
            index2++
          ) {
            if (this.menus[index].items[index2].name === name) {
              this.$store.commit(
                'setMenuName',
                this.menus[index].items[index2].text
              )
            }
          }
        } else {
          if (this.menus[index].name === name) {
            this.$store.commit('setMenuName', this.menus[index].text)
          }
        }
      }
    },
    async initUserInfo () {
      const res = await this.getUserInfo()
      if (res.status) {
        this.user = res.data
        // console.log(JSON.stringify(this.user))
        this.storeSetUserinfo(res.data)
      } else {
        this.antmessage.error(res.message)
      }
    },
    async initMenuNavigation () {
      const res = await this.getMenusForNavigation()
      if (res.status) {
        const data = res.data.map((x) => {
          return {
            ...x,
            ...{
              to: { name: x.name },
              items: x.items.map((y) => {
                return { ...y, ...{ to: { name: y.name } } }
              })
            }
          }
        })
        // TODO Add Profile And Notification is here ---------------
        const appendDefaultMenu = [
          // Profile
          {
            create_by_system: false,
            name: 'profilePage',
            description: '',
            index: 0,
            path: '',
            to: { name: 'profilePage' },
            text: 'โปรไฟล์',
            icon: 'mdi-account-outline',
            items: []
          },
          // Notification
          {
            create_by_system: false,
            name: 'notificationPage',
            description: 'notification',
            index: 0,
            path: '',
            to: { name: 'notificationPage' },
            text: 'แจ้งเตือน',
            icon: 'mdi-bell-outline',
            items: []
          }
        ]
        // TODO ----------------------------------------------------
        this.menus = [...data, ...appendDefaultMenu]

        if (
          this.$router.currentRoute.path === '/home' &&
          this.menus.length !== 0
        ) {
          this.$router.push({ name: this.menus[0].name }).catch(() => {})
        }
        this.setNameMenu(this.$router.currentRoute)
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style scoped>
.title {
  font-family: "Kanit", sans-serif !important;
  font-size: 25px !important;
  font-weight: 300 !important;
  color: var(--v-tprimary-base);
}
.title::first-letter {
  font-weight: 600;
  /* color: #55cfe5; */
  color: var(--v-primary-base);
}
.name-menu {
  font-size: 20px;
  color: var(--v-primary-base);
}
.main-page {
  /* background: green; */
  height: 100vh;
  overflow: hidden;
}
.main-content {
  /* background: blue; */
  width: 100%;
  height: 100%;
}
>>> .main-container {
  /* background: green; */
  width: 100%;
  height: 100%;
}
/* Router Transition https://markus.oberlehner.net/blog/vue-router-page-transitions/ */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease-out;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
  transition: 0.5s;
  transform: translateX(800px);
  animation: 0.5s infinite;
}
</style>
