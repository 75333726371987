<template>
  <div class="main-layout">
    <div class="layout-title">
      <u-title-page :title="$vuetify.lang.t(`$vuetify.users`)" />
    </div>
    <div class="layout-tab">
      <u-tab v-model="tab" :items="tabItems" @click="settingUserVerify"></u-tab>
    </div>
    <div class="layout-content">
      <u-tab-items v-model="tab" class="layout-tab-items">
        <!-- //# Tab Manage Users-->
        <template v-slot:users>
          <u-table
            :headers="headers"
            :items="items"
            :showExpand="false"
            :mobileBreakpoint="900"
            :offsetHeight="48"
            itemKey="user_id"
            v-model="userSelected"
            :pagination="pagination"
            @onChangePagination="onChangePagination"
            :loading="loading"
          >
            <!-- // # Header Table -->
            <template v-slot:header>
              <v-row justify="space-between" align="center">
                <!-- // # Action Group -->
                <v-col cols="12" md="2">
                  <v-btn color="primary" block dark @click="dialog = !dialog">
                    <v-icon>mdi-account-plus</v-icon>เพิ่ม{{ NameComponent }}
                  </v-btn>
                </v-col>

                <!-- // # Search Group -->
                <v-col cols="12" md="4">
                  <v-row justify="center" align="center">
                    <v-col
                      ><v-text-field
                        v-model="keyword.statusApprove"
                        name="name"
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        placeholder="ค้นหา"
                        label="ค้นหา"
                        hide-details
                      ></v-text-field
                    ></v-col>
                    <v-col cols="4">
                      <v-btn color="primary" block dark @click="init()"
                        >ค้นหา</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>

            <!-- // # Items Table  -->
            <template v-slot:[`item.number`]="{ item }">
              {{
                pagination.row * pagination.page -
                pagination.row +
                (items.indexOf(item) + 1)
              }}
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <v-avatar color="primary" size="35">
                <span
                  v-if="item.image === ''"
                  class="white--text text-h7 text-uppercase"
                >
                  {{ item.first_name.charAt(0) }}{{ item.last_name.charAt(0) }}
                </span>
                <v-img v-else :src="profileUrl(item.image)"></v-img>
              </v-avatar>
            </template>

            <template v-slot:[`item.name_title`]="{ item }">
              {{ item.name_title != "" ? item.name_title : "-" }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ item.gender != "" ? item.gender : "-" }}
            </template>
            <template v-slot:[`header.name`]="{ header }">
              {{ header.text.toUpperCase() }}
            </template>
            <template v-slot:[`item.permissions`]="{ item }">
              <v-chip
                outlined
                small
                v-for="t in item.permissions"
                :key="t"
                color="primary"
                class="text-capitalize my-1"
              >
                {{ getPermission(t) }}
              </v-chip>
            </template>
            <template v-slot:[`item.user`]="{ item }">
              {{ returnUser(item) }}
            </template>
            <template v-slot:[`item.methods`]="{ item }">
              <v-chip
                outlined
                small
                v-for="t in item.methods"
                :key="t.id"
                color="primary"
                class="text-capitalize"
              >
                {{ t.provider }}
              </v-chip>
            </template>
            <!-- // # Action Fields -->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="action-nowrap">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      fab
                      x-small
                      color="primary"
                      @click="editItem(item)"
                      :dark="item.user_id !== userInfo.user_id"
                      :disabled="item.user_id == userInfo.user_id"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>แก้ไขข้อมูล</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      fab
                      x-small
                      color="primary"
                      @click="resetPassword(item)"
                      :dark="item.user_id !== userInfo.user_id"
                      :disabled="item.user_id == userInfo.user_id"
                    >
                      <v-icon>mdi-lock-reset</v-icon>
                    </v-btn>
                  </template>
                  <span>เปลี่ยนรหัสผ่าน</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      fab
                      x-small
                      color="error"
                      @click="deleteItem(item)"
                      :dark="item.user_id !== userInfo.user_id"
                      :disabled="item.user_id == userInfo.user_id"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                  </template>
                  <span>ลบ</span>
                </v-tooltip>
              </div>
            </template>
          </u-table>
        </template>

        <!-- //# Tab Approve Users -->
        <template v-slot:userApprove>
          <u-table
            :headers="headers"
            :items="items"
            :showExpand="false"
            :mobileBreakpoint="900"
            :offsetHeight="48"
            itemKey="user_id"
            :pagination="pagination"
            @onChangePagination="onChangePagination"
            :loading="loading"
          >
            <template v-slot:header>
              <v-row justify="end" align="center">
                <!-- // # Search Group -->
                <v-col cols="12" md="4">
                  <v-row justify="center" align="center">
                    <v-col
                      ><v-text-field
                        v-model="keyword.statusPending"
                        name="name"
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        placeholder="ค้นหา"
                        label="ค้นหา"
                        hide-details
                      ></v-text-field
                    ></v-col>
                    <v-col cols="4">
                      <v-btn color="primary" block dark @click="init()"
                        >ค้นหา</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.number`]="{ item }">
              {{
                pagination.row * pagination.page -
                pagination.row +
                (items.indexOf(item) + 1)
              }}
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <v-avatar color="primary" size="35">
                <span
                  v-if="item.image === ''"
                  class="white--text text-h7 text-uppercase"
                >
                  {{ item.first_name.charAt(0) }}{{ item.last_name.charAt(0) }}
                </span>
                <v-img v-else :src="profileUrl(item.image)"></v-img>
              </v-avatar>
            </template>

            <template v-slot:[`item.name_title`]="{ item }">
              {{ item.name_title != "" ? item.name_title : "-" }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ item.gender != "" ? item.gender : "-" }}
            </template>
            <template v-slot:[`item.permissions`]="{ item }">
              <v-chip
                outlined
                small
                v-for="t in item.permissions"
                :key="t"
                color="primary"
                class="text-capitalize my-1"
              >
                {{ getPermission(t) }}
              </v-chip>
            </template>
            <template v-slot:[`item.user`]="{ item }">
              {{ returnUser(item) }}
            </template>
            <template v-slot:[`item.methods`]="{ item }">
              <v-chip
                outlined
                small
                v-for="t in item.methods"
                :key="t.id"
                color="primary"
                class="text-capitalize"
              >
                {{ t.provider }}
              </v-chip>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="action-nowrap">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      fab
                      icon
                      x-small
                      dark
                      color="success"
                      @click="approve(item)"
                      ><v-icon>mdi-check</v-icon>
                      <!-- อนุมัติ -->
                    </v-btn>
                  </template>
                  <span>อนุมัติ</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      fab
                      icon
                      x-small
                      dark
                      color="error"
                      @click="approve(item, false)"
                    >
                      <v-icon>mdi-close</v-icon>
                      <!-- ไม่อนุมัติ -->
                    </v-btn>
                  </template>
                  <span>ไม่อนุมัติ</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      fab
                      x-small
                      dark
                      color="error"
                      @click="deleteItem(item)"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                  </template>
                  <span>ลบ</span>
                </v-tooltip>
              </div>
            </template>
          </u-table>
        </template>

        <!-- //# Tab Reject Users -->
        <template v-slot:userReject>
          <u-table
            :headers="headers"
            :items="items"
            :showExpand="false"
            :mobileBreakpoint="900"
            :offsetHeight="48"
            itemKey="user_id"
            :pagination="pagination"
            @onChangePagination="onChangePagination"
            :loading="loading"
          >
            <template v-slot:header>
              <v-row justify="end" align="center">
                <v-col cols="12" md="4">
                  <v-row justify="center" align="center">
                    <!-- // # Search Group -->
                    <v-col
                      ><v-text-field
                        v-model="keyword.statusReject"
                        name="name"
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        placeholder="ค้นหา"
                        label="ค้นหา"
                        hide-details
                      ></v-text-field
                    ></v-col>
                    <v-col cols="4">
                      <v-btn color="primary" block dark @click="init()"
                        >ค้นหา</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.number`]="{ item }">
              {{
                pagination.row * pagination.page -
                pagination.row +
                (items.indexOf(item) + 1)
              }}
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <v-avatar color="primary" size="35">
                <span
                  v-if="item.image === ''"
                  class="white--text text-h7 text-uppercase"
                >
                  {{ item.first_name.charAt(0) }}{{ item.last_name.charAt(0) }}
                </span>
                <v-img v-else :src="profileUrl(item.image)"></v-img>
              </v-avatar>
            </template>

            <template v-slot:[`item.name_title`]="{ item }">
              {{ item.name_title != "" ? item.name_title : "-" }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ item.gender != "" ? item.gender : "-" }}
            </template>
            <template v-slot:[`item.permissions`]="{ item }">
              <v-chip
                outlined
                small
                v-for="t in item.permissions"
                :key="t"
                color="primary"
                class="text-capitalize my-1"
              >
                {{ getPermission(t) }}
              </v-chip>
            </template>
            <template v-slot:[`item.user`]="{ item }">
              {{ returnUser(item) }}
            </template>

            <template v-slot:[`item.methods`]="{ item }">
              <v-chip
                outlined
                small
                v-for="t in item.methods"
                :key="t.id"
                color="primary"
                class="text-capitalize"
              >
                {{ t.provider }}
              </v-chip>
            </template>

            <!-- // # Action Fields -->
            <template v-slot:[`item.actions`]="{ item }">
              <div class="action-nowrap">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      fab
                      x-small
                      dark
                      color="success"
                      @click="restore(item)"
                    >
                      <v-icon>mdi-restore</v-icon>
                    </v-btn>
                  </template>
                  <span>ขออนุมัติอีกครั้ง</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      fab
                      x-small
                      dark
                      color="error"
                      @click="deleteItem(item)"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                  </template>
                  <span>ลบ</span>
                </v-tooltip>
              </div>
            </template>
          </u-table>
        </template>
      </u-tab-items>
    </div>
    <!-- form reset password -->
    <v-dialog v-model="dialogResetpassword" max-width="500">
      <v-card class="pa-5">
        <div class="title-reset">{{ formTitleResetpassword }}</div>
        <v-radio-group v-model="changePassword" row class="mb-n5">
          <v-radio label="กรอกรหัสผ่าน" value="input" class="mb-2"></v-radio>
          <v-radio label="ส่งไปยังอีเมล" value="email" class="mb-2"></v-radio>
        </v-radio-group>
        <v-form ref="formResetpassword" v-model="validResetpassword">
          <div v-if="changePassword == 'input'">
            <div>
              <span class="required">*</span><span>กรุณากรอกรหัสผ่านใหม่</span>
            </div>
            <v-text-field
              required
              v-model="passwordNew.password_New"
              :rules="[Rule.noInPut, Rule.noSpace]"
              :append-icon="passwordNew.show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="passwordNew.show ? 'text' : 'password'"
              @click:append="passwordNew.show = !passwordNew.show"
              outlined
              dense
              placeholder="กรุณากรอกรหัสผ่านใหม่"
            ></v-text-field>
          </div>
          <div
            v-if="changePassword == 'email'"
            class="text-reset-password mb-5"
          >
            <v-icon size="80px" color="primary">mdi-email-fast-outline</v-icon>
            <div>ส่งฟอร์มเปลี่ยนรหัสผ่านไปยัง <br />{{ mailUser }}</div>
          </div>
        </v-form>

        <v-row>
          <v-col>
            <v-btn
              @click="confirmResetPassword()"
              :disabled="!validResetpassword"
              block
              color="primary"
              :loading="loadingSave"
            >
              {{ changePassword == "input" ? "บันทึก" : "ส่ง" }}
            </v-btn>
          </v-col>
          <v-col
            ><v-btn block color="error" @click="closeResetpassword"
              >ยกเลิก</v-btn
            ></v-col
          >
        </v-row>
      </v-card>
    </v-dialog>
    <!-- form reset password -->
    <!-- //#  Dialog Add / Edit User  -->
    <u-card-form
      :title="formTitle"
      :subtitle="formSubTitle"
      ref="form"
      v-model="dialog"
    >
      <template v-slot:form>
        <!-- // # Selector User Mode -->
        <div v-if="editedIndex < 0">
          <div>
            <span class="required">*</span
            ><span>เพิ่ม{{ NameComponent }}โดย</span>
          </div>
          <v-select
            v-model="userLocalMode"
            :items="userModeItems"
            :placeholder="`กรุณาเลือกประเภท${NameComponent}`"
            :rules="[Rule.noInPut]"
            required
            outlined
            dense
          ></v-select>
        </div>

        <!-- // # Selector เลือกโปรเจคจาก Ra-->
        <div v-if="editedIndex < 0 && userLocalMode === 'ra'">
          <div><span class="required">*</span><span>โปรเจค</span></div>
          <v-select
            v-model="bizIdEdit"
            :items="projects"
            item-text="name_th"
            placeholder="กรุณาเลือกโปรเจค"
            :rules="[Rule.noInPut]"
            required
            outlined
            dense
          ></v-select>
        </div>

        <!-- // # Selector เลือกผู้ใช้จาก ra  ของ Project ที่เราเลือก-->
        <div v-if="editedIndex < 0 && userLocalMode === 'ra'">
          <div>
            <span class="required">*</span><span>{{ NameComponent }}</span>
          </div>
          <v-select
            :disabled="bizIdEdit == ''"
            v-model="userIdEdit"
            :items="users"
            item-value="user_id"
            :item-text="(item) => item.first_name + ' ' + item.last_name"
            :placeholder="`กรุณาเลือก${NameComponent}`"
            :rules="[Rule.noInPut]"
            required
            outlined
            dense
          ></v-select>
        </div>
        <div class="d-flex flex-wrap flex-sm-nowrap flex-column flex-sm-row">
          <div
            v-if="editedIndex >= 0 || userLocalMode === 'local'"
            class="pr-sm-1"
          >
            <div><span class="required">*</span><span>เพศ</span></div>
            <v-select
              v-model="editedItem.gender"
              :items="gender"
              item-text="text"
              item-value="val"
              :rules="[Rule.notNull]"
              outlined
              dense
              placeholder="กรุณาเลือกเพศ"
              @change="returnNameTitle(editedItem.gender)"
            ></v-select>
          </div>
          <div
            v-if="editedIndex >= 0 || userLocalMode === 'local'"
            class="pl-sm-1"
          >
            <div><span class="required">*</span><span>คำนำหน้า</span></div>
            <v-select
              v-model="editedItem.name_title"
              :items="nameTitle"
              item-text="text"
              item-value="val"
              :rules="[Rule.notNull]"
              outlined
              dense
              placeholder="กรุณาเลือกคำนำหน้า"
            ></v-select>
          </div>
        </div>
        <!-- // # ชื่อ -->
        <div v-if="editedIndex >= 0 || userLocalMode === 'local'">
          <div><span class="required">*</span><span>ชื่อ</span></div>
          <v-text-field
            required
            v-model="editedItem.first_name"
            :rules="[Rule.noInPut, Rule.noSpace]"
            outlined
            dense
            placeholder="กรุณากรอกชื่อ"
          ></v-text-field>
        </div>

        <!-- //# นามสกุล -->
        <div v-if="editedIndex >= 0 || userLocalMode === 'local'">
          <div><span class="required">*</span><span>นามสกุล</span></div>
          <v-text-field
            required
            v-model="editedItem.last_name"
            :rules="[Rule.noInPut, Rule.noSpace]"
            outlined
            dense
            placeholder="กรุณากรอกนามสกุล"
          ></v-text-field>
        </div>

        <div v-if="editedIndex >= 0 || userLocalMode === 'local'">
          <div><span class="required">*</span><span>วันเกิด</span></div>
          <v-dialog
            ref="dialog"
            v-model="modalDatePicker"
            :return-value.sync="editedItem.date_of_birth"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="returnFormateDate"
                placeholder="กรุณาเลือกวันเกิด"
                :rules="[Rule.noInPut]"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="editedItem.date_of_birth"
              color="primary"
              no-title
              scrollable
              locale="th"
              :max="DateNow"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(editedItem.date_of_birth)"
              >
                ตกลง
              </v-btn>
              <v-btn text color="primary" @click="modalDatePicker = false">
                ยกเลิก
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>

        <!-- // #  หมายเลขโทรศัพท์ -->
        <div v-if="editedIndex >= 0 || userLocalMode === 'local'">
          <div><span class="required">*</span><span>หมายเลขโทรศัพท์</span></div>
          <v-text-field
            required
            v-model="editedItem.phone"
            :rules="[Rule.noInPut, Rule.numberOnly, Rule.phoneNumber]"
            maxlength="10"
            counter
            outlined
            dense
            placeholder="กรุณากรอกหมายเลขโทรศัพท์"
          ></v-text-field>
        </div>

        <!-- // # เพศ -->

        <!-- //# ชื่อผู้ใช้งาน  Username -->
        <div v-if="userLocalMode === 'local'">
          <div>
            <span class="required">*</span
            ><span
              >ชื่อผู้ใช้งาน{{
                editedIndex >= 0 ? "(อีเมล userlocal)" : "(อีเมล)"
              }}</span
            >
          </div>
          <v-text-field
            required
            v-model="editedItem.username"
            :rules="[
              Rule.noInPut,
              Rule.noSpace,
              Rule.englishOnly,
              Rule.emailOnly,
            ]"
            outlined
            dense
            placeholder="กรุณากรอกชื่อผู้ใช้งาน(อีเมล)"
          ></v-text-field>
        </div>

        <!-- // # Permission ที่ใช้งานได้ -->
        <div v-if="userLocalMode === 'local'">
          <div><span>สิทธิการใช้งาน</span></div>
          <v-select
            v-model="editedItem.permissions"
            :items="permissions"
            required
            chips
            placeholder="กรุณาเลือกสิทธิการใช้งาน"
            dense
            multiple
            outlined
            item-text="name"
            item-value="permission_id"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index <= 1">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ editedItem.permissions.length - 1 }} others)
              </span>
            </template>
          </v-select>
        </div>

        <div>
          <div><span>บันทึกรายละเอียด</span></div>
          <v-textarea
            outlined
            v-model="editedItem.description"
            placeholder="รายละเอียด"
            value=""
          ></v-textarea>
        </div>
      </template>

      <template v-slot:actions="{ valid }">
        <v-row>
          <v-col>
            <v-btn
              @click="save"
              :disabled="!valid"
              block
              color="primary"
              :loading="loadingSave"
            >
              บันทึก
            </v-btn>
          </v-col>
          <v-col
            ><v-btn block color="error" @click="close">ยกเลิก</v-btn></v-col
          >
        </v-row>
      </template>
    </u-card-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      DateNow: this.$moment().format('YYYY-MM-DD'),
      userInfo: '',
      NameComponent: 'บัญชีผู้ใช้',
      // TODO Form Add/Edit --------
      userLocalMode: 'local', // สำหรับ mode user ที่จะใช้ Register
      bizIdEdit: '', // สำหรับ เก็บค่า Project ที่เลือกใน dropdown
      userIdEdit: '', // สำหรับ เก็บค่า user_id ที่เลือกใน dropdown
      dialog: false, // สำหรับใช้เปิดปิด dialog add/edit
      userModeItems: [
        // { text: 'Ra', value: 'ra' },
        { text: 'Local', value: 'local' }
      ], // สำหรับ mode ที่สามรถ create user ได้
      modalDatePicker: false,

      userSelected: [], // สำหรับ User ที่ถูก Select ในตราง User
      userApproveSelected: [], // สำหรับ User ที่ถูก Select ในตราง User approve
      editedIndex: -1,
      editedItem: {
        user_id: '',
        first_name: '',
        last_name: '',
        username: '',
        phone: '',
        gender: '',
        name_title: '',
        image: '',
        date_of_birth: '',
        verify: 0,
        street: '',
        zip_code: '',
        city: '',
        country_code: '',
        country: '',
        permissions: [],
        email_verify: '',
        description: ''
      },
      defaultItem: {
        user_id: '',
        first_name: '',
        last_name: '',
        username: '',
        phone: '',
        gender: '',
        name_title: '',
        image: '',
        date_of_birth: '',
        verify: 0,
        street: '',
        zip_code: '',
        city: '',
        country_code: '',
        country: '',
        permissions: [],
        email_verify: '',
        description: ''
      },
      pagination: {
        total: 0,
        page: 1,
        row: 10
      },
      rules: [(v) => !!v || 'กรุณากรอกข้อมูล'],
      permissions: [],
      // TODO Tab ------------------
      tab: '',
      tabItems: [
        {
          text: 'บัญชีผู้ใช้',
          name: 'users',
          verify: 2,
          description: 'คลิกเพื่อดูรายชื่อผู้ใช้งาน'
        },
        {
          text: 'คำขอ',
          name: 'userApprove',
          verify: 0,
          description: 'คลิกเพื่อดูคำขอการใช้งานระบบ'
        },
        {
          text: 'ปฏิเสธ',
          name: 'userReject',
          verify: 1,
          disableTooltip: false,
          description: 'คลิกเพื่อดูผู้ใช้ที่ไม่อนุมัติการใช้งานระบบ'
        }
      ],

      // TODO Table ----------------
      keyword: {
        statusApprove: '',
        statusPending: '',
        statusReject: ''
      },
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number'
        },
        {
          text: 'รูปโปรไฟล์',
          align: 'center',
          sortable: false,
          value: 'image'
        },

        {
          text: 'คำนำหน้า',
          value: 'name_title',
          sortable: false,
          align: 'start'
        },
        {
          text: 'ชื่อ',
          align: 'start',
          sortable: false,
          value: 'first_name'
        },
        { text: 'นามสกุล', value: 'last_name', sortable: false },
        { text: 'เบอร์โทร', value: 'phone', sortable: false, align: 'center' },
        { text: 'เพศ', value: 'gender', sortable: false, align: 'center' },
        {
          text: 'ชื่อผู้ใช้งาน(local)',
          value: 'user',
          sortable: false,
          align: 'center'
        },
        {
          text: 'ผู้ให้บริการ',
          value: 'methods',
          sortable: false,
          align: 'center'
        },
        {
          text: 'สิทธิ',
          value: 'permissions',
          sortable: false,
          align: 'center'
        },
        { text: 'จัดการ', value: 'actions', sortable: false, align: 'center' }
        // { text: '', value: 'data-table-expand' }
      ],

      // TODO Mock -----------------
      items: [],
      loading: false,
      loadingSave: false,
      verify: 2,
      nameTitle: [
        { text: 'นางสาว', val: 'นางสาว' },
        { text: 'นาง', val: 'นาง' },
        { text: 'นาย', val: 'นาย' },
        { text: 'ไม่ระบุ', val: '' }
      ],
      gender: [
        { text: 'หญิง', val: 'หญิง' },
        { text: 'ชาย', val: 'ชาย' },
        { text: 'ไม่ระบุ', val: '' }
      ],
      projects: [
        // mock ra
        {
          biz_id: '1',
          tax_id: '1',
          name_th: 'โปรเจค 1',
          name_en: 'โปรเจค 1'
        },
        {
          biz_id: '2',
          tax_id: '2',
          name_th: 'โปรเจค 2',
          name_en: 'โปรเจค 2'
        }
      ],
      users: [],
      // form reset password
      formTitleResetpassword: 'เปลี่ยนรหัสผ่าน',
      changePassword: 'input',
      dialogResetpassword: false,
      passwordNew: {
        password_New: '',
        show: false
      },
      mailUser: '',
      IDResetpassword: '',
      validResetpassword: false
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? `เพิ่ม${this.NameComponent}`
        : `แก้ไข${this.NameComponent}`
    },
    formSubTitle () {
      return this.editedIndex === -1
        ? `กรอกข้อมูลเพื่อสร้าง${this.NameComponent}ใหม่`
        : `กรอกข้อมูลเพื่อแก้ไข${this.NameComponent}`
    },

    returnFormateDate: {
      set (val) {
        return val
      },
      get () {
        return this.editedItem.date_of_birth !== ''
          ? this.$moment(this.editedItem.date_of_birth)
            .add(543, 'year')
            .format('DD-MM-YYYY')
          : ''
      }
    }
  },
  async created () {
    await this.initUserInfo()
    await this.initPermission()
    await this.init()
  },

  methods: {
    async init () {
      this.items = []
      this.loading = true
      const payload = {
        page: this.pagination.page,
        row: this.pagination.row,
        verify: this.verify
      }
      if (this.verify === 2) {
        payload.keyword = this.keyword.statusApprove
      } else if (this.verify === 0) {
        payload.keyword = this.keyword.statusPending
      } else if (this.verify === 1) {
        payload.keyword = this.keyword.statusReject
      }
      const res = await this.getUserForTable(payload)
      this.loading = false
      if (res.status) {
        // TODO when get data success
        this.items = res.data.data
        this.pagination = res.data.pagination
      } else {
        // TODO when get data fail
        this.antmessage.error(res.message)
      }
    },
    returnNameTitle (gender) {
      this.editedItem.name_title = ''
      if (gender === '') {
        this.nameTitle = [
          { text: 'นางสาว', val: 'นางสาว' },
          { text: 'นาง', val: 'นาง' },
          { text: 'นาย', val: 'นาย' },
          { text: 'ไม่ระบุ', val: '' }
        ]
      } else if (gender === 'ชาย') {
        this.nameTitle = [
          { text: 'นาย', val: 'นาย' },
          { text: 'ไม่ระบุ', val: '' }
        ]
      } else if (gender === 'หญิง') {
        this.nameTitle = [
          { text: 'นางสาว', val: 'นางสาว' },
          { text: 'นาง', val: 'นาง' },
          { text: 'ไม่ระบุ', val: '' }
        ]
      }
    },
    async initPermission () {
      this.loading = true
      const res = await this.getPermissionForDropdown()
      this.loading = false
      if (res.status) {
        // TODO when get data success
        this.permissions = res.data
      } else {
        // TODO when get data fail
        this.antmessage.error(res.message)
      }
    },
    async initUserInfo () {
      this.loading = true
      const res = await this.getUserInfo()
      this.loading = false
      if (res.status) {
        this.userInfo = res.data
      } else {
        this.antmessage.error(res.message)
      }
    },

    settingUserVerify (e) {
      this.verify = e.verify
      this.init()
    },

    onChangePagination (val) {
      this.pagination.page = val.page
      this.pagination.row = val.row
      this.init()
    },

    editItem (item) {
      this.editedIndex = this.items.indexOf(item)

      this.editedItem = Object.assign({}, item)
      const user = this.editedItem.methods.filter(
        (val) => val.provider === 'local'
      )
      this.editedItem.username = user[0].username
      this.dialog = true
    },
    returnUser (item) {
      const user = item.methods.filter((val) => val.provider === 'local')
      return user[0].username
    },
    resetPassword (item) {
      this.changePassword = 'input'
      const user = item.methods.filter((val) => val.provider === 'local')
      this.mailUser = user[0].username
      this.IDResetpassword = item
      this.dialogResetpassword = true
    },
    confirmResetPassword () {
      if (this.changePassword === 'input') {
        this.$swal({
          allowEnterKey: false,
          title: 'เปลี่ยนรหัสผ่าน',
          text: 'คุณต้องการเปลี่ยนรหัสผ่านใช่หรือไม่',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              user_id: this.IDResetpassword.user_id,
              password: this.passwordNew.password_New
            }
            const res = await this.resetPasswordUser(payload)
            this.loadingSave = false
            if (res.status) {
              this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: 'เปลี่ยนรหัสผ่านสำเร็จ',
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.closeResetpassword()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else if (this.changePassword === 'email') {
        this.$swal({
          allowEnterKey: false,
          title: 'ส่งฟอร์มเปลี่ยนรหัสผ่าน',
          text: 'คุณต้องการส่งฟอร์มเปลี่ยนรหัสผ่านไปยังอีเมลผู้ช้งานใช่หรือไม่',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              email: this.mailUser
            }
            const res = await this.sendEmailresetPasswordUser(payload)
            this.loadingSave = false
            if (res.status) {
              this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: 'ส่งฟอร์มเปลี่ยนรหัสผ่าน',
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.mailUser = ''
              this.closeResetpassword()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      }
    },
    closeResetpassword () {
      this.IDResetpassword = ''
      this.$refs.formResetpassword.reset()
      // this.passwordNew.password_New = ''
      this.dialogResetpassword = false
    },
    deleteItem (item) {
      this.$swal({
        allowEnterKey: false,
        title: `ลบ${this.NameComponent}`,
        text: `คุณต้องการลบ${this.NameComponent}นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = { user_id: item.user_id }
          const res = await this.deleteUser(payload)
          this.antmessage.destroy()
          if (res.status) {
            this.init()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `ลบ${this.NameComponent}สำเร็จ`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },

    close () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.userLocalMode = 'local'
        this.bizIdEdit = ''
        this.userIdEdit = ''
      })
    },

    save () {
      if (this.editedIndex > -1) {
        // TODO Update Data
        this.$swal({
          allowEnterKey: false,
          title: `แก้ไข${this.NameComponent}`,
          text: `คุณต้องการแก้ไข${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              user_id: this.editedItem.user_id,
              name_title: this.editedItem.name_title,
              first_name: this.editedItem.first_name,
              last_name: this.editedItem.last_name,
              phone: this.editedItem.phone,
              gender: this.editedItem.gender,
              permissions: this.editedItem.permissions,
              date_of_birth: this.editedItem.date_of_birth,
              street: this.editedItem.street,
              zip_code: this.editedItem.zip_code,
              city: this.editedItem.city,
              country_code: this.editedItem.country_code,
              country: this.editedItem.country,
              time_zone: this.editedItem.time_zone,
              description: this.editedItem.description,
              username: this.editedItem.username
            }
            const res = await this.updateUser(payload)
            this.loadingSave = false
            if (res.status) {
              this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `แก้ไข${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else {
        // TODO Create Data
        this.$swal({
          allowEnterKey: false,
          title: `เพิ่ม${this.NameComponent}`,
          text: `คุณต้องการเพิ่ม${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const res = await this.createUser(this.editedItem)
            this.loadingSave = false
            if (res.status) {
              // TODO when get data success
              this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `เพิ่ม${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.close()
            } else {
              // TODO when get data fail
              this.antmessage.error(res.message)
            }
          }
        })
      }
    },
    approve (item, action = true) {
      this.$swal({
        allowEnterKey: false,
        title: `${action ? '' : 'ไม่'}อนุมัติ${this.NameComponent}`,
        text: `คุณต้องการ${action ? 'อนุมัติ' : 'ไม่อนุมัติ'}${
          this.NameComponent
        }นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = {
            user_id: item.user_id,
            action: action ? 'approve' : 'reject'
          }
          const res = await this.approveUser(payload)
          if (res.status) {
            this.antmessage.destroy()
            this.init()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `${
                action
                  ? `อนุมัติ${this.NameComponent}สำเร็จ`
                  : `ปฏิเสธ${this.NameComponent}สำเร็จ`
              }`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
          } else {
            setTimeout(() => {
              this.antmessage.destroy()
              this.antmessage.error(res.message)
            }, 1000)
          }
        }
      })
    },
    restore (item) {
      this.$swal({
        allowEnterKey: false,
        title: `ขออนุมัติ${this.NameComponent}`,
        text: `คุณต้องการขออนุมัติ${this.NameComponent}นี้อีกครั้งใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = {
            user_id: item.user_id,
            action: 'restore'
          }
          const res = await this.approveUser(payload)
          if (res.status) {
            this.antmessage.destroy()
            this.init()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `ขออนุมัติ${this.NameComponent}นี้อีกครั้งสำเร็จ`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
          } else {
            setTimeout(() => {
              this.antmessage.destroy()
              this.antmessage.error(res.message)
            }, 1000)
          }
        }
      })
    },

    getPermission (permissionId) {
      const x = this.permissions.filter(
        (i) => i.permission_id === permissionId
      )
      if (x.length > 0) return x[0].name
      else return permissionId
    }
  }
}
</script>

<style lang="css" scoped>
.text-reset-password {
  font-size: 20px;
  text-align: center;
}
.title-reset {
  color: var(--v-primary-base);
  font-size: 22px;
}
/* // TODO สำหรับจัดการหน้าเพจทั้งหมด */
.main-layout {
  height: 100%;
}

/* // TODO สำหรับ จัดการ title ของเพจ */
.layout-title {
  height: 58px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* // TODO สำหรับจัดการ Tab ของ Page */
.layout-tab {
  height: 48px;
  overflow: hidden;
}

/* // TODO สำหรับจัดการ Content ของเพจ */
.layout-content {
  height: calc(100% - 58px - 48px);
  padding: 0;
  margin: 0;
}

.header-custom-table .header-custom-search {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.header-custom-table .header-custom-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
  min-height: 70px;
}
</style>
