<template>
  <div>
    <!-- Min : {{ min }}|Max : {{ max }} -->
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateFormatted"
          :placeholder="placeholder"
          persistent-hint
          v-bind="attrs"
          v-on="on"
          outlined
          dense
          :rules="rules"
          :label="label"
          readonly
        ></v-text-field>
      </template>
      <v-card>
        <div class="content">
          <div class="content-body">
            <div class="date-layout">
              <v-date-picker
                color="primary"
                v-model="date"
                no-title
                scrollable
                :max="max"
                :min="min"
                locale="th"
                :show-current="true"
              ></v-date-picker>
            </div>
            <div class="time-layout" v-for="item of listTimes" :key="item.name">
              <div class="time-title">
                <span>{{ item.name }}</span>
              </div>
              <div class="time-body" :id="`${id}-time-${item.name}-parent`">
                <!-- <div
                  v-for="(t, idx) of item.lenght"
                  :key="idx"
                  :id="`${id}-time-${item.name}-children-${idx}`"
                  :class="[
                    'item',
                    // times[item.name] == idx ? 'active' : 'inactive',
                    'disable'
                  ]"
                  @click="updateTime(item.name, idx)"
                >
                  <span>{{ String(idx).padStart(2, "0") }}</span>
                </div> -->
                <v-btn
                  :class="[
                    'item',
                    times[item.name] == idx ? 'active' : 'inactive',
                  ]"
                  v-for="(t, idx) of item.lenght"
                  @click="updateTime(item.name, idx)"
                  :key="idx"
                  icon
                  :id="`${id}-time-${item.name}-children-${idx}`"
                  :disabled="retrunDisableTime(item.name, idx)"
                >
                  {{ String(idx).padStart(2, "0") }}
                </v-btn>
              </div>
            </div>
          </div>
          <div class="content-action">
            <v-row>
              <v-col>
                <v-btn color="primary" block small @click="setNow()"
                  >ปัจจุบัน</v-btn
                >
              </v-col>
              <!-- <v-col>
              <v-btn color="primary" block small @click="menu = false"
                >ตกลง</v-btn
              >
            </v-col> -->
            </v-row>
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      id: this._uid,
      // Real Format Date
      // date: this.$moment().format(this.format),
      // Show Format Date
      dateFormatted: this.$moment().format(this.format),

      dateModel: this.$moment().format(this.format),
      timeModel: this.$moment().format(this.format),

      menu: false,
      listTimes: [
        { name: 'H', lenght: 24 },
        { name: 'M', lenght: 60 },
        { name: 'S', lenght: 60 }
      ],
      times: {
        H: 0,
        M: 0,
        S: 0
      }
    }
  },

  props: {
    inputFormat: {
      // TODO เป็น Format ที่ใช้ในการ Show ใน Text Field
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss'
    },
    showFormat: {
      // TODO Format ของ Date เป็น Srtring
      // TODO โดยจะเป็น format ของ Input และ Output
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss'
    },
    value: {
      type: String,
      default: new Date().toISOString()
    },
    placeholder: {
      type: String,
      default: ''
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    isBE: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },

  created () {
    // TODO เป็นการ initial ค่าเริ่มต้อนให้เป็น format date
    this.dateFormatted = this.formatDate(this.value)
  },

  watch: {
    value: {
      handler (n) {
        // TODO เมื่อ Value ภายนอกถูกเปลี่ยนแปลงไปจาก State ใน Component
        this.dateFormatted = this.formatDate(n)
        const t = this.$moment(n)
        this.times = {
          H: t.hour(),
          M: t.minute(),
          S: t.second()
        }
      },
      deep: true,
      immediate: true
    },
    menu (n) {
      if (n) {
        setTimeout(() => {
          for (const [key, value] of Object.entries(this.times)) {
            const list = document.getElementById(
              `${this.id}-time-${key}-parent`
            )
            const targetLi = document.getElementById(
              `${this.id}-time-${key}-children-${value}`
            ) // id tag of the <li> element
            list.scrollTop = targetLi.offsetTop - 57
          }
        }, 250)
      }
    },
    time (val) {
      const day = this.date
      const time = this.times
      const MaxP1S = this.$moment(this.max).add(1, 'seconds')
      const MaxDayP1S = MaxP1S.format('YYYY-MM-DD')
      const MinM1S = this.$moment(this.min).subtract(1, 'seconds')
      const MinDayM1S = MinM1S.format('YYYY-MM-DD')

      if (day === MaxDayP1S) {
        const maxDay = this.$moment(this.max)
        const maxH = maxDay.hour()
        const maxM = maxDay.minute()
        const maxS = maxDay.second()
        if (time.H > maxH) {
          this.updateTime('H', maxH)
        } else if (time.H === maxH && time.M > maxM) {
          this.updateTime('M', maxM)
        } else if (time.H === maxH && time.M === maxM && time.S > maxS) {
          this.updateTime('S', maxS)
        }
      }
      if (day === MinDayM1S) {
        const minDay = this.$moment(this.min)
        const minH = minDay.hour()
        const minM = minDay.minute()
        const minS = minDay.second()
        if (time.H < minH) {
          this.updateTime('H', minH)
        } else if (time.H === minH && time.M < minM) {
          this.updateTime('M', minM)
        } else if (time.H === minH && time.M === minM && time.S < minS) {
          this.updateTime('S', minS)
        }
      }
    }
  },

  computed: {
    date: {
      set (value) {
        // TODO เมื่อ datepicker มีการเปลี่ยนแปลง
        this.$emit('input', this.paserOutput(value, this.time))
        return value
      },
      get () {
        // TODO เมื่อต้องการค่า date
        return this.parseDate(this.value)
      }
    },
    time: {
      set (value) {
        // TODO เมื่อ Timepicker มีการเปลี่ยนแปลง
        this.$emit('input', this.paserOutput(this.date, value))
        return value
      },
      get () {
        // TODO เมื่อต้องการค่า time
        return this.paserTime(this.value)
      }
    }
  },

  // const x = {
  //         H: t.hour(),
  //         M: t.minute(),
  //         S: t.second()
  //       }
  //       this.times = x
  methods: {
    setNow () {
      const date = this.$moment().format('YYYY-MM-DD')
      const time = this.$moment().format('HH:mm:ss')
      const t = this.$moment(`${date} ${time}`)
      const H = t.hour()
      const M = t.minute()
      const S = t.second()

      // --> scrollTop H
      const listH = document.getElementById(`${this.id}-time-H-parent`)
      const targetLiH = document.getElementById(
        `${this.id}-time-H-children-${H}`
      ) // id tag of the <li> element
      listH.scrollTop = targetLiH.offsetTop - 57
      // --> scrollTop M
      const listM = document.getElementById(`${this.id}-time-M-parent`)
      const targetLiM = document.getElementById(
        `${this.id}-time-M-children-${M}`
      ) // id tag of the <li> element
      listM.scrollTop = targetLiM.offsetTop - 57
      // --> scrollTop S
      const listS = document.getElementById(`${this.id}-time-S-parent`)
      const targetLiS = document.getElementById(
        `${this.id}-time-S-children-${S}`
      ) // id tag of the <li> element
      listS.scrollTop = targetLiS.offsetTop - 57

      this.$emit('input', this.paserOutput(date, time))
    },
    retrunDisableTime (unit, val) {
      let disable = false
      const day = this.date
      const time = this.times
      const MaxP1S = this.$moment(this.max).add(1, 'seconds')
      const MaxDayP1S = MaxP1S.format('YYYY-MM-DD')
      const MinM1S = this.$moment(this.min).subtract(1, 'seconds')
      const MinDayM1S = MinM1S.format('YYYY-MM-DD')
      // console.log('disable', disable)
      // console.log('day', day)
      // console.log('time', time)
      // console.log('min', this.min)
      // console.log('max', this.max)

      if (day === MaxDayP1S) {
        const maxDay = this.$moment(this.max)
        const maxH = maxDay.hour()
        const maxM = maxDay.minute()
        const maxS = maxDay.second()
        // console.log('shoot-maxday')
        // console.log('MaxDayP1S', MaxDayP1S)
        // console.log('maxH', maxH)
        // console.log('maxM', maxM)
        // console.log('maxS', maxS)
        if (unit === 'H' && val > maxH) {
          disable = true
        }
        if (unit === 'M' && time.H === maxH && val > maxM) {
          disable = true
        }
        if (unit === 'S' && time.H === maxH && time.M === maxM && val > maxS) {
          disable = true
        }
      }
      if (day === MinDayM1S) {
        const minDay = this.$moment(this.min)
        const minH = minDay.hour()
        const minM = minDay.minute()
        const minS = minDay.second()
        // console.log('shoot-minday')
        // console.log('MinDayM1S', MinDayM1S)
        // console.log('minH', minH)
        // console.log('minM', minM)
        // console.log('minS', minS)
        if (unit === 'H' && val < minH) {
          disable = true
        }
        if (unit === 'M' && time.H === minH && val < minM) {
          disable = true
        }
        if (unit === 'S' && time.H === minH && time.M === minM && val < minS) {
          disable = true
        }
      }

      return disable
    },
    formatDate (date) {
      // NOTE สำหรับ format ข้อมูล Text Field ที่ใช้ในการ Show ข้อมูล
      if (!date) return null
      else {
        const t = this.$moment(date)
        // .set('hour', this.times.H)
        // .set('minute', this.times.M)
        if (this.isBE) {
          return t.add(543, 'year').format(this.showFormat)
        } else {
          return t.format(this.showFormat)
        }
      }
    },
    parseDate (date) {
      // NOTE สำหรับส่งออกไปสู่ภายนอก
      if (!date) return null
      return this.$moment(date).format('YYYY-MM-DD')
    },

    paserTime (time) {
      if (!time) return null
      return this.$moment(time).format('HH:mm:ss')
    },

    paserOutput (date, time) {
      return this.$moment(`${date} ${time}`).format(this.inputFormat)
    },

    updateTime (name, val) {
      // TODO เป็น Function ในการ UPDATE TIME โดยเมื่อมีการ Click Time ที่ต้องการแล้ว
      // TODO จะทำการ Emit Time ไปยังภายนอก Componnet และ Scroll Element ที่เลือก
      // TODO ไปยัง Top Element ของ Parent ที่ Overflow ออกมา
      // REF https://stackoverflow.com/questions/12864901/how-to-scroll-to-li-element-in-a-ul-list
      const list = document.getElementById(`${this.id}-time-${name}-parent`)
      const targetLi = document.getElementById(
        `${this.id}-time-${name}-children-${val}`
      ) // id tag of the <li> element
      list.scrollTop = targetLi.offsetTop - 57

      this.times[name] = val

      let t = `${String(this.times.H).padStart(2, '0')}`
      t += `:${String(this.times.M).padStart(2, '0')}`
      t += `:${String(this.times.S).padStart(2, '0')}`
      this.time = t
    }
  }
}
</script>

<style lang="scss" scoped>
.v-menu__content.menuable__content__active {
  min-width: auto !important;
}

.content {
  height: 348px;
  padding: 10px;
  // background-color: lightseagreen;
  // background-color: white;
}
.content .content-body {
  // background-color: blue;
  background-color: none;
  height: 100%;
  width: 100%;
  display: flex;
  height: calc(100% - 38px);
}

.content .content-action {
  height: 38px;
  padding-top: 5px;
  padding-bottom: 5px;
  // background-color: lightsalmon;
  background-color: none;
}
.date-layout {
  width: 290px;
}
.time-layout {
  // background-color: red;
  height: 100%;
  min-width: 34px;
  padding: 0;
  margin: 0;
  display: block;
  padding-left: 1px;
  padding-right: 1px;
}

.time-layout .time-title {
  height: 46px;
  // background-color: yellow;
  background-color: none;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.time-layout .time-body {
  max-height: calc(100% - 46px);
  // background-color: blueviolet;
  background-color: none;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  // padding: 1px;
  font-size: 12px !important;
  font-weight: 500;
  scroll-behavior: smooth;
}
::v-deep .time-layout .time-body .v-btn .v-btn__content {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.time-layout .time-body::-webkit-scrollbar {
  display: none;
}

.item {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.item.active {
  background-color: var(--v-primary-base) !important;
  border: thin solid var(--v-primary-base);
  color: #fff;
  border-radius: 50%;
}

.item.disable {
  background-color: inherit !important;
  // border: thin solid var(--v-primary-base);
  color: rgba(255, 255, 255, 0.3) !important;
  // border-radius: 50%;
  cursor: default;
}

::v-deep .v-picker__body.theme--dark {
  background-color: #1e1e1e;
}
</style>
