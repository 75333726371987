<template>
  <v-card outlined elevation="0">
    <v-card-text>
      <canvas :id="name" class="chart-js" :aria-label="name" role="img">
      </canvas>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Untitled'
    },
    labels: {
      type: Array,
      default: () => []
    },
    datasets: {
      type: Array,
      default: () => []
    }
    // name: {
    //   type: String,
    //   default: () => {
    //     return `ukrit-${Date.now()}`
    //   }
    // }
  },
  data () {
    return {
      chart: null,
      data: {
        labels: [],
        datasets: []
      },
      options: {
        borderColor: '#fff',
        borderWidth: 0,
        // rotation: 270,
        // circumference: 180,
        spacing: 0.5,

        plugins: {
          legend: {
            labels: {
              padding: 20,
              font: {
                size: 10,
                family: "'Kanit', sans-serif",
                weight: '200'
              },
              color: this.$vuetify.theme.currentTheme.tprimary
            },
            display: true,
            position: 'bottom',
            align: 'center'
          },
          title: {
            display: true,
            text: this.title,
            position: 'top',
            align: 'start',
            padding: {
              top: 0,
              bottom: 15
            },
            color: this.$vuetify.theme.currentTheme.primary,
            font: {
              size: 24,
              family: "'Kanit', sans-serif",
              weight: '300'
            }
          }
        }
      }
    }
  },
  watch: {
    '$vuetify.theme.dark' () {
      this.UpdateOptionsTheme()
    }
  },
  computed: {
    name () {
      return `ukrit-${this.generateUUID()}`
    }
  },
  methods: {
    UpdateOptionsTheme () {
      const color = this.$vuetify.theme.currentTheme.tprimary
      //   const color = '#757575'

      //   Edit Options Here -------------
      // Font
      this.options.plugins.legend.labels.color = color
      //   Edit Options Here -------------

      this.chart.options = this.options
      this.chart.update()
    },
    Update () {
      this.chart.update()
    }
  },
  mounted () {
    const options = this.options
    const name = this.name
    // Mapping Data ---------------
    this.data.labels = this.labels
    this.data.datasets = this.datasets

    const data = this.data
    const ctx = document.getElementById(name).getContext('2d')
    this.chart = new this.Chart(ctx, { type: 'doughnut', data, options })
  }
}
</script>

<style lang="css" scoped>
.v-card {
  height: 100%;
}
</style>
