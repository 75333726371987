<template>
  <div>
    <h1 class="title-header-text">
      {{ title }}{{ detail == "" ? "" : ` / ${detail}` }}
    </h1>
    <hr class="title-header-line" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Untitled'
    },
    detail: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="css" scoped>
::v-deep .title-header-text {
  color: var(--v-primary-base);
  font-weight: 300;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.title-header-line {
  border-top: 1px solid var(--v-primary-base);
}
</style>
