<template>
  <div class="main-layout">
    <div class="layout-content">
      <u-table
        :headers="headers"
        :items="items"
        :mobileBreakpoint="900"
        :offsetHeight="48"
        :showSelect="false"
        itemKey="menu_id"
        :loading="loading"
        :pagination="pagination"
        @onChangePagination="onChangePagination"
      >
        <template v-slot:header>
          <v-row
            :justify="priorityUser === 0 ? 'space-between' : 'end'"
            align="center"
            class="header-custom-table"
          >
            <!-- // # Action Group -->
            <v-col cols="12" md="4" v-if="priorityUser === 0">
              <v-row justify="start" align="center">
                <v-col cols="12" md="6">
                  <v-btn
                    color="primary"
                    block
                    dark
                    @click="(dialog = !dialog), getProjectMenu()"
                  >
                    <v-icon>mdi-home-plus</v-icon>เพิ่ม{{ NameComponent }}
                  </v-btn></v-col
                >
              </v-row>
            </v-col>
            <!-- // # Search Group -->
            <v-col cols="12" md="4">
              <v-row justify="center" align="center">
                <v-col
                  ><v-text-field
                    v-model="keyword"
                    id="id"
                    name="name"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    placeholder="ค้นหา"
                    label="ค้นหา"
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col cols="4">
                  <v-btn color="primary" block dark @click="init()"
                    >ค้นหา</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.number`]="{ item }">
          {{
            pagination.row * pagination.page -
            pagination.row +
            (items.indexOf(item) + 1)
          }}
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <span class="fix-overflow-ellipsis">
            {{
              item.description !== "" && item.description !== null
                ? item.description
                : "-"
            }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="action-nowrap">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  color="primary"
                  @click="getProjectMenu(), editItem(item)"
                  :disabled="
                    item.create_by_system === false && item.type != 'none'
                      ? false
                      : true
                  "
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>แก้ไขข้อมูล</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  color="error"
                  @click="deleteItem(item)"
                  :disabled="item.create_by_system === true ? true : false"
                >
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </template>
              <span>ลบ</span>
            </v-tooltip>
          </div>
        </template>
      </u-table>
    </div>

    <u-card-form
      :title="formTitle"
      :subtitle="formSubTitle"
      ref="form"
      v-model="dialog"
    >
      <template v-slot:form>
        <v-row no-gutters>
          <v-col cols="12">
            <u-selector
              :title="NameComponent"
              v-model="editedItem.type"
              :rules="[Rule.noInPut]"
              :items="menu"
              item-value="type"
              item-text="text"
              :placeholder="`กรุณาเลือก${NameComponent}`"
              required
            >
              <!-- :disabled="editedIndex != -1" -->
            </u-selector>
          </v-col>

          <!-- TODO NONE -->
          <v-col cols="12" v-if="editedItem.type == 'none'">
            <u-text-field
              title="เส้นทางเมนู"
              required
              v-model="editedItem.name"
              :rules="[Rule.noInPut, Rule.noSpace, Rule.englishOnly]"
              placeholder="กรุณากรอกเส้นทางเมนู"
            >
            </u-text-field>
          </v-col>

          <v-col cols="12" v-if="editedItem.type != 'none'">
            <u-text-field
              title="ชื่อเมนู"
              required
              v-model="editedItem.name"
              :rules="[Rule.noInPut, Rule.noSpace]"
              placeholder="กรุณากรอกชื่อเมนู"
            >
            </u-text-field>
          </v-col>
          <v-col cols="12" v-if="editedItem.type != 'none'">
            <u-text-field
              title="ชื่อที่แสดง"
              required
              v-model="editedItem.text"
              :rules="[Rule.noInPut, Rule.noSpace]"
              placeholder="กรุณากรอกชื่อที่แสดง"
            >
            </u-text-field>
          </v-col>

          <!-- TODO IFRAME  -->
          <v-col cols="12" v-if="editedItem.type == 'iframe'">
            <u-text-field
              title="URL"
              required
              v-model="editedItem.url"
              :rules="[Rule.noInPut, Rule.noSpace]"
              placeholder="กรุณากรอกURL"
            >
            </u-text-field>
          </v-col>

          <!-- TODO DATASOURCE -->
          <v-col cols="12" v-if="editedItem.type == 'datasource'">
            <u-selector
              v-model="editedItem.datasource_id"
              title="Datasource"
              :rules="[Rule.noInPut]"
              :items="datasourceItems"
              item-text="name"
              item-value="datasource_id"
              :placeholder="`กรุณาเลือก${NameComponent}`"
              required
              @change="getAllIndex"
            ></u-selector>
          </v-col>

          <v-col cols="12" v-if="editedItem.type == 'datasource'">
            <u-text-field
              v-model="editedItem.datasource_index"
              title="Datasource index"
              :items="indices"
              item-text="index"
              item-value="index"
              :placeholder="`กรุณาเลือก index`"
              required
            ></u-text-field>
          </v-col>

          <!-- TODO ALL TYPE -->
          <v-col cols="12">
            <u-text-area
              title="รายละเอียด"
              placeholder="รายละเอียด"
              v-model="editedItem.description"
            ></u-text-area>
          </v-col>
        </v-row>
        <!-- <div v-if="editedIndex === -1">
          <span class="required">*</span><span>{{ NameComponent }}</span>
        </div>
        <v-select
          v-if="editedIndex === -1"
          v-model="selectMenu"
          :rules="[Rule.noInPut]"
          :items="menu"
          item-text="text"
          :placeholder="`กรุณาเลือก${NameComponent}`"
          @change="returnMenutext(selectMenu)"
          return-object
          dense
          outlined
        >
        </v-select> -->

        <!-- <div v-if="editedItem.is_iframe">
          <span class="required">*</span><span>ชื่อเมนู</span>
        </div>
        <v-text-field
          v-if="editedItem.is_iframe"
          v-model="editedItem.text"
          :rules="[Rule.noInPut, Rule.noSpace]"
          placeholder="กรุณากรอกชื่อเมนู"
          dense
          outlined
        ></v-text-field> -->

        <!-- <div v-if="editedItem.is_iframe">
          <span class="required">*</span><span>เส้นทางเมนู</span>
        </div>
        <v-text-field
          v-if="editedItem.is_iframe"
          v-model="editedItem.name"
          :rules="[Rule.noInPut, Rule.noSpace, Rule.englishOnly]"
          placeholder="กรุณากรอกเส้นทางเมนู"
          dense
          outlined
        ></v-text-field> -->
        <!-- <div v-if="editedItem.is_iframe">
          <span class="required">*</span><span>URL</span>
        </div>
        <v-text-field
          v-if="editedItem.is_iframe"
          v-model="editedItem.url"
          :rules="[Rule.noInPut, Rule.noSpace]"
          placeholder="กรุณากรอกURL"
          dense
          outlined
        ></v-text-field> -->
        <!-- <div>
          <span v-if="editedItem.is_iframe" class="required">*</span
          ><span>รายละเอียด</span>
        </div>
        <v-textarea
          v-model="editedItem.description"
          :rules="[Rule.noInPut]"
          placeholder="รายละเอียด"
          outlined
          :disabled="!editedItem.is_iframe"
        ></v-textarea> -->
      </template>
      <template v-slot:actions="{ valid }">
        <v-row>
          <v-col>
            <v-btn
              @click="save"
              :disabled="!valid"
              block
              color="primary"
              :loading="loadingSave"
            >
              บันทึก
            </v-btn>
          </v-col>
          <v-col
            ><v-btn block color="error" @click="close">ยกเลิก</v-btn></v-col
          >
        </v-row>
      </template>
    </u-card-form>
  </div>
</template>

<script>
export default {
  props: {
    priorityUser: {
      type: Number,
      default: null
    },
    project_id: {
      type: String
    },
    menuSystem: {
      type: Array
    }
  },
  data () {
    return {
      indices: [],
      menu: [],
      NameComponent: 'เมนู',
      dialog: false, // สำหรับใช้เปิดปิด dialog add/edit
      editedIndex: -1,
      selectMenu: {},
      editedItem: {
        text: '',
        name: '',
        description: '',
        is_iframe: false,
        type: '',
        url: '',
        layouts: [],
        datasource_id: '',
        datasource_index: ''
      },
      defaultItem: {
        text: '',
        name: '',
        description: '',
        is_iframe: false,
        type: '',
        url: '',
        layouts: [],
        datasource_id: '',
        datasource_index: ''
      },
      keyword: '',
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number'
        },
        {
          text: 'ชื่อที่แสดง',
          align: 'start',
          value: 'text',
          sortable: false
        },
        {
          text: 'เส้นทางเมนู',
          value: 'name',
          align: 'start',
          sortable: false
        },
        {
          text: 'รายละเอียด',
          value: 'description',
          align: 'start',
          sortable: false
        },
        { text: 'จัดการ', value: 'actions', sortable: false, align: 'center' }
      ],
      items: [],
      loading: false,
      loadingSave: false,
      pagination: {
        total: 0,
        page: 1,
        row: 10
      },
      datasourceItems: []
    }
  },
  created () {
    this.init()
    this.initDatasource()
    this.setHeaderByPriority()
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? `เพิ่ม${this.NameComponent}`
        : `แก้ไข${this.NameComponent}`
    },
    formSubTitle () {
      return this.editedIndex === -1
        ? `กรอกข้อมูลเพื่อสร้าง${this.NameComponent}`
        : `กรอกข้อมูลเพื่อแก้ไข${this.NameComponent}`
    }
  },

  methods: {
    async getAllIndex () {
      this.indices = await this.getAllIndexsInDatasource(
        this.editedItem.datasource_id
      )
    },
    setHeaderByPriority () {
      // TODO สำหรับตรวจสอบว่าเป็น Super Admin หรือไม่หากเป็น Super Admin
      // TODO แล้วจึงจะสามารถ ใช้งาน Action ได้
      if (this.priorityUser !== 0) {
        this.headers = [
          {
            text: 'ชื่อที่แสดง',
            align: 'start',
            value: 'text',
            sortable: false
          },
          {
            text: 'เส้นทางเมนู',
            value: 'name',
            align: 'start',
            sortable: false
          },
          {
            text: 'รายละเอียด',
            value: 'description',
            align: 'start',
            sortable: false
          }
        ]
      }
    },

    setMenuitem (items) {
      const itemMenu = this.menuSystem.slice()
      for (let index = 0; index < items.length; index++) {
        for (let index1 = 0; index1 < itemMenu.length; index1++) {
          if (
            items[index].name === itemMenu[index1].name &&
            items[index].type !== 'alert'
          ) {
            itemMenu.splice(index1, 1)
          }
        }
      }
      this.menu = itemMenu
    },

    async getProjectMenu () {
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการทำ select
      const payload = {
        page: '',
        row: '',
        project_id: this.project_id,
        keyword: ''
      }
      const res = await this.getProjectMenuForTable(payload)
      this.loading = false
      if (res.status) {
        const itemsMenu = res.data.data
        this.setMenuitem(itemsMenu)
      } else {
        this.antmessage.error(res.message)
      }
    },

    async init () {
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        page: this.pagination.page,
        row: this.pagination.row,
        project_id: this.project_id,
        keyword: this.keyword
      }
      const res = await this.getProjectMenuForTable(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination = res.data.pagination
      } else {
        this.antmessage.error(res.message)
      }
    },

    async initDatasource () {
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        page: 1,
        row: 99,
        keyword: ''
      }
      const res = await this.getDataSourceForTable(payload)
      this.loading = false
      if (res.status) {
        this.datasourceItems = res.data.data
      } else {
        this.antmessage.error(res.message)
      }
    },

    onChangePagination (val) {
      this.pagination.page = val.page
      this.pagination.row = val.row
      this.init()
    },
    // returnMenutext (item) {
    //   const itemEdie = Object.assign({}, item)
    //   delete itemEdie.text
    //   this.editedItem = Object.assign({}, itemEdie)
    //   this.$refs.form.resetValidation()
    // },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      this.$swal({
        allowEnterKey: false,
        title: `ลบ${this.NameComponent}`,
        text: `คุณต้องการลบ${this.NameComponent}นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = {
            project_id: this.project_id,
            menu_id: item.menu_id
          }
          const res = await this.deleteProjectMenu(payload)
          this.antmessage.destroy()
          if (res.status) {
            this.init()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `ลบ${this.NameComponent}สำเร็จ`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
            this.$emit('changeMenu')
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },
    save () {
      if (this.editedIndex > -1) {
        // TODO Update Data
        this.$swal({
          allowEnterKey: false,
          title: `แก้ไข${this.NameComponent}`,
          text: `คุณต้องการแก้ไข${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              ...this.editedItem,
              ...{
                project_id: this.project_id
              }
            }
            const res = await this.updateProjectMenu(payload)
            this.loadingSave = false
            if (res.status) {
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `แก้ไข${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.init()
              this.close()
              this.$emit('changeMenu')
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else {
        // TODO Create Data
        this.$swal({
          allowEnterKey: false,
          title: `เพิ่ม${this.NameComponent}`,
          text: `คุณต้องการเพิ่ม${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Edit Calling Here -------------------
            this.loadingSave = true
            const payload = {
              ...this.editedItem,
              ...{
                project_id: this.project_id
              }
            }
            console.log(payload)
            const res = await this.createProjectMenu(payload)
            this.loadingSave = false
            if (res.status) {
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `เพิ่ม${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.init()
              this.close()
              this.$emit('changeMenu')
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      }
    },
    close () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.editedItem = Object.assign({}, this.defaultItem)
      this.selectMenu = {}
      this.editedIndex = -1
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
/* สำหรับจัดการหน้าเพจทั้งหมด */
.main-layout {
  height: 100%;
}
/* สำหรับจัดการ Content ของเพจ */
.layout-content {
  height: 100%;
  padding: 0;
  margin: 0;
}
.header-custom-table .header-custom-search {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.header-custom-table .header-custom-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
  min-height: 70px;
}
</style>
