var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-layout"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"item item-left"},[_c('div',{staticClass:"logo"},[_c('img',{staticClass:"logo-img",attrs:{"src":_vm.logoImage,"alt":"ภาพโลโก้"}})]),_c('div',{staticClass:"description"},[_vm._m(0),_c('div',{staticClass:"contact"},[_c('div',{staticClass:"description-title"},[_vm._v("ติดต่อเรา")]),_vm._m(1),_c('div',{staticClass:"contact-item contact-calling"},[_c('div',{staticClass:"icon"},[_c('v-icon',{attrs:{"color":"#d60304","size":"30"}},[_vm._v("mdi-phone-outline")])],1),_c('div',{staticClass:"text"},[_vm._v("02-257-7111")])]),_c('div',{staticClass:"contact-item contact-email"},[_c('div',{staticClass:"icon"},[_c('v-icon',{attrs:{"color":"#d60304","size":"30"}},[_vm._v("mdi-email-open-outline")])],1),_vm._m(2)])])])]),_c('div',{staticClass:"item item-right"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"form-login"},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"custom-text-field",attrs:{"color":"primary","placeholder":"ผู้ใช้งาน","dense":"","outlined":"","rounded":"","rules":[
                  _vm.Rule.noInPut,
                  _vm.Rule.noSpace,
                  _vm.Rule.englishOnly,
                  _vm.Rule.emailOnly,
                ]},model:{value:(_vm.signInData.username),callback:function ($$v) {_vm.$set(_vm.signInData, "username", $$v)},expression:"signInData.username"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"custom-text-field",attrs:{"color":"primary","placeholder":"รหัสผ่าน","dense":"","outlined":"","rounded":"","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"rules":[_vm.Rule.noInPut, _vm.Rule.noSpace, _vm.Rule.noSpace]},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.signInData.password),callback:function ($$v) {_vm.$set(_vm.signInData, "password", $$v)},expression:"signInData.password"}})],1),_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"end"},attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"text":"","rounded":""},on:{"click":function($event){return _vm.$emit('click:forget')}}},[_vm._v(" ลืมรหัสผ่าน ? ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"my-3 sign-in-btn",attrs:{"disabled":!_vm.valid,"type":"submit","color":"#595959","loading":_vm.loading,"block":"","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('click:login', {
                    username: _vm.signInData.username,
                    password: _vm.signInData.password,
                  })}}},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v("เข้าสู่ระบบ")])])],1)],1),_c('div',{staticClass:"oauth-sign-in pt-6"},[_c('div',[_c('OauthBtn',{attrs:{"block":"","rounded":"","color":"primary","image":require('@/assets/images/oneid.png'),"text":"OneID"},on:{"click":function($event){return _vm.$emit('click:oneid')}}}),_c('OauthBtn',{staticClass:"mt-2",attrs:{"block":"","rounded":"","color":"primary","image":require('@/assets/images/logo-provider-id.png'),"text":"ProviderID"},on:{"click":function($event){return _vm.$emit('click:providerid')}}})],1)])],1)],1),_c('div',{staticClass:"version-text"},[_c('span',[_vm._v("version  "+_vm._s(_vm.version))])])])]),_c('div',{staticClass:"triangle-up"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('div',{staticClass:"description-title"},[_vm._v("เกี่ยวกับ")]),_c('div',{staticClass:"description-detail"},[_vm._v(" Centralized Log Management "),_c('br'),_vm._v(" บริการจัดเก็บข้อมูลจราจรทางคอมพิวเตอร์ที่สอดคล้องตาม พ.ร.บ ว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์ 2560 และได้ผ่านการรับรองตามมาตรฐาน NTS 4003.1 - 2560 จาก NECTEC ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description-detail"},[_vm._v(" บริษัท วัน ออเทน จำกัด "),_c('br'),_vm._v(" 1768 อาคารไทยซัมมิท ทาวเวอร์ ชั้นที่ 16 ถนนเพชรบุรีตัดใหม่ แขวงบางกะปิ เขตห้วยขวาง กรุงเทพฯ 10310 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_vm._v("noc@inet.co.th"),_c('br'),_vm._v("team@oneauthen.in.th")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title overflow-elips"},[_c('span',{staticClass:"title-text"},[_vm._v("เข้าสู่ระบบ")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-description"},[_c('span',[_vm._v("เข้าสู่ระบบด้วยบัญชีผู้ใช้งานของคุณ")])])
}]

export { render, staticRenderFns }