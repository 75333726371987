<template>
  <canvas :id="myID" class="mychart"></canvas>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    indexAxis: {
      type: String,
      default: 'x'
    },
    labels: {
      type: Array,
      default: () => []
    },
    datasets: {
      type: Array,
      default: () => []
    },
    dataField: {
      type: String,
      default: ''
    }
  },
  watch: {
    // TODO เมื่อมีการ Update Labels จะ Rerender Chart
    labels: {
      handler: function () {
        this.chart.data.labels = this.labels
        this.chart.update()
      },
      deep: true
    },
    // TODO เมื่อมีการ Update Datasets จะ Rerender Chart
    datasets: {
      handler: function () {
        this.chart.data.datasets = this.datasets
        this.chart.update()
      },
      deep: true
    }
  },
  data () {
    return {
      myID: 'my-id',
      chart: null,

      // TODO Config Chart.js
      DefaultOptions: {
        layout: {
          padding: 25
        },
        indexAxis: this.indexAxis,
        plugins: {
          legend: { display: true, position: 'bottom' },
          tooltip: {
            position: 'custom'
          }
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
            grid: {
              display: false
            }
          },
          x: {
            display: false,
            grid: {
              display: false
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
        // aspectRatio: 1 | 1
      },
      // สำหรับ เก็บค่าที่ click ที่ pie chart
      // labelPie: '',
      // labelPieValue: ''
      inputValue: ''

    }
  },
  created () {
    // TODO Create Unique id of component chart
    this.myID = 'my-id-' + this._uid
  },
  mounted () {
    const pieLabelLine = {
      id: 'pieLabelLine',
      afterDraw (chart, args, options) {
        const {
          ctx,
          chartArea: { width, height }
        } = chart
        chart.data.datasets.forEach((dataset, i) => {
          chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
            // console.log(datapoint.tooltipPosition(), index)
            if (index <= 1) {
              const { x, y } = datapoint.tooltipPosition()

              const halfwidth = width / 2
              const halfheight = height / 2
              const linewidth = 40

              const xLine = x >= halfwidth ? x + linewidth : x - linewidth
              const yLine = y >= halfheight ? y + linewidth : y - linewidth
              const extraLine = x >= halfwidth ? linewidth : -linewidth

              // Line
              ctx.beginPath()
              ctx.moveTo(x, y)
              ctx.lineTo(xLine, yLine)
              ctx.lineTo(xLine + extraLine, yLine)

              // ctx.strokeStyle = dataset.borderColor[index]
              // console.log(ctx)
              ctx.stroke()

              // text
              // const textWidth = ctx.measureText(chart.data.labels[i]).width
              ctx.font = '12px Arial'

              const textXPosition = x >= halfwidth ? 'left' : 'right'
              const plusFivePx = x >= halfwidth ? 5 : -5

              const labelFontColor = chart.options.scales.y.ticks.color
              // console.log(labelFontColor)
              ctx.textAlign = textXPosition
              ctx.textBaseline = 'middle'
              ctx.strokeStyle = labelFontColor
              ctx.fillStyle = labelFontColor
              // console.log(dataset.labelColor)
              let v = dataset.data[index]
              // if (props.unit == 'byte') {
              //   v = convertToBytes(v)
              // } else
              v = v.toLocaleString()
              const s =
                chart.data.labels[index].length > 5
                  ? chart.data.labels[index].slice(0, 5) + '...'
                  : chart.data.labels[index]
              ctx.fillText(
                ' ' + s + ' (' + v + ') ',
                xLine + extraLine + plusFivePx,
                yLine
              )
            }
          })
        })
      }
    }

    // TODO initial Chart js
    const ctx = document.getElementById(this.myID)
    const that = this
    this.chart = new this.Chart(ctx, {
      type: 'pie',
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: {
        // responsive: true, // It make the chart responsive
        // This plugin will display Title of chart
        layout: {
          padding: 25
        },
        indexAxis: this.indexAxis,
        // plugins: {
        //   legend: { display: true, position: 'bottom' },
        //   tooltip: {
        //     position: 'custom'
        //   }
        // },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
            grid: {
              display: false
            }
          },
          x: {
            display: false,
            grid: {
              display: false
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        // aspectRatio: 1 | 1
        // plugins: [pieLabelLine],
        plugins: {
          legend: { display: true, position: 'bottom' },
          tooltip: {
            position: 'custom'
          }
        },

        // plugins: {
        //   title: {
        //     display: true,
        //     text: 'Number of Students Enrolled Course'
        //   }
        // },
        // Event handler for a click on a chart element
        onClick: function (event, elements) {
          if (elements?.length > 0) {
            const clickedElement = elements[0]
            const datasetIndex = clickedElement.index
            // const label = chart.data.labels[datasetIndex]
            const label = this.data.labels[datasetIndex]
            const labelValue = this.data.datasets[0].data[datasetIndex]

            // Show an alert with information about the clicked segment
            // alert(`Clicked on: ${label} and it's value is ${labelValue}`)

            // ส่งค่า label และ labelvalue ไป clickWidgetPie()
            that.clickWidgetPie(label, labelValue)
          }
        },
        onHover: (event, activeElements) => {
          if (activeElements?.length > 0) {
            event.native.target.style.cursor = 'pointer'
          } else {
            event.native.target.style.cursor = 'auto'
          }
        }
      },
      plugins: [pieLabelLine]
    })
  },
  methods: {
    clickWidgetPie (label, labelValue) {
      // console.log(label)
      // console.log(labelValue)
      // console.log(this.dataField)
      const dataPieChartPatter = this.dataField + ':' + '"' + label + '"'
      this.$EventBus.$emit('clickWidgetPieChart', dataPieChartPatter)
    }
  }
}
</script>

<style lang="scss" scoped>
.mychart {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: contain !important;
}
</style>
