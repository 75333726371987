import moment from 'moment-timezone'
export default {
  data () {
    return {
      moment: moment,
      variables: [
        {
          case: '$$ACCESS_TOKEN',
          regx: /\$\$ACCESS_TOKEN/g
        },
        {
          case: '$$PROJECT_ID',
          regx: /\$\$PROJECT_ID/g
        }
      ],
      message: 'Hello World',
      Err: {
        noInPut: 'กรุณากรอกข้อมูล',
        noNull: 'กรุณาเลือกข้อมูล',
        noArray: 'กรุณาเลือกข้อมูล',
        numberOnly: 'กรุณากรอกตัวเลขเท่านั้น',
        noSpace: 'ห้ามมีช่องว่าง',
        phoneNumber: 'กรุณากรอกหมายเลขโทรศัพท์มือถือ 10 หลัก',
        english: 'ภาษาอังกฤษเท่านั้น',
        email: 'รูปแบบอีเมลไม่ถูกต้อง',
        countingNumber: 'กรุณากรอกตัวเลข 1 - 9999',
        cron: 'รูปแบบไม่ถูกต้อง (* * * */1 9)'
      },
      Regx: {
        UUID: '',
        CID: '',
        number: /^\d+$/,
        space: /^\S+$/,
        english: /[^A-Za-z0-9@!_.-]/,
        email: /^\S+@\S+\.\S+$/,
        countingNumber: /^(?:[1-9]\d{0,4})$/,
        cron: /^((\*|([0-5]?\d)|\*\/[0-5]?\d)\s+){4}(\*|([0-5]?\d)|\*\/[0-5]?\d)$/
      },
      Rule: {
        noInPut: (v) => this.isEmpty(v) || this.Err.noInPut,
        notNull: (v) => this.isNull(v) || this.Err.noNull,
        noArray: (v) => this.isArray(v) || this.Err.noArray,
        noSpace: (v) => this.isSpace(v) || this.Err.noSpace,
        numberOnly: (v) => this.isNumber(v) || this.Err.numberOnly,
        phoneNumber: (v) => this.isPhoneNumber(v) || this.Err.phoneNumber,
        englishOnly: (v) => this.isEnglish(v) || this.Err.english,
        emailOnly: (v) => this.isEmail(v) || this.Err.email,
        countingNumberOnly: (v) =>
          this.isCountingNumber(v) || this.Err.countingNumber,
        cronFormat: (v) => this.ifCron(v) || this.Err.cron
      },
      gender: [
        { text: 'หญิง', val: 'หญิง' },
        { text: 'ชาย', val: 'ชาย' },
        { text: 'ไม่ระบุ', val: '' }
      ]
    }
  },
  filters: {
    moment: function (date) {
      // return moment(date).format('YYYY/MM/DD HH:mm')
      return moment(date).fromNow(true)
    },
    YYYY_MM_DD: function (date) {
      const x = moment(date).format('YYYY-MM-DD')
      return x === '' ? null : x
    },
    YYYY_MM_DD_HH_MM_SS: function (date) {
      const x = moment(date).format('YYYY-MM-DD HH:mm:ss')
      return x === '' ? null : x
    },
    YYYY_MM_DD_HH_MM: function (date) {
      const x = moment(date).format('YYYY-MM-DD HH:mm')
      return x === '' ? null : x
    },
    HH_MM_SS: function (date) {
      const x = moment(date).format('HH:mm:ss')
      return x === '' ? null : x
    },
    BE_DD_MM_YYYY: function (date) {
      const x = moment.utc(date).local().add(543, 'year').format('DD-MM-YYYY')
      return x === '' ? null : x
    },

    BE_DD_MM_YYYY_HH_MM: function (date) {
      const x = moment
        .utc(date)
        .local()
        .add(543, 'year')
        .format('DD-MM-YYYY HH:mm')
      return x === '' ? null : x
    },

    BE_DD_MM_YYYY_HH_MM_SS: function (date) {
      const x = moment
        .utc(date)
        .local()
        .add(543, 'year')
        .format('DD-MM-YYYY HH:mm:ss')
      return x === '' ? null : x
    },

    TextOverFlow: function (data) {
      if (data.length > 3) return data.substring(0, 4) + '...'
      else return data
    }
  },
  methods: {
    // NOTE Rule function
    isEmpty (str) {
      return !!str
    },
    isNull (str) {
      return str !== null
    },
    isArray (str) {
      return str != null && str.length !== 0
    },
    isSpace (str) {
      return this.Regx.space.test(str)
    },
    isNumber (str) {
      return this.Regx.number.test(str)
    },
    isPhoneNumber (str) {
      return str !== null && str.length === 10
    },
    isEnglish (str) {
      return !this.Regx.english.test(str)
    },
    isEmail (str) {
      return this.Regx.email.test(str)
    },
    isCountingNumber (str) {
      return this.Regx.countingNumber.test(str)
    },
    ifCron (str) {
      return this.Regx.cron.test(str)
    },
    async checkCaseReplace (url) {
      let URL = url
      if (/\$\$ACCESS_TOKEN/g.test(URL)) {
        const encodedToken = window.btoa(localStorage.getItem('Accesstoken'))
        URL = URL.replaceAll('$$ACCESS_TOKEN', encodedToken)
      }
      if (/\$\$PROJECT_ID/g.test(URL)) {
        URL = URL.replaceAll('$$PROJECT_ID', this.$store.state.ProjectID)
      }
      return URL
    },
    async awaitAxios (options) {
      try {
        const res = await this.axios(options)
        return res.data
      } catch (error) {
        if (error.data !== undefined) return error.data
        else {
          return { code: 400, message: 'Bad Request', data: {}, status: false }
        }
      }
    },
    profileUrl (filename) {
      let url = `${process.env.VUE_APP_API}/api/v1/image/profile/${filename}`
      url += `?accesstoken=${btoa(localStorage.getItem('Accesstoken'))}`
      return url
    },

    getFileUrl (filename) {
      const url = `${process.env.VUE_APP_API}/api/v1/file/${filename}`
      // url += `?accesstoken=${btoa(localStorage.getItem('Accesstoken'))}`
      return url
    },
    // LandinfPage
    async sendContact (payload) {
      const data = {
        ...{
          // Edit Constructure here
          email: '',
          first_name: '',
          last_name: '',
          subject: '',
          phone: '',
          description: ''
        },
        ...payload
      }
      // NOTE this function signin
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/contact`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    // LandinfPage
    async registerPageUser (payload) {
      let data = Object.assign({}, payload)
      data.date_of_birth = this.$moment(data.date_of_birth)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      data = {
        ...{
          // Edit Constructure here
          // user_id: '',
          first_name: '',
          last_name: '',
          phone: '',
          gender: '',
          name_title: '',
          username: '',
          permissions: [],
          email_verify: '',
          // date_of_birth: '',
          street: '',
          zip_code: '',
          city: '',
          country_code: '',
          country: '',
          time_zone: 'UTC',
          // time_zone: this.$moment.tz.guess(),
          description: '',
          // verify: 0,
          image: ''
        },
        ...data
        // ...{ date_of_birth: this.$moment().format('YYYY-MM-DD HH:mm:ss') }
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/signup`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    // ResetPasswordMail-----------------------------
    async resetPassword (payload) {
      const data = {
        ...{
          // Edit Constructure here
          password: ''
        },
        ...payload
      }
      // NOTE this function signin
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/user/password/new`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    // ResetPasswordMail-----------------------------
    // NOTE User Function --------------------------------
    async sendEmailresetPasswordUser (payload) {
      // NOTE this function sent email for reset passsword
      const data = {
        ...{
          // Edit Constructure here
          email: ''
        },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/user/password/reset`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async resetPasswordUser (payload) {
      // NOTE this function edit data user
      const data = Object.assign({}, payload)
      delete data.user_id
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/user/${payload.user_id}/password/reset`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async getUserInfo () {
      // NOTE this function get user info
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/user/info`,
        method: 'get'
      }
      return await this.awaitAxios(options)
    },
    async getUserForTable (payload) {
      const params = {
        ...{
          // Edit Constructure here
          page: 1,
          row: 10,
          verify: 0,
          keyword: ''
        },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/users`,
        method: 'get',
        params: params
      }
      return await this.awaitAxios(options)
    },
    async getUserForDropdownByPermission (payload) {
      const params = Object.assign({}, payload)
      delete params.project_id
      params.permission_id = params.permission_id.join()
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/permission/system/users`,
        method: 'get',
        params: params
      }
      return await this.awaitAxios(options)
    },
    async createUser (payload) {
      let data = Object.assign({}, payload)
      data.date_of_birth = this.$moment(data.date_of_birth)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      data = {
        ...{
          // Edit Constructure here
          // user_id: '',
          first_name: '',
          last_name: '',
          phone: '',
          gender: '',
          name_title: '',
          username: '',
          permissions: [],
          email_verify: '',
          date_of_birth: '',
          street: '',
          zip_code: '',
          city: '',
          country_code: '',
          country: '',
          time_zone: 'UTC',
          // time_zone: this.$moment.tz.guess(),
          description: '',
          // verify: 0,
          image: ''
        },
        ...data
        // ...{ date_of_birth: this.$moment().format('YYYY-MM-DD HH:mm:ss') }
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/user`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async updateUser (payload) {
      // NOTE this function edit data user
      let data = Object.assign({}, payload)
      data.date_of_birth = this.$moment(data.date_of_birth)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      delete data.user_id
      data = {
        ...{
          // Edit Constructure here
          name_title: '',
          first_name: '',
          last_name: '',
          phone: '',
          gender: '',
          permissions: [],
          date_of_birth: '',
          street: '',
          zip_code: '',
          city: '',
          country_code: '',
          country: '',
          time_zone: 'UTC',
          description: '',
          username: ''
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/user/${payload.user_id}`,
        method: 'put',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async deleteUser (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/user/${payload.user_id}`,
        method: 'delete'
      }
      return await this.awaitAxios(options)
    },
    async approveUser (payload) {
      // NOTE this function approve or reject user
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/user/${payload.user_id}/${payload.action}`,
        method: 'post'
      }
      return await this.awaitAxios(options)
    },

    // NOTE Permission Function -------------------------
    async getPermissionForTable (payload) {
      const params = {
        ...{
          // Edit Constructure here
          keyword: '',
          page: 1,
          row: 10
        },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/permissions`,
        method: 'get',
        params: params
      }
      return await this.awaitAxios(options)
    },
    async getPermissionForDropdown () {
      // NOTE this function get permission dropdown
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/permissions/dropdown`,
        method: 'get'
      }
      return await this.awaitAxios(options)
    },
    async getTagForDropdown () {
      // NOTE this function get permission dropdown
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/tags`,
        method: 'get'
      }
      return await this.awaitAxios(options)
    },
    async createPermission (payload) {
      let data = Object.assign({}, payload)
      data = {
        ...{
          // Edit Constructure here
          name: '',
          description: '',
          menus: []
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/permission`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async updatePermission (payload) {
      let data = Object.assign({}, payload)
      delete data.permission_id
      data = {
        ...{
          // Edit Constructure here
          name: '',
          description: '',
          menus: []
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/permission/${payload.permission_id}`,
        method: 'put',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async deletePermission (payload) {
      // NOTE this function delete Permission
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/permission/${payload.permission_id}`,
        method: 'delete'
      }
      return await this.awaitAxios(options)
    },

    // NOTE Menu Function --------------------------------
    async getMenusForNavigation () {
      // NOTE this function get menus for navigation
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/menus/navigation`,
        method: 'get'
      }
      return await this.awaitAxios(options)
    },
    async getMenuForTable (payload) {
      // NOTE this function get items menu for table
      const params = {
        ...{
          // Edit Constructure here
          keyword: '',
          page: 1,
          row: 10
        },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/menus`,
        method: 'get',
        params: params
      }
      return await this.awaitAxios(options)
    },
    async getMenuForDropdown () {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/menus/dropdown`,
        method: 'get'
      }
      return await this.awaitAxios(options)
    },
    async createMenu (payload) {
      let data = Object.assign({}, payload)
      data = {
        ...{
          // Edit Constructure here
          text: '',
          name: '',
          path: '',
          icon: '',
          description: ''
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/menu`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async updateMenu (payload) {
      let data = Object.assign({}, payload)
      delete data.menu_id
      data = {
        ...{
          // Edit Constructure here
          text: '',
          name: '',
          path: '',
          icon: '',
          description: ''
        },
        ...data
      }
      const options = {
        // url:
        //   this.menu_id === ''
        //     ? `${process.env.VUE_APP_API}/api/v1/menu/${this.editedItem.menu_id}`
        //     : `${process.env.VUE_APP_API}/api/v1/menu/${this.menu_id}/submenu/${this.editedItem.menu_id}`,
        url: `${process.env.VUE_APP_API}/api/v1/menu/${payload.menu_id}`,
        method: 'put',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async deleteMenu (payload) {
      // NOTE this function delete Menu
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/menu/${payload.menu_id}`,
        method: 'delete'
      }
      return await this.awaitAxios(options)
    },

    // NOTE SubMenu --------------------------------------
    async createSubMenu (payload) {
      let data = Object.assign({}, payload)
      delete data.menu_id
      data = {
        ...{
          // Edit Constructure here
          text: '',
          name: '',
          path: '',
          icon: '',
          description: ''
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/menu/${payload.menu_id}/submenu`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async updateSubMenu (payload) {
      let data = Object.assign({}, payload)
      delete data.menu_id
      delete data.submenu_id
      data = {
        ...{
          // Edit Constructure here
          text: '',
          name: '',
          path: '',
          icon: '',
          description: ''
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/menu/${payload.menu_id}/submenu/${payload.submenu_id}`,
        method: 'put',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async deleteSubMenu (payload) {
      // NOTE this function delete subMenu
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/menu/${payload.menu_id}/submenu/${payload.submenu_id}`,
        method: 'delete'
      }
      return await this.awaitAxios(options)
    },

    // NOTE project ------------------------------------
    async getProjectInfo (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/info`,
        method: 'get'
      }
      return await this.awaitAxios(options)
    },
    async getProjectForTable (payload) {
      // NOTE this function get projects pagination
      const params = {
        ...{
          // Edit Constructure here
          keyword: '',
          page: 1,
          row: 10
        },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/projects`,
        method: 'get',
        params: params
      }
      return await this.awaitAxios(options)
    },
    async createProject (payload) {
      let data = Object.assign({}, payload)
      delete data.project_id
      data = {
        ...{
          // Edit Constructure here
          name: '',
          biz_id: '',
          // phone: '',
          image: '',
          description: '',
          limit_date: 365
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async updateProject (payload) {
      let data = Object.assign({}, payload)
      delete data.project_id
      data = {
        ...{
          // Edit Constructure here
          name: '',
          biz_id: '',
          // phone: '',
          image: '',
          description: '',
          limit_date: 365
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}`,
        method: 'put',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async deleteProject (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}`,
        method: 'delete'
      }
      return await this.awaitAxios(options)
    },
    // menu in project-----------------------------------
    async getProjectMenuForTable (payload) {
      const params = {
        ...{
          // Edit Constructure here
          page: 1,
          row: 10,
          keyword: ''
        },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/menus`,
        method: 'get',
        params: params
      }
      return await this.awaitAxios(options)
    },
    async getProjectMenuDropdown (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/menus/dropdown`,
        method: 'get'
      }
      return await this.awaitAxios(options)
    },
    async getProjectMenuFromUser (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/user/menu`,
        method: 'get'
      }
      return await this.awaitAxios(options)
    },
    async deleteProjectMenu (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/menu/${payload.menu_id}`,
        method: 'delete'
      }
      return await this.awaitAxios(options)
    },
    async updateProjectMenu (payload) {
      let data = Object.assign({}, payload)
      delete data.project_id
      delete data.menu_id
      data = {
        ...{
          // Edit Constructure here
          text: '',
          name: '',
          path: '',
          icon: '',
          description: '',
          is_iframe: false,
          url: ''
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/menu/${payload.menu_id}`,
        method: 'put',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async createProjectMenu (payload) {
      let data = Object.assign({}, payload)
      delete data.project_id
      data = {
        ...{
          // Edit Constructure here
          text: '',
          name: '',
          description: '',
          is_iframe: false,
          url: ''
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/menu`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    // permission in project-----------------------------------
    async getProjectPermissionForTable (payload) {
      const params = {
        ...{
          // Edit Constructure here
          page: 1,
          row: 10,
          keyword: ''
        },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/permissions`,
        method: 'get',
        params: params
      }
      return await this.awaitAxios(options)
    },
    async getProjectPermissionForDropdown (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/permissions/dropdown`,
        method: 'get',
        params: payload
      }
      return await this.awaitAxios(options)
    },
    async getProjectPermissionForDropdownEdit (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/user/${payload.user_id}/permissions`,
        method: 'get',
        params: payload
      }
      return await this.awaitAxios(options)
    },
    async deleteProjectPermission (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/permission/${payload.permission_id}`,
        method: 'delete'
      }
      return await this.awaitAxios(options)
    },
    async updateProjectPermission (payload) {
      let data = Object.assign({}, payload)
      delete data.project_id
      delete data.permission_id
      data = {
        ...{
          // Edit Constructure here
          name: '',
          description: '',
          menus: [],
          permissions: [],
          tags: []
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/permission/${payload.permission_id}`,
        method: 'put',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async createProjectPermission (payload) {
      let data = Object.assign({}, payload)
      delete data.project_id
      data = {
        ...{
          // Edit Constructure here
          name: '',
          menus: [],
          permissions: [],
          description: '',
          tags: []
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/permission`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    // users in project-----------------------------------
    async getProjectUserForTable (payload) {
      const params = {
        ...{
          // Edit Constructure here
          page: 1,
          row: 10,
          verify: '2',
          keyword: ''
        },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/users`,
        method: 'get',
        params: params
      }
      return await this.awaitAxios(options)
    },
    async deleteProjectUser (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/user/${payload.user_id}`,
        method: 'delete'
      }
      return await this.awaitAxios(options)
    },
    async updateProjectUser (payload) {
      let data = Object.assign({}, payload)
      delete data.project_id
      delete data.user_id
      data = {
        ...{
          // Edit Constructure here
          permissions: []
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/user/${payload.user_id}`,
        method: 'put',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async createProjectUser (payload) {
      let data = Object.assign({}, payload)
      delete data.project_id
      data = {
        ...{
          // Edit Constructure here
          user_id: '',
          permissions: []
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/user`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async verifyUserProject (payload) {
      // NOTE this function approve or reject user in project
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/user/${payload.user_id}/${payload.action}`,
        method: 'post'
      }
      return await this.awaitAxios(options)
    },

    // NOTE project -------------------------------------
    // NOTE signin
    async signIn (payload) {
      // NOTE this function signin
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/signin`,
        // url: 'http://203.151.199.185:3001/api/v1/signin',
        method: 'post',
        data: payload
      }
      return await this.awaitAxios(options)
    },
    async GetAESSignature () {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/signin/signature`,
        // url: 'http://203.151.199.185:3001/api/v1/signin/signature',
        method: 'get'
      }
      return await this.awaitAxios(options)
    },
    // NOTE Notification ----------------------------------
    async GetNotifications () {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/notifications/now`,
        method: 'get'
      }
      return await this.awaitAxios(options)
    },
    async CloseNotification (notificationId = '') {
      if (notificationId.length === 0) {
        return console.warn('not found notification_id')
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/notification/${notificationId}`,
        method: 'delete'
      }
      return await this.awaitAxios(options)
    },
    async ReadNotification (notificationId = '') {
      if (notificationId.length === 0) {
        return console.warn('not found notification_id')
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/notification/${notificationId}/read`,
        method: 'put'
      }
      return await this.awaitAxios(options)
    },

    // NOTE Announcer ----------------------------------
    async getAnnouncerForTable (payload) {
      // NOTE this function get data for table
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/announcers`,
        method: 'get',
        params: payload
      }
      return await this.awaitAxios(options)
    },
    async createAnnounucer (payload) {
      let data = Object.assign({}, payload)
      data.start_date = this.$moment(data.start_date)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      data.end_date = this.$moment(data.end_date)
        .endOf('day')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')

      data = {
        ...{
          // Edit Constructure here
          announcer_id: '',
          start_date: '',
          end_date: '',
          title: '',
          description: '',
          image: '',
          url: '',
          icon: '',
          type: '',
          time_zone: 'UTC'
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/announcer`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async updateAnnouncer (payload) {
      let data = Object.assign({}, payload)
      delete data.announcer_id
      data.start_date = this.$moment(data.start_date)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      data.end_date = this.$moment(data.end_date)
        .endOf('day')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')

      data = {
        ...{
          // Edit Constructure here
          start_date: '',
          end_date: '',
          title: '',
          description: '',
          image: '',
          url: '',
          icon: '',
          type: '',
          time_zone: 'UTC'
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/announcer/${payload.announcer_id}`,
        method: 'put',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async deleteItemAnnouncer (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/announcer/${payload.announcer_id}`,
        method: 'delete'
      }
      return await this.awaitAxios(options)
    },
    async getAnnouncerToday () {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/announcers/today`,
        method: 'get'
      }
      return await this.awaitAxios(options)
    },
    // NOTE PRofile ------------------------------------
    async getProfile () {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/profile`,
        method: 'get'
      }
      return await this.awaitAxios(options)
    },
    async updateProfile (payload) {
      let data = Object.assign({}, payload)
      data.date_of_birth = this.$moment(data.date_of_birth)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      data = {
        ...{
          // Edit Constructure here
          first_name: '',
          last_name: '',
          phone: '',
          gender: '',
          date_of_birth: '',
          name_title: '',
          street: '',
          zip_code: '',
          city: '',
          country_code: '',
          country: '',
          time_zone: 'UTC',
          description: '',
          email: ''
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/profile`,
        method: 'put',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async UploadProfileImage (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/profile/image`,
        method: 'put',
        // maxContentLength: Infinity,
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: payload
      }
      return await this.awaitAxios(options)
    },
    // NOTE Page Notification ------------------------------------
    async getNotificationForTable (payload) {
      // DTO
      const p = {
        ...{
          page: 1,
          row: 10,
          keyword: ''
        },
        ...payload
      }
      // NOTE this function get data for table
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/notifications`,
        method: 'get',
        params: p
      }
      return await this.awaitAxios(options)
    },
    async sendCmdNotification (payload) {
      const p = {
        ...{
          notification_id: '',
          command: ''
        },
        ...payload
      }
      // NOTE this function get data for table
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/notification/${p.notification_id}/cmd/${p.command}`,
        method: 'put'
      }
      return await this.awaitAxios(options)
    },
    async deleteItemNotification (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/notification/${payload.notification_id}`,
        method: 'delete'
      }
      return await this.awaitAxios(options)
    },
    async resetPasswordProfile (payload) {
      let data = Object.assign({}, payload)
      data = {
        ...{
          // Edit Constructure here
          old_password: '',
          new_password: ''
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/user/password/reset`,
        method: 'put',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async signInOneIDAuthorizationCode (payload) {
      // NOTE this function signin
      const p = {
        ...{ authorization_code: '', redirect_url: '' },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/signin/oneid`,
        method: 'post',
        data: p
      }
      return await this.awaitAxios(options)
    },
    async OauthLogOutOneID () {
      // COMMENT เป็นการ Logout แบบ SSO
      const redirectUri = `${window.location.origin}${process.env.VUE_APP_BASE_URL}login`
      const uri = `${process.env.VUE_APP_ONEID_URL}/api/oauth/logout?redirect_uri=${redirectUri}`
      location.replace(uri)
    },
    async ConnectOneIDAccount (payload) {
      const p = {
        ...{ authorization_code: '', redirect_uri: '' },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/profile/connect/oneid`,
        method: 'post',
        data: p
      }
      return await this.awaitAxios(options)
    },

    // NOTE DATASOURCE ---------------------------------------------
    async getDataSourceForTable (payload) {
      // NOTE Data Tranfer Object
      const p = {
        ...{
          page: 1,
          row: 10,
          keyword: ''
        },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/datasources`,
        method: 'get',
        params: p
      }
      return await this.awaitAxios(options)
    },
    async createDatasource (payload) {
      let data = Object.assign({}, payload)
      delete data.datasource_id
      // NOTE Data Tranfer Object
      data = {
        ...{
          // TODO Edit Constructure here
          name: '',
          uri: '',
          authorization: '',
          type: '',
          description: ''
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/datasource`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async updateDatasource (payload) {
      let data = Object.assign({}, payload)
      delete data.datasource_id
      // NOTE Data Tranfer Object
      data = {
        ...{
          // TODO Edit Constructure here
          name: '',
          uri: '',
          authorization: '',
          type: '',
          description: ''
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/datasource/${payload.datasource_id}`,
        method: 'put',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async deleteDatasource (payload) {
      // DTO
      const p = {
        ...{
          datasource_id: ''
        },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/datasource/${p.datasource_id}`,
        method: 'delete'
      }
      return await this.awaitAxios(options)
    },

    async directQueryElasticsearch (payload) {
      const p = {
        ...{
          datasource_id: '',
          path: '',
          query: {}
        },
        ...JSON.parse(JSON.stringify(payload))
      }

      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/elasticserch/datasource/${p.datasource_id}`,
        method: 'post',
        data: {
          path: p.path,
          query: payload.query
        }
      }
      return await this.awaitAxios(options)
    },
    // NOTE DATASOURCE ---------------------------------------------
    // NOTE WIDEGT -------------------------------------------------
    async createWidget (payload) {
      let data = Object.assign({}, payload)
      delete data.menu_id
      delete data.project_id
      // NOTE Data Tranfer Object
      data = {
        ...{
          // TODO Edit Constructure here
          index: 0,
          title: '',
          description: '',
          type: '',
          query: {},
          path: '',
          options: {
            width: '50%',
            height: '50%'
          }
        },
        ...data
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/menu/${payload.menu_id}/widget`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async getwidgets (payload) {
      // NOTE Data Tranfer Object
      const p = {
        ...{
          project_id: '',
          menu_id: ''
        },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${p.project_id}/menu/${p.menu_id}/widgets`,
        method: 'get',
        params: p
      }
      return await this.awaitAxios(options)
    },
    async updateWidget (payload) {
      const data = Object.assign({}, payload)
      delete data.menu_id
      delete data.project_id
      delete data.widget_id
      // NOTE Data Tranfer Object
      // data = {
      //   ...{
      //     // TODO Edit Constructure here
      //     name: '',
      //     uri: '',
      //     authorization: '',
      //     type: '',
      //     description: ''
      //   },
      //   ...data
      // }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/menu/${payload.menu_id}/widget/${payload.widget_id}`,
        method: 'put',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async deleteWidget (payload) {
      // DTO
      const p = {
        ...{
          project_id: '',
          menu_id: '',
          widget_id: ''
        },
        ...payload
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${p.project_id}/menu/${p.menu_id}/widget/${p.widget_id}`,
        method: 'delete'
      }
      return await this.awaitAxios(options)
    },
    async getWidgetData (payload) {
      // DTO
      const data = Object.assign({}, payload)
      delete data.menu_id
      delete data.project_id
      delete data.widget_id

      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/getdata/project/${payload.project_id}/menu/${payload.menu_id}/widget/${payload.widget_id}`,
        method: 'post',
        data: data
      }
      return await this.awaitAxios(options)
    },
    async getProjectUserTag (payload) {
      const p = {
        project_id: payload.project_id
      }
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${p.project_id}/user/tag`,
        method: 'get',
        params: p
      }
      return await this.awaitAxios(options)
    },
    // NOTE WIDEGT -------------------------------------------------
    async UploadFile (payload) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/file`,
        method: 'post',
        // maxContentLength: Infinity,
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: payload
      }
      return await this.awaitAxios(options)
    },
    async ReadFile (filename) {
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/file/${filename}`,
        method: 'get',
        // maxContentLength: Infinity,
        maxBodyLength: Infinity
      }
      return await this.awaitAxios(options)
    }
  }
}
