<template>
  <div class="main-layout">
    <div class="layout-content">
      <u-table
        :headers="headers"
        :items="items"
        :mobileBreakpoint="900"
        :offsetHeight="48"
        :showSelect="false"
        itemKey="permission_id"
        :loading="loading"
        :pagination="pagination"
        @onChangePagination="onChangePagination"
      >
        <template v-slot:header>
          <v-row
            justify="space-between"
            align="center"
            class="header-custom-table"
          >
            <!-- // # Action Group -->
            <v-col cols="12" md="4">
              <v-row justify="start" align="center">
                <v-col cols="12" md="6">
                  <v-btn color="primary" block dark @click="dialog = !dialog">
                    <v-icon>mdi-home-plus</v-icon>เพิ่ม{{ NameComponent }}
                  </v-btn></v-col
                >
              </v-row>
            </v-col>
            <!-- // # Search Group -->
            <v-col cols="12" md="4">
              <v-row justify="center" align="center">
                <v-col
                  ><v-text-field
                    v-model="keyword"
                    name="name"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    placeholder="ค้นหา"
                    label="ค้นหา"
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col cols="4">
                  <v-btn color="primary" block dark @click="init()"
                    >ค้นหา</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.number`]="{ item }">
              {{
                pagination.row * pagination.page -
                pagination.row +
                (items.indexOf(item) + 1)
              }}
            </template>
        <template v-slot:[`item.menus`]="{ item }">
          <span v-if="item.menus.length === 0">-</span>
          <v-chip
            v-else
            outlined
            small
            v-for="t in item.menus"
            :key="t"
            color="primary"
            class="my-1"
          >
            {{ getMenu(t) }}
          </v-chip>
        </template>
        <template v-slot:[`item.permissions`]="{ item }">
          <v-chip
            outlined
            small
            v-for="t in item.permissions"
            :key="t"
            color="primary"
            class="text-capitalize my-1"
          >
            {{ getPermission(t) }}
          </v-chip>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <span class="fix-overflow-ellipsis">
            {{
              item.description !== "" && item.description !== null
                ? item.description
                : "-"
            }}
          </span>
        </template>
        <template v-slot:[`item.tags`]="{ item }">
          <span v-if="item.tags.length === 0">-</span>
          <v-chip
            v-else
            outlined
            small
            v-for="t in item.tags"
            :key="t"
            color="primary"
            class="my-1"

          >
          {{ getTag(t) }}
            <!-- {{ t}} -->
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="action-nowrap">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  dark
                  color="primary"
                  @click="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>แก้ไขข้อมูล</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  dark
                  color="error"
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </template>
              <span>ลบ</span>
            </v-tooltip>
          </div>
        </template>
      </u-table>
    </div>
    <u-card-form
      :title="formTitle"
      :subtitle="formSubTitle"
      ref="form"
      v-model="dialog"
    >
      <template v-slot:form>
        <div>
          <span class="required">*</span
          ><span>ชื่อ{{ NameComponent }}ที่ใช้แสดง</span>
        </div>
        <v-text-field
          v-model="editedItem.name"
          :rules="[Rule.noInPut]"
          :placeholder="`กรุณากรอกชื่อ${NameComponent}ที่ใช้แสดง`"
          required
          outlined
          dense
        ></v-text-field>
        <div><span>เมนูที่ใช้งานได้</span></div>
        <v-select
          v-model="editedItem.menus"
          :items="menus"
          item-text="text"
          item-value="menu_id"
          placeholder="กรุณาเลือกเมนูที่สามารถใช้งานได้"
          :disabled="loading"
          :loading="loading"
          chips
          dense
          multiple
          outlined
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index <= 1">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 2" class="grey--text text-caption">
              (+{{ editedItem.menus.length - 2 }} others)
            </span>
          </template>
        </v-select>
        <div><span class="required">*</span><span>สิทธิการใช้งานLV1</span></div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-select
              v-bind="attrs"
              v-on="on"
              v-model="editedItem.permissions"
              :items="permissions"
              item-text="name"
              item-value="permission_id"
              :rules="[Rule.noArray]"
              placeholder="กรุณาเลือกสิทธิการใช้งาน"
              :disabled="loading"
              :loading="loading"
              required
              chips
              dense
              multiple
              outlined
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index <= 1">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ editedItem.permissions.length - 1 }} others)
                </span>
              </template>
            </v-select>
          </template>
          <span
            >ใช้เลือกกลุ่มผู้ใช้ตอนเพิ่มบัญชีผู้ใช้<br />โดยเลือกคนที่ต้องมีทุกสิทธิที่เลือก</span
          >
        </v-tooltip>

        <div><span>ติดป้าย</span></div>
        <v-autocomplete
          v-model="editedItem.tags"
          :items="tags"
          item-text="tag_text"
          item-value="tag_id"
          placeholder="กรุณาเลือกป้าย"
          :disabled="loading"
          :loading="loading"
          clearable
          dense
          multiple
          outlined
          small-chips
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index <= 1" small>
              <span>{{ item.tag_text }}</span>
            </v-chip>
            <span v-if="index === 2" class="grey--text text-caption">
              (+{{ editedItem.tags.length - 2 }} others)
            </span>
          </template>
        </v-autocomplete>

        <div><span>บันทึกรายละเอียด</span></div>
        <v-textarea
          v-model="editedItem.description"
          placeholder="รายละเอียด"
          outlined
          value=""
        ></v-textarea>
      </template>
      <template v-slot:actions="{ valid }">
        <v-row>
          <v-col>
            <v-btn
              @click="save"
              :disabled="!valid"
              block
              color="primary"
              :loading="loadingSave"
            >
              บันทึก
            </v-btn>
          </v-col>
          <v-col
            ><v-btn block color="error" @click="close">ยกเลิก</v-btn></v-col
          >
        </v-row>
      </template>
    </u-card-form>
  </div>
</template>

<script>
export default {
  props: {
    project_id: {
      type: String
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? `เพิ่ม${this.NameComponent}`
        : `แก้ไข${this.NameComponent}`
    },
    formSubTitle () {
      return this.editedIndex === -1
        ? `กรอกข้อมูลเพื่อสร้าง${this.NameComponent}`
        : `กรอกข้อมูลเพื่อแก้ไข${this.NameComponent}`
    }
  },
  data () {
    return {
      NameComponent: 'สิทธิ',
      dialog: false, // สำหรับใช้เปิดปิด dialog add/edit
      editedIndex: -1,
      editedItem: {
        tags: [],
        name: '',
        menus: [],
        permissions: [],
        description: ''
      },
      defaultItem: {
        tags: [],
        name: '',
        menus: [],
        permissions: [],
        description: ''
      },
      tags: [],
      menus: [],
      permissions: [],
      keyword: '',
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number'
        },
        {
          text: 'ชื่อสิทธิการใช้งาน',
          align: 'start',
          value: 'name',
          sortable: false
        },
        {
          text: 'เมนู',
          value: 'menus',
          align: 'start',
          sortable: false
        },
        {
          text: 'สิทธิLV1',
          value: 'permissions',
          align: 'start',
          sortable: false
        },
        {
          text: 'รายละเอียด',
          value: 'description',
          align: 'start',
          sortable: false
        },
        {
          text: ' ป้าย',
          value: 'tags',
          align: 'center',
          sortable: false
        },
        { text: 'จัดการ', value: 'actions', sortable: false, align: 'center' }
      ],
      items: [],
      loading: false,
      loadingSave: false,
      pagination: {
        total: 0,
        page: 1,
        row: 10
      }
    }
  },
  watch: {
    dialog (n) {
      if (n) {
        this.initPermission()
        this.initMenu()
        this.initTag()
      }
    }
  },
  created () {
    this.init()
    // this.initPermission() move in init()
    // this.initMenu() move in init()
  },
  methods: {
    async initPermission () {
      this.loading = true
      const res = await this.getPermissionForDropdown()
      // console.log(res)
      this.loading = false
      if (res.status) {
        // TODO when get data success
        this.permissions = res.data
      } else {
        // TODO when get data fail
        this.antmessage.error(res.message)
      }
    },
    async initTag () {
      this.loading = true
      const res = await this.getTagForDropdown()
      // console.log(res)
      this.loading = false
      if (res.status) {
        // TODO when get data success
        this.tags = res.data
      } else {
        // TODO when get data fail
        this.antmessage.error(res.message)
      }
    },
    async initMenu () {
      this.loading = true
      // TODO Get Drop down Menu
      const payload = {
        project_id: this.project_id
      }
      const res = await this.getProjectMenuDropdown(payload)
      this.loading = false
      if (res.status) {
        this.menus = res.data
      } else {
        this.antmessage.error(res.message)
      }
    },
    async init () {
      this.initPermission()
      this.initMenu()
      this.initTag()

      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        page: this.pagination.page,
        row: this.pagination.row,
        project_id: this.project_id,
        keyword: this.keyword
      }
      const res = await this.getProjectPermissionForTable(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination = res.data.pagination
      } else {
        this.antmessage.error(res.message)
      }
    },
    onChangePagination (val) {
      this.pagination.page = val.page
      this.pagination.row = val.row
      this.init()
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      this.$swal({
        allowEnterKey: false,
        title: `ลบ${this.NameComponent}`,
        text: `คุณต้องการลบ${this.NameComponent}นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = {
            project_id: this.project_id,
            permission_id: item.permission_id
          }
          const res = await this.deleteProjectPermission(payload)
          this.antmessage.destroy()
          if (res.status) {
            this.init()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `ลบ${this.NameComponent}สำเร็จ`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },
    save () {
      if (this.editedIndex > -1) {
        // TODO Update Data
        this.$swal({
          allowEnterKey: false,
          title: `แก้ไข${this.NameComponent}`,
          text: `คุณต้องการแก้ไข${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              project_id: this.project_id,
              permission_id: this.editedItem.permission_id,
              name: this.editedItem.name,
              description: this.editedItem.description,
              menus: this.editedItem.menus,
              permissions: this.editedItem.permissions,
              tags: this.editedItem.tags
            }
            const res = await this.updateProjectPermission(payload)
            this.loadingSave = false
            if (res.status) {
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `แก้ไข${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.init()
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else {
        // TODO Create Data
        this.$swal({
          allowEnterKey: false,
          title: `เพิ่ม${this.NameComponent}`,
          text: `คุณต้องการเพิ่ม${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              project_id: this.project_id,
              name: this.editedItem.name,
              menus: this.editedItem.menus,
              permissions: this.editedItem.permissions,
              description: this.editedItem.description,
              tags: this.editedItem.tags
            }
            const res = await this.createProjectPermission(payload)
            this.loadingSave = false
            if (res.status) {
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `เพิ่ม${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.init()
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      }
    },
    getMenu (menuId) {
      const x = this.menus.filter((i) => i.menu_id === menuId)
      if (x.length > 0) return x[0].text
      else return menuId
    },
    getPermission (permissionId) {
      const x = this.permissions.filter(
        (i) => i.permission_id === permissionId
      )
      if (x.length > 0) return x[0].name
      else return permissionId
    },
    getTag (tagsId) {
      const x = this.tags.filter(
        (i) => i.tag_id === tagsId
      )
      if (x.length > 0) return x[0].tag_text
      else return tagsId
    },
    close () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    }
  }
}
</script>

<style lang="scss" scoped>
.main-layout {
  height: 100%;
}
.layout-content {
  height: 100%;
  padding: 0;
  margin: 0;
}
.header-custom-table .header-custom-search {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.header-custom-table .header-custom-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
  min-height: 70px;
}
</style>
