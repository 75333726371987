<template>
  <div class="main-layout">
    <div class="layout-title">
      <u-title-page :title="NameComponent" />
    </div>
    <div class="layout-content">
      <u-table
        :headers="headers"
        :items="items"
        :mobileBreakpoint="900"
        :showSelect="false"
        itemKey="package_id"
        :pagination="pagination"
        @onChangePagination="onChangePagination"
        :loading="loading"
        @dbclickRow="editItem"
      >
        <template v-slot:header>
          <v-row
            justify="space-between"
            align="center"
            class="header-custom-table"
          >
            <!-- // # Search Group -->
            <v-spacer></v-spacer>
            <v-col cols="12" md="4">
              <v-row justify="center" align="center">
                <v-col>
                  <v-text-field
                    v-model="keyword"
                    name="name"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    placeholder="ค้นหา"
                    label="ค้นหา"
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-btn color="primary" block dark @click="init">
                    ค้นหา
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>

        <!-- // # Items Table  -->
        <template v-slot:[`item.number`]="{ item }">
              {{
                pagination.row * pagination.page -
                pagination.row +
                (items.indexOf(item) + 1)
              }}
            </template>
        <template v-slot:[`item.create_date`]="{ item }">
          {{ item.create_date | BE_DD_MM_YYYY_HH_MM }}
        </template>
        <template v-slot:[`item.message`]="{ item }">
          <span class="fix-overflow-ellipsis">
            {{ item.message }}
          </span>
        </template>

        <template v-slot:[`item.read`]="{ item }">
          <v-chip
            small
            class="ma-2"
            :color="item.read ? 'success' : 'error'"
            outlined
          >
            {{ item.read ? "อ่านแล้ว" : "ยังไม่ได้อ่าน" }}
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="action-nowrap">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  dark
                  color="primary"
                  @click="editItem(item)"
                >
                  <v-icon>mdi-clipboard-text</v-icon>
                </v-btn>
              </template>
              <span>อ่าน</span>
            </v-tooltip>
            <v-tooltip top v-if="item.read">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  :color="item.type === type ? 'btnDisable' : 'error'"
                  @click="item.type !== type ? deleteItem(item) : ''"
                >
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </template>
              <span>{{ item.type !== type ? "ลบ" : "ไม่อนุญาตให้ลบ" }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.state`]="{ item }">
          <!-- COMMENT ปุ่ม APPROVE USER Project -->
          <div style="min-width: 200px">
            <v-tooltip top v-if="item.type == type && item.state == 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-1"
                  elevation="1"
                  v-on="on"
                  v-bind="attrs"
                  small
                  color="primary"
                  @click="sendCMD(item, 'approve')"
                >
                  อนุมัติ
                </v-btn>
              </template>
              <span>อนุมัติ</span>
            </v-tooltip>
            <!-- COMMENT ปุ่ม REJECT USER Project -->
            <v-tooltip top v-if="item.type == type && item.state == 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="1"
                  v-on="on"
                  v-bind="attrs"
                  small
                  color="error"
                  @click="sendCMD(item, 'reject')"
                >
                  ปฏิเสธ
                </v-btn>
              </template>
              <span>ปฏิเสธ</span>
            </v-tooltip>
            <span v-if="item.type != type">-</span>
          </div>

          <!-- COMMENT ปุ่ม STATUS APPROVE/REJECT Project -->
          <v-chip
            v-if="item.type == type && item.state !== 0"
            class="ma-2"
            :color="item.state == 2 ? 'success' : 'error'"
            outlined
            small
          >
            {{ item.state == 2 ? "อนุมัติ" : "ปฏิเสธ" }}
          </v-chip>
        </template>
      </u-table>
    </div>

    <!-- // # Form Add/Edit -->
    <u-card-form
      v-model="dialog"
      :title="formTitle"
      :subtitle="formSubTitle"
      ref="form"
    >
      <template v-slot:form>
        <v-row>
          <v-col cols="12" class="pb-0">
            <span class="text-h5 font-weight-black text-uppercase"
              >{{ editedItem.title }}
            </span>
          </v-col>

          <v-col cols="12" class="pb-0">
            <span>{{ editedItem.message }}</span>
          </v-col>

          <v-col cols="12">
            <span class="text-overline font-weight-thin">
              {{ editedItem.create_date | YYYY_MM_DD_HH_MM_SS }}
            </span>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions="{}">
        <v-row>
          <!-- <v-col>
            <v-btn
              :disabled="!valid"
              @click="save"
              block
              color="primary"
              :loading="loadingSave"
            >
              บันทึก
            </v-btn>
          </v-col> -->
          <v-col><v-btn block color="error" @click="close">ปิด</v-btn></v-col>
        </v-row>
      </template>
    </u-card-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      type: 'approve_project_user',
      keyword: '',
      start_date_status: false,
      end_date_status: false,
      loading: false,
      loadingSave: false,
      NameComponent: 'แจ้งเตือน',
      dialog: false, // สำหรับใช้เปิดปิด dialog add/edit
      IdEdit: '',
      editedIndex: -1,
      editedItem: {
        notification_id: '',
        user_id: '',
        project_id: '',
        type: '',
        title: '',
        message: '',
        icon: '',
        status: true,
        read: false,
        state: 0,
        create_by: '',
        name: '',
        description: ''
      },
      defaultItem: {
        notification_id: '',
        user_id: '',
        project_id: '',
        type: '',
        title: '',
        message: '',
        icon: '',
        status: true,
        read: false,
        state: 0,
        create_by: '',
        name: '',
        description: ''
      },
      pagination: {
        total: 0,
        page: 1,
        row: 10
      },
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number'
        },
        {
          text: 'หัวข้อ',
          align: 'start',
          value: 'title',
          sortable: false
        },
        {
          text: 'รายละเอียด',
          value: 'message',
          align: 'start',
          sortable: false
        },
        {
          text: 'สถานะ',
          value: 'read',
          align: 'center',
          sortable: false
        },
        {
          text: 'วันที่',
          value: 'create_date',
          align: 'center',
          sortable: false
        },
        {
          text: 'คำขอ',
          value: 'state',
          align: 'center',
          sortable: false
        },

        { text: 'จัดการ', value: 'actions', sortable: false, align: 'left' }
      ],
      items: [],
      type_items: ['Type_A', 'Type_B']
    }
  },
  watch: {},
  computed: {
    formTitle () {
      return this.NameComponent
    },
    formSubTitle () {
      return `รายละเอียดของ${this.NameComponent}`
    },
    returnFormateDate: {
      set (val) {
        return val
      },
      get () {
        return this.editedItem.date_of_birth !== ''
          ? this.$moment(this.editedItem.date_of_birth)
            .add(543, 'year')
            .format('YYYY-MM-DD')
          : ''
      }
    },
    editor () {
      return this.$refs.myTextEditor.quill
    }
  },
  created () {
    this.init()
  },

  methods: {
    async init () {
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        page: this.pagination.page,
        row: this.pagination.row,
        keyword: this.keyword
      }
      const res = await this.getNotificationForTable(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination = res.data.pagination
      } else {
        this.antmessage.error(res.message)
      }
    },
    onChangePagination (val) {
      this.pagination.page = val.page
      this.pagination.row = val.row
      this.init()
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      if (!this.editedItem.read) {
        this.ReadNotification(this.editedItem.notification_id)
      }
    },
    deleteItem (item) {
      this.$swal({
        allowEnterKey: false,
        title: `ลบ${this.NameComponent}`,
        text: `คุณต้องการลบ${this.NameComponent}นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = { notification_id: item.notification_id }
          const res = await this.deleteItemNotification(payload)
          this.antmessage.destroy()
          if (res.status) {
            this.init()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `ลบ${this.NameComponent}สำเร็จ`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },

    close () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.dialog = false
      this.$nextTick(() => {
        if (!this.editedItem.read) this.init()
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async sendCMD (item, command = 'approve') {
      this.loading = true
      const res = await this.sendCmdNotification({
        notification_id: item.notification_id,
        command: command
      })
      this.loading = false
      if (res.status) {
        this.antmessage.info(res.message)
      } else {
        this.antmessage.error(res.message)
      }
      this.$nextTick(() => {
        this.init()
      })
    }
  }
}
</script>

<style lang="css" scoped>
/* สำหรับจัดการหน้าเพจทั้งหมด */
.main-layout {
  height: 100%;
}

/* สำหรับ จัดการ title ของเพจ */
.layout-title {
  height: 58px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* สำหรับจัดการ Content ของเพจ */
.layout-content {
  height: calc(100% - 58px);
  padding: 0;
  margin: 0;
}

.header-custom-table .header-custom-search {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.header-custom-table .header-custom-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
  min-height: 70px;
}

.custom-col {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
/* .preview {
  width: 100%;
  border: solid 1px grey;
  aspect-ratio: 16 / 9;
  position: relative;
  border-radius: 5px;
} */

::v-deep .content {
  width: 100%;
  border: solid 1px grey;
  aspect-ratio: 16 / 9;
  border-radius: 5px;
}
</style>
