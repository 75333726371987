<template>
  <v-tabs-items v-model="tab" class="tab-items">
    <v-tab-item v-for="field in scopedSlots()" :key="field" :value="`${field}`">
      <slot :name="field"></slot>
    </v-tab-item>
    <slot name="customDashbord"></slot>
  </v-tabs-items>
</template>

<script>
export default {
  methods: {
    scopedSlots () {
      delete this.$scopedSlots.customDashbord
      return Object.keys(this.$scopedSlots)
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    tab: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  }
}
</script>

<style lang="css" scoped>

.v-tabs-items {
  height: 100% !important;
  background: none !important;
}

>>>.v-window-item {
  height: 100% !important;
}

>>>.v-window__container{
  height: 100% !important;
}
</style>
