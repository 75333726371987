<template>
<v-dialog v-model="dialog">
    <v-card class="forget-password-card">
        <div class="btn-close">
            <v-icon @click="(dialog = false), reset()">mdi-close</v-icon>
        </div>
        <v-img class="img-header mx-auto" src="@/../public/img/createForDialog.png" />
        <div class="text-for-password">
            <div class="text-header">เปลี่ยนรหัสผ่าน</div>
            <div class="text-password">ใส่อีเมลของคุณเพื่อเปลี่ยนรหัสผ่าน</div>
        </div>
        <v-form ref="formSignIn">
            <div class="d-flex my-5">
                <v-text-field class="px-8" v-model="signInData.username" :rules="[
                Rule.noInPut,
                Rule.noSpace,
                Rule.englishOnly,
                Rule.emailOnly,
              ]" placeholder="อีเมล" label="ชื่อผู้ใช้(อีเมล)" outlined flat dense></v-text-field>
                <!-- <v-text-field
            label="Solo"
            placeholder="Placeholder"
            solo
          ></v-text-field> -->
            </div>
        </v-form>
        <div class="btn-ok-cancel">
            <!-- <v-btn :class="btn-ok" @click="save" block>ตกลง</v-btn> -->
            <div class="btn-ok mr-2" @click="saveDiglog">บันทึก</div>
            <div class="btn-cancel" @click="(dialog = false), reset()">NO</div>
        </div>
    </v-card>
</v-dialog>
<!-- <DialogConfirm :value="dialogForSave"> -->
    <!-- <template v-slot:actionsCloseBtn>
                        <div class="btn-close">
                            <v-icon @click="(dialogForSave = false),reset()">mdi-close</v-icon>
                        </div>
                    </template> -->
<!-- </DialogConfirm> -->
<!-- <DialogConfirm :value="dialogForSave" :title="titleDialogConfirm.formTitle" :subtitle="titleDialogConfirm.subtitle" :colorForMainTitle="titleDialogConfirm.colorForTitle" :imageDialog="require('@/../public/img/createAccountSuccess.png')">
                    <template v-slot:actionsCloseBtn>
                        <div class="btn-close">
                            <v-icon @click="(dialogForSave = false),reset()">mdi-close</v-icon>
                        </div>
                    </template>
            </DialogConfirm> -->
</template>

<script>
// import DialogConfirm from '@/components/Forms/DialogConfirm.vue'

export default {
  // components: {
  //     DialogConfirm
  // },
  data () {
    return {
      signInData: {
        username: ''
      },
      editedIndex: 1,
      NameComponent: 'เปลี่ยนรหัสผ่าน',
      dialogForSave: ''
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    reset () {
      this.$refs.formSignIn.reset()
    },
    async saveDiglog () {
      // ปิด diglog form แรก
      this.dialog = false
      // TODO when get data success
      this.$emit('emailUser', this.signInData.username)
      // this.dialogForSave = true
    },
    save () {
      if (this.$refs.formSignIn.validate()) {
        if (this.editedIndex > -1) {
          // TODO Update Data
          this.$swal({
            allowEnterKey: false,
            title: `${this.NameComponent}`,
            text: `คุณต้องการ${this.NameComponent}ใช่หรือไม่ ระบบจะทำการส่งฟอร์มไปยังอีเมลที่คุณกรอก`,
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง',
            customClass: {
              container: 'sweet_containerImportant',
              title: 'sweet_titleImportant',
              actions: 'sweet_actionsImportant',
              confirmButton: 'sweet_confirmbuttonImportant',
              cancelButton: 'sweet_cancelbuttonImportant'
            }
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('emailUser', this.signInData.username)
            }
          })
        }
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>
/* https://codepen.io/melikamy/pen/zeyOLP */
::v-deep .sweet_container {
    background-color: rgba(0, 0, 100, 0.5);
}

::v-deep .sweet_containerImportant {
    background-color: rgba(0, 0, 100, 0.5) !important;
}

.forget-password-card {
    height: 100%;
    width: 100%;
    /* background: yellow; */
    position: absolute;
    max-width: 472px;
    max-height: 469px;
    left: calc(50% - 472px/2);
    top: calc(50% - 469px/2 - 0.5px);

    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 4px 8px -2px rgba(27, 46, 94, 0.04);
    border-radius: 32px;
}

.img-header {
    max-width: 30%;
    margin-top: 16px;
    margin-bottom: 16px;
}

.text-for-password {
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #333333;
    margin-top: 16px;
    margin-bottom: 16px;
}

.text-header {
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    color: #333333;
}

.text-password {
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-weight: 300;
    font-size: 18px;
}

/* .boxstyle {
    background: rgba(256, 10, 20, 0.5);
} */

/* ::v-deep .v-btn__content{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 10px;

  width: 148px;
  height: 42px;

  background: #50A7DC;
  border-radius: 32px;
} */
/* ::v-deep .v-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 10px;

  width: 148px;
  height: 42px;

  background: #50A7DC;
  border-radius: 32px;
  cursor: pointer;
} */
.btn-ok-cancel {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    /* background: plum; */
}

.btn-ok {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    /* gap: 10px; */

    width: 148px;
    height: 42px;
    background: #50A7DC;
    border-radius: 32px;

    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* identical to box height, or 144% */
    color: #FFFFFF;
    cursor: pointer;
}

.btn-cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    /* gap: 10px; */

    width: 148px;
    height: 42px;
    border: 1px solid #C4C4C4;
    border-radius: 32px;

    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* identical to box height, or 144% */
    color: #989898;
    cursor: pointer;
}

.btn-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
    color: #989898;
}

/* ::v-deep .text-green input {
      color: green !important;
      background: #50A7DC;
    } */
/*  */
/* ::v-deep .text-input-blue .v-text-field__slot input {
   color: goldenrod !important;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 16px;
} */
::v-deep .v-text-field--outlined fieldset {
    color: red !important;
    background: #FFFFFF;
    border: 1px solid #E6E6E6 !important;
    /* border: 1px solid #df2121 !important; */
    border-radius: 16px;
    height: 48px;
}

@media screen and (max-width: 1050px) {
    .forget-password-card {
        height: 100%;
        width: 100%;
        background: yellow;
        /* position: absolute; */
        max-width: 372px;
        /* max-height: 339px; */
        left: calc(50% - 370px/2);
        /* top: calc(50% - 339px/2 - 0.5px); */

        background: #FFFFFF;
        border: 1px solid #F2F2F2;
        /* Grey Shadow  2 */
        box-shadow: 0px 4px 8px -2px rgba(27, 46, 94, 0.04);
        border-radius: 32px;
    }
}
</style>
