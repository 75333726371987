<template>
  <div>
    <div class="main-layout">
      <div class="layout-title">
        <u-title-page title="โปรไฟล์" />
      </div>
      <div class="layout-content">
        <v-card class="layout-card" elevation="0">
          <v-card-text>
            <!-- TODO Perview Profile -->
            <v-row class="py-5">
              <v-col cols="12" sm="3">
                <div class="profile-image">
                  <u-upload-avata
                    v-model="files"
                    :src="profileUrl(this.data.image)"
                    :firstName="data.first_name"
                    :lastName="data.last_name"
                    @change="uploadImage"
                  ></u-upload-avata>
                </div>
              </v-col>
              <v-col>
                <div class="profile-preview">
                  <div class="content">
                    <div class="name">
                      {{ data.first_name }} {{ data.last_name }}
                    </div>
                    <div class="description">{{ data.phone }}</div>
                  </div>
                </div>
              </v-col>
            </v-row>

            <!-- TODO Form ในการกรอกข้อมูล -->
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <!-- TODO ข้อมูลส่วนตัว -->

                <v-col cols="12" sm="6" md="6" class="custom-col">
                  <div><span>ชื่อ</span></div>
                  <v-text-field
                    :rules="[Rule.noInPut, Rule.noSpace]"
                    outlined
                    dense
                    placeholder="ชื่อ"
                    v-model="data.first_name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="custom-col">
                  <div><span>นามสกุล</span></div>
                  <v-text-field
                    :rules="[Rule.noInPut, Rule.noSpace]"
                    outlined
                    dense
                    placeholder="นามสกุล"
                    v-model="data.last_name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <LineSeparator class="my-5" text="ข้อมูลส่วนตัว"></LineSeparator>
              <v-row>
                <v-col cols="12" sm="4" md="4" class="custom-col">
                  <div><span>เพศ</span></div>
                  <v-select
                    v-model="data.gender"
                    :items="gender"
                    item-text="text"
                    item-value="val"
                    :rules="[Rule.notNull]"
                    outlined
                    dense
                    placeholder="กรุณาเลือกเพศ"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="custom-col">
                  <div><span>คำนำหน้า</span></div>
                  <v-select
                    v-model="data.name_title"
                    :items="getGender()"
                    item-text="text"
                    item-value="val"
                    :rules="[Rule.notNull]"
                    outlined
                    dense
                    placeholder="กรุณาเลือกคำนำหน้า"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="4" md="4" class="custom-col">
                  <div>วันเกิด</div>
                  <u-date-picker
                    isBE
                    format="DD-MM-YYYY"
                    format_output="YYYY-MM-DD"
                    v-model="data.date_of_birth"
                    placeholder="วันเกิด"
                    :rules="[Rule.noInPut]"
                    :max="$moment().format('YYYY-MM-DD')"
                  ></u-date-picker>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="custom-col">
                  <div><span>อีเมล</span></div>
                  <v-text-field
                    :rules="[
                      Rule.noInPut,
                      Rule.noSpace,
                      Rule.englishOnly,
                      Rule.emailOnly,
                    ]"
                    outlined
                    dense
                    placeholder="อีเมล"
                    v-model="email"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="custom-col">
                  <div><span>เบอร์ติดต่อ</span></div>
                  <v-text-field
                    :rules="[
                      Rule.noInPut,
                      Rule.noSpace,
                      Rule.numberOnly,
                      Rule.phoneNumber,
                    ]"
                    outlined
                    dense
                    placeholder="เบอร์ติดต่อ"
                    v-model="data.phone"
                    maxlength="10"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <LineSeparator class="my-5" text="จัดการ"></LineSeparator>
                  <v-row>
                    <v-col>
                      <v-btn disabled block color="error">
                        ลบบัญชีผู้ใช้งาน
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn
                        :disabled="!valid"
                        block
                        color="primary"
                        @click="resetPassword"
                        :loading="loading"
                        >เปลี่ยนรหัสผ่าน</v-btn
                      >
                      <!-- @click="dialogForget = true" -->
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <LineSeparator class="my-5" text="เชื่อมต่อบัญชี">
                  </LineSeparator>
                  <v-row justify-content="center">
                    <v-col  style="text-align: end;">
                      <OauthBtn
                        rounded
                        block
                        :disabled="
                          data.methods.filter((x) => x.provider === 'oneid')
                            .length > 0
                        "
                        :loading="loading"
                        :image="require('@/assets/images/oneid.png')"
                        text="Connect OneID"
                        @click="OauthLogInOneID"
                      ></OauthBtn>
                    </v-col>
                    <v-col>
                      <OauthBtn
                        block
                        rounded
                        :disabled="
                          data.methods.filter(
                            (x) => x.provider === 'providerid'
                          ).length > 0
                        "
                        color="white"
                        :image="require('@/assets/images/providerid.png')"
                        text="provider ID"
                        @click="OauthLogInProviderID"
                      >
                      </OauthBtn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row align="center" justify="end">
              <v-col cols="12" md="3">
                <v-btn
                  :disabled="!valid"
                  block
                  color="primary"
                  :loading="loading"
                  @click="update"
                  >บันทึก</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="dd" persistent max-width="100">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-dialog>

    <DialogResetPassword
      v-model="dialogForget"
      @resetPasswordUser="resetPassword"
    />
  </div>
</template>

<script>
import DialogResetPassword from '@/components/Forms/DialogResetPassword.vue'

import OauthBtn from '@/components/Button/OauthBtn.vue'
import LineSeparator from '@/components/LineSeparator.vue'
export default {
  components: {
    OauthBtn,
    LineSeparator,
    DialogResetPassword
  },
  data () {
    return {
      dialogForget: false,
      nameTitleAll: [
        { text: 'นางสาว', val: 'นางสาว' },
        { text: 'นาง', val: 'นาง' },
        { text: 'นาย', val: 'นาย' },
        { text: 'ไม่ระบุ', val: '' }
      ],
      nameTitleMan: [
        { text: 'นาย', val: 'นาย' },
        { text: 'ไม่ระบุ', val: '' }
      ],
      nameTitleWomen: [
        { text: 'นางสาว', val: 'นางสาว' },
        { text: 'นาง', val: 'นาง' },
        { text: 'ไม่ระบุ', val: '' }
      ],
      gender: [
        { text: 'หญิง', val: 'หญิง' },
        { text: 'ชาย', val: 'ชาย' },
        { text: 'ไม่ระบุ', val: 'ไม่ระบุ' }
      ],
      valid: true,
      loading: false,
      email: '',
      data: {
        user_id: '',
        first_name: '',
        last_name: '',
        phone: '',
        gender: '',
        image: '',
        methods: [
          // {
          //   method_id: '',
          //   provider: '',
          //   id: '',
          //   email: '',
          //   username: '',
          //   description: '',
          //   status: true,
          //   verify: 0,
          //   create_date: '',
          //   update_date: ''
          // }
        ],
        date_of_birth: '',
        permissions: [],
        status: true,
        verify: 2,
        create_date: '',
        update_date: '',
        street: '',
        zip_code: '',
        city: '',
        country_code: '',
        country: '',
        description: '',
        name_title: ''
      },
      files: null,
      img: '',
      dd: false
    }
  },
  async created () {
    // ตรวจสอบว่ามี Authorization code ใน Query Parameter หรือไม่
    // if (this.$route.query.code !== undefined) {
    //   this.loading = true
    //   const payload = {
    //     authorization_code: this.$route.query.code,
    //     redirect_uri: `${window.location.origin}${process.env.VUE_APP_BASE_URL}home/profile`
    //   }
    //   const res = await this.ConnectOneIDAccount(payload)
    //   this.loading = false
    //   this.$router.replace({ query: null }).catch(() => {})
    //   if (!res.status) {
    //     this.antmessage.error(res.message)
    //   }
    // }
    // await this.init()
    if (this.$route.params.provider === 'providerid') {
      if (this.$route.query.code !== undefined) {
        this.loading = true
        const payload = {
          authorization_code: this.$route.query.code,
          redirect_uri: `${window.location.origin}${process.env.VUE_APP_BASE_URL}home/profile/providerid`

        }
        this.$router.replace({ query: null }).catch(() => {})
        // const res = await this.signInProvidAuthorizationCode(payload)
        const res = await this.ConnectProviderIDAccount(payload)
        this.loading = false
        // if (res.status) {
        //   localStorage.setItem('Accesstoken', res.data.accesstoken)
        //   // this.$router.push({ name: 'homePage' }).catch(() => {})
        // } else {
        // this.antmessage.error(res.message)
        // }
        if (!res.status) {
          this.antmessage.error(res.message)
        }
      }
    }
    if (this.$route.params.provider === 'oneid') {
      // ตรวจสอบว่ามี Authorization code ใน Query Parameter หรือไม่
      if (this.$route.query.code !== undefined) {
        this.loading = true
        const payload = {
          authorization_code: this.$route.query.code,
          // redirect_uri: `${window.location.origin}${process.env.VUE_APP_BASE_URL}home/profile`
          redirect_uri: `${window.location.origin}${process.env.VUE_APP_BASE_URL}home/profile/oneid`

        }
        const res = await this.ConnectOneIDAccount(payload)
        this.loading = false
        this.$router.replace({ query: null }).catch(() => {})
        if (!res.status) {
          this.antmessage.error(res.message)
        }
      }
    }
    await this.init()
  },
  methods: {
    async init () {
      this.loading = true
      const res = await this.getProfile()
      this.loading = false
      if (res.status) {
        this.data = res.data
        // console.log(this.data)
        const x = this.data.methods.filter((ele) => ele.provider === 'local')
        if (x.length > 0) {
          this.email = x[0].email
        }
      } else {
        this.antmessage.error(res.message)
      }
    },

    async uploadImage (val) {
      this.loading = true
      const formData = new FormData()
      for (const i of val) {
        formData.append('files', i)
      }
      const res = await this.UploadProfileImage(formData)
      this.loading = false
      if (res.status) {
        this.antmessage.success(res.message)
      } else {
        this.antmessage.error(res.message)
      }
    },
    async update () {
      this.loading = true
      const res = await this.updateProfile({
        phone: this.data.phone,
        email: this.email,
        first_name: this.data.first_name,
        last_name: this.data.last_name,
        gender: this.data.gender,
        name_title: this.data.name_title,
        date_of_birth: this.data.date_of_birth
      })
      this.loading = false
      if (res.status) {
        this.data = res.data
        this.antmessage.success(res.message)
      } else {
        this.antmessage.error(res.message)
      }
    },
    async resetPassword () {
      this.loading = true
      const res = await this.sendEmailresetPasswordUser({ email: this.email })
      if (res.status) {
        this.loading = false
        // this.antmessage.success(res.message)
        this.antmessage.success('send email success')
      } else {
        this.loading = false
        this.antmessage.error(res.message)
      }
    },
    getGender () {
      if (this.data.gender === 'ชาย') return this.nameTitleMan
      if (this.data.gender === 'หญิง') return this.nameTitleWomen
      else return this.nameTitleAll
    },
    async OauthLogInOneID () {
      // TODO Edit Here --------------------------
      const clientID = process.env.VUE_APP_ONEID_CLIENT_ID
      const redirectUri = `${window.location.origin}${process.env.VUE_APP_BASE_URL}home/profile/oneid`
      const responseType = 'code'
      const scope = 'title+first_name+last_name+birth_date+email+tel+pic'
      // TODO Edit Here --------------------------
      const uri = `${process.env.VUE_APP_ONEID_URL}/oauth/authorize?client_id=${clientID}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`
      location.replace(uri)
    },

    async OauthLogInProviderID () {
      // console.log(this.$route.params.provider)
      // TODO Replace Url and Reload
      const res = await this.getProviderIDCredential()
      const clientID = res.data.client_id
      const healthId = res.data.url
      // const clientID = '9b676974-d41b-4840-b747-9e34cbda23dc'
      const redirectUri = `${window.location.origin}${process.env.VUE_APP_BASE_URL}home/profile/providerid`
      const url = `${healthId}/oauth/redirect?client_id=${clientID}&redirect_uri=${redirectUri}&response_type=code`
      window.location.replace(url)
      // TODO Get Authen Code
      // const urlParams = new URLSearchParams(window.location.search)
      // const code = urlParams.get('code')
      // console.log(code)
      // TODO ขอ Accesstoken
      // console.log(code)
    }

  }
}
</script>

<style lang="scss" scoped>
/* // TODO สำหรับจัดการหน้าเพจทั้งหมด */
.main-layout {
  height: 100%;
}
/* // TODO สำหรับจัดการหน้าเพจทั้งหมด */
.main-layout {
  height: 100%;
}

/* // TODO สำหรับ จัดการ title ของเพจ */
.layout-title {
  height: 58px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* // TODO สำหรับจัดการ Content ของเพจ */
.layout-content {
  height: calc(100% - 58px);
  padding: 0;
  margin: 0;
  // background: gray;
}

.custom-col {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.profile-image {
  // background: pink;
  text-align: center;
  width: 100%;
  height: 100%;
}
.profile-preview {
  // background: pink;
  line-height: normal;
  text-align: start;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0em;
}

.profile-preview .content {
  line-height: 2rem;
}
.profile-preview .content .name {
  font-size: 40px;
  font-weight: 600;
}

.profile-preview .content .description {
  font-size: 20px;
  font-weight: 200;
}

.layout-card {
  width: 100%;
  height: 100%;
  background: none;
  // background: red;
}

.v-card__text {
  height: calc(100% - 52px);
  overflow-y: scroll;
}
</style>
