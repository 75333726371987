<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon color="primary" text @click="close()"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-actions>
        <v-carousel
          v-model="model"
          hide-delimiters
          hide-delimiter-background
          show-arrows-on-hover
          cycle
          :interval="interval"
        >
          <v-carousel-item v-for="(data, i) in announce" :key="data[i]">
            <v-row class="fill-height px-8" align="center" justify="center">
              <div class="display-announce output ql-snow">
                <div class="ql-editor" v-html="data.description"></div>
              </div>
            </v-row>
          </v-carousel-item>
        </v-carousel>
        <div class="page-row">{{ model + 1 }}/{{ announce.length }}</div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      interval: 5000,
      model: 0
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    announce: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
  },
  methods: {
    close () {
      this.dialog = false
      this.model = 0
    }
  }
}
</script>

<style scoped>
>>> .v-dialog > .v-card > .v-card__actions {
    padding: 0px 0px;
}
.page-row {
  text-align: center;
  padding-bottom: 10px;
}
.display-announce{
  overflow-y: auto;
  height: 100%;
}
</style>
