<template>
  <div class="body">
    <div class="content">
      <div class="value">{{ v }}</div>
      <div class="label">{{ description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'undefined'
    },
    value: {
      type: Number,
      default: 0
    },
    description: {
      type: String,
      default: 'undefined'
    },
    type: {
      type: String,
      default: 'none',
      validator: function (value) {
        return ['none', 'matrix', 'byte'].includes(value)
      }
    },
    digit: {
      type: Number,
      default: 0
    }
  },
  computed: {
    v () {
      switch (this.type) {
        case 'matrix':
          return this.Matrix(this.value, 2)
        case 'byte':
          return this.Bytes(this.value, 2)
        default:
          return this.value.toLocaleString('en-US')
      }
    }
  },
  methods: {
    // TODO เป็น function ในการ แปลงข้อมูลเป็น Byte
    // TODO REF https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascriptP
    Bytes (bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },

    // TODO เป็น function สำหรับแปลง value เป็น matrix
    // TODO REF https://stackoverflow.com/questions/2685911/is-there-a-way-to-round-numbers-into-a-reader-friendly-format-e-g-1-1k
    Matrix (number, decPlaces = 2) {
      // 2 decimal places => 100, 3 => 1000, etc
      decPlaces = Math.pow(10, decPlaces)
      // Enumerate number abbreviations
      const abbrev = ['K', 'M', 'B', 'T']
      // Go through the array backwards, so we do the largest first
      for (let i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        const size = Math.pow(10, (i + 1) * 3)
        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
          // Here, we multiply by decPlaces, round, and then divide by decPlaces.
          // This gives us nice rounding to a particular decimal place.
          number = Math.round((number * decPlaces) / size) / decPlaces
          // Handle special case where we round up to the next abbreviation
          if (number === 1000 && i < abbrev.length - 1) {
            number = 1
            i++
          }
          // Add the letter for the abbreviation
          number += ' ' + abbrev[i]
          // We are done... stop
          break
        }
      }
      return number
    }
  }
}
</script>

<style lang="css" scoped>
.body {
  width: 100%;
  height: 100%;
  position: relative;
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height : initial
}

.content .value {
  font-size: 50px;
  text-align: center;
  font-weight: 500;
  width: 100%;
}
.content .label {
  font-size: 18px;
  text-align: center;
  width: 100%;
}
</style>
