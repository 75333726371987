<template>
  <div
    :ref="`cardContainer${chartID}`"
    :id="`${elementId}`"
    class="card-pie-chart card-scale"
    :style="`width: ${px_w}; height:${px_h}`"
  >
    <div class="button-scale" v-show="!disabled">
      <v-icon class="button-scale-icon" icon size="13">
        mdi-arrow-top-left-bottom-right
      </v-icon>
      <div class="button-scale-filter" :id="`button-${elementId}`"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data () {
    return {
      arr: [],
      arrCard: [],
      percentCard: 0,
      displaySize: null,
      setCard: 0,
      update: false,
      saveValue: null,
      element: null,
      button: null,
      initialWidth: 0,
      initialHeight: 0,
      mouseX: 0,
      mouseY: 0
    }
  },
  props: {
    min: {
      type: Number,
      default: 250
    },
    chartID: {
      type: String,
      default: ''
    },
    widthCard: {
      type: Number,
      default: 500
    },
    heightCard: {
      type: Number,
      default: 500
    },
    bgColor: {
      type: String,
      default: 'gray'
    },
    widthDisplay: {
      type: String,
      default: ''
    },
    saveVal: {
      type: Boolean,
      default: false
    },
    // TODO New Zone --------
    parentHeight: {
      // NOTE PX
      type: Number,
      default: 0
    },
    parentWidth: {
      // NOTE PX
      type: Number,
      default: 0
    },
    width: {
      // NOTE Percent
      type: String,
      default: '0'
    },
    height: {
      // NOTE Percent
      type: String,
      default: '0'
    },
    elementId: {
      type: String,
      default: 'undefined'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    px_w: {
      get () {
        const v = parseInt(
          (parseInt(this.width) * parseInt(this.parentWidth)) / 100
        )
        return v + 'px'
      },
      set (val) {
        // NOTE Val is PX
        const valPercent = parseInt((val / parseInt(this.parentWidth)) * 100)
        this.$emit('update:width', `${valPercent}`)
        return valPercent
      }
    },
    px_h: {
      get () {
        const v = parseInt(
          (parseInt(this.height) * parseInt(this.parentHeight)) / 100
        )
        return v + 'px'
      },
      set (val) {
        // NOTE Val is PX
        const valPercent = parseInt((val / parseInt(this.parentHeight)) * 100)
        this.$emit('update:height', `${valPercent}`)
        return valPercent
      }
    }
  },
  mounted () {
    this.resize(`${this.elementId}`, `button-${this.elementId}`)
  },
  methods: {
    getMousePositions (e) {
      this.mouseX = e.pageX
      this.mouseY = e.pageY
    },
    getElementDimensions () {
      this.initialWidth = this.element.clientWidth
      this.initialHeight = this.element.clientHeight
    },
    removeScale (e) {
      this.$emit('onResize', this.elementId)
      window.removeEventListener('mousemove', this.scale)
      window.removeEventListener('mouseup', this.removeScale)
    },
    scale (e) {
      const width = this.initialWidth + (e.pageX - this.mouseX)
      const height = this.initialHeight + (e.pageY - this.mouseY)
      if (width > this.min) {
        this.element.style.width = width + 'px'
        this.px_w = width
      }
      if (height > this.min) {
        this.element.style.height = height + 'px'
        this.px_h = height
      }
    },
    resize (elementId, buttonId) {
      this.element = document.getElementById(elementId)
      this.button = document.getElementById(buttonId)
      this.button.addEventListener('mousedown', (e) => {
        this.getMousePositions(e)
        this.getElementDimensions()
        window.addEventListener('mousemove', this.scale)
        window.addEventListener('mouseup', this.removeScale)
      })
    }
  }
}
</script>
<style scoped>
.card-pie-chart {
  border-radius: 10px;
  align-items: center;
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2); */
  /* padding-top: 30px; */
  /* padding-bottom: 30px; */
  padding: 0;
  margin: 0;
}

.card-scale {
  /* background-color: green !important; */
  position: relative !important;
  z-index: 0;
}
.button-scale {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  /* background-color: red; */
  z-index: 999 !important;
  border-radius: inherit;
}
.button-scale .button-scale-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0 !important;
  /* background-color: green; */
  transform: translate(-50%, -50%);
}
.button-scale .button-scale-filter {
  /* background-color: pink !important; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999 !important;
  border-radius: inherit;
}
.button-scale:hover {
  /* background-color: green; */
  cursor: nwse-resize;
}
.card-pie-chart-1 {
  /* display: flex; */
  /* width: 16.35%; */
  /* min-height: 403px; */
  border-radius: 10px;
  align-items: center;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: pink;
}
</style>
