<template>
  <div class="layout-switch">
    <div class="lang en-lang mr-2">
      <span> TH </span>
      <!-- <v-avatar class="mr-2" size="30">
        <img src="@/assets/flags/th-flag.svg" alt="John" />
      </v-avatar> -->
    </div>
    <label class="switch">
      <input
        v-model="status"
        type="checkbox"
        name=""
        id=""
        @click="$emit('click', '')"
      />
      <div class="slider"></div>
    </label>
    <div class="lang en-lang ml-2 ">
      <span> EN </span>
      <!-- <v-avatar class="ml-2" size="30">
        <img src="@/assets/flags/en-flag.svg" alt="John" />
      </v-avatar> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: 'mdi-lightbulb-on'
    },
    appendIcon: {
      type: String,
      default: 'mdi-lightbulb-outline'
    }
  },

  // created () {
  //   const darkModeCookie = this.$cookies.get('darkMode')
  //   this.status = Boolean(darkModeCookie)
  // },
  computed: {
    // status: {
    //   get () {
    //     // console.log('get')
    //     const darkModeCookie = this.$cookies.get('app.darkMode')
    //     this.$vuetify.theme.dark = Boolean(darkModeCookie)
    //     return Boolean(darkModeCookie)
    //   },
    //   set (val) {
    //     // console.log('set')
    //     this.$vuetify.theme.dark = val
    //     this.$cookies.set('app.darkMode', val)
    //     // this.$emit('input', val)
    //   }
    status: {
      get () {
        return this.value
      },
      set (val) {
        // this.$cookies.set('darkMode', val)
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.layout-switch {
  display: flex;
  justify-content: center;
  align-content: center;
}

.lang {
  color: var(--v-primary-base);
  font-size: 15px;
  padding-top: 3px;
}
:root {
  --bg1: #e0e0e0e0;
  --bg2: #100f12;
  --orange: #ffc207;
  --dark-purple: #27173a;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px; /*30px*/
  border-radius: 50px;
  overflow: hidden;
}

input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  /* background-color: #27173a; */
  background: var(--v-swbg-base);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  transition: all 0.4s;
  cursor: pointer;
}

.slider::before {
  position: absolute;
  content: "";
  background: #ffc207;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  left: 5px;
  bottom: 5px; /**5px */
  transition: 0.4s;
}

.slider::after {
  position: absolute;
  content: "";
  /* background: #27173a; */
  background: var(--v-swbg-base);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  left: -26px;
  bottom: 4px;
  transition: 0.4s;
}

input:checked + .slider::before {
  transform: translateX(30px);
}

input:checked + .slider {
  background: var(--v-primary-base);
}

/* input:checked + .slider::after {
  transform: translateX(50px);
  background: var(--v-primary-base);
} */
</style>
