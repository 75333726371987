<template>
  <div class="pa-0 ma-0" style="width: 100%; height: 100%">
    <v-card style="height: 100%; width: 100%; background: none" class="pa-0">
      <v-card-title style="height: 72px" class="px-0">
        <v-row justify="space-between" align-content="center">
          <!-- // NOTE ปุ่มสำหรับ Add Widget -->
          <v-col cols="2">
            <v-btn
              color="primary"
              block
              dark
              @click="dialog = !dialog"
              v-if="checkPermission(['admin'])"
            >
              <v-icon>mdi-plus</v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">
                เพิ่ม{{ NameComponent }}
              </span>
            </v-btn>
          </v-col>

          <v-col cols="10">
            <v-row justify="end" align-content="center">
              <!-- // NOTE ส่วนสำหรับช่อง Filter -->
              <v-col cols="6">
                <wSearch
                  @getDataForSearch="DataForSearch"
                  :items="listFields"
                />
              </v-col>

              <!-- // NOTE ส่วนสำหรับ Filter Data Time -->
              <v-col cols="2">
                <u-date-time-picker
                  isBE
                  v-model="datetime.start"
                  label="เริ่มต้น"
                  :min="mainDataStart"
                  :max="datetime.end"
                  input-format="YYYY-MM-DD HH:mm:ss"
                  show-format="DD-MM-YYYY HH:mm:ss"
                ></u-date-time-picker>
              </v-col>
              <v-col cols="2">
                <u-date-time-picker
                  isBE
                  v-model="datetime.end"
                  label="สิ้นสุด"
                  :min="datetime.start"
                  :max="$moment().format('YYYY-MM-DD HH:mm:ss')"
                  show-format="DD-MM-YYYY HH:mm:ss"
                ></u-date-time-picker>
              </v-col>

              <!-- // NOTE ส่วนสำหรับ Update ข้อมูล -->
              <v-col cols="2">
                <v-btn block color="primary" @click="getDataAll()">
                  <v-icon>mdi-magnify</v-icon>
                  <span v-if="!$vuetify.breakpoint.mobile"> ค้นหา </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <!-- // NOTE ส่วนสำหรับแสดง Dashboard -->
      <v-card-text style="height: calc(100% - 72px)" class="px-0">
        <div class="main-layout" :id="rootElement" v-resize="resizeParent">
          <resize
            :parent-height="parentHeight"
            :parent-width="parentWidth"
            :width.sync="item.layout.width"
            :height.sync="item.layout.height"
            v-for="(item, idx) in items"
            :key="item.widget_id"
            :class="[`item`, `item-drag-drop`]"
            :idx="item.id"
            :element-id="`item-card-${item.widget_id}`"
            @onResize="onEndResizeWidget"
            :min="250"
            :disabled="!checkPermission(['admin'])"
          >
            <div
              class="content content-item"
              :idx="item.id"
              :id="`content-item-${item.id}`"
              :draggable="statusDragdrop"
              @dragstart="dragStart(idx, $event)"
              @dragover.prevent
              @dragenter="dragEnter(idx, $event, item.id)"
              @dragleave="dragLeave(idx, $event)"
              @dragend="dragEnd"
              @drop="dragDrop(idx, $event)"
            >
              <!-- // NOTE Title ของ Widget -->
              <div class="title" :idx="item.id" v-if="item.type != 'label'">
                {{ item.title }}
              </div>

              <!-- // NOTE Button Setting ของ Widget -->
              <div
                class="setting-btn item-btn"
                :id="`btn-setting-item-${item.id}`"
                :idx="item.id"
                @click="editItem(item)"
                v-if="checkPermission(['admin'])"
              >
                <v-icon :idx="item.id">mdi-cog-outline</v-icon>
              </div>

              <!--  // NOTE Button Delete Widget  -->
              <div
                class="delete-btn item-btn"
                :id="`btn-delete-item-${item.id}`"
                :idx="item.id"
                @click="deleteItem(item)"
                v-if="checkPermission(['admin'])"
              >
                <v-icon :idx="item.id">mdi-delete-outline</v-icon>
              </div>

              <!-- // NOTE Button ปรับ Scale ของ Widget -->
              <!-- <div
                class="scale-btn item-btn"
                :id="`btn-scale-item-${item.id}`"
                :idx="item.id"
                v-if="checkPermission(['admin'])"
                @mousedown="mouseEnter(idx, $event)"
                @mousemove="mouseMove(idx, $event)"
                @mouseleave="mouseLeave(idx, $event)"
              >
                <v-icon :idx="item.id">mdi-arrow-bottom-right</v-icon>
              </div> -->
              <!-- // NOTE Button Move ของ Widget -->
              <div
                class="move-btn item-btn"
                :id="`btn-move-item-${item.id}`"
                :idx="item.id"
                @mouseenter="() => (statusDragdrop = true)"
                @mouseleave="() => (statusDragdrop = false)"
                v-if="checkPermission(['admin'])"
              >
                <v-icon :idx="item.id">mdi-arrow-all</v-icon>
              </div>

              <!-- // NOTE Content ของ Widget -->
              <div class="content" :idx="item.id" style="">
                <div
                  style="
                    width: 100%;
                    height: 100%;
                    padding: 1rem;
                    padding-top: 32px;
                  "
                >
                  <!-- {{ item.data.labels }} -->

                  <!-- NOTE สำหรับ Widget Text -->
                  <wText
                    v-if="item.type == 'text'"
                    :value="item.data?.datasets[0]?.data[0]"
                    :description="item.options.text_operation"
                    :type="item?.options?.text_unit"
                  >
                  </wText>

                  <!-- NOTE สำหรับ Widget pie -->
                  <wPie
                    v-if="item.type == 'pie'"
                    :labels="item.data?.labels"
                    :datasets="item.data?.datasets"
                    :dataField="item.field"
                  >
                  </wPie>

                  <!-- NOTE สำหรับ Widget bar -->
                  <wBar
                    v-if="item.type == 'bar'"
                    :indexAxis="item.options.indexAxis"
                    :labels="item.data?.labels"
                    :datasets="item.data?.datasets"
                    :dataField="item.field"
                    :dataType="item.type"
                  >
                  </wBar>

                  <!-- NOTE สำหรับ widget line -->
                  <wLine
                    v-if="item.type == 'line'"
                    :labels="item.data?.labels"
                    :datasets="item.data?.datasets"
                  >
                  </wLine>

                  <!-- NOTE สำหรับ Widget Table -->
                  <wTable
                    v-if="item.type == 'table'"
                    :headers="item.headers"
                    :items="item.data?.items"
                    :pagination.sync="item.data.pagination"
                    @onChangePagination="getData()"
                    item-key="_id"
                    show-expand
                    single-expand
                  >
                  </wTable>

                  <!-- FIX เดี๋ยวต้องมาตามแก้ไข ---------------------------->
                  <!-- FIX log_average_monitor -->
                  <!-- NOTE สำหรับ Widget Text สำหรับ Average monitor -->
                  <wText
                    v-if="item.type == 'log_average_mornitor'"
                    :value="item.data?.datasets[0]?.data[0]"
                    :description="item.data?.labels[0]"
                    :type="item.datatype"
                  >
                  </wText>

                  <!-- NOTE สำหรับ Widget Text เพื่อที่ Show Storage size -->
                  <w-text
                    v-if="item.type == 'storage_size'"
                    :value="item.data?.datasets[0]?.data[0]"
                    :description="item.data?.labels[0]"
                    :type="item?.options?.text_unit"
                  >
                  </w-text>

                  <!-- FIX event_count -->
                  <wBar
                    v-if="item.type == 'event_count'"
                    :indexAxis="item.options.indexAxis"
                    :labels="item.data?.labels"
                    :datasets="item.data?.datasets"
                    :type="item.type"
                  >
                  </wBar>

                  <!-- NOTE เป็น Widget สำหรับแสดง Event Per Seconds -->
                  <wLine
                    v-if="item.type == 'eps'"
                    :labels="item.data?.labels"
                    :datasets="item.data?.datasets"
                  >
                  </wLine>

                  <!-- NOTE เป็น Widget สำหรับแสดง Event Count ของข้อมูล -->
                  <wBar
                    v-if="item.type == 'ec'"
                    :indexAxis="
                      item.options.indexAxis == ''
                        ? 'x'
                        : item.options.indexAxis
                    "
                    :labels="item.data?.labels"
                    :datasets="item.data?.datasets"
                    :dataType="item.type"
                  >
                  </wBar>

                  <!-- NOTE เป็น Widget สำหรับแสดง List -->
                  <wList
                    v-if="item.type == 'list'"
                    :headers="item.data?.headers"
                    :items="item.data?.items"
                    :dataField="item.field"
                  >
                  </wList>

                  <!-- NOTE เป็น Widget สำหรับแสดง Label -->
                  <wLabel
                    v-if="item.type == 'label'"
                    :title="item.title"
                    :description="item.description"
                  >
                  </wLabel>

                  <!-- NOTE เป็น Widget สำหรับแสดง Gauge -->
                  <wGauge
                    v-if="item.type == 'gauge'"
                    :value="item.data.gauge.value"
                    :title="item.data.gauge.title"
                  >
                  </wGauge>

                  <!-- NOTE เป็น Widget สำหรับแสดงผล Histogram -->
                  <w-histogram
                    v-if="item.type == 'composition_histogram'"
                    :datasets="item.data?.dataset"
                    :labels="item.data?.labels"
                    :composite="item.data?.composit"
                    :show-detail="item.data?.composit.length > 0"
                  >
                  </w-histogram>

                  <w-group
                    v-if="item.type == 'group'"
                    :datasets="item.data?.dataset"
                    :composite="item.data?.composit"
                    :show-detail="item.data?.composit.length > 0"
                  ></w-group>
                  <!-- FIX เดี๋ยวต้องมาตามแก้ไข ---------------------------->
                  <wComposite
                    v-if="item.type == 'composite'"
                    :data="item.data?.composit"
                    show-total
                  >
                  </wComposite>

                  <wCompHis
                    v-if="item.type == 'composite_histrogram'"
                    :datasets="item.data?.dataset"
                    :labels="item.data?.labels"
                    :composite="item.data?.composit"
                    :show-detail="item.data?.composit.length > 0"
                  >
                  </wCompHis>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </resize>
        </div>
      </v-card-text>
    </v-card>

    <!-- NOTE Form สำหรับ Edit ข้อมูล -->
    <u-card-form
      :title="formTitle"
      :subtitle="formSubTitle"
      ref="form"
      v-model="dialog"
    >
      <template v-slot:form>
        <v-row no-gutters>
          <!-- TODO Widget Title -->
          <v-col cols="12" class="pr-1">
            <u-text-field
              v-model="editedItem.title"
              required
              title="ชื่อ widget"
              :rules="[Rule.noInPut]"
            ></u-text-field>
          </v-col>
          <field-set title="General configuration">
            <v-row>
              <!-- TODO Widget Type  -->
              <v-col cols="12" class="">
                <u-selector
                  v-model="editedItem.type"
                  :items="widgetTypes"
                  required
                  title="Widget Type"
                  item-text="name"
                  item-value="value"
                  :rules="[Rule.noInPut]"
                  @change="updateWidgetQuery"
                >
                </u-selector>
              </v-col>
              <!-- TODO Widget Field Time to Query -->
              <v-col
                cols="12"
                class=""
                v-if="
                  editedItem.sync_index &&
                  [
                    'bar',
                    'line',
                    'pie',
                    'list',
                    'text',
                    'ec',
                    'eps',
                    'gauge',
                    'table',
                    'composition_histogram',
                    'group',
                  ].includes(editedItem.type)
                "
              >
                <u-autocomplete
                  v-model="editedItem.time_field"
                  :items="listFields.filter((e) => e.type == 'date')"
                  title="Time Field"
                  placeholder="input here"
                  :rules="[Rule.noInPut]"
                  item-text="text"
                  item-value="value"
                  @input="updateWidgetQuery"
                >
                </u-autocomplete>
              </v-col>

              <!-- TODO Widget Sync Index From Menu -->
              <v-col cols="6">
                <v-switch
                  v-model="editedItem.sync_index"
                  inset
                  false-value="true"
                  true-value="false"
                  :label="`Custom Query`"
                  disabled
                ></v-switch>
              </v-col>

              <v-col cols="6">
                <v-switch
                  v-model="editedItem.custom_filter_status"
                  inset
                  :label="`Static Filter`"
                >
                </v-switch>
              </v-col>
            </v-row>
          </field-set>

          <!-- TODO ส่วนสำหรับทำ Custom filter  -->
          <v-col cols="12" v-if="editedItem.custom_filter_status">
            <!-- NOTE ส่วนสำหรับ config Custom filter  -->
            <field-set title="Static Filter configuration">
              <!-- TODO ส่วนสำหรับ Layout สำหรับ Add และ Edit Field In must -->
              <v-row
                style="padding: 0; margin: 0; width: 100%"
                justify="end"
                align-item="center"
              >
                <!-- TODO ส่วนสำหรับ Edit ข้อมูล -->
                <v-col
                  cols="12"
                  v-for="(cqm_item, cqm_idx) in currentQueryMust"
                  :key="`filter-index-${cqm_idx}`"
                >
                  <v-row
                    style="padding: 0; margin: 0; width: 100%"
                    justify="center"
                    align-item="end"
                  >
                    <v-col cols="4" style="padding-right: 4px">
                      <u-selector
                        :items="listFields"
                        required
                        title="Key"
                        item-text="text"
                        item-value="value"
                        :rules="[Rule.noInPut]"
                        v-model="cqm_item.key"
                      />
                    </v-col>
                    <v-col cols="3">
                      <u-selector
                        :items="[
                          { text: 'AND', value: 'and' },
                          { text: 'OR', value: 'or' },
                          { text: 'NOT', value: 'not' }
                        ]"
                        required
                        title="Operation"
                        item-text="text"
                        item-value="value"
                        :rules="[Rule.noInPut]"
                        v-model="cqm_item.operation"
                      />
                    </v-col>
                    <v-col cols="4" style="padding-left: 4px">
                      <u-text-field
                        required
                        title="Value"
                        :rules="[]"
                        v-model="cqm_item.query"
                      />
                    </v-col>
                    <v-col cols="1" style="position: relative; width: 100%">
                      <div
                        style="
                          position: absolute;
                          top: 50%;
                          right: 0;
                          transform: translate(0, -50%);
                        "
                      >
                        <v-btn
                          color="error"
                          fab
                          x-small
                          @click="onRemoveCustomFilterMust(cqm_idx)"
                        >
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- TODO ส่วนสำหรับปุ่ม Append Value in must -->
                <v-col cols="1">
                  <div style="text-align: end; padding-bottom: 1rem">
                    <v-btn
                      color="success"
                      fab
                      x-small
                      @click="onAddCustomFilterMust"
                    >
                      <!-- @click="() => editedItem.query.query.bool.must.push({`${listFields[0]:{}}`})" -->
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </field-set>

            <!-- <fieldset style="border: 1px solid #000;width:100%">
              <legend style="width:auto">AAA</legend>

              <v-row>
                <v-col>
                  <u-selector
                    :items="listFields"
                    required
                    title="Data Field filter"
                    item-text="text"
                    item-value="value"
                    :rules="[Rule.noInPut]"
                  />
                </v-col>
                <v-col>
                  <u-text-field required title="Custom filter" :rules="[]" />
                </v-col>
                <v-col>action</v-col>
              </v-row>
            </fieldset> -->

            <!-- {{ editedItem.query.query.bool.must }} -->
            <!-- <u-text-field required title="Custom filter" :rules="[]">
            </u-text-field> -->
          </v-col>
          <!-- TODO Widget Custom Index Not Sync Menu Index -->
          <v-col cols="12" v-if="!editedItem.sync_index">
            <u-text-field
              v-model="editedItem.path"
              required
              title="Path"
              :rules="[Rule.noInPut]"
            >
            </u-text-field>
          </v-col>

          <!-- TODO Widget Field to get data -->
          <v-col
            cols="12"
            v-if="
              editedItem.sync_index &&
              [
                'bar',
                'line',
                'pie',
                'list',
                'text',
                'ec',
                'eps',
                'gauge',
                'table',
                'composition_histogram',
                'group',
              ].includes(editedItem.type)
            "
          >
            <field-set title="Output configuration">
              <v-row
                ><v-col cols="12">
                  <u-autocomplete
                    v-model="editedItem.field"
                    :items="listFields"
                    title="Data Field"
                    placeholder="input here"
                    :rules="[Rule.noInPut]"
                    item-text="text"
                    item-value="value"
                    @input="updateWidgetQuery"
                    @change="() => (editedItem.options.gauge_fields = [])"
                  >
                  </u-autocomplete>
                </v-col>
                <!-- TODO Widget Bar Index Axis to Present (X,Y) -->
                <v-col
                  cols="12"
                  v-if="['bar', 'line'].includes(editedItem.type)"
                >
                  <u-selector
                    v-model="editedItem.options.indexAxis"
                    :items="[
                      { name: 'X', value: 'x' },
                      { name: 'Y', value: 'y' },
                    ]"
                    required
                    title="Index Axis"
                    item-text="name"
                    item-value="value"
                    :rules="[Rule.noInPut]"
                  ></u-selector>
                </v-col>
                <v-col
                  cols="6"
                  class="pr-1"
                  v-if="['text', 'storage_size'].includes(editedItem.type)"
                >
                  <u-selector
                    v-model="editedItem.options.text_operation"
                    :items="TextOperation"
                    required
                    title="Operation"
                    item-text="name"
                    item-value="value"
                    :rules="[Rule.noInPut]"
                    @input="updateWidgetQuery"
                  >
                  </u-selector>
                </v-col>
                <v-col
                  cols="6"
                  class="pl-1"
                  v-if="['text', 'storage_size'].includes(editedItem.type)"
                >
                  <u-selector
                    v-model="editedItem.options.text_unit"
                    :items="TextUnit"
                    required
                    title="Unit"
                    item-text="name"
                    item-value="value"
                    :rules="[Rule.noInPut]"
                  >
                  </u-selector>
                </v-col>

                <!-- NOTE สำหรับเลือกจำนวนกลุ่มของข้อมูล -->
                <v-col
                  cols="12"
                  v-if="
                    ['bar', 'line', 'pie', 'list'].includes(editedItem.type) &&
                    editedItem.sync_index
                  "
                >
                  <u-text-field
                    v-model="editedItem.size"
                    required
                    title="Size Of Content"
                    :rules="[Rule.noInPut, ruleDataSize]"
                    @input="updateWidgetQuery"
                  >
                  </u-text-field>
                </v-col>

                <!-- NOTE สำหรับเลือกข้อมูลที่ต้องการแสดง -->
                <v-col cols="12" v-if="['gauge'].includes(editedItem.type)">
                  <u-selector
                    multiple
                    v-model="editedItem.options.gauge_fields"
                    :items="gaugeFields"
                    required
                    item-text="key"
                    item-value="key"
                    title="Gauge Filter Field"
                    :rules="[Rule.noInPut]"
                  >
                  </u-selector>
                </v-col>

                <!-- NOTE TABLE Column -->
                <v-col
                  cols="12"
                  v-if="
                    ['table'].includes(editedItem.type) && editedItem.sync_index
                  "
                >
                  <u-selector
                    multiple
                    v-model="editedItem.headers"
                    :items="listFields"
                    required
                    return-object
                    title="Table Column"
                    item-text="text"
                    :rules="[]"
                  ></u-selector>
                </v-col>
              </v-row>
            </field-set>
          </v-col>

          <v-col cols="12" class="p-0">
            <field-set title="Widget Size configuration">
              <v-row>
                <v-col cols="6" class="pr-1">
                  <u-text-field
                    v-model="editedItem.layout.width"
                    required
                    title="width (percent of screen width)"
                    :rules="[Rule.noInPut, Rule.noSpace, ruleWidget]"
                  >
                  </u-text-field>
                </v-col>
                <v-col cols="6" class="pl-1">
                  <u-text-field
                    v-model="editedItem.layout.height"
                    required
                    title="Height (percent of screen height)"
                    :rules="[Rule.noInPut, Rule.noSpace, ruleHeigth]"
                  >
                  </u-text-field>
                </v-col>
              </v-row>
            </field-set>
          </v-col>

          <!-- FIX 2024-08-12 ต้องมาแก้เรื่องของการทำ custom query ด้วย -->
          <v-col cols="12" v-if="!editedItem.sync_index">
            <LineSeparator text="Custom Query"></LineSeparator>
          </v-col>

          <v-col cols="12" v-if="!editedItem.sync_index">
            <!-- <v-col cols="12"> -->
            <u-text-area
              class="pb-5"
              json
              required
              title="Query"
              v-model="editedItem.query"
            ></u-text-area>
          </v-col>
          <!-- FIX 2024-08-12 ต้องมาแก้เรื่องของการทำ custom query ด้วย -->

          <v-col
            cols="12"
            v-if="['bar', 'line', 'pie', 'ec', 'eps'].includes(editedItem.type)"
          >
            <!-- <LineSeparator text="Colors"></LineSeparator>  -->
            <field-set title="Colors configuration">
              <v-row
                style="padding: 0; margin: 0; width: 100%"
                justify="end"
                align-item="center"
              >
                <v-col
                  cols="12"
                  v-for="(c, idx) in editedItem.options.colors"
                  :key="idx"
                  class="pb-2"
                >
                  <v-row>
                    <v-col cols="11">
                      <ColorPickerItem
                        v-model="editedItem.options.colors[idx]"
                      />
                    </v-col>
                    <v-col cols="1" style="position: relative; width: 100%">
                      <div
                        style="
                          position: absolute;
                          top: 50%;
                          right: 0;
                          transform: translate(0, -50%);
                        "
                      >
                        <v-btn
                          fab
                          x-small
                          color="error"
                          class="mt-2"
                          @click="
                            () => editedItem.options.colors.splice(idx, 1)
                          "
                        >
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1">
                  <div style="text-align: end; padding-bottom: 1rem">
                    <v-btn
                      color="success"
                      @click="() => editedItem.options.colors.push('#1976D233')"
                      fab
                      x-small
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </field-set>
          </v-col>

          <v-col cols="12" v-if="['label'].includes(editedItem.type)">
            <u-text-area
              class="pb-5"
              v-model="editedItem.description"
              title="รายละเอียด"
              :rules="[Rule.noInPut]"
            ></u-text-area>
          </v-col>
          {{ items.widget_id }}
        </v-row>
      </template>
      <template v-slot:actions="{ valid }">
        <v-row>
          <v-col>
            <v-btn
              @click="save"
              :disabled="!valid"
              block
              color="primary"
              :loading="loadingSave"
            >
              บันทึก
            </v-btn>
          </v-col>
          <v-col>
            <v-btn block color="error" @click="close">ยกเลิก</v-btn>
          </v-col>
        </v-row>
      </template>
    </u-card-form>

    <!-- NOTE Form filter data  -->
    <u-card-form
      title="Advance Filter (Beta)"
      subtitle="สำหรับ Advance Filter ข้อมูล"
      ref="formSearch"
      v-model="advanceSearch"
      icon="mdi-magnify"
    >
      <template v-slot:actions="{ valid }">
        <v-row>
          <v-col>
            <v-btn
              @click="save"
              :disabled="!valid"
              block
              color="primary"
              :loading="loadingSave"
            >
              บันทึก
            </v-btn>
          </v-col>
          <v-col>
            <v-btn block color="error" @click="() => (advanceSearch = false)"
              >ยกเลิก</v-btn
            >
          </v-col>
        </v-row>
      </template>
    </u-card-form>
  </div>
</template>

<script>
// REF DragDrop
// https://codepen.io/pasoevi/pen/ooOrpo
// https://codepen.io/theomj/pen/PyeXov
// https://web.dev/articles/drag-and-drop

// REF Resize
// https://codepen.io/colinah/pen/MWeKMdz?editors=1010
// https://phuoc.ng/collection/html-dom/make-a-resizable-element/
// https://stackoverflow.com/questions/51873582/how-do-i-get-the-mousemove-event-to-function-correctly-inside-a-vue-component
// https://stackoverflow.com/questions/2614461/javascript-get-mouse-position-relative-to-parent-element

// REf V-mask
// https://codepen.io/JamieCurnow/pen/KKPjraK
// https://stackoverflow.com/questions/47857673/how-to-use-mask-in-vuetify-text-field

import wText from '@/components/widgets/widget-text.vue'
import wPie from '@/components/widgets/widget-pie-chart.vue'
import wBar from '@/components/widgets/widget-bar-chart.vue'
import wLine from '@/components/widgets/widget-line-chart.vue'
import wTable from '@/components/widgets/widget-table.vue'
import wList from '@/components/widgets/widget-list.vue'
import LineSeparator from '@/components/LineSeparator.vue'
// import wDoughnut from './widget-doughnut-chart.vue'
import wComposite from '@/components/widgets/widget-composite.vue'
import wCompHis from '../../../components/widgets/widget-composite-histogram.vue'
import wHistogram from '@/components/widgets/widget-histogram.vue'
import wGroup from '@/components/widgets/widget-group.vue'
import { mapGetters } from 'vuex'
import ColorPickerItem from '@/components/ColorPickerItem.vue'
import wLabel from '@/components/widgets/widget-label.vue'
import wGauge from '@/components/widgets/widget-gauge.vue'
import wSearch from '@/components/widgets/widget-search-bar.vue'
import resize from '@/components/resizeBox_v2.vue'
import fieldSet from '@/components/FieldSet.vue'
export default {
  components: {
    wText,
    wPie,
    wBar,
    wLine,
    wTable,
    wList,
    wComposite,
    wCompHis,
    wHistogram,
    wGroup,
    LineSeparator,
    ColorPickerItem,
    wLabel,
    wGauge,
    // wDoughnut
    wSearch,
    resize,
    fieldSet
  },
  props: {
    projectId: {
      type: String,
      default: ''
    },
    menuId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      ruleWidget: (v) => (v >= 20 && v <= 100) || 'ข้อมูลไม่ถูกต้อง 20 - 100',
      ruleHeigth: (v) => (v >= 5 && v <= 100) || 'ข้อมูลไม่ถูกต้อง 5 - 100',
      ruleDataSize: (v) => (v >= 1 && v <= 50) || 'ข้อมูลไม่ถูกต้อง 1 - 50',

      value1: '',
      datetime: {
        start: this.$moment().subtract(1, 'h').format('YYYY-MM-DD HH:mm:ss'),
        end: this.$moment().format('YYYY-MM-DD HH:mm:ss')
      },
      advanceSearch: false,
      currentElementScale: '', // NOTE สำหรับเก็บ Id ของElement ที่มีการ Click Edit Scale
      c: 'main-layout-scale',
      NameComponent: 'วิดเจ็ต',
      dialog: false,
      interval: null,
      defaultQuery: {
        count: {
          aggs: {
            result: {
              value_count: {
                field: ''
              }
            }
          },
          query: {
            bool: {
              must: [],
              must_not: [],
              should: []
            }
          },
          size: '0'
        },
        sum: {
          aggs: {
            result: {
              sum: {
                field: ''
              }
            }
          },
          query: {
            bool: {
              must: [],
              must_not: [],
              should: []
            }
          },
          size: '0'
        },
        // TODO Data Histogram (line,bar,list)
        term: {
          aggs: {
            result: {
              terms: {
                field: '',
                order: {
                  _count: 'desc'
                },
                size: '10'
              }
            }
          },
          query: {
            bool: {
              must: [],
              must_not: [],
              should: []
            }
          },
          size: '0'
        },
        // TODO Data Histogram (ec,eps)
        histogram: {
          aggs: {
            result: {
              date_histogram: {
                field: '@timestamp',
                fixed_interval: '3h',
                time_zone: 'Asia/Bangkok'
              }
            }
          },
          query: {
            bool: {
              must: [],
              must_not: [],
              should: []
              // bool: {
              //   should: []
              // }
            }
          },
          size: '0'
        },
        composition_histogram: {
          aggs: {
            result: {
              composite: {
                size: '65536',
                sources: [
                  {
                    date: {
                      date_histogram: {
                        field: '@timestamp',
                        fixed_interval: '1m',
                        format: 'yyyy-MM-dd HH:mm:ss',
                        order: 'asc'
                      }
                    }
                  },
                  {
                    result: {
                      terms: {
                        field: 'Action.keyword'
                      }
                    }
                  }
                ]
              }
            },
            resultCount: {
              composite: {
                sources: [
                  {
                    result: {
                      terms: {
                        field: 'Action.keyword'
                      }
                    }
                  }
                ]
              }
            }
          },
          size: '0'
        },
        group: {
          aggs: {
            resultCount: {
              composite: {
                sources: [
                  {
                    result: {
                      terms: {
                        field: 'Action.keyword'
                      }
                    }
                  }
                ]
              }
            }
          },
          size: '0'
        },
        // TODO Data Table (table)
        table: {
          aggs: {
            result: {
              value_count: {
                field: ''
              }
            }
          },
          from: '0',
          query: {
            bool: {
              must: [],
              must_not: [],
              should: []
            }
          },
          size: '0'
        },
        // TODO Data Table (log_average_monitor)
        // aggs.result.filters.filters.result.bool.filter[0].bool.should[0].query_string.query
        mornitor: {
          aggs: {
            result: {
              aggs: {
                result: {
                  avg: {
                    field: 'index_stats.primaries.store.size_in_bytes',
                    script:
                      "doc['index_stats.primaries.store.size_in_bytes'].value / 7"
                  }
                }
              },
              filters: {
                filters: {
                  result: {
                    bool: {
                      filter: [
                        {
                          bool: {
                            minimum_should_match: '1',
                            should: [
                              {
                                query_string: {
                                  fields: ['index_stats.index'],
                                  query: 'oneauth-yano.fw.fortigate.log-*'
                                }
                              }
                            ]
                          }
                        }
                      ],
                      must: [],
                      must_not: [],
                      should: []
                    }
                  }
                }
              }
            }
          },
          size: '0'
        }
      },
      widgetTypes: [
        { name: 'Label', value: 'label' },
        { name: 'Bar Chart', value: 'bar' },
        { name: 'Line Chart', value: 'line' },
        { name: 'Pie Chart', value: 'pie' }, // TODO สำหรับแสดงข้อมูลเป็น pie
        { name: 'Gauge', value: 'gauge' },
        { name: 'Text', value: 'text' }, // TODO สำหรับแสดง ข้อมูลเป็นจัวเลข
        { name: 'Table', value: 'table' }, // TODO สำหรับแสดง ตราง Raw Data
        { name: 'List', value: 'list' }, // TODO สำหรับแสดงตราง Count ของข้อมูล จาก Key ของข้อมูล,
        { name: 'Histogram', value: 'composition_histogram' },
        { name: 'Group', value: 'group' },
        { name: 'Storage', value: 'storage_size' },

        { name: 'Log Average Monitor', value: 'log_average_mornitor' }, // FIX เดี๋ยวมาทำให้สามารถใช้งานกับส่วนอื่นๆได้
        // { name: 'Event Count', value: 'event_count' }, // FIX เดี๋ยวมาทำให้สามารถใช้งานกับส่วนอื่นๆได้

        { name: 'Event Per seconds ', value: 'eps' }, // FIX เดี๋ยวมาทำให้สามารถใช้งานกับส่วนอื่นๆได้
        { name: 'Event Count ', value: 'ec' }, // FIX เดี๋ยวมาทำให้สามารถใช้งานกับส่วนอื่นๆได้

        // TODO Cloud flare only ----------------------
        {
          name: 'Composite Histogram (Beta for cloudflare)',
          value: 'composite_histogram'
        }, // FIX for cloud flare
        { name: 'Composite (Beta for cloudflare)', value: 'composite' } // FIX for cloudflare composite data
      ],

      TextOperation: [
        { name: 'Count', value: 'value_count' },
        { name: 'Sum', value: 'sum' }
      ],

      TextUnit: [
        { name: 'None', value: 'none' },
        { name: 'Matrix', value: 'matrix' },
        { name: 'Byte', value: 'byte' }
      ],

      statusDragdrop: false,
      statusScale: false,
      mouseX: 0,
      mouseY: 0,
      startX: 0,
      startY: 0,
      eleMainLayout: null,
      eleMainLayoutW: 0,
      eleMainLayoutH: 0,
      editedIndex: -1,
      editedItem: {
        id: 0, // NOTE สำหรับเก็บ index ของ Array Item โดย Frontend
        item_id: '', // NOTE สำหรับเก็บ Unique key ของ item
        type: '', // NOTE สำหรับเก็บ Type ของ Widget "text|bar|line|pie|table"
        title: '', // NOTE สำหรับเก็บ Title ของ Chart
        description: '', // NOTE สำหรับเก็บข้อมูลรายละเอียดของ Widget
        query: {}, // NOTE ใช้สำหรับเก็บ Query ของ Elasticsearch
        options: {
          indexAxis: '',
          colors: ['#1976D233'],
          background_colors: [],
          border_colors: [],
          text_unit: 'none',
          text_operation: 'value_count',
          gauge_values: []
        }, // NOTE ใช้สำหรับแก้ไข option ของ Chart.JS
        indexAxis: 'x', // NOTE ให้สำหรับกำหนด Layout ของ index
        datatype: 'none', // NOTE หน่วยที่ใช้ในการแสดงข้อมูล สำหรับ Widget text
        path: '',
        field: '', // NOTE Field ข้อมูล
        size: '5',
        time_field: '',
        sync_index: true, // NOTE สำหรับใช้ index จาก Menu แทนจากการใช้ path
        layout: {
          width: '50', // NOTE สำหรับเก็บความกว้างของ Element
          height: '50' // NOTE สำหรับเก็บความสูงของ Element
        },
        data: {
          // NOTE สำหรับเก็ขข้อมูลที่ต้องการแสดงใน widget
          labels: [],
          dataset: [],
          listHeaders: [],
          composit: []
        },
        headers: [],
        listHeaders: []
      },
      defaultItem: {
        id: 0, // NOTE สำหรับเก็บ index ของ Array Item โดย Frontend
        item_id: '', // NOTE สำหรับเก็บ Unique key ของ item
        type: '', // NOTE สำหรับเก็บ Type ของ Widget "text|bar|line|pie|table"
        title: '', // NOTE สำหรับเก็บ Title ของ Chart
        description: '', // NOTE สำหรับเก็บข้อมูลรายละเอียดของ Widget
        query: {}, // NOTE ใช้สำหรับเก็บ Query ของ Elasticsearch
        options: {
          indexAxis: '',
          colors: ['#1976D233'],
          background_colors: [],
          border_colors: [],
          text_unit: 'none',
          text_operation: 'value_count',
          gauge_values: [] // NOTE สำหรับเก็บ field ทีใช้ในการทำ gauge
        }, // NOTE ใช้สำหรับแก้ไข option ของ Chart.JS
        indexAxis: 'x', // NOTE ให้สำหรับกำหนด Layout ของ index
        datatype: 'none', // NOTE หน่วยที่ใช้ในการแสดงข้อมูล สำหรับ Widget text
        field: '',
        path: '',
        size: '5',
        sync_index: true,
        time_field: '',
        layout: {
          width: '50', // NOTE สำหรับเก็บความกว้างของ Element
          height: '50' // NOTE สำหรับเก็บความสูงของ Element
        },
        data: {
          // NOTE สำหรับเก็ขข้อมูลที่ต้องการแสดงใน widget
          labels: [],
          dataset: [],
          listHeaders: [],
          composit: []
        },
        headers: [],
        listHeaders: []
      },
      x: [],
      pagination: {
        total: 0,
        page: 1,
        row: 10
      },
      loading: false,
      loadingSave: false,
      items: [],
      mPosX: 0,
      // NOTE Style
      borderWidth: 1,
      borderRadius: 5,
      lineTension: 0.5, // ความโค้งของ line chart
      // fill: true,
      borderSkipped: false,
      backgroundColor: [
        'rgba(0, 117, 255 ,0.2)',
        'rgba(159, 101, 241 ,0.2)',
        'rgba(223, 81, 212 ,0.2)',
        'rgba(255, 67, 175 ,0.2)',
        'rgba(255, 74, 133 ,0.2)',
        'rgba(255, 101, 93 ,0.2)',
        'rgba(255, 134, 53 ,0.2)',
        'rgba(255, 166, 0 ,0.2)'

        // 'rgba(54, 162, 235, 0.2)',
        // 'rgba(75, 192, 192, 0.2)',
        // 'rgba(255, 159, 64, 0.2)',
        // 'rgba(255, 99, 132, 0.2)',
        // 'rgba(255, 205, 86, 0.2)'
      ],
      borderColorCloudflare: [
        '#ffe43e',
        '#55d584',
        '#b73cdf',
        '#4693ff',
        '#ff43af',
        '#ff4a85',
        '#ff655d',
        '#ff8635',
        '#ffa600'
      ],
      borderColor: [
        // 'rgba(0, 117, 255 ,1)',
        // 'rgba(159, 101, 241 ,1)',
        // 'rgba(223, 81, 212 ,1)',
        // 'rgba(255, 67, 175 ,1)',
        // 'rgba(255, 74, 133 ,1)',
        // 'rgba(255, 101, 93 ,1)',
        // 'rgba(255, 134, 53 ,1)',
        // 'rgba(255, 166, 0 ,1)'

        '#0075ff',
        '#9f65f1',
        '#df51d4',
        '#ff43af',
        '#ff4a85',
        '#ff655d',
        '#ff8635',
        '#ffa600'

        // 'rgba(54, 162, 235, 1)',
        // 'rgba(75, 192, 192, 1)',
        // 'rgba(255, 159, 64, 1)',
        // 'rgba(255, 99, 132, 1)',
        // 'rgba(255, 205, 86, 1)'
      ],
      colors: [],
      queryString: '', // TODO 2023-08-23 สำหรับ Search Data จาก Elasticsearch
      currentDragIdx: null,
      listFields: [],
      statusSyncIndex: true,
      gaugeFields: [],
      // dataFromSearch: []
      dataFromSearch: {
        bool: {
          must: [],
          must_not: [],
          should: []
        }
      },
      parentWidth: 0,
      parentHeight: 0,
      resizeCurentElementId: '',
      uuidRegex: /\b([0-9a-fA-F]{8}-(?:[0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12})\b/g,
      currentQueryMust: [
        // {
        //   key: '',
        //   query: '',
        //   operation: ''
        // }
      ], // TODO สำหรับเก็บข้อมูล ของ Query Must เพื่อใช้สำหรับ Static Query Must
      baseQuery: {
        bool: {
          filter: [{ range: {} }],
          must: [],
          must_not: [],
          should: [],
          minimum_should_match: '0'
        } // TODO สำหรับเก็บ Base Query สำหรับ filter ข้อมูล
      }
    }
  },

  async created () {
    if (this.storeGetProjectLimitDate === 90) {
      this.datetime.start = this.$moment()
        .subtract(24, 'h')
        .format('YYYY-MM-DD HH:mm:ss')
    }

    this.rootElement = `main-layout-scale-${this._uid}`
    this.$EventBus.$on('addquery', () => {
      this.getDataAll()
    })
    this.$EventBus.$on('inputNull', () => {
      this.getDataAll()
    })
    this.$EventBus.$on('clickWidgetPieChart', () => {
      this.getDataAll()
    })
    this.$EventBus.$on('clickWidgetBarChart', () => {
      this.getDataAll()
    })
    // this.$EventBus.$on('addquery', () => setTimeout(() => {
    //   this.getDataAll()
    // }, 200))

    await this.init()
    if (
      this.storeGetCurentDatasourceId !== '' &&
      this.storeGetCurentDatasourceIndex !== ''
    ) {
      this.listFields = await this.getAllFieldsFromIndex(
        this.storeGetCurentDatasourceId,
        this.storeGetCurentDatasourceIndex
      )
    }
    setTimeout(() => {
      this.getData()
    }, 1000)
  },
  // beforeUnmount () {
  //   console.log('beforeUnmount ', this.id)
  // },
  // beforeDestroy () {
  //   console.log('beforeDestroy ', this.id)
  //   window.clearInterval(this.interval)
  // },

  // REF https://www.youtube.com/watch?v=YcRj52VovYQ     doughnut line label
  // REF https://stackoverflow.com/questions/26233180/resize-a-div-on-border-drag-and-drop-without-adding-extra-markup
  // REF https://stackoverflow.com/questions/34950867/vue-js-how-to-set-a-unique-id-for-each-component-instance
  // async mounted () {
  //   this.eleMainLayout = document.getElementById(this.rootElement)
  // },
  async mounted () {
    // await this.resizeParent()

    // FIX Set the interval to call functionA every 1000 ms
    // FIX เกิดปัญหาเวลา Get Parent Element แล้วได้ Height 0
    let intervalId = ''
    intervalId = setInterval(async () => {
      await this.resizeParent()
      if (this.parentHeight > 0) {
        // console.log('Value is greater than 0. Clearing the interval.')
        clearInterval(intervalId)
      }
    }, 200)
  },
  computed: {
    ...mapGetters([
      'storeGetCurentDatasourceId',
      'storeGetCurentDatasourceIndex',
      'storeGetProjectLimitDate'
    ]),
    formTitle () {
      return this.editedIndex === -1
        ? `เพิ่ม${this.NameComponent}`
        : `แก้ไข${this.NameComponent}`
    },
    formSubTitle () {
      return this.editedIndex === -1
        ? `กรอกข้อมูลเพื่อสร้าง${this.NameComponent}`
        : `กรอกข้อมูลเพื่อแก้ไข${this.NameComponent}`
    },
    mainDataStart () {
      if (this.storeGetProjectLimitDate === 0) return undefined
      else {
        return this.$moment()
          .subtract(this.storeGetProjectLimitDate - 1, 'd')
          .format('YYYY-MM-DD HH:mm:ss')
      }
    }
  },
  methods: {
    onAddCustomFilterMust () {
      // TODO เป็น Function สำหรับเพิ่ม Query Must สำหรับ Custom filter
      this.currentQueryMust.push({
        key: '',
        query: '',
        operation: 'and'
      })
    },
    onRemoveCustomFilterMust (idx) {
      // TODO เป็น Function สำหรับเพิ่ม Query Must สำหรับ Custom filter
      this.currentQueryMust.splice(idx, 1)
    },
    onUpdateCustomFilterMust (idx, key, value, condition = 'and') {
      // TODO เป็น Function สำหรับ Update Must Filter
    },
    convertQueryMustToJson (mustFormat = [], operation = 'and') {
      // TODO เป็น function ในการแปลง ELK Query Must to JSON format
      // NOTE Version 1
      return mustFormat.map((item) => {
        const key = Object.keys(item?.match)[0]
        const queryObject = item?.match[key]
        return {
          key: key,
          query: queryObject?.query || queryObject,
          operation: operation
        }
      })
    },
    convertJsonToQueryMust (json = []) {
      // TODO เป็น Function ในการแปลง Json format ไปเป็น ELK Must Format
      return json.map((item) => {
        // NOTE Version 1
        // return {
        //   match: {
        //     [item.key]: {
        //       query: item?.query,
        //       // operator: item?.operation
        //       operator: 'and'
        //     }
        //   }
        // }
        // NOTE Version 2
        return {
          match: {
            [item.key]: item?.query
          }
        }
      })
    },

    async onEndResizeWidget (val) {
      const wId = val.replace('item-card-', '')
      const its = this.items.filter((e) => e.widget_id === wId)
      for (const i of its) {
        const payload = {
          widget_id: i.widget_id,
          options: {},
          layout: { height: i.layout.height, width: i.layout.width },
          project_id: this.projectId,
          menu_id: this.menuId
        }
        const res = await this.updateWidget(payload)
        if (res.status) {
          this.$notification.success({
            message: 'Success',
            description: 'Update widget success',
            placement: 'bottomRight',
            duration: 3
          })
        }
      }
    },
    resizeParent () {
      // TODO สำหรับเมื่อ Parent Element Resize
      this.parentWidth = parseInt(
        document.getElementById(this.rootElement).offsetWidth
      )
      this.parentHeight = parseInt(
        document.getElementById(this.rootElement).offsetHeight
      )
    },
    DataForSearch (data) {
      this.dataFromSearch = data
    },

    async updateWidgetQuery () {
      // TODO เป็น Function สำหรับเมื่อ User มีการเปลี่ยน Type ของ Widget
      // TODO จะทำการสราง Elastic Query ใหม่ขึ้นมาเพื่อให้เหมาะกับ widget ที่ต้องการใช้งาน
      if (this.editedItem.sync_index) {
        let field = this.editedItem.field
        const f = this.listFields.filter(
          (e) => e.text === this.editedItem.field
        )

        if (f.length > 0) {
          if (f[0].type === 'text') {
            field += '.keyword'
          }
        }

        if (['gauge'].includes(this.editedItem.type)) {
          if (this.editedItem.field !== '' && this.editedItem.field != null) {
            this.gaugeFields = await this.listAllValueInfield(field)
          }
        }

        if (
          ['line', 'bar', 'pie', 'list', 'gauge'].includes(this.editedItem.type)
        ) {
          this.editedItem.query.size = '0'
          this.editedItem.query = this.defaultQuery.term
          this.editedItem.query.aggs.result.terms.field = field
          this.editedItem.query.aggs.result.terms.size = this.editedItem.size
        } else if (['table'].includes(this.editedItem.type)) {
          this.editedItem.query.size = '0'
          this.editedItem.query = this.defaultQuery.table
          this.editedItem.query.aggs.result.value_count.field = field
        } else if (['eps', 'ec'].includes(this.editedItem.type)) {
          this.editedItem.query.size = '0'
          this.editedItem.query = this.defaultQuery.histogram
          this.editedItem.options.indexAxis = 'x'
          this.editedItem.query.aggs.result.date_histogram.field = field
        } else if (['text'].includes(this.editedItem.type)) {
          if (this.editedItem.options.text_operation === 'sum') {
            this.editedItem.query = this.defaultQuery.sum
            this.editedItem.query.aggs.result.sum.field = this.editedItem.field
          } else {
            this.editedItem.query = this.defaultQuery.count
            this.editedItem.query.aggs.result.value_count.field = field
          }
        } else if (['log_average_mornitor'].includes(this.editedItem.type)) {
          this.editedItem.query.size = '0'
          this.editedItem.query = this.defaultQuery.mornitor
          this.editedItem.path = '/.ds-.monitoring*/_search'
          this.editedItem.query.aggs.result.filters.filters.result.bool.filter[0].bool.should[0].query_string.query =
            this.storeGetCurentDatasourceIndex
        } else if (['composition_histogram'].includes(this.editedItem.type)) {
          this.editedItem.query.size = '0'
          this.editedItem.query = this.defaultQuery.composition_histogram

          // Line Chart Result
          this.editedItem.query.aggs.result.composite.sources[0].date.date_histogram.field =
            this.editedItem.time_field
          this.editedItem.query.aggs.result.composite.sources[1].result.terms.field =
            field

          // Summary Result Count
          this.editedItem.query.aggs.resultCount.composite.sources[0].result.terms.field =
            field
        } else if (['group'].includes(this.editedItem.type)) {
          this.editedItem.query.size = '0'
          this.editedItem.query = this.defaultQuery.composition_histogram

          // Summary Result Count
          this.editedItem.query.aggs.resultCount.composite.sources[0].result.terms.field =
            field
        } else if (['storage_size'].includes(this.editedItem.type)) {
          this.editedItem.query = {}
        }
      }
    },
    ChangeWidgetSize (val) {
      // TODO เป็น Function สำหรับเปลี่ยนขนาดข้อข้อมูลที่จะเอามาโชว์
      // TODO เช่นเมื่อเป็น 5 ใน pie จะแบ่งข้อมูลเป็น 5 ส่วน เมื่อเป็น Bar จะมีข้อมูล 5 แท่ง
      this.editedItem.query.size = val
    },
    /* eslint-disable */
    handleSearch(val) {
      let res = [];
      if (!val || val.indexOf("@") >= 0) {
        res = [];
      } else {
        res = ["gmail.com", "163.com", "qq.com"].map((domain) => ({
          value: `${val}@${domain}`,
        }));
      }
    },
    /* eslint-enable */
    mouseEnter (idx, e) {
      const ele = document.getElementById(this.rootElement)
      const styles = window.getComputedStyle(ele)

      // console.log(this.items[idx].layout.width)
      this.startX = e.offsetLeft
      this.startY = e.offsetTop

      this.mouseX = e.clientX
      this.mouseY = e.clientY

      this.eleMainLayoutW = parseFloat(styles.width)
      this.eleMainLayoutH = parseFloat(styles.height)

      // console.log(this.eleMainLayoutW, 'x', this.eleMainLayoutH)
      this.statusScale = true
    },
    mouseMove (idx, e) {
      if (this.statusScale) {
        // console.log(event.screenX + '-' + event.screenY)
        // this.items[idx].layout.width = parseInt(this.items[idx].layout.width) + 10
        // console.log('mouseMove')
        // How far the mouse has been moved
        const dx = e.clientX - this.mouseX
        const dy = e.clientY - this.mouseY

        // this.items[idx].layout.width = (this.eleMainLayoutW + dx) + 'px'

        // FIX ยังคำนวนไม่ค่อยถูกทำให้ Scale ไม่ Smooth
        this.items[idx].layout.width = (
          parseFloat(this.items[idx].layout.width) +
          (dx * 100.0) / this.eleMainLayoutW
        ).toFixed(8)

        this.items[idx].layout.height = (
          parseFloat(this.items[idx].layout.height) +
          (dy * 100.0) / this.eleMainLayoutH
        ).toFixed(8)

        // console.log(dx, '-', dy, '|', parseInt(this.items[idx].layout.width), '|', Math.round(dx * 100 / this.eleMainLayoutW))
        // console.log(dx, '-', dy, '|', parseFloat(this.items[idx].layout.width))
      }
    },
    mouseLeave (idx, e) {
      this.statusScale = false
      // console.log('mouseLeave')
    },
    dragStart (idx, e) {
      // NOTE เมื่อมีการเริ่ม Drag Element
      e.target.style.opacity = '0.4'
      this.currentDragIdx = idx

      // e.dataTransfer.effectAllowed = 'move'
      // e.dataTransfer.setData('text/html', this.innerHTML)
    },
    dragEnter (idx, e) {
      // e.target.classList.add('over')
    },
    dragLeave (idx, e) {
      // e.target.classList.remove('over')
    },
    dragEnd (e) {
      e.target.style.opacity = '1'
      // document.querySelectorAll('.over').forEach((item) => {
      //   item.classList.remove('over')
      // })
      this.currentDragIdx = null
    },
    async dragDrop (idx, e) {
      e.stopPropagation() // stops the browser from redirecting.
      // const self = this
      if (this.currentDragIdx !== idx) {
        const tmp = Object.assign({}, this.items[this.currentDragIdx])
        this.items.splice(this.currentDragIdx, 1)
        this.items.splice(idx, 0, tmp)
        // console.log(`Curent : ${this.currentDragIdx} | Drop : ${idx}  `)
        const payload = this.items.map((e, id) => {
          return {
            index: id,
            widget_id: e.widget_id
          }
        })
        const p = {
          url: `${process.env.VUE_APP_API}/api/v1/project/${this.projectId}/menu/${this.menuId}/widget/index`,
          method: 'put',
          data: payload
        }
        const res = await this.awaitAxios(p)
        if (res.status) {
          this.$notification.success({
            message: 'Success',
            description: 'Update widget success',
            placement: 'bottomRight',
            duration: 3
          })
        }
      }

      return false
    },
    async getDataAll () {
      await this.resizeParent()
      for (let index = 0; index < this.items.length; index++) {
        if (this.items[index].type === 'table') {
          this.items[index].data.pagination.page = 1
        }
      }
      this.items = JSON.parse(JSON.stringify(this.items))
      await this.getData()

      // console.log('-->', this.items)

      this.$EventBus.$emit('clickSearchBtn', 'มีการคลิ๊กปุ่มค้นหา')
    },

    // TODO Function สำหรับคำนวน จุดบน Chart
    async calculateInterval (timeStart, timeEnd, range = 35) {
      const start = this.$moment(timeStart) // todays date
      const end = this.$moment(timeEnd) // another date

      // TODO จะได้ Duration ออกมาเป็นวินาที
      const duration = end.diff(start, 'seconds')
      return Math.round(duration / range)
    },
    async getData () {
      const itv = await this.calculateInterval(
        this.datetime.start,
        this.datetime.end
      )

      this.items = await Promise.all(
        this.items.map(async (e, idx) => {
          // TODO Allow widget to get data from elasticsearch
          if (!['label'].includes(e.type)) {
            // TODO สร้าง Object ใหม่เพื่อแก้ปัญหาเรื่อง Pointer
            const q = JSON.parse(JSON.stringify(e.query))

            // FIX เดี๋ยวต้องไปแก้ที่ Backend ไม่ให้คืน Null กลับมาแต่ตอนนี้เวลา 2023-11-16 00:08 แก้ไม่ไหวเลยแก้แบบนี้ไปก่อน
            if (e.options.colors == null) e.options.colors = []
            // FIX เดี๋ยวต้องกลับมาแก่ไข Backend ไม่ให้คืน null
            if (e.options.gauge_fields == null) e.options.gauge_fields = []
            // FIX ---------------------------------------------------------------------------------------

            // TODO เมื่อไม่ใช่ Type ที่ไปดึงค่า Monitor
            if (e.type !== 'log_average_mornitor') {
              // TODO เพิ่ม Query ด้วยเวลาเข้าไป

              if (e.sync_index) {
                // NOTE เมื่อใช้ Mode Syne  Index จำเป็นจะต้องเลือก Time Field ด้วย

                // const baseQuery = {
                //   bool: {
                //     filter: [{ range: {} }],
                //     must: [],
                //     must_not: [],
                //     should: []
                //   }
                // }

                if (!e.custom_filter_status) {
                  q.query = JSON.parse(JSON.stringify(this.baseQuery))
                } else {
                  q.query.bool = JSON.parse(
                    JSON.stringify({
                      ...this.baseQuery.bool,
                      ...e.query.query.bool
                    })
                  )
                }

                q.query.bool.filter[0].range[e.time_field] = {
                  gte: this.$moment(this.datetime.start).utc().format(),
                  lte: this.$moment(this.datetime.end).utc().format(),
                  format: 'strict_date_optional_time'
                }
              } else {
                // console.log('Sync : ', q.sync_index)
                // NOTE เมื่อไม่ Sync Index จะ filter จาก Field @time
                q.query = JSON.parse(
                  JSON.stringify({
                    bool: {
                      filter: [
                        {
                          range: {
                            '@timestamp': {
                              gte: this.$moment(this.datetime.start)
                                .utc()
                                .format(),
                              lte: this.$moment(this.datetime.end)
                                .utc()
                                .format(),
                              format: 'strict_date_optional_time'
                            }
                          }
                        }
                      ],
                      must: [],
                      must_not: [],
                      should: []
                    }
                  })
                )
              }

              // TODO 2023-08-23 15:48 เพิ่มในส่วนของการ Search
              // if (this.queryString !== '') {
              // // if (this.DataForSearch.length > 0) {
              //   q.query.bool.filter.push({
              //     multi_match: {
              //       type: 'best_fields',
              //       // query: this.queryString,
              //       query: this.dataFromSearch.must[0].match.query,
              //       lenient: true
              //     }
              //   })
              // }

              // NOTE เมื่อมีการ Filter จากช่อง filter จะทำการ filter

              if (!e.custom_filter_status) {
                // TODO เมื่อ custom filter status เป็น false แสดงว่าจะใช้ filter จาก Search filter
                if (this.dataFromSearch.bool.must.length > 0) {
                  for (const i of this.dataFromSearch.bool.must) {
                    q.query.bool.must.push(JSON.parse(JSON.stringify(i)))
                  }
                }
                if (this.dataFromSearch.bool.must_not.length > 0) {
                  for (const i of this.dataFromSearch.bool.must_not) {
                    q.query.bool.must_not.push(JSON.parse(JSON.stringify(i)))
                    // q.query.bool.must_not = JSON.parse(JSON.stringify(i))
                  }
                }
              }
            }

            // TODO ใช้สำหรับทำ Pagination ของ Table
            if (e.type === 'table') {
              q.size = `${e.data.pagination.row}`
              q.from = `${
                e.data.pagination.row * (e.data.pagination.page - 1)
              }`

              // FIX Table Sort desc ---------
              const x = {}
              q.sort = []
              x[e.time_field] = {
                order: 'desc'
              }
              q.sort.push(x)
              // FIX -----------------------
            }

            // TODO คำนวน Interval สำหรับ Histogram
            if (e.type === 'eps' || e.type === 'ec') {
              q.aggs.result.date_histogram.fixed_interval = itv + 's'
            }

            // TODO คำนวน interval สำหรับ composite histogram
            if (e.type === 'composite_histrogram') {
              for (const i in q.aggs.result.composite.sources) {
                if ('date' in q.aggs.result.composite.sources[i]) {
                  q.aggs.result.composite.sources[
                    i
                  ].date.date_histogram.fixed_interval = itv + 's'
                }
              }
            }

            if (e.type === 'composition_histogram') {
              for (const i in q.aggs.result.composite.sources) {
                if ('date' in q.aggs.result.composite.sources[i]) {
                  q.aggs.result.composite.sources[
                    i
                  ].date.date_histogram.fixed_interval = itv + 's'
                }
              }
            }

            let path = ''

            // TODO สำหรับ Set Path เพื่อใช้ในการ Query Elastic
            if (e.sync_index) {
              if (e.type === 'storage_size') {
                path = `/${this.storeGetCurentDatasourceIndex}/_stats/store`
              } else {
                path = `/${this.storeGetCurentDatasourceIndex}/_search`
              }
            } else {
              path = e.path
            }

            if (e.type === 'log_average_mornitor') {
              if (!e.sync_index) {
                path = e.path
              }
            }

            const payload = {
              project_id: this.projectId,
              menu_id: this.menuId,
              widget_id: e.widget_id,
              path: path,
              query: q
            }

            if (e.type === 'storage_size') {
              delete payload.query
            }

            // TODO init variable ------
            let newData = JSON.parse(JSON.stringify(e))
            let labels = []
            let data = []
            let items = []
            const listHeaders = []
            let compositData = []
            let key = ''
            let tmp = ''
            let obj = {}
            let total = 0
            let count = 0
            let tmpField = []
            // TODO init variable ------

            // TODO เป็นส่วนสำหรับ Call API ในการ Query Elastic Search
            const res = await this.getWidgetData(payload)

            // console.log(res)

            // if (e.type === 'table') {
            // }

            // NOTE Response from Elastic Search
            if (res.data !== undefined) {
              switch (e.type) {
                // NOTE TEXT COUNT
                case 'text':
                  newData = await {
                    ...e,
                    ...{
                      id: idx,
                      data: {
                        labels: ['Count'],
                        datasets: [
                          {
                            label: 'Count',
                            data: [res?.data.aggregations.result.value],
                            borderWidth: this.borderWidth,
                            borderRadius: this.borderRadius,
                            lineTension: this.lineTension,
                            borderSkipped: this.borderSkipped,
                            backgroundColor: this.backgroundColor,
                            borderColor: this.borderColor
                          }
                        ]
                      }
                    }
                  }
                  break
                // NOTE PIE
                case 'pie':
                  // TODO Create labels
                  res.data.aggregations?.result?.buckets.forEach((ele) => {
                    labels.push(ele.key)
                    data.push(ele.doc_count)
                  })
                  newData = {
                    ...e,
                    ...{
                      id: idx,
                      data: {
                        labels: labels,
                        datasets: [
                          {
                            label: 'count',
                            data: data,
                            borderWidth: this.borderWidth,
                            borderRadius: this.borderRadius,
                            lineTension: this.lineTension,
                            borderSkipped: this.borderSkipped,
                            // backgroundColor: this.backgroundColor,
                            backgroundColor:
                              e.options.colors.length > 0
                                ? e.options.colors
                                : this.backgroundColor,
                            // borderColor: this.borderColor,
                            borderColor:
                              e.options.colors.length > 0
                                ? e.options.colors.map(
                                  (e) => e.slice(0, -2) + 'ff'
                                )
                                : this.borderColor,
                            hoverBorderWidth: 2
                          }
                        ]
                      }
                    }
                  }
                  break
                // NOTE bar
                case 'bar':
                  // console.log(res.data.aggregations.result.buckets)
                  // TODO Create labels
                  res.data.aggregations?.result.buckets.forEach((ele) => {
                    labels.push(ele.key)
                    data.push(ele.doc_count)
                  })
                  newData = {
                    ...e,
                    ...{
                      id: idx,
                      data: {
                        labels: labels,
                        datasets: [
                          {
                            label: 'count',
                            data: data,
                            borderWidth: this.borderWidth,
                            borderRadius: this.borderRadius,
                            lineTension: this.lineTension,
                            borderSkipped: this.borderSkipped,
                            // backgroundColor: this.backgroundColor,
                            backgroundColor:
                              e.options.colors.length > 0
                                ? e.options.colors
                                : this.backgroundColor,
                            // borderColor: this.borderColor,
                            borderColor:
                              e.options.colors.length > 0
                                ? e.options.colors.map(
                                  (e) => e.slice(0, -2) + 'ff'
                                )
                                : this.borderColor,
                            hoverBorderWidth: 2
                          }
                        ]
                      }
                    }
                  }
                  break
                // NOTE Line
                case 'line': // TODO Create labels
                  res.data.aggregations?.result.buckets.forEach((ele) => {
                    labels.push(ele.key)
                    data.push(ele.doc_count)
                  })
                  newData = {
                    ...e,
                    ...{
                      id: idx,
                      data: {
                        labels: labels,
                        datasets: [
                          {
                            label: 'count',
                            data: data,
                            borderWidth: this.borderWidth,
                            borderRadius: this.borderRadius,
                            lineTension: this.lineTension,
                            borderSkipped: this.borderSkipped,
                            // backgroundColor: this.backgroundColor,
                            backgroundColor:
                              e.options.colors.length > 0
                                ? e.options.colors
                                : this.backgroundColor,
                            // borderColor: this.borderColor,
                            borderColor:
                              e.options.colors.length > 0
                                ? e.options.colors.map(
                                  (e) => e.slice(0, -2) + 'ff'
                                )
                                : this.borderColor
                          }
                        ]
                      }
                    }
                  }
                  break
                // NOTE Table List
                case 'list':
                  newData = window._.merge(e, {
                    // id: idx
                    //   data: {
                    // items: JSON.parse(JSON.stringify(items)),
                    // headers: []
                    // listHeaders: listHeaders,
                    // pagination: {
                    //   total: res?.data.aggregations.result.value
                    //   // , page: 1
                    // }
                    //   }
                  })
                  // TODO แก้ไขปัญหาเรื่องการ Merge ข้อมูลซ้ำกัน
                  newData.data.items = JSON.parse(
                    JSON.stringify(res.data.aggregations?.result.buckets)
                  )
                  newData.data.headers = [
                    {
                      text: 'key',
                      align: 'start',
                      width: '',
                      value: 'key',
                      type: 'text'
                    },
                    {
                      text: 'Count',
                      align: 'start',
                      width: '',
                      value: 'doc_count',
                      type: 'text'
                    }
                  ]

                  newData = JSON.parse(JSON.stringify(newData))
                  break
                // NOTE Event Per Seconds
                case 'eps':
                  // console.log(res.data.aggregations.result.buckets)
                  // TODO Create labels
                  res.data.aggregations?.result.buckets.forEach((ele) => {
                    let str = ele.key
                    str = str.toString()
                    str = str.slice(0, -3)
                    str = parseInt(str)
                    labels.push(
                      this.$moment.unix(str).format('DD/MM/YYYY HH:mm:ss')
                    )
                    data.push(ele.doc_count / itv)
                  })
                  newData = {
                    ...e,
                    ...{
                      id: idx,
                      data: {
                        labels: labels,
                        datasets: [
                          {
                            label: 'event/sec',
                            data: data,
                            borderWidth: this.borderWidth,
                            borderRadius: this.borderRadius,
                            lineTension: this.lineTension,
                            borderSkipped: this.borderSkipped,
                            // backgroundColor: this.backgroundColor[0],
                            backgroundColor:
                              e.options.colors.length > 0
                                ? e.options.colors
                                : this.backgroundColor[0],
                            // borderColor: this.borderColor,
                            borderColor:
                              e.options.colors.length > 0
                                ? e.options.colors.map(
                                  (e) => e.slice(0, -2) + 'ff'
                                )
                                : this.borderColor[0],
                            pointHoverRadius: 10
                          }
                        ]
                      }
                    }
                  }
                  break
                // NOTE Event Count
                case 'ec':
                  // console.log(res.data.aggregations.result.buckets)
                  // TODO Create labels
                  res.data.aggregations?.result.buckets.forEach((ele) => {
                    let str = ele.key
                    str = str.toString()
                    str = str.slice(0, -3)
                    str = parseInt(str)
                    labels.push(
                      this.$moment.unix(str).format('DD/MM/YYYY HH:mm:ss')
                    )
                    data.push(ele.doc_count)
                  })
                  newData = {
                    ...e,
                    ...{
                      id: idx,
                      data: {
                        labels: labels,
                        datasets: [
                          {
                            label: 'event count',
                            data: data,
                            borderWidth: this.borderWidth,
                            borderRadius: this.borderRadius,
                            lineTension: this.lineTension,
                            borderSkipped: this.borderSkipped,
                            // backgroundColor: this.backgroundColor[0],
                            backgroundColor:
                              e.options.colors.length > 0
                                ? e.options.colors
                                : this.backgroundColor[0],
                            // borderColor: this.borderColor,
                            borderColor:
                              e.options.colors.length > 0
                                ? e.options.colors.map(
                                  (e) => e.slice(0, -2) + 'ff'
                                )
                                : this.borderColor[0],
                            hoverBorderWidth: 2
                            // hoverBackgroundColor: 'red'
                          }
                        ]
                      }
                    }
                  }
                  break
                // NOTE Table
                case 'table':
                  // console.log(res.data.hits)
                  // listHeaders = await this.listAllField(newData)
                  // listHeaders = []

                  items = res.data.hits.hits.map((ele) => {
                    let tmp = Object.assign({})
                    tmp = {
                      ...{ _index: ele._index, _id: ele._id },
                      ...ele._source
                    }
                    return tmp
                  })

                  // TODO ใช้ Loadash lib ในการ Merge เนื่องจากปัญหาเลข Index ในตรางมีปัญหา
                  // TODO spred operator ไม่สามรถ merge nest object ได้ทำให้ field หาย
                  newData = window._.merge(e, {
                    id: idx,
                    data: {
                      // items: JSON.parse(JSON.stringify(items)),
                      // headers: this.headers,
                      listHeaders: listHeaders,
                      pagination: {
                        total: res?.data.aggregations.result.value
                        // , page: 1
                      }
                    }
                  })
                  // TODO แก้ไขปัญหาเรื่องการ Merge ข้อมูลซ้ำกัน
                  newData.data.items = JSON.parse(JSON.stringify(items))

                  newData = JSON.parse(JSON.stringify(newData))
                  break
                // NOTE Log Size
                case 'log_average_mornitor':
                  // FIX ทำสำหรับแสดง Log Average Monitor เดี๋ยวต้องมาตามแก้ให้ Generic
                  // console.log(e.path)
                  // console.log(JSON.stringify(q))
                  // console.log(
                  //   res.data.aggregations.result.buckets.result.result.value
                  // )
                  newData = await {
                    ...e,
                    ...{
                      id: idx,
                      data: {
                        labels: ['Average'],
                        datasets: [
                          {
                            label: 'count',
                            data: [
                              res.data?.aggregations?.result?.buckets?.result
                                ?.result?.value
                            ],
                            borderWidth: this.borderWidth,
                            borderRadius: this.borderRadius,
                            lineTension: this.lineTension,
                            borderSkipped: this.borderSkipped,
                            backgroundColor: this.backgroundColor,
                            borderColor: this.borderColor
                          }
                        ]
                      },
                      datatype: 'byte'
                    }
                  }
                  break
                // NOTE For Cloudflare Data format
                case 'composite':
                  // console.log(res.data?.aggregations)
                  for (const i in res.data?.aggregations) {
                    tmp = ''
                    key = i
                    obj = {}
                    obj[key] = Object.assign([])
                    obj[key] = res.data.aggregations[i].buckets.map(
                      (e, idx) => {
                        for (const k in e.key) {
                          tmp = k
                        }
                        return {
                          doc_count: e.doc_count,
                          key: e.key[tmp],
                          color: this.backgroundColor[idx]
                        }
                      }
                    )
                    compositData.push(obj)
                  }

                  compositData[0].result = compositData[0].result.sort(
                    (a, b) => parseFloat(b.doc_count) - parseFloat(a.doc_count)
                  )
                  compositData[0].result = compositData[0].result.map(
                    (e, idx) => {
                      return { ...e, ...{ color: this.backgroundColor[idx] } }
                    }
                  )
                  if (compositData.length > 0) {
                    newData.data.composit = JSON.parse(
                      JSON.stringify(compositData[0].result)
                    )
                  }
                  break
                // NOTE For Cloudflare Data Format
                case 'composite_histrogram':
                  compositData = []
                  // TODO Calculate Totla ---------------
                  if ('resultCount' in res.data?.aggregations) {
                    compositData =
                      res.data?.aggregations.resultCount.buckets.map((e) => {
                        tmp = ''
                        for (const k in e.key) {
                          tmp = k
                        }
                        return {
                          doc_count: e.doc_count,
                          key: e.key[tmp],
                          color: this.backgroundColor[0]
                        }
                      })

                    // NOTE Sort Array OBJ
                    compositData = compositData.sort(
                      (a, b) =>
                        parseFloat(b.doc_count) - parseFloat(a.doc_count)
                    )
                    // NOTE Set Color
                    compositData = compositData.map((e, idx) => {
                      return { ...e, ...{ color: this.borderColor[idx] } }
                    })

                    // NOTE Set to parent element
                    if (compositData.length > 0) {
                      newData.data.composit = JSON.parse(
                        JSON.stringify(compositData)
                      )
                    }
                  }
                  // TODO Calculate Histrogram -----------

                  // console.log(res.data?.aggregations)
                  obj = {}
                  key = ''
                  labels = Object.assign([])
                  // console.log(res.data?.aggregations?.result.buckets.length)
                  data = res.data?.aggregations?.result.buckets.map((e) => {
                    let x = ''
                    for (const o in e.key) if (o !== 'date') x = o
                    if (x !== '') return e.key[x]
                    else return null
                  })

                  data = Array.from(new Set(data)).map((e) => {
                    return { key: e, doc_count: 0 }
                  }) // TODO All value in field

                  for (const i of res.data?.aggregations?.result.buckets) {
                    if (!labels.includes(i.key.date)) labels.push(i.key.date) // TODO  สำหรับสร้าง Labels

                    for (const o in i.key) if (o !== 'date') key = o
                    if (!(i.key.date in obj)) obj[i.key.date] = []
                    obj[i.key.date].push({
                      key: i.key[key],
                      doc_count: i.doc_count
                    })
                  }

                  for (const [idx, i] of data.entries()) {
                    const d = []
                    for (const j of labels) {
                      const k = obj[j].filter((e) => e.key === i.key)
                      if (k.length > 0) d.push(k[0].doc_count)
                      else d.push(0)
                    }

                    // NOTE Get Color from count
                    if ('resultCount' in res.data?.aggregations) {
                      const x = newData.data.composit.filter(
                        (e) => e.key === i.key
                      )
                      let bd = this.borderColor[idx]
                      let bg = this.backgroundColor[idx]
                      if (x.length > 0) {
                        bd = x[0].color
                        bg = x[0].color
                      }
                      items.push({
                        data: d,
                        label: i.key,
                        borderWidth: this.borderWidth,
                        borderRadius: this.borderRadius,
                        lineTension: this.lineTension,
                        borderSkipped: this.borderSkipped,
                        // backgroundColor: this.backgroundColor[idx],
                        // borderColor: this.borderColor[idx],
                        backgroundColor: bg,
                        borderColor: bd,
                        pointHoverRadius: 10
                      })
                    } else {
                      items.push({
                        data: d,
                        label: i.key,
                        borderWidth: this.borderWidth,
                        borderRadius: this.borderRadius,
                        lineTension: this.lineTension,
                        borderSkipped: this.borderSkipped,
                        backgroundColor: this.backgroundColor[idx],
                        borderColor: this.borderColor[idx],
                        pointHoverRadius: 10
                      })
                    }
                  }

                  if (items.length > 0) {
                    newData.data.labels = labels
                    newData.data.dataset = items
                  }
                  break
                // NOTE For widget Gauge
                case 'gauge':
                  tmpField = []
                  total = 0
                  items = []
                  data = []

                  // TODO เพื่อแก้ปัญหา Pointer
                  items = JSON.parse(
                    JSON.stringify(res.data?.aggregations?.result?.buckets)
                  )
                  data = JSON.parse(JSON.stringify(e.options.gauge_fields))

                  // TODO สร้างข้อมูล Label ของ widget
                  newData.data.gauge.title = e.options.gauge_fields.toString()

                  // TODO หาว่าในข้อมูลที่ได้มาจาก Elasticsearch นั้นมี Field ที่เราสนใจหรือไม่
                  tmpField = await items.filter((i) => {
                    if (data.filter((x) => x === i.key).length) return true
                    else return false
                  })

                  if (tmpField.length > 0) {
                    // TODO คำนวนหา total ทั้งหมด เพื่อคำนวน percent
                    total = res.data?.aggregations?.result?.buckets?.reduce(
                      (acc, cur) => acc + cur.doc_count,
                      0
                    )

                    // TODO คำนวนหา total ของ field ที่เราสนใจ
                    count = tmpField.reduce(
                      (acc, cur) => acc + cur.doc_count,
                      0
                    )

                    newData.data.gauge.value = parseInt((count * 100) / total)
                  } else newData.data.gauge.value = 0

                  break
                // NOTE Composition Histogram
                case 'composition_histogram':
                  compositData = []

                  // TODO Calculate Total ---------------
                  if ('resultCount' in res.data?.aggregations) {
                    compositData =
                      res.data?.aggregations.resultCount.buckets.map((e) => {
                        tmp = ''
                        for (const k in e.key) {
                          tmp = k
                        }
                        return {
                          doc_count: e.doc_count,
                          key: e.key[tmp],
                          color: this.backgroundColor[0]
                        }
                      })

                    // NOTE Sort Array OBJ
                    compositData = compositData.sort(
                      (a, b) =>
                        parseFloat(b.doc_count) - parseFloat(a.doc_count)
                    )
                    // NOTE Set Color
                    compositData = compositData.map((e, idx) => {
                      return {
                        ...e,
                        ...{
                          color:
                            this.borderColorCloudflare[
                              idx % this.borderColorCloudflare.length
                            ]
                        }
                      }
                    })

                    // NOTE Set to parent element
                    if (compositData.length > 0) {
                      newData.data.composit = JSON.parse(
                        JSON.stringify(compositData)
                      )
                    }
                  }
                  // TODO Calculate Histogram -----------

                  // console.log(res.data?.aggregations)
                  obj = {}
                  key = ''
                  labels = Object.assign([])
                  // console.log(res.data?.aggregations?.result.buckets.length)
                  data = res.data?.aggregations?.result.buckets.map((e) => {
                    let x = ''
                    for (const o in e.key) if (o !== 'date') x = o
                    if (x !== '') return e.key[x]
                    else return null
                  })

                  data = Array.from(new Set(data)).map((e) => {
                    return { key: e, doc_count: 0 }
                  }) // TODO All value in field

                  for (const i of res.data?.aggregations?.result.buckets) {
                    if (!labels.includes(i.key.date)) labels.push(i.key.date) // TODO  สำหรับสร้าง Labels

                    for (const o in i.key) if (o !== 'date') key = o
                    if (!(i.key.date in obj)) obj[i.key.date] = []
                    obj[i.key.date].push({
                      key: i.key[key],
                      doc_count: i.doc_count
                    })
                  }

                  for (const [idx, i] of data.entries()) {
                    const d = []
                    for (const j of labels) {
                      const k = obj[j].filter((e) => e.key === i.key)
                      if (k.length > 0) d.push(k[0].doc_count)
                      else d.push(0)
                    }

                    // NOTE Get Color from count
                    if ('resultCount' in res.data?.aggregations) {
                      const x = newData.data.composit.filter(
                        (e) => e.key === i.key
                      )
                      let bd = this.borderColor[idx]
                      let bg = this.backgroundColor[idx]
                      if (x.length > 0) {
                        bd = x[0].color
                        bg = x[0].color
                      }
                      items.push({
                        data: d,
                        label: i.key,
                        // borderWidth: this.borderWidth,
                        borderWidth: 3,
                        borderRadius: this.borderRadius,
                        lineTension: this.lineTension,
                        borderSkipped: this.borderSkipped,
                        // backgroundColor: this.backgroundColor[idx],
                        // borderColor: this.borderColor[idx],
                        backgroundColor: bg,
                        borderColor: bd,
                        pointHoverRadius: 10
                      })
                    } else {
                      items.push({
                        data: d,
                        label: i.key,
                        borderWidth: this.borderWidth,
                        borderRadius: this.borderRadius,
                        lineTension: this.lineTension,
                        borderSkipped: this.borderSkipped,
                        backgroundColor: this.backgroundColor[idx],
                        borderColor: this.borderColor[idx],
                        pointHoverRadius: 10
                      })
                    }
                  }

                  if (items.length > 0) {
                    labels = labels.map((e) =>
                      this.moment.utc(e).local().format('YYYY-MM-DD HH:mm:ss')
                    )
                    newData.data.labels = labels
                    newData.data.dataset = items
                  }
                  break
                // NOTE Group
                case 'group':
                  compositData = []

                  // TODO Calculate Total ---------------
                  if ('resultCount' in res.data?.aggregations) {
                    compositData =
                      res.data?.aggregations.resultCount.buckets.map((e) => {
                        tmp = ''
                        for (const k in e.key) {
                          tmp = k
                        }
                        return {
                          doc_count: e.doc_count,
                          key: e.key[tmp],
                          color: this.backgroundColor[0]
                        }
                      })

                    // NOTE Sort Array OBJ
                    compositData = compositData.sort(
                      (a, b) =>
                        parseFloat(b.doc_count) - parseFloat(a.doc_count)
                    )
                    // NOTE Set Color
                    compositData = compositData.map((e, idx) => {
                      return { ...e, ...{ color: this.borderColor[idx] } }
                    })

                    // NOTE Set to parent element
                    if (compositData.length > 0) {
                      newData.data.composit = JSON.parse(
                        JSON.stringify(compositData)
                      )
                    }
                  }

                  break
                case 'storage_size':
                  // TODO จำนวนข้อมูลทั้งหมดที่ใช้งาน
                  newData = await {
                    ...e,
                    ...{
                      id: idx,
                      data: {
                        labels: ['Average Storage / Day'],
                        datasets: [
                          {
                            label: 'count',
                            data: [
                              res.data?._all?.total?.store?.size_in_bytes /
                                (Object.keys(res.data.indices).length * 7)
                            ],
                            borderWidth: this.borderWidth,
                            borderRadius: this.borderRadius,
                            lineTension: this.lineTension,
                            borderSkipped: this.borderSkipped,
                            backgroundColor: this.backgroundColor,
                            borderColor: this.borderColor
                          }
                        ]
                      },
                      datatype: 'byte'
                    }
                  }
                  break
              }
            }
            return await JSON.parse(JSON.stringify(newData))
          } else return e
        })
      )
      // console.log("+++++++++++++++++++++")
      // console.log(this.itens)
    },
    async init () {
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        project_id: this.projectId,
        menu_id: this.menuId
      }
      const res = await this.getwidgets(payload)

      this.loading = false
      if (res.status) {
        this.items = res.data.map((e) => {
          return {
            ...e,
            ...{
              data: {
                labels: [],
                datasets: [],
                headers: [],
                items: [],
                composit: [],
                gauge: {
                  title: '',
                  value: 0,
                  total: 0,
                  list_fields: []
                },
                pagination: { page: 1, row: 10, total: 0 }
              }
            }
          }
        })
      } else {
        this.antmessage.error(res.message)
      }

      // console.log(this.items[0])
    },
    editItem (item) {
      // TODO เป็น Function สำหรับเตรียมข้อมูลสำหรับ Edit Widget
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, JSON.parse(JSON.stringify(item)))

      // TODO ต้องแปลง format ก่อนนำมาใช้งานใน Current Query Must
      if (this.editedItem.type !== 'storage_size') {
        // BUG เป็นการแก้ปัญหาให้ใช้ได้ไปก่อนเนื่องจาก type storage นั้นไม่ต้องการ Query ในการค้นหาข้อมูล
        this.currentQueryMust = [
          ...this.convertQueryMustToJson(
            this.editedItem.query?.query?.bool?.must,
            'and'
          ),
          ...this.convertQueryMustToJson(
            this.editedItem.query?.query?.bool?.should,
            'or'
          ),
          ...this.convertQueryMustToJson(
            this.editedItem.query?.query?.bool?.must_not,
            'not'
          )
        ]
      }

      // TODO เพื่อให้ไป Get Key ของ Widget ที่สามารถทำ Gauge ได้
      if (this.editedItem.type === 'gauge') this.updateWidgetQuery()

      this.dialog = true
    },
    deleteItem (item) {
      this.$swal({
        allowEnterKey: false,
        title: `ลบ${this.NameComponent}`,
        text: `คุณต้องการลบ${this.NameComponent}นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = {
            project_id: this.projectId,
            menu_id: this.menuId,
            widget_id: item.widget_id
          }
          const res = await this.deleteWidget(payload)
          this.antmessage.destroy()
          if (res.status) {
            await this.init()
            await this.getData()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `ลบ${this.NameComponent}สำเร็จ`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },
    close () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.dialog = false
      this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
        this.editedIndex = -1
        this.bizIdEdit = ''
      })
    },
    save () {
      if (this.editedIndex > -1) {
        // TODO Update Data
        this.$swal({
          allowEnterKey: false,
          title: `แก้ไข${this.NameComponent}`,
          text: `คุณต้องการแก้ไข${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            //  TODO 2024-08-12 เพิ่มส่วนของการ Convert Query Must Format
            if (this.editedItem.type !== 'storage_size') {
              // BUG เป็นการแก้ปัญหาให้ใช้ได้ไปก่อนเนื่องจาก type storage นั้นไม่ต้องการ Query ในการค้นหาข้อมูล

              // TODO AND
              this.editedItem.query.query.bool.must =
                this.convertJsonToQueryMust(
                  this.currentQueryMust.filter(
                    (item) => item.operation === 'and'
                  )
                )

              // TODO OR
              this.editedItem.query.query.bool.should =
                this.convertJsonToQueryMust(
                  this.currentQueryMust.filter(
                    (item) => item.operation === 'or'
                  )
                )

              this.editedItem.query.query.bool.must_not =
                this.convertJsonToQueryMust(
                  this.currentQueryMust.filter(
                    (item) => item.operation === 'not'
                  )
                )

              if (this.editedItem.query.query.bool.should.length > 0) {
                // TODO เมื่อมีการ query or จะต้องให้เจออย่างน้อย 1 field
                this.editedItem.query.query.bool.minimum_should_match = '1'
              } else {
                this.editedItem.query.query.bool.minimum_should_match = '0'
              }
            }

            const payload = {
              ...this.editedItem,
              ...{ project_id: this.projectId, menu_id: this.menuId }
            }
            const res = await this.updateWidget(payload)
            this.loadingSave = false
            if (res.status) {
              await this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `แก้ไข${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })

              this.close()
              await this.getData()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else {
        // TODO Create Data
        this.$swal({
          allowEnterKey: false,
          title: `เพิ่ม${this.NameComponent}`,
          text: `คุณต้องการเพิ่ม${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              ...this.editedItem,
              ...{
                project_id: this.projectId,
                menu_id: this.menuId,
                index: this.items.length + 1
              }
            }
            const res = await this.createWidget(payload)
            this.loadingSave = false
            if (res.status) {
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `เพิ่ม${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              await this.init()
              await this.close()
              await this.getData()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      }
    },
    async listAllField (e) {
      // TODO สร้าง Payload เพื่อที่จะไปดึง Field ทั้งหมดจาก Elasticsearch
      const payload = {
        project_id: this.projectId,
        menu_id: this.menuId,
        widget_id: e.widget_id,
        path: e.path.replace('_search', '_mapping?pretty')
      }

      // TODO Send Query To Elasticsearch
      const res = await this.getWidgetData(payload)
      if (res.status) {
        // TODO ประกาศตัวเเปรเพื่อใช้งาน
        // const end = false // NOTE สำหรับทำแค่ Element แรกเท่านั้น
        // NOTE สำหรับเก็บค่า Field ทั้งหมด
        const ListFields = [
          {
            text: '_id',
            align: 'start',
            width: '',
            value: '_id',
            type: 'text'
          },
          {
            text: '_index',
            align: 'start',
            width: '',
            value: '_index',
            type: 'text'
          },
          {
            text: 'Msg.raw',
            align: 'start',
            width: '',
            value: 'Msg.raw',
            type: 'text'
          }
        ]

        const tmp = {}
        // TODO Loop เพื่อดึง Index จากที่ส่งมา
        for (const property in res.data) {
          window._.merge(tmp, res.data[property])
        }
        // // TODO จะออกจาก loop เมื่อ end เป็น True
        // if (end) break
        // end = true

        // TODO ประกาศ LF เพื่อเก็บข้อมูลเเละเพื่อเรียกใช้ใน Function
        const FL = Object.assign({}, tmp.mappings.properties)

        // TODO สร้าง Array ที่เก็บ list field ของ Index
        for (const key in FL) {
          await ListFields.push({
            text: key,
            align: 'start',
            width: '',
            value: key,
            type: FL[key].type
          })
        }
        return ListFields
      }
    },
    async listAllValueInfield (field = '') {
      // TODO สำหรับดึงข้อมูลเพื่อทำเป็น Dropdown สำหรับเลือก Field
      const datasourceId = this.storeGetCurentDatasourceId
      const datasourecIndex = this.storeGetCurentDatasourceIndex
      const res = await this.getAllValueFromField(
        datasourceId,
        datasourecIndex,
        field
      )
      return res
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .title {
  font-family: inherit !important;
  font-weight: 400;
  font-size: 16px !important;
}
.main-search {
  height: 72px;
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
}

.main-search .search {
  height: 100%;
  margin: 0;
}
.main-layout {
  width: 100%;
  height: 100%;
  // height: calc(100% - 72px) !important;
  padding: 0;
  margin: 0;
  // background: green;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.main-layout::-webkit-scrollbar {
  display: none;
}

// TODO COMPONENT TEXT CARD
.item {
  padding: 0.2rem;
  min-width: 10%;
  min-height: 10%;
  max-height: 100%;
  max-width: 100%;
  // width: 25%;
  // background-color: lightsteelblue;
  position: relative;
  overflow: hidden;
  z-index: 3 !important;
}
.item .content {
  z-index: 2 !important;
  width: 100%;
  height: 100%;
  // background-color: yellow;
  position: relative;
  // padding: 1rem;
  // padding-top: 32px;
  margin: 0;
  border-radius: 0.5rem;
  overflow: hidden;
  // overflow: scroll;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.item .content .title {
  position: absolute;
  top: 0;
  left: 1rem;
  // background: pink;
}

.item .content .item-btn {
  // background: red;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.item-btn i {
  font-size: 16px;
}

.item .content .item-btn.setting-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  color: var(--v-primary-base) !important;
}

::v-deep i {
  color: inherit !important;
}
.item .content .item-btn.delete-btn {
  position: absolute;
  top: 0;
  right: 24px;
  z-index: 4;
  color: var(--v-error-base) !important;
}

.item .content .item-btn.scale-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;
  cursor: se-resize !important;
}

.item .content .item-btn.move-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  cursor: move !important;
}

// TODO Dragdrop
// ::v-deep .over {
//   background: red !important;
// }

@media only screen and (max-width: 1000px) {
  .item {
    width: 50% !important;
  }
}

@media only screen and (max-width: 650px) {
  .item {
    width: 100% !important;
  }
}

.over {
  border: 3px dotted #666;
}
</style>
