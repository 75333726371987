<template>
  <div>
    <v-menu
      offset-y
      :close-on-content-click="false"
      rounded="t-lg b-lg"
      class="pa-0"
      max-width="400"
      min-width="300"
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <div>
          <v-badge
            v-if="notifications.length > 0"
            bottom
            color="error"
            offset-x="20"
            offset-y="45"
            :content="notifications.length"
          >
            <v-btn icon color="tprimary" @click="show" v-bind="attrs" v-on="on">
              <v-icon>mdi-bell-outline</v-icon>
            </v-btn>
          </v-badge>
          <v-btn icon v-else v-bind="attrs" v-on="on" disabled>
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn>
        </div>
      </template>

      <v-list class="layout-notification-layout">
        <v-list-item
          v-for="item in notifications"
          :key="item.notification_id"
          class="pa-1"
        >
          <v-alert
            text
            dense
            :type="alertColor(item.type, item.state)"
            border="left"
            elevation="1"
            class="ma-0 notification-alert"
          >
            <div class="d-flex">
              <span class="notification-title text-uppercase pr-3">
                {{ item.title }}
              </span>
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  color="primary"
                  outlined
                  small
                  @click="Close(item.notification_id)"
                >
                  อ่านแล้ว
                </v-btn>
              </div>
            </div>
            <div class="notification-message">
              {{ item.message }}
            </div>
            <div class="notification-create-by">By : {{ item.name }}</div>
            <span class="notification-time"
              >{{ item.create_date | moment }}
            </span>
          </v-alert>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      audio: new Audio(require('@/assets/sounds/computerwav-14702.mp3')),
      notifications: [],
      selected: [],
      showMenu: false,
      x: 0,
      y: 0,
      items: []
    }
  },
  created () {
    this.initNotification()
  },
  mounted () {
    this.$websocket.$on('notification', (e) => {
      this.audio.pause()
      const arr = [e, ...this.notifications]
      this.notifications = arr
      this.audio.play()
    })
    this.$websocket.$on('logout', (e) => {
      this.$websocket.Disconnect()
      localStorage.clear()
      this.$swal({
        allowOutsideClick: false,
        allowEnterKey: false,
        title: 'มีการ Login เข้าใช้งานจากเครื่องอื่น',
        text: 'กรุณา Login เพื่อเข้าใช้งานใหม่อีกครั้ง',
        icon: 'warning',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$vuetify.theme.dark = false
          this.OauthLogOutOneID()
          // this.$router.push({ name: 'loginPage' }).catch(() => {})
        }
      })
    })
  },
  methods: {
    show (e) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    async initNotification () {
      const res = await this.GetNotifications()
      if (res.status) {
        this.notifications = res.data
      } else {
        this.antmessage.error(res.message)
      }
    },

    async Close (notificationId) {
      await this.ReadNotification(notificationId)
      await this.initNotification()
    },
    alertColor (type, state) {
      if (type === 'news' || type === 'approve_project_user') {
        return 'info'
      } else if (type === 'alert') {
        if (state === 0) {
          return 'success'
        } else if (state === 1) {
          return 'info'
        } else if (state === 2) {
          return 'warning'
        } else if (state === 3) {
          return 'error'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-notification-layout {
  // width: 500px;
  max-height: 60vh;
  overflow-y: auto;
  padding: 0px;
}

.detail-noti {
  font-size: 14px;
  font-weight: 300;
}
.detail-noti-by {
  font-size: 12px;
  font-weight: 300;
  color: var(--v-tprimary-base);
}
.notification-alert {
  width: 100%;
}

.notification-title {
  font-size: 18px;
  font-weight: 600;
}

.notification-time {
  font-size: 11px;
  font-weight: 300;
  line-height: 2.5em;
}

.notification-message {
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--v-tprimary-base);
}

.notification-create-by {
  font-size: 12px;
  font-weight: 300;
  color: var(--v-tprimary-base);
}
</style>
