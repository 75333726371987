<template>
  <v-menu rounded="t-lg b-lg" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ attrs, on }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <v-avatar color="primary" :size="size">
          <span v-if="image === ''" class="white--text text-h7 text-uppercase">
            {{ firstName.charAt(0) }}{{ lastName.charAt(0) }}
          </span>
          <v-img v-else :src="profileUrl(image)"></v-img>
        </v-avatar>
      </v-btn>
    </template>
    <v-card class="mx-auto" min-width="300" max-width="344" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <!-- <div class="text-overline mb-4">Preference</div> -->
          <v-list-item-title class="text-h5 mb-1">
            {{ firstName }} {{ lastName }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar color="success" size="80">
          <span v-if="image === ''"  class="white--text text-h6 text-uppercase">
            {{ firstName.charAt(0) }}{{ lastName.charAt(0) }}
          </span>
          <v-img v-else :src="profileUrl(image)"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-card-actions>
        <v-list dense style="width: 100%">
          <v-subheader>
            {{ $vuetify.lang.t("$vuetify.preference") }}
          </v-subheader>

          <v-list-item class="pa-0">
            <v-row>
              <v-col>
                <u-switch-theme v-model="isDark" />
              </v-col>
              <v-col>
                <u-switch-lang @click="langSwitch" />
              </v-col>
            </v-row>
          </v-list-item>
          <v-subheader>
            {{ $vuetify.lang.t("$vuetify.action") }}
          </v-subheader>

          <v-list-item class="pa-0">
            <v-btn block color="error" @click="logout">
              {{ $vuetify.lang.t("$vuetify.logout") }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '35'
    },
    image: {
      type: String,
      default: 'Untitled'
    },
    firstName: {
      type: String,
      default: 'Untitled'
    },
    lastName: {
      type: String,
      default: 'Untitled'
    },
    subtitle: {
      type: String,
      default: 'Untitled'
    }
  },
  data () {
    return {
      DataStorage: {
        get (key) {
          return localStorage.getItem(key)
        },
        save (key, accessCategory) {
          localStorage.setItem(key, accessCategory)
        }
      }
    }
  },
  methods: {
    logout () {
      this.$websocket.Disconnect()
      localStorage.clear()
      this.$vuetify.theme.dark = false
      this.OauthLogOutOneID()
      // this.$router.push({ name: 'loginPage' }).catch(() => {})
    },
    langSwitch () {
      this.$vuetify.lang.current === 'th'
        ? (this.$vuetify.lang.current = 'en')
        : (this.$vuetify.lang.current = 'th')
    }
  },

  async created () {
    const darkMode = this.DataStorage.get('darkMode')
    if (darkMode !== null) {
      this.$vuetify.theme.dark = JSON.parse(darkMode)
    }
  },
  computed: {
    isDark: {
      get () {
        const darkMode = this.DataStorage.get('darkMode')
        if (darkMode !== null) {
          return JSON.parse(darkMode)
        }
        return false
      },
      set () {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        this.DataStorage.save('darkMode', this.$vuetify.theme.dark)
      }
    }
  },

  watch: {
    dark: {
      handler: function (val) {
        this.$cookies.set('dark', val)
      },
      deep: true,
      immediate: true
    }
  }

  // created () {
  //   this.initTheme()
  // }
}
</script>

<style lang="scss" scoped></style>
