<template>
  <div class="main-layout">
    <div class="layout-title">
      <u-title-page :title="NameComponent" />
    </div>
    <div class="layout-content">
      <u-table
        :headers="headers"
        :items="items"
        :mobileBreakpoint="900"
        :showSelect="false"
        itemKey="permission_id"
        :pagination="pagination"
        @onChangePagination="onChangePagination"
        :loading="loading"
      >
        <template v-slot:header>
          <v-row
            justify="space-between"
            align="center"
            class="header-custom-table"
          >
            <!-- // # Action Group -->
            <v-col cols="12" md="4">
              <v-row justify="start" align="center">
                <v-col cols="12" md="6">
                  <v-btn color="primary" block dark @click="dialog = !dialog">
                    <v-icon>mdi-home-plus</v-icon>เพิ่ม{{ NameComponent }}
                  </v-btn></v-col
                >
              </v-row>
            </v-col>
            <!-- // # Search Group -->
            <v-col cols="12" md="4">
              <v-row justify="center" align="center">
                <v-col
                  ><v-text-field
                    v-model="keyword"
                    name="name"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    placeholder="ค้นหา"
                    label="ค้นหา"
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col cols="4">
                  <v-btn color="primary" block dark @click="init()"
                    >ค้นหา</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.number`]="{ item }">
              {{
                pagination.row * pagination.page -
                pagination.row +
                (items.indexOf(item) + 1)
              }}
            </template>
        <template v-slot:[`item.description`]="{ item }">
          <span class="fix-overflow-ellipsis">
            {{
              item.description !== "" && item.description !== null
                ? item.description
                : "-"
            }}
          </span>
        </template>

        <template v-slot:[`item.menus`]="{ item }">
          <v-chip
            outlined
            small
            v-for="t in item.menus"
            :key="t"
            color="primary"
            class="my-1"
          >
            {{ getMenu(t) }}
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="action-nowrap">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  color="primary"
                  @click="editItem(item)"

                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>แก้ไขข้อมูล</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  color="error"
                  @click="deleteItem(item)"
                  :dark="item.priority === 0 ? false : true"
                  :disabled="item.priority === 0 ? true : false"
                >
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </template>
              <span>ลบ</span>
            </v-tooltip>
          </div>
        </template>
      </u-table>
    </div>

    <!-- // # Form Add/Edit -->
    <u-card-form
      :title="formTitle"
      :subtitle="formSubTitle"
      ref="form"
      v-model="dialog"
    >
      <template v-slot:form>
        <div>
          <span class="required">*</span><span>ชื่อ{{ NameComponent }}</span>
        </div>
        <v-text-field
          v-model="editedItem.name"
          :rules="[Rule.noInPut]"
          required
          outlined
          dense
          :placeholder="`กรุณากรอกชื่อ${NameComponent}`"
        ></v-text-field>
        <div><span class="required">*</span><span>เมนูที่ใช้งานได้</span></div>
        <v-select
          v-model="editedItem.menus"
          :rules="[Rule.noArray]"
          :items="menus"
          chips
          placeholder="กรุณาเลือกเมนูที่สามารถใช้งานได้"
          dense
          multiple
          outlined
          item-text="text"
          item-value="menu_id"
          :disabled="loading"
          :loading="loading"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index <= 1">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 2" class="grey--text text-caption">
              (+{{ editedItem.menus.length - 2 }} others)
            </span>
          </template>
        </v-select>

        <div><span>บันทึกรายละเอียด</span></div>
        <v-textarea
          v-model="editedItem.description"
          outlined
          placeholder="รายละเอียด"
          value=""
        ></v-textarea>
      </template>
      <template v-slot:actions="{ valid }">
        <v-row>
          <v-col>
            <v-btn
              @click="save"
              :disabled="!valid"
              block
              color="primary"
              :loading="loadingSave"
            >
              บันทึก
            </v-btn>
          </v-col>
          <v-col
            ><v-btn block color="error" @click="close">ยกเลิก</v-btn></v-col
          >
        </v-row>
      </template>
    </u-card-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      NameComponent: 'สิทธิการใช้งาน', // ชื่อของ Modal
      dialog: false, // สำหรับใช้เปิดปิด dialog add/edit
      editedIndex: -1,
      editedItem: {
        permission_id: '',
        name: '',
        description: '',
        menus: []
      },
      defaultItem: {
        permission_id: '',
        name: '',
        description: '',
        menus: []
      },
      keyword: '',
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number'
        },
        {
          text: 'ชื่อสิทธิการใช้งาน',
          align: 'start',
          value: 'name'
        },
        {
          text: 'รายละเอียด',
          value: 'description',
          align: 'start',
          sortable: false
        },
        { text: 'เมนู', value: 'menus', align: 'center', sortable: false },
        { text: 'จัดการ', value: 'actions', sortable: false, align: 'center' }
      ],
      items: [],
      pagination: {
        total: 0,
        page: 1,
        row: 10
      },
      loading: false,
      loadingSave: false,
      menus: []
    }
  },
  watch: {
    dialog (n) {
      if (n) this.initMenu()
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? `เพิ่ม${this.NameComponent}`
        : `แก้ไข${this.NameComponent}`
    },
    formSubTitle () {
      return this.editedIndex === -1
        ? `กรอกข้อมูลเพื่อสร้าง${this.NameComponent}`
        : `กรอกข้อมูลเพื่อแก้ไข${this.NameComponent}`
    }
  },
  created () {
    this.init()
    this.initMenu()
  },

  methods: {
    async init () {
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        page: this.pagination.page,
        row: this.pagination.row,
        keyword: this.keyword
      }
      const res = await this.getPermissionForTable(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination = res.data.pagination
      } else {
        this.antmessage.error(res.message)
      }
    },

    async initMenu () {
      // TODO Get Drop down Menu
      this.loading = true
      const res = await this.getMenuForDropdown()
      this.loading = false
      if (res.status) {
        this.menus = res.data
      } else {
        this.antmessage.error(res.message)
      }
    },
    onChangePagination (val) {
      this.pagination.page = val.page
      this.pagination.row = val.row
      this.init()
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.$swal({
        allowEnterKey: false,
        title: `ลบ${this.NameComponent}`,
        text: `คุณต้องการลบ${this.NameComponent}นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = { permission_id: item.permission_id }
          const res = await this.deletePermission(payload)
          this.antmessage.destroy()
          if (res.status) {
            this.init()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `ลบ${this.NameComponent}สำเร็จ`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },

    close () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },

    save () {
      if (this.editedIndex > -1) {
        // TODO Update Data
        this.$swal({
          allowEnterKey: false,
          title: `แก้ไข${this.NameComponent}`,
          text: `คุณต้องการแก้ไข${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              permission_id: this.editedItem.permission_id,
              name: this.editedItem.name,
              description: this.editedItem.description,
              menus: this.editedItem.menus
            }
            const res = await this.updatePermission(payload)
            this.loadingSave = false
            if (res.status) {
              this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `แก้ไข${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else {
        // TODO Create Data
        this.$swal({
          allowEnterKey: false,
          title: `เพิ่ม${this.NameComponent}`,
          text: `คุณต้องการเพิ่ม${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              name: this.editedItem.name,
              description: this.editedItem.description,
              menus: this.editedItem.menus
            }
            const res = await this.createPermission(payload)
            this.loadingSave = false
            if (res.status) {
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `เพิ่ม${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.init()
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      }
    },
    getMenu (menuId) {
      const x = this.menus.filter((i) => i.menu_id === menuId)
      if (x.length > 0) return x[0].text
      else return menuId
    }
  }
}
</script>

<style lang="css" scoped>
/* สำหรับจัดการหน้าเพจทั้งหมด */
.main-layout {
  height: 100%;
}

/* สำหรับ จัดการ title ของเพจ */
.layout-title {
  height: 58px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* สำหรับจัดการ Content ของเพจ */
.layout-content {
  height: calc(100% - 58px);
  padding: 0;
  margin: 0;
}

.header-custom-table .header-custom-search {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.header-custom-table .header-custom-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
  min-height: 70px;
}
</style>
