<template>
  <div class="main-layout">
    <div class="layout-title">
      <u-title-page :title="NameComponent" />
    </div>
    <div class="layout-content">
      <u-table
        :headers="headers"
        :items="items"
        :mobileBreakpoint="900"
        :showSelect="false"
        itemKey="menu_id"
        :showExpand="true"
        :singleExpand="true"
        :loading="loading"
        :pagination="pagination"
        :expanded.sync="expanded"
        @onChangePagination="onChangePagination"
      >
        <template v-slot:header>
          <v-row
            justify="space-between"
            align="center"
            class="header-custom-table"
          >
            <!-- // # Action Group -->
            <v-col cols="12" md="4">
              <v-row justify="start" align="center">
                <v-col cols="12" md="6">
                  <v-btn color="primary" block dark @click="dialog = !dialog">
                    <v-icon>mdi-home-plus</v-icon>เพิ่ม{{ NameComponent }}
                  </v-btn></v-col
                >
              </v-row>
            </v-col>
            <!-- // # Search Group -->
            <v-col cols="12" md="4">
              <v-row justify="center" align="center">
                <v-col
                  ><v-text-field
                    v-model="keyword"
                    name="name"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    placeholder="ค้นหา"
                    label="ค้นหา"
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col cols="4">
                  <v-btn color="primary" block dark @click="init()"
                    >ค้นหา</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </template>

        <!-- // # Expland Menu -->
        <template v-slot:[`item.number`]="{ item }">
              {{
                pagination.row * pagination.page -
                pagination.row +
                (items.indexOf(item) + 1)
              }}
            </template>
        <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
          <v-btn
            v-if="item.items.length !== 0"
            @click="handleExpansion(item, isExpanded)"
            icon
          >
            <v-icon>{{
              isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon></v-btn
          >
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="custom-table-expland">
            <v-data-table
              :headers="headers"
              :items="item.items"
              hide-default-footer
              class="elevation-0"
              mobile-breakpoint="900"
            >
              <template v-slot:[`item.icon`]="{ item }">
                <v-icon>{{ item.icon }}</v-icon>
              </template>

              <template v-slot:[`item.description`]="{ item }">
                <span class="fix-overflow-ellipsis">
                  {{
                    item.description !== "" && item.description !== null
                      ? item.description
                      : "-"
                  }}
                </span>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <div class="action-nowrap">
                  <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      fab
                      x-small
                      @click="editSubItem(item)"
                      color="primary"
                      :dark="item.create_by_system === true ? false : true"
                      :disabled="item.create_by_system === true ? true : false"
                    >
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>แก้ไขข้อมูล</span>
                </v-tooltip> -->

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        fab
                        x-small
                        @click="deleteSubItem(item)"
                        color="error"
                        :dark="item.create_by_system === true ? false : true"
                        :disabled="
                          item.create_by_system === true ? true : false
                        "
                      >
                        <v-icon> mdi-delete-forever </v-icon>
                      </v-btn>
                    </template>
                    <span>ลบ</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </td>
        </template>

        <template v-slot:[`item.icon`]="{ item }">
          <v-icon>{{ item.icon }}</v-icon>
        </template>

        <!-- // # Action Menu -->
        <template v-slot:[`item.description`]="{ item }">
          <span class="fix-overflow-ellipsis">
            {{
              item.description !== "" && item.description !== null
                ? item.description
                : "-"
            }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="action-nowrap">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  :dark="item.create_by_system === true ? false : true"
                  :disabled="item.create_by_system === true ? true : false"
                  @click="
                    (dialogSub = !dialogSub),
                      (menu_id = item.menu_id),
                      setSubMenuitem(item)
                  "
                  color="success"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>เพิ่ม{{ NameComponent }}ย่อย</span>
            </v-tooltip>

            <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                fab
                x-small
                @click="editItem(item)"
                color="primary"
                :dark="item.create_by_system === true ? false : true"
                :disabled="item.create_by_system === true ? true : false"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>แก้ไขข้อมูล</span>
          </v-tooltip> -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  @click="deleteItem(item)"
                  color="error"
                  :dark="item.create_by_system === true ? false : true"
                  :disabled="item.create_by_system === true ? true : false"
                >
                  <v-icon> mdi-delete-forever </v-icon>
                </v-btn>
              </template>
              <span>ลบ</span>
            </v-tooltip>
          </div>
        </template>
      </u-table>
    </div>

    <!-- // # Form Add/Edit  เดี๋ยวจะมาจามแก้ให้ main menu และ sub menu อยู่ใน form เดียวกัน 2022-11-26 20:47-->
    <u-card-form
      :title="formTitle"
      :subtitle="formSubTitle"
      ref="form"
      v-model="dialog"
    >
      <template v-slot:form>
        <div>
          <span class="required">*</span
          ><span>ชื่อ{{ NameComponent }}ที่ใช้แสดง</span>
        </div>
        <v-select
          v-model="editedItem.name"
          :rules="[Rule.noInPut]"
          :items="itemMenu"
          item-value="name"
          item-text="text"
          :placeholder="`กรุณาเลือก${NameComponent}`"
          @change="returnItemMenu(editedItem.name)"
          required
          dense
          outlined
        >
        </v-select>

        <div>
          <span>ชื่อ{{ NameComponent }}</span>
        </div>
        <v-text-field
          v-model="editedItem.name"
          :placeholder="`ชื่อ${NameComponent}`"
          :rules="[Rule.noInPut]"
          required
          outlined
          dense
          disabled
        ></v-text-field>

        <div>
          <span>เส้นทาง{{ NameComponent }}</span>
        </div>
        <v-text-field
          v-model="editedItem.path"
          :placeholder="`พาทของ${NameComponent}`"
          outlined
          dense
          disabled
        ></v-text-field>

        <div><span>ไอคอน</span></div>
        <v-text-field
          v-model="editedItem.icon"
          placeholder="ไอคอน"
          :append-icon="editedItem.icon"
          :rules="[Rule.noInPut]"
          required
          outlined
          dense
          disabled
        ></v-text-field>

        <div><span>บันทึกรายละเอียด</span></div>
        <v-textarea
          v-model="editedItem.description"
          outlined
          placeholder="รายละเอียด"
          value=""
          disabled
        ></v-textarea>
      </template>
      <template v-slot:actions="{ valid }">
        <v-row>
          <v-col>
            <v-btn
              @click="save"
              :disabled="!valid"
              block
              color="primary"
              :loading="loadingSave"
            >
              บันทึก
            </v-btn>
          </v-col>
          <v-col
            ><v-btn block color="error" @click="close">ยกเลิก</v-btn></v-col
          >
        </v-row>
      </template>
    </u-card-form>

    <!-- // # sub menu เดี๋ยวจะมาตามแก้ 2022-11-26 20:49 -->
    <u-card-form
      :title="formTitleSub"
      :subtitle="formSubTitleSub"
      ref="formSub"
      v-model="dialogSub"
    >
      <template v-slot:form>
        <div>
          <span class="required">*</span
          ><span>ชื่อ{{ NameComponent }}ที่ใช้แสดง</span>
        </div>
        <v-select
          v-model="editedSubItem.name"
          :rules="[Rule.noInPut]"
          :items="itemSubMenu"
          item-value="name"
          item-text="text"
          :placeholder="`กรุณาเลือก${NameComponent}`"
          @change="returnItemSubMenu(editedSubItem.name)"
          required
          dense
          outlined
        >
        </v-select>

        <div>
          <span>ชื่อ{{ NameComponent }}</span>
        </div>
        <v-text-field
          v-model="editedSubItem.name"
          :placeholder="`ชื่อ${NameComponent}`"
          :rules="[Rule.noInPut]"
          required
          outlined
          dense
          disabled
        ></v-text-field>

        <div>
          <span>เส้นทาง{{ NameComponent }}</span>
        </div>
        <v-text-field
          v-model="editedSubItem.path"
          :placeholder="`พาทของ${NameComponent}`"
          outlined
          dense
          disabled
        ></v-text-field>

        <div><span>ไอคอน</span></div>
        <v-text-field
          v-model="editedSubItem.icon"
          placeholder="ไอคอน"
          :append-icon="editedSubItem.icon"
          :rules="[Rule.noInPut]"
          required
          outlined
          dense
          disabled
        ></v-text-field>

        <div><span>บันทึกรายละเอียด</span></div>
        <v-textarea
          v-model="editedSubItem.description"
          name="input-7-4"
          placeholder="รายละเอียด"
          value=""
          outlined
          disabled
        ></v-textarea>
      </template>
      <template v-slot:actions="{ valid }">
        <v-row>
          <v-col>
            <v-btn
              @click="saveSub"
              :disabled="!valid"
              block
              color="primary"
              :loading="loadingSave"
            >
              บันทึก
            </v-btn>
          </v-col>
          <v-col>
            <v-btn block color="error" @click="closeSub">ยกเลิก</v-btn>
          </v-col>
        </v-row>
      </template>
    </u-card-form>
  </div>
</template>

<script>
export default {
  created () {
    this.init()
  },
  data () {
    return {
      // expanded: [],
      NameComponent: 'เมนู',
      dialog: false, // สำหรับใช้เปิดปิด dialog add/edit
      menu_id: '',
      // # Main Menu
      editedIndex: -1,
      editedItem: {
        text: '',
        menu_id: '',
        name: '',
        path: '',
        icon: '',
        index: '',
        description: ''
      },
      defaultItem: {
        text: '',
        menu_id: '',
        name: '',
        path: '',
        icon: '',
        index: '',
        description: ''
      },
      // # Sub Menu
      expanded: [],
      dialogSub: false, // สำหรับใช้เปิดปิด dialog add/edit Sub Menu
      editedSubIndex: -1,
      editedSubItem: {
        text: '',
        menu_id: '',
        name: '',
        path: '',
        icon: '',
        index: '',
        description: ''
      },
      defaultSubItem: {
        text: '',
        menu_id: '',
        name: '',
        path: '',
        icon: '',
        index: '',
        description: ''
      },
      keyword: '',
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number'
        },
        { text: 'ไอคอน', value: 'icon', align: 'start', sortable: false },
        {
          text: 'ชื่อที่แสดง',
          align: 'start',
          value: 'text',
          sortable: false
        },
        {
          text: 'ชื่อ',
          value: 'name',
          align: 'start',
          sortable: false
        },
        {
          text: 'เส้นทาง',
          value: 'path',
          align: 'start',
          sortable: false
        },
        {
          text: 'รายละเอียด',
          value: 'description',
          align: 'start',
          sortable: false
        },
        { text: 'จัดการ', value: 'actions', sortable: false, align: 'center' },
        { text: '', value: 'data-table-expand' }
      ],
      items: [],
      pagination: {
        total: 0,
        page: 1,
        row: 10
      },
      loading: false,
      loadingSave: false,
      menuAll: [
        {
          name: 'userPage',
          text: 'บัญชีผู้ใช้งาน',
          path: '',
          icon: 'mdi-account-cog-outline',
          description: '',
          items: []
        },
        {
          name: 'projectPage',
          text: 'โปรเจค',
          path: '',
          icon: 'mdi-chart-areaspline',
          description: '',
          items: []
        },
        {
          name: 'announcePage',
          text: 'ประกาศ',
          path: '',
          icon: 'mdi-bullhorn-variant-outline',
          description: '',
          items: []
        },
        {
          name: 'datasourcePage',
          text: 'Datasource',
          path: '-',
          icon: 'mdi-database-outline',
          description: '',
          items: []
        },
        // {
        //   name: 'profilePage',
        //   text: 'โปรไฟล์',
        //   path: '',
        //   icon: 'mdi-account-outline',
        //   description: '',
        //   items: []
        // },
        // {
        //   name: 'notificationPage',
        //   text: 'แจ้งเตือน',
        //   path: '',
        //   icon: 'mdi-bell-outline',
        //   description: '',
        //   items: []
        // },
        {
          name: 'settingPage',
          text: 'ตั้งค่า',
          path: '',
          icon: 'mdi-cog-outline',
          description: '',
          items: [
            {
              name: 'permissionPage',
              text: 'สิทธิการใช้งาน',
              path: '',
              icon: 'mdi-key-chain-variant',
              description: ''
            },
            {
              name: 'menuPage',
              text: 'เมนู',
              path: '',
              icon: 'mdi-format-list-bulleted',
              description: ''
            },
            {
              name: 'preferencePage',
              text: 'ปรับแต่ง',
              path: '',
              icon: 'mdi-palette-outline',
              description: ''
            }
          ]
        }
      ],
      itemMenu: [],
      itemSubMenu: []
    }
  },
  methods: {
    setMenuitem (items) {
      const itemMenu = this.menuAll.slice()
      for (let index = 0; index < items.length; index++) {
        for (let index1 = 0; index1 < itemMenu.length; index1++) {
          if (items[index].name === itemMenu[index1].name) {
            itemMenu.splice(index1, 1)
          }
        }
      }
      this.itemMenu = itemMenu
    },
    setSubMenuitem (items) {
      let itemMenu = this.menuAll.filter((i) => i.name === items.name)
      itemMenu = itemMenu[0].items.slice()
      this.itemSubMenu = itemMenu
      for (let index = 0; index < items.items.length; index++) {
        for (let index1 = 0; index1 < itemMenu.length; index1++) {
          if (items.items[index].name === itemMenu[index1].name) {
            itemMenu.splice(index1, 1)
          }
        }
      }
      this.itemMenu = itemMenu
    },
    returnItemSubMenu (name) {
      const menu = this.itemSubMenu.filter((i) => i.name === name)
      this.editedSubItem.text = menu[0].text
      this.editedSubItem.path = menu[0].path
      this.editedSubItem.icon = menu[0].icon
      this.editedSubItem.description = menu[0].description
    },
    returnItemMenu (name) {
      const menu = this.itemMenu.filter((i) => i.name === name)
      this.editedItem.text = menu[0].text
      this.editedItem.path = menu[0].path
      this.editedItem.icon = menu[0].icon
      this.editedItem.description = menu[0].description
    },
    handleExpansion (item, state) {
      const itemIndex = this.expanded.indexOf(item)
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item)
    },
    async init () {
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        page: this.pagination.page,
        row: this.pagination.row,
        keyword: this.keyword
      }
      const res = await this.getMenuForTable(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination = res.data.pagination
        this.setMenuitem(this.items)
      } else {
        this.antmessage.error(res.message)
      }
    },
    onChangePagination (val) {
      this.pagination.page = val.page
      this.pagination.row = val.row
      this.init()
    },

    // # Main Menu Table -----------------------------------------------------------------
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.$swal({
        allowEnterKey: false,
        title: `ลบ${this.NameComponent}`,
        text: `คุณต้องการลบ${this.NameComponent}นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = { menu_id: item.menu_id }
          const res = await this.deleteMenu(payload)
          this.antmessage.destroy()
          if (res.status) {
            this.init()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `ลบ${this.NameComponent}สำเร็จ`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },

    close () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },

    save () {
      if (this.editedIndex > -1) {
        // TODO Update Data
        this.$swal({
          allowEnterKey: false,
          title: `แก้ไข${this.NameComponent}`,
          text: `คุณต้องการแก้ไข${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              menu_id: this.editedItem.menu_id,
              text: this.editedItem.text,
              name: this.editedItem.name,
              path: this.editedItem.path,
              icon: this.editedItem.icon,
              description: this.editedItem.description
            }
            const res = await this.updateMenu(payload)
            this.loadingSave = false
            if (res.status) {
              this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `แก้ไข${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else {
        // TODO Create Data
        this.$swal({
          allowEnterKey: false,
          title: `เพิ่ม${this.NameComponent}`,
          text: `คุณต้องการเพิ่ม${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              text: this.editedItem.text,
              name: this.editedItem.name,
              path: this.editedItem.path,
              icon: this.editedItem.icon,
              description: this.editedItem.description
            }
            const res = await this.createMenu(payload)
            this.loadingSave = false
            if (res.status) {
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `เพิ่ม${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.init()
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      }
    },
    // # Main Sub Menu Table -----------------------------------------------------------------
    editSubItem (item) {
      // # เมื่อเป็น Submenu
      if (this.expanded.length > 0) {
        const objs = this.items.filter(
          (v) => v.menu_id === this.expanded[0].menu_id
        )
        const editMenuItemIdx = this.items.indexOf(objs[0])
        this.editedSubIndex = this.items[editMenuItemIdx].items.indexOf(item)
        this.editedSubItem = Object.assign({}, item)
        this.dialogSub = true
      } else {
        this.antmessage.error('Not found Main menu')
      }
    },
    deleteSubItem (item) {
      if (this.expanded.length > 0) {
        const objs = this.items.filter(
          (v) => v.menu_id === this.expanded[0].menu_id
        )
        const editMenuItemIdx = this.items.indexOf(objs[0])
        this.editedSubIndex = this.items[editMenuItemIdx].items.indexOf(item)
        this.editedSubIndex = this.items.indexOf(item)
        this.$swal({
          allowEnterKey: false,
          title: `ลบ${this.NameComponent}ย่อย`,
          text: `คุณต้องการลบ${this.NameComponent}ย่อยนี้ใช่หรือไม่`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.antmessage.loading('กำลังดำเนินการ', 0)
            const payload = {
              menu_id: this.expanded[0].menu_id,
              submenu_id: item.menu_id
            }
            const res = await this.deleteSubMenu(payload)
            this.antmessage.destroy()
            if (res.status) {
              this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `ลบ${this.NameComponent}ย่อยสำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else {
        this.antmessage.error('Not found Main menu')
      }
    },
    closeSub () {
      this.$refs.formSub.reset()
      this.$refs.formSub.resetValidation()
      this.dialogSub = false
      this.$nextTick(() => {
        this.editedSubItem = Object.assign({}, this.defaultItem)
        this.editedSubIndex = -1
      })
    },
    saveSub () {
      if (this.editedSubIndex > -1) {
        // TODO Update Data
        this.$swal({
          allowEnterKey: false,
          title: `แก้ไข${this.NameComponent}ย่อย`,
          text: `คุณต้องการแก้ไข${this.NameComponent}ย่อยนี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              menu_id: this.expanded[0].menu_id,
              submenu_id: this.editedSubItem.menu_id,
              name: this.editedSubItem.name,
              text: this.editedSubItem.text,
              path: this.editedSubItem.path,
              icon: this.editedSubItem.icon,
              description: this.editedSubItem.description
            }
            const res = await this.updateSubMenu(payload)
            this.loadingSave = false
            if (res.status) {
              this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `แก้ไข${this.NameComponent}ย่อยสำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })

              this.closeSub()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else {
        // TODO Create Data
        this.$swal({
          allowEnterKey: false,
          title: `เพิ่ม${this.NameComponent}ย่อย`,
          text: `คุณต้องการเพิ่ม${this.NameComponent}ย่อยนี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              menu_id: this.menu_id,
              name: this.editedSubItem.name,
              text: this.editedSubItem.text,
              path: this.editedSubItem.path,
              icon: this.editedSubItem.icon,
              description: this.editedSubItem.description
            }
            const res = await this.createSubMenu(payload)
            this.loadingSave = false
            if (res.status) {
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `เพิ่ม${this.NameComponent}ย่อยสำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.init()
              this.closeSub()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      }
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? `เพิ่ม${this.NameComponent}`
        : `แก้ไข${this.NameComponent}`
    },
    formSubTitle () {
      return this.editedIndex === -1
        ? `กรอกข้อมูลเพื่อสร้าง${this.NameComponent}`
        : `กรอกข้อมูลเพื่อแก้ไข${this.NameComponent}`
    },

    formTitleSub () {
      return this.editedSubIndex === -1
        ? `เพิ่ม${this.NameComponent}ย่อย`
        : `แก้ไข${this.NameComponent}ย่อย`
    },
    formSubTitleSub () {
      return this.editedSubIndex === -1
        ? `กรอกข้อมูลเพื่อสร้าง${this.NameComponent}ย่อย`
        : `กรอกข้อมูลเพื่อแก้ไข${this.NameComponent}ย่อย`
    }
  }
}
</script>

<style lang="css" scoped>
/* สำหรับจัดการหน้าเพจทั้งหมด */
.main-layout {
  height: 100%;
  /* background: red; */
}

/* สำหรับ จัดการ title ของเพจ */
.layout-title {
  height: 58px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* สำหรับจัดการ Content ของเพจ */
.layout-content {
  height: calc(100% - 58px);
  padding: 0;
  margin: 0;
}

.header-custom-table .header-custom-search {
  display: flex;
  gap: 1rem;

  /* background: gray; */
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.header-custom-table .header-custom-actions {
  display: flex;
  gap: 1rem;
  /* background: gray; */
  align-items: center;
  height: 100%;
  min-height: 70px;

  /* justify-content: flex-end; */
}

/* // # EXPAND  */
>>> .v-data-table
  .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

>>> .v-data-table__expanded
  .v-data-table
  .v-data-table__wrapper
  table
  thead
  tr
  th {
  background: none !important;
}

>>> .v-data-table__expanded table thead tr th {
  color: #757575 !important;
  font-size: 12px !important;
}
@media only screen and (max-width: 900px) {
  ::v-deep .custom-table-expland {
    display: block;
    width: 100%;
    clear: both;
  }
}
</style>
