<template>
  <div style="width: 100%; height: calc(100% - 48px)">
    <u-table
      :headers="headers"
      :items="items"
      :mobileBreakpoint="900"
      :offsetHeight="48"
      :showSelect="false"
      itemKey="menu_id"
      :loading="loading"
      :pagination="pagination"
      @onChangePagination="onChangePagination"
    >
      <!-- TODO Header of Table -->
      <template v-slot:header>
        <v-row justify="space-between" align="center">
          <!-- NOTE Action Group -->
          <v-col cols="12" md="2">
            <v-btn color="primary" block dark @click="dialog = !dialog">
              <v-icon>mdi-account-plus</v-icon>เพิ่ม{{ NameComponent }}
            </v-btn>
          </v-col>

          <!-- NOTE Search Group -->
          <v-col cols="12" md="4">
            <v-row justify="center" align="center">
              <v-col>
                <v-text-field
                  v-model="keyword"
                  name="name"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  placeholder="ค้นหา"
                  label="ค้นหา"
                  id="id"
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn color="primary" block dark @click="init()">ค้นหา</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>

      <!-- TODO Number of row -->
      <template v-slot:[`item.number`]="{ item }">
        {{
          pagination.row * pagination.page -
          pagination.row +
          (items.indexOf(item) + 1)
        }}
      </template>

      <!-- TODO Actions of row -->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="action-nowrap">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                fab
                x-small
                dark
                color="primary"
                @click="editItem(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>แก้ไขข้อมูล</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                fab
                x-small
                color="error"
                @click="deleteItem(item)"
              >
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </template>
            <span>ลบ</span>
          </v-tooltip>
        </div>
      </template>
    </u-table>

    <!-- TODO Dialog Config Alert -->
    <AlertConfigDialog
      v-model="dialog"
      :form-title="formTitle"
      :form-sub-title="formSubTitle"
      :data.sync="editedItem"
      @close="close"
      @save="save"
      :alert-menu-items="menus"
    >
    </AlertConfigDialog>
  </div>
</template>

<script>
import AlertConfigDialog from '@/components/Project/AlertConfigsDialog.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    AlertConfigDialog
  },
  computed: {
    ...mapGetters(['storeGetProjectID', 'storeGetCurProjectMenus']),
    formTitle () {
      return this.editedIndex === -1
        ? `เพิ่ม${this.NameComponent}`
        : `แก้ไข${this.NameComponent}`
    },
    formSubTitle () {
      return this.editedIndex === -1
        ? `กรอกข้อมูลเพื่อสร้าง${this.NameComponent}`
        : `กรอกข้อมูลเพื่อแก้ไข${this.NameComponent}`
    }
  },
  async created () {
    await this.init()

    // TODO Filter Menu โดยจะ Filter เฉพาะ Type Datasource
    // TODO เพื่อทำ Dropdown ในการเลือก Project ที่จะ Alert
    this.menus = await Promise.all(
      this.storeGetCurProjectMenus
        .filter((e) => e.type === 'datasource')
        .map(async (e) => {
          return {
            menu_id: e.menu_id,
            datasource_id: e.datasource_id,
            datasource_index: e.datasource_index,
            text: e.text,
            name: e.name,
            path: e.path,
            list_field:
              (await this.getAllFieldsFromIndex(
                e.datasource_id,
                e.datasource_index
              )) || []
          }
        })
    )
  },
  data () {
    return {
      NameComponent: 'Alert Config ',
      editedIndex: -1,
      editedItem: {
        rule_id: '',
        rule_mode: '',
        title: '',
        rules: [],
        scheduled: '* * * * *',
        rate: 0,
        description: '',
        menus: [],
        black_list_ips: [],
        alert_methods: [],
        ststus: true,
        create_date: '',
        update_date: '',
        create_by: '',
        update_by: ''
      },
      defaultItem: {
        rule_id: '',
        rule_mode: '',
        title: '',
        rules: [
          // { menu_id: '', time_field: '', rules: [{ field: '', regex: '' }] }
        ],
        scheduled: '* * * * *',
        rate: 0,
        description: '',
        menus: [],
        black_list_ips: [],
        alert_methods: [],
        ststus: true,
        create_date: '',
        update_date: '',
        create_by: '',
        update_by: ''
      },
      dialog: false,
      loading: false,
      keyword: '',
      items: [],
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number',
          width: '100px'
        },
        {
          text: 'ชื่อ',
          align: 'start',
          value: 'title',
          sortable: false
        },
        {
          text: 'โหมด',
          value: 'rule_mode',
          align: 'start',
          sortable: false
        },
        {
          text: 'เวลา',
          value: 'scheduled',
          align: 'center',
          sortable: false
        },
        {
          text: 'Description',
          value: 'description',
          align: 'start',
          sortable: false
        },
        {
          text: 'จัดการ',
          value: 'actions',
          align: 'center',
          sortable: false,
          width: '120px'
        }
      ],
      menus: [], // NOTE Datasource menu from project
      pagination: {
        total: 0,
        page: 1,
        row: 10
      }
    }
  },

  methods: {
    async init () {
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        page: this.pagination.page,
        row: this.pagination.row,
        project_id: this.storeGetProjectID,
        keyword: this.keyword
      }
      const res = await this.GetAlertRules(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination = res.data.pagination
      } else {
        this.antmessage.error(res.message)
      }
    },

    onChangePagination (val) {
      this.pagination.page = val.page
      this.pagination.row = val.row
      this.init()
    },

    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = JSON.parse(JSON.stringify(item))
      this.dialog = true
    },

    deleteItem (item) {
      this.$swal({
        allowEnterKey: false,
        title: `ลบ${this.NameComponent}`,
        text: `คุณต้องการลบ${this.NameComponent}นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = {
            project_id: this.storeGetProjectID,
            rule_id: item.rule_id
          }
          const res = await this.DeleteAlertRules(payload)
          this.antmessage.destroy()
          if (res.status) {
            this.init()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `ลบ${this.NameComponent}สำเร็จ`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },
    save () {
      if (this.editedIndex > -1) {
        // TODO Update Data
        this.$swal({
          allowEnterKey: false,
          title: `แก้ไข${this.NameComponent}`,
          text: `คุณต้องการแก้ไข${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              ...{
                project_id: this.storeGetProjectID
              },
              ...this.editedItem
            }
            const res = await this.UpdateAlertRules(payload)
            this.loadingSave = false
            if (res.status) {
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `แก้ไข${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.init()
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else {
        // TODO Create Data
        this.$swal({
          allowEnterKey: false,
          title: `เพิ่ม${this.NameComponent}`,
          text: `คุณต้องการเพิ่ม${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              ...{
                project_id: this.storeGetProjectID
              },
              ...this.editedItem
            }
            const res = await this.CreateAlertRule(payload)
            this.loadingSave = false
            if (res.status) {
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `เพิ่ม${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.init()
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      }
    },
    close () {
      this.dialog = false
      // this.editedItem = Object.assign({}, this.defaultItem)
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
      setTimeout(() => {
        this.editedIndex = -1
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped></style>
