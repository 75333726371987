<template>
  <div class="main-layout">
    <div class="layout-content">
      <u-table
        :headers="headers"
        :items="items"
        :mobileBreakpoint="900"
        :offsetHeight="48"
        :showSelect="false"
        itemKey="user_id"
        v-model="userSelected"
        :pagination="pagination"
        @onChangePagination="onChangePagination"
        :loading="loading"
      >
        <!-- // # Header Table -->
        <template v-slot:header>
          <v-row justify="space-between" align="center">
            <!-- // # Action Group -->
            <v-col cols="12" md="2">
              <v-btn color="primary" block dark @click="dialog = !dialog">
                <v-icon>mdi-account-plus</v-icon>เพิ่ม{{ NameComponent }}
              </v-btn>
            </v-col>

            <!-- // # Search Group -->
            <v-col cols="12" md="4">
              <v-row justify="center" align="center">
                <v-col
                  ><v-text-field
                    v-model="keyword"
                    name="name"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    placeholder="ค้นหา"
                    label="ค้นหา"
                    id="id"
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col cols="4">
                  <v-btn color="primary" block dark @click="init()"
                    >ค้นหา</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.number`]="{ item }">
          {{
            pagination.row * pagination.page -
            pagination.row +
            (items.indexOf(item) + 1)
          }}
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <v-avatar color="primary" size="35">
            <span
              v-if="item.image === ''"
              class="white--text text-h7 text-uppercase"
            >
              {{ item.first_name.charAt(0) }}{{ item.last_name.charAt(0) }}
            </span>
            <v-img v-else :src="profileUrl(item.image)"></v-img>
          </v-avatar>
        </template>
        <template v-slot:[`item.name_title`]="{ item }">
          {{ item.name_title != "" ? item.name_title : "-" }}
        </template>
        <template v-slot:[`item.gender`]="{ item }">
          {{ item.gender != "" ? item.gender : "-" }}
        </template>
        <template v-slot:[`item.permissions`]="{ item }">
          <v-chip
            outlined
            small
            v-for="t in item.permissions"
            :key="t.permission_id"
            color="primary"
            class="text-capitalize my-1"
          >
            {{ getPermission(t.permission_id) }}
          </v-chip>
        </template>
        <!-- // # Action Fields -->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="action-nowrap">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  dark
                  color="primary"
                  @click="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>แก้ไขข้อมูล</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  :dark="item.user_id === userInfo.user_id ? false : true"
                  color="error"
                  @click="deleteItem(item)"
                  :disabled="item.user_id === userInfo.user_id ? true : false"
                >
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </template>
              <span>ลบ</span>
            </v-tooltip>
          </div>
        </template>
      </u-table>
    </div>
    <u-card-form
      :title="formTitle"
      :subtitle="formSubTitle"
      ref="form"
      v-model="dialog"
    >
      <template v-slot:form>
        <div v-if="editedIndex > -1">
          <v-autocomplete
            v-model="editedItem.permissions"
            :rules="[Rule.noArray]"
            :items="permissionsEdit"
            item-text="name"
            item-value="permission_id"
            placeholder="กรุณาเลือกสิทธิการใช้งาน"
            :disabled="loading"
            :loading="loading"
            required
            multiple
            dense
            outlined
            clearable
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index <= 1">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ editedItem.permissions.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </div>
        <div v-if="editedIndex < 0">
          <div><span class="required">*</span><span>สิทธิการใช้งาน</span></div>
          <v-autocomplete
            v-model="editedItem.permissions"
            :rules="[Rule.noArray]"
            :items="permissions"
            item-text="name"
            item-value="permission_id"
            placeholder="กรุณาเลือกสิทธิการใช้งาน"
            @change="
              ((editedItem.user_id = ''), (users = [])), initUserDropdown()
            "
            :disabled="loading"
            :loading="loading"
            required
            multiple
            dense
            outlined
            clearable
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index <= 1">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ editedItem.permissions.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
          <div>
            <span class="required">*</span><span>{{ NameComponent }}</span>
          </div>

          <v-autocomplete
            v-model="editedItem.user_id"
            :rules="[Rule.noInPut]"
            :items="users"
            required
            item-value="user_id"
            item-text="first_name"
            :placeholder="`กรุณาเลือก${NameComponent}`"
            :disabled="
              editedItem.permissions.length !== 0 &&
              editedItem.permissions !== undefined &&
              editedItem.permissions !== ''
                ? false
                : true
            "
            dense
            outlined
          >
            <template v-slot:selection="{ item }">
              {{ item.first_name }} {{ item.last_name }}
            </template>

            <template v-slot:item="{ item }">
              {{ item.first_name }} {{ item.last_name }}
            </template>
          </v-autocomplete>
        </div>
      </template>
      <template v-slot:actions="{ valid }">
        <v-row>
          <v-col>
            <v-btn
              @click="save"
              :disabled="!valid"
              block
              color="primary"
              :loading="loadingSave"
            >
              บันทึก
            </v-btn>
          </v-col>
          <v-col
            ><v-btn block color="error" @click="close">ยกเลิก</v-btn></v-col
          >
        </v-row>
      </template>
    </u-card-form>
  </div>
</template>

<script>
export default {
  props: {
    project_id: {
      type: String
    },
    userInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      verify: '',
      users: [],
      permissions: [],
      permissionsEdit: [],
      userSelected: [], // สำหรับ User ที่ถูก Select ในตราง User
      NameComponent: 'บัญชีผู้ใช้',
      dialog: false, // สำหรับใช้เปิดปิด dialog add/edit
      editedIndex: -1,
      editedItem: {
        user_id: '',
        permissions: []
      },
      defaultItem: {
        user_id: '',
        permissions: []
      },
      keyword: '',
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number'
        },
        {
          text: 'รูปโปรไฟล์',
          align: 'center',
          sortable: false,
          value: 'image'
        },
        {
          text: 'คำนำหน้า',
          value: 'name_title',
          sortable: false,
          align: 'start'
        },
        {
          text: 'ชื่อ',
          align: 'start',
          sortable: false,
          value: 'first_name'
        },
        { text: 'นามสกุล', value: 'last_name', sortable: false },
        { text: 'เบอร์โทร', value: 'phone', sortable: false, align: 'center' },
        { text: 'เพศ', value: 'gender', sortable: false, align: 'center' },
        {
          text: 'สิทธิ',
          value: 'permissions',
          sortable: false,
          align: 'center'
        },
        { text: 'จัดการ', value: 'actions', sortable: false, align: 'center' }
      ],
      items: [],
      loading: false,
      loadingSave: false,
      pagination: {
        total: 0,
        page: 1,
        row: 10
      }
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? `เพิ่ม${this.NameComponent}`
        : `แก้ไข${this.NameComponent}`
    },
    formSubTitle () {
      return this.editedIndex === -1
        ? `กรอกข้อมูลเพื่อสร้าง${this.NameComponent}`
        : `กรอกข้อมูลเพื่อแก้ไข${this.NameComponent}`
    }
  },
  watch: {
    dialog (n) {
      if (n) {
        if (this.editedIndex === -1) {
          this.initPermissionDropdown()
        }
      }
    }
  },
  created () {
    this.init()
    // this.initPermissionDropdown()  move in init()
  },
  methods: {
    async init () {
      this.initPermissionDropdown()

      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        page: this.pagination.page,
        row: this.pagination.row,
        project_id: this.project_id,
        verify: '2',
        keyword: this.keyword
      }
      const res = await this.getProjectUserForTable(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination = res.data.pagination
      } else {
        this.antmessage.error(res.message)
      }
    },
    async initPermissionDropdown () {
      this.loading = true
      const payload = {
        project_id: this.project_id
      }
      const res = await this.getProjectPermissionForDropdown(payload)
      this.loading = false
      if (res.status) {
        // TODO when get data success
        this.permissions = res.data
      } else {
        // TODO when get data fail
        this.antmessage.error(res.message)
      }
    },
    async initPermissionDropdownEdit () {
      this.loading = true
      const payload = {
        project_id: this.project_id,
        user_id: this.editedItem.user_id
      }
      const res = await this.getProjectPermissionForDropdownEdit(payload)
      this.loading = false
      if (res.status) {
        // TODO when get data success
        this.permissionsEdit = res.data
      } else {
        // TODO when get data fail
        this.antmessage.error(res.message)
      }
    },
    async initUserDropdown () {
      if (this.editedItem.permissions.length !== 0) {
        this.loading = true
        const payload = {
          project_id: this.project_id,
          permission_id: this.editedItem.permissions
        }
        const res = await this.getUserForDropdownByPermission(payload)
        this.loading = false
        if (res.status) {
          // TODO when get data success
          this.users = res.data
          // console.log('this.users', this.users)
        } else {
          // TODO when get data fail
          this.antmessage.error(res.message)
        }
      }
    },
    onChangePagination (val) {
      this.pagination.page = val.page
      this.pagination.row = val.row
      this.init()
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      const listPermission = []
      for (let index = 0; index < this.editedItem.permissions.length; index++) {
        listPermission.push(this.editedItem.permissions[index].permission_id)
      }
      this.editedItem.permissions = listPermission
      if (this.editedItem.permissions.length === 0) {
        this.editedItem.permissions = []
      }
      this.initPermissionDropdownEdit()
      this.dialog = true
    },
    deleteItem (item) {
      this.$swal({
        allowEnterKey: false,
        title: `ลบ${this.NameComponent}`,
        text: `คุณต้องการลบ${this.NameComponent}นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = {
            project_id: this.project_id,
            user_id: item.user_id
          }
          const res = await this.deleteProjectUser(payload)
          this.antmessage.destroy()
          if (res.status) {
            this.init()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `ลบ${this.NameComponent}สำเร็จ`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },
    save () {
      if (this.editedIndex > -1) {
        // TODO Update Data
        this.$swal({
          allowEnterKey: false,
          title: `แก้ไข${this.NameComponent}`,
          text: `คุณต้องการแก้ไข${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              project_id: this.project_id,
              user_id: this.editedItem.user_id,
              permissions: this.editedItem.permissions
            }
            const res = await this.updateProjectUser(payload)
            this.loadingSave = false
            if (res.status) {
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `แก้ไข${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.init()
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else {
        // TODO Create Data
        this.$swal({
          allowEnterKey: false,
          title: `เพิ่ม${this.NameComponent}`,
          text: `คุณต้องการเพิ่ม${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              project_id: this.project_id,
              user_id: this.editedItem.user_id,
              permissions: this.editedItem.permissions
            }
            const res = await this.createProjectUser(payload)
            this.loadingSave = false
            if (res.status) {
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `เพิ่ม${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.init()
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      }
    },
    getPermission (permissionId) {
      const x = this.permissions.filter(
        (i) => i.permission_id === permissionId
      )
      if (x.length > 0) return x[0].name
      else return permissionId
    },
    close () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      setTimeout(() => {
        this.editedIndex = -1
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
.main-layout {
  height: 100%;
}
.layout-content {
  height: 100%;
  padding: 0;
  margin: 0;
}
.header-custom-table .header-custom-search {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.header-custom-table .header-custom-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
  min-height: 70px;
}
</style>
