<template>
  <!--  :height="windowSize.y - headerSize.h - 24 - 59 - 36 - 90 - offsetHeight" -->
  <div style="height: 100%; width: 100%" ref="cardLayout">
    <!-- <v-card elevation="0" v-resize="onResize" class="card-layout"> -->
    <v-card
      elevation="0"
      :class="[
        'card-layout',
        Object.keys($scopedSlots).includes('header') ? 'header' : 'no-header',
      ]"
    >
      <!-- // # Title Items -->

      <v-card-title :class="['px-0', 'card-title']" ref="cardTitle">
        <div class="table-header">
          <slot name="header"> </slot>
        </div>
      </v-card-title>
      <!-- // # Body Items -->
      <v-card-text class="card-text" ref="cardText" :style="`height:${h}px`">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          class="elevation-0 main-table"
          fixed-header
          :show-select="showSelect"
          :search="search"
          :single-expand="singleExpand"
          :expanded.sync="expand"
          :item-key="itemKey"
          :show-expand="showExpand"
          :mobile-breakpoint="mobileBreakpoint"
          hide-default-footer
          @click:row="$emit(`clickRow`, $event)"
          @dblclick:row="dbClick"
          @item-expanded="(e) => $emit('itemExpanded', e)"
          :page.sync="page"
          :items-per-page="row"
          :height="h"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <template
            v-for="field in ScopedSlots()"
            v-slot:[field]="{ header, headers, item, isExpanded }"
          >
            <slot
              style
              :name="field"
              :item="item"
              :header="header"
              :headers="headers"
              :isExpanded="isExpanded"
            />
          </template>
        </v-data-table>
      </v-card-text>

      <!-- // # Pagination -->
      <v-card-actions class="card-actions" ref="cardAction">
        <div class="pagination-layout">
          <span class="pt-1 mr-2" v-if="download">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="exportCSVFile"
                  :disabled="headers.length <= 0 || items.length <= 0"
                >
                  <v-icon size="20">mdi-download-outline</v-icon>
                </v-btn>
              </template>
              <span>Download CSV</span>
            </v-tooltip>
          </span>
          <span v-if="!disabledPagination" class="pt-2 mr-2 pagination-text"
            >Rows Per Page :
          </span>
          <span>
            <v-select
              v-if="!disabledPagination"
              v-model="row"
              :items="perPageList"
              placeholder="Standard"
              hide-details
              dense
              class="row-per-page"
              @change="(page = 1), onChangePagination()"
            ></v-select>
          </span>
          <span
            v-if="items.length == 0 && !disabledPagination"
            class="pt-2 mx-2 pagination-text"
            >0-0 of 0</span
          >
          <span
            v-if="!disabledPagination"
            class="pt-2 mr-2 ml-2 pagination-text"
          >
            {{ row * (page - 1) + 1 }} -
            {{
              row * (page - 1) + row >= pagination.total
                ? pagination.total
                : row * (page - 1) + row
            }}
            of {{ pagination.total }}</span
          >
          <v-btn
            v-if="!disabledPagination"
            icon
            color="primary"
            :disabled="page <= 1"
            @click="page--, onChangePagination()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            v-if="!disabledPagination"
            icon
            color="primary"
            :disabled="page >= Math.ceil(pagination.total / row, 1)"
            @click="page++, onChangePagination()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <!-- <v-pagination
          v-model="page"
          :length="Math.round(pagination.total / row) || 1"
          :total-visible="4"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        ></v-pagination> -->
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// scopeSlot ref https://stackoverflow.com/questions/62442915/passing-dynamic-slots-down-from-parent-to-child-to-grandchild
// dynamic height ref https://stackoverflow.com/questions/59867308/dynamic-height-of-table-vuetify
export default {
  data: () => ({
    // itemsPerPage: 20,
    // page: 1,
    h: 0,
    page: 1,
    row: 10,
    prePage: 10,
    windowSize: {
      x: 0,
      y: 0
    },
    headerSize: 0,
    scopedSlots: []
    // expanded: []
  }),

  props: {
    value: {
      type: Array,
      default: () => []
    },
    offsetHeight: {
      type: Number,
      default: 0 // 48
    },

    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    showExpand: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    expanded: {
      type: Array,
      default: () => []
    },
    singleExpand: {
      type: Boolean,
      default: false
    },
    mobileBreakpoint: {
      type: Number,
      default: 600
    },
    itemKey: {
      type: String,
      default: 'name'
    },
    itemsPerPage: {
      type: Number,
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    },

    // # Pagination
    total: {
      type: Number,
      default: 0
    },
    pagination: {
      type: Object,
      default: () => {
        return { page: 1, row: 10, total: 0 }
      }
    },
    perPageList: {
      type: Array,
      default: () => [10, 20, 30, 50]
    },

    download: {
      type: Boolean,
      default: false
    },
    disabledPagination: {
      type: Boolean,
      default: false
    }

    // # Pagination
  },
  watch: {
    pagination (val) {
      this.page = val.page
    }
  },
  computed: {
    selected: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    },
    expand: {
      set (value) {
        this.$emit('update:expanded', value)
      },
      get () {
        return this.expanded
      }
    }
  },

  updated () {
    this.onResize()
    //   this.h =
    //     this.$refs.cardLayout.clientHeight -
    //     this.$refs.cardTitle.clientHeight -
    //     this.$refs.cardAction.clientHeight
    //   console.log('this.$refs.cardLayout.clientHeight', this.h)
  },
  methods: {
    ScopedSlots () {
      // เป็น Function ที่จะ Remove scopeSlot ที่ไม่ใช่ของ v-data-table
      const whitList = ['header']
      const sS = Object.keys(this.$scopedSlots)
      for (const i of whitList) {
        // Check whitList is exists in sS
        const index = sS.indexOf(i)
        if (index > -1) {
          sS.splice(index, 1)
        }
      }
      return sS
    },
    onResize () {
      // this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      // this.h = this.$refs.cardText.clientHeight
      this.h =
        this.$refs.cardLayout.clientHeight -
        this.$refs.cardTitle.clientHeight -
        this.$refs.cardAction.clientHeight
    },
    dbClick (e, { item }) {
      this.$emit('dbclickRow', item)
    },

    onChangePagination () {
      this.$emit('update:pagination', {
        page: this.page,
        row: this.row,
        total: this.pagination.total
      })
      this.$emit('onChangePagination', {
        page: this.page,
        row: this.row,
        total: this.pagination.total
      })
    },
    jsonToCsv (headers, objArray) {
      const array =
        typeof objArray !== 'object' ? JSON.parse(objArray) : objArray

      // TODO String ทั้งหมดของ CSV File
      let str = ''

      // TODO Generate Column Header ของ Csv
      let column = ''
      for (const i of headers) {
        // TODO ถ้า column มีข้อมูล ให้เติม ',' แล้วจึงค่อยเติมข้อมูลลงไป
        if (column !== '') column += ','
        column += i
      }
      // TODO รวม column และ endof line และใล่ไปยัง String
      str += column + '\r\n'

      // TODO Generate Body ของ CSV
      for (let i = 0; i < array.length; i++) {
        let line = ''
        for (const index of headers) {
          if (line !== '') line += ','
          // TODO เช็คว่าเป็น field 'number' ให้ใส่ index ไปแทน
          if (index === 'number') line += i + 1
          else line += array[i][index]
        }
        str += line + '\r\n'
      }
      return str
    },
    exportCSVFile () {
      // REF https://medium.com/@danny.pule/export-json-to-csv-file-using-javascript-a0b7bc5b00d2
      // TODO เช็คว่ามี Headers หรือไม่หากว่ามีค่อยทำต่อ
      if (this.headers.length > 0) {
        // TODO เช็คว่ามี items หรีือไม่หากว่ามี items ค่อยทำงานต่อ
        if (this.items.length > 0) {
          // TODO สร้าง list ของ column ทั้งหมด
          const columns = this.headers.map((e) => e.value)

          // TODO Create csv file
          const csv = this.jsonToCsv(columns, JSON.stringify(this.items))
          const fileTitle = 'test-export'

          const exportedFilenmae = fileTitle + '.csv' || 'export.csv'

          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae)
          } else {
            const link = document.createElement('a')
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              const url = URL.createObjectURL(blob)
              link.setAttribute('href', url)
              link.setAttribute('download', exportedFilenmae)
              link.style.visibility = 'hidden'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
          }
        } else console.log('Not found items')
      } else console.log('Not found headers')
    }
  }
}
</script>

<style lang="css" scoped>
/* ::v-deep ::-webkit-scrollbar {
  display: block !important;
} */

/* // TODO สำหรับจัดการขนาดของ Card ที่คลุมตัว Data Table */
.card-layout {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: none;
  /* background: blue; */
}

.main-table {
  background: none;
}
/* Custom Table */
::v-deep .v-data-table__wrapper {
  /* height: calc(100vh - 290px) !important; */
  font-weight: 300;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  /* overflow-x: hidden; */

  /* IE and Edge */
  /* -ms-overflow-style: unset;  */
  /* Firefox */
  /* scrollbar-width: unset;  */
  /* background-color: red; */
}
/* Hide scrollbar for Chrome, Safari and Opera */
/* ::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  display: unset;
} */

/* Custom Table Header
::v-deep .v-data-table__wrapper .v-data-table-header th {
  font-weight: 400 !important;
  font-size: 16px;
} */
/* Custom Table Field */
::v-deep .v-data-table__wrapper tbody tr td {
  font-weight: 300 !important;
  font-size: 14px !important;
}

/* .main-container .v-data-table .v-sheet {
  background: green;
} */

/* >>> .v-toolbar__content {
  background: red;
} */

/* // TODO แก้ไขเรื่องการ Overflow ของ Header ใน ฏฟะฟ Table */
>>> .v-data-table-header th {
  white-space: nowrap !important;
}

/* // TODO สำหรับจัดการในส่วนของ Header Action ของ Table  */
.table-header {
  /* display: flex;
  align-items: center;
  min-height: 56px;
  padding-top: 4px;
  padding-bottom: 4px; */
  width: 100%;
}

>>> .v-data-table__checkbox.v-simple-checkbox i.mdi-checkbox-marked {
  color: var(--v-tableCheckBox-base);
}
>>> .v-data-table__checkbox.v-simple-checkbox i.mdi-minus-box {
  color: var(--v-tableCheckBox-base);
}

>>> .v-data-table-header tr th:first-child {
  border-radius: 5px 0px 0px 0px !important;
}
>>> .v-data-table-header tr th:last-child {
  border-radius: 0px 5px 0px 0px !important;
}

>>> .v-data-table-header-mobile tr th {
  border-radius: 5px 5px 0px 0px !important;
}
>>> .v-data-table-header tr th {
  background: var(--v-tableBgHeader-base) !important;
  /* color: #fff !important; */
  font-weight: 600;
  /* background: red !important; */
}

>>> .v-data-table-header tr th .mdi-checkbox-blank-outline {
  background: var(--v-tableBgHeader-base) !important;
  /* color: #fff !important; */
  /* background: red !important; */
}

/* .card-outter {
  padding-bottom: 50px;
} */

.v-data-table {
  height: 100%;
  /* background: red; */
  padding: 0;
  margin: 0;
}
.card-actions {
  /* margin-left: 16px !important; */
  /* margin-right: 16px !important; */
  /* background: rgb(0, 255, 115); */
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding-left: 0px;
  padding-right: 0px;
}

.pagination-layout {
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  display: flex;
}
::v-deep .card-text {
  /* background: pink !important; */
  width: 100%;
  /* height: 100%; */
  /* height: calc(100% - 52px - 72px); */
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0px;
  padding-right: 0px;

  /* background: red; */
}

.row-per-page {
  /* background: pink; */
  max-width: 60px !important;
  white-space: nowrap;
  text-align: center;
}

>>> .row-per-page .v-select__slot .v-select__selection {
  /* color: red !important; */
  font-weight: 200;
  font-size: 12px;
}
>>> .row-per-page .v-select__slot .v-select__selections input {
  /* color: red !important; */
  display: none !important;
}

>>> .row-per-page .v-select__slot .v-input__append-inner {
  padding-top: 4px;
}

.pagination-text {
  font-weight: 200;
  font-size: 12px;
}

/* // TODO เมื่อมีการ Overflow ของ Header Action */
/* @media only screen and (max-width: 600px) {
  .card-text {
    max-height: calc(100% - 52px - 124px);
  }
} */

/* Fix Responsesive Filter Color Header */
/* >>> .v-select__slot .v-input__append-inner .v-input__icon i {
  color: #fff !important;
} */

/* TODO แก้ไขเรื่อง Label ของ v-text-field */
/* >>> .v-select__slot .v-label {
  color: #fff;
} */

/* ::v-deep .v-data-table__mobile-row__cell{
  width: 50%;
} */
/* [x] แก้ไขเรื่อง Overflow ของ Table  */
::v-deep .v-data-table__wrapper {
  width: 100%;
  /* background-color: blue; */
}

/* ::v-deep .v-data-table__wrapper table { */
/* background: red; */
/* width: auto; */
/* width: 100%; */
/* display: block; */
/* display: none; */
/* } */

/* TODO 2023-06-09 เมื่อเป็นหน้าจอมือถือขนาดเล็ก */
::v-deep .v-data-table__wrapper table tbody {
  width: 100%;
}

/* TODO 2023-06-09 เมื่อเป็นหน้าจอมือถือขนาดเล็ก */
::v-deep .v-data-table__mobile-row__header {
  white-space: nowrap;
}

/* TODO 2023-06-09 เมื่อเป็นหน้าจอมือถือขนาดเล็ก */
::v-deep .v-data-table__mobile-row__cell {
  white-space: nowrap;
  width: 100%;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
}

::v-deep .v-card__text.card-text {
  height: calc(100% - 48px - 72px) !important;
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  /* background: red; */
}

::v-deep .v-card__text.card-text {
  height: calc(100% - 48px - 72px) !important;
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  /* background: red; */
}
::v-deep .card-layout.no-header .v-card__text.card-text {
  height: calc(100% - 48px) !important;
}

::v-deep .v-data-table__wrapper {
  height: 100% !important;
}

::v-deep .card-title {
  /* min-height: 72px; */
  /* background: pink; */
}

::v-deep .card-layout.no-header .card-title {
  height: 0px;
  padding: 0;
  margin: 0;
}

::v-deep
  .v-data-table.theme--light
  > .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th {
  background-color: #fff !important ;
}
::v-deep
  .v-data-table.theme--dark
  > .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th {
  background-color: #121212 !important ;
}
</style>
