<template>
  <div>
    <div style="font-size: 30px; text-align: center" class="primary--text">
      Coming soon...<v-icon color="primary" size="30">mdi-run</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style lang="css" scoped>
/* // TODO สำหรับจัดการหน้าเพจทั้งหมด */
.main-layout {
  height: 100%;
}

/* // TODO สำหรับ จัดการ title ของเพจ */
.layout-title {
  height: 58px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* // TODO สำหรับจัดการ Tab ของ Page */
.layout-tab {
  height: 48px;
  overflow: hidden;
}

/* // TODO สำหรับจัดการ Content ของเพจ */
.layout-content {
  height: calc(100% - 58px - 48px);
  padding: 0;
  margin: 0;
}

.tab-items {
  overflow: scroll;
}
*::-webkit-scrollbar {
  all: unset !important;
}

::-webkit-scrollbar-thumb {
  all: unset;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: inherit; /* IE and Edge */
  scrollbar-width: 10px; /* Firefox */
}
</style>
