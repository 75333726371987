var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-login-page"},[_c('div',{staticClass:"img-login"},[_c('div',{staticClass:"text-welcome"},[_c('div',{staticClass:"text-DashboardManageAI"},[_vm._v("ยินดีต้อนรับเข้าสู่ระบบ")]),_c('div',{staticClass:"text-DashboardManageAI"},[_vm._v("Dashboard")]),_vm._m(0),_c('div',{staticClass:"text-DashboardManageAI"},[_c('p',{staticClass:"text-ManageAI"},[_vm._v("ถ้าคุณยังไม่มีบัญชีผู้ใช้งาน")]),_c('p',{staticClass:"text-ManageAI"},[_c('a',{staticStyle:{"color":"#50a7dc"},attrs:{"href":"#"},on:{"click":function($event){return _vm.$emit('click:register')}}},[_vm._v("ลงทะเบียนที่นี่ !")])])])]),_c('img',{attrs:{"src":require("@/../public/img/ImageLogin.png")}})]),_c('div',{staticClass:"login-content"},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"ma-2 form-box"},[_c('div',{staticClass:"title-ManagAI"},[_c('img',{attrs:{"src":require("@/../public/img/logoManageAIWithOutText.png")}}),_vm._v(" ManageAI ")]),_c('div',{staticClass:"title-loginForm"},[_vm._v("เข้าสู่ระบบ")]),_c('div',[_c('v-text-field',{staticClass:"custom-text-field",class:_vm.textError ? 'textfielderror' : 'textfieldsuccess',attrs:{"prepend-inner-icon":"mdi-email","color":"blue","rules":[
              _vm.Rule.noInPut,
              _vm.Rule.noSpace,
              _vm.Rule.englishOnly,
              _vm.Rule.emailOnly,
            ],"placeholder":"อีเมล","label":"ชื่อผู้ใช้(อีเมล)","dense":"","outlined":"","rounded":""},model:{value:(_vm.signInData.username),callback:function ($$v) {_vm.$set(_vm.signInData, "username", $$v)},expression:"signInData.username"}}),_c('v-text-field',{staticClass:"custom-text-field",class:_vm.textError ? 'textfielderror' : 'textfieldsuccess',attrs:{"prepend-inner-icon":"mdi-lock","rules":[_vm.Rule.noInPut, _vm.Rule.noSpace, _vm.Rule.noSpace],"append-icon":_vm.signInData.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.signInData.show ? 'text' : 'password',"placeholder":"รหัสผ่าน","label":"รหัสผ่าน","dense":"","outlined":"","rounded":""},on:{"click:append":function($event){_vm.signInData.show = !_vm.signInData.show}},model:{value:(_vm.signInData.password),callback:function ($$v) {_vm.$set(_vm.signInData, "password", $$v)},expression:"signInData.password"}})],1),_c('div',{staticClass:"forget-password"},[_c('a',{staticClass:"forget-password-btn",attrs:{"href":"#"},on:{"click":function($event){return _vm.$emit('click:forget')}}},[_vm._v("ลืมรหัสผ่าน ?")]),_c('v-btn',{staticClass:"sign-in-btn",staticStyle:{"height":"48px"},attrs:{"disabled":!_vm.valid,"type":"submit","color":"primary","loading":_vm.loading,"block":"","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('click:login', {
                username: _vm.signInData.username,
                password: _vm.signInData.password,
              })}}},[_vm._v("เข้าสู่ระบบ")])],1),_c('h2',{staticClass:"hr-lines"},[_vm._v("เข้าสู่ระบบด้วย")]),_c('div',{staticClass:"oauth-sign-in"},[_c('div',[_c('OauthBtn',{attrs:{"block":"","rounded":"","color":"primary","image":require('@/assets/images/onePlatform.png'),"text":"OneID"},on:{"click":function($event){return _vm.$emit('click:oneid')}}}),_c('OauthBtn',{staticClass:"ml-2",attrs:{"block":"","rounded":"","color":"primary","image":require('@/assets/images/logo-provider-id.png'),"text":"ProviderID"},on:{"click":function($event){return _vm.$emit('click:providerid')}}})],1)]),_c('div',{staticClass:"pt-5"},[_vm._v(" ถ้าคุณยังไม่มีบัญชีผู้ใช้งาน "),_c('a',{staticStyle:{"color":"#50a7dc"},attrs:{"href":"#"},on:{"click":function($event){return _vm.$emit('click:register')}}},[_vm._v("ลงทะเบียนที่นี่ !")])]),_c('div',{},[_vm._v(" คู่มือ Dashboard FDH สำหรับผู้บริหาร "),_c('span',{staticStyle:{"color":"#50a7dc","cursor":"pointer"},on:{"click":_vm.openManual}},[_vm._v(" คลิก ")])])])])],1),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-DashboardManageAI"},[_c('p',{staticClass:"text-ManageAI"},[_vm._v("Manage")]),_c('p',{staticClass:"text-ManageAI"},[_vm._v("AI")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-login-iphon"},[_c('img',{attrs:{"src":require("@/../public/img/ImageLogin.png")}})])
}]

export { render, staticRenderFns }