<template>
  <div>
    <div style="font-size: 30px; text-align: center" class="primary--text">
      Coming soon...<v-icon color="primary" size="30">mdi-run</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
