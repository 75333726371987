<template>
  <u-table :headers="h" :items="items" disabled-pagination item-key="key">
    <template v-slot:[`item.key`]="{ item }">
      <span class="fix-overflow-ellipsis">
        {{ item.key || '-' }}
      </span>
    </template>
    <template v-slot:[`item.filter`]="{ item }">
      <!-- <v-btn v-if="btnState" icon x-small color="success" @click="clickIncludeBtn(dataField,item.key)"><v-icon>mdi-check</v-icon></v-btn>
        <v-btn v-if="!btnState" icon x-small color="blue" @click="clickIncludeBtn(dataField,item.key)"><v-icon>mdi-minus-circle</v-icon></v-btn>
        <v-btn v-else icon x-small color="orange" @click="clickIncludeBtn(dataField,item.key)"><v-icon>mdi-minus-circle</v-icon></v-btn> -->
      <span v-if="!dataList.includes(item.key) && !btnState">
        <v-btn
          x-small
          size="40px"
          icon
          color="green"
          @click="() => editItem(dataField, item.key)"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </span>
      <!-- home -->
      <span v-else-if="!dataList.includes(item.key) && btnState">
        <v-btn
          x-small
          size="40px"
          icon
          color="green"
          @click="() => editItem(dataField, item.key)"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </span>
      <!-- grey -->
      <span v-else-if="dataList.includes(item.key) && btnState">
        <v-btn
          x-small
          size="40px"
          icon
          color="grey"
          @click="() => saveItem(dataField, item.key)"
          ><v-icon>mdi-minus-circle</v-icon></v-btn
        >
      </span>
      <!-- yellow -->
      <span v-else>
        <v-btn
          x-small
          size="40px"
          icon
          color="grey"
          @click="() => saveItem(dataField, item.key)"
        >
          <v-icon>mdi-minus-circle</v-icon>
        </v-btn>
      </span>

      <!-- <v-btn icon x-small color="red" class="mx-2" @click="clickExcludeBtn(dataField,item.key)"><v-icon>mdi-close</v-icon></v-btn> -->
      <span v-if="!dataList.includes(item.key) && !btnState">
        <v-btn
          icon
          x-small
          color="red"
          class="mx-2"
          @click="clickExcludeBtn(dataField, item.key)"
          ><v-icon>mdi-minus</v-icon></v-btn
        >
      </span>
      <!-- home -->
      <span v-else-if="!dataList.includes(item.key) && btnState">
        <v-btn
          icon
          x-small
          color="red"
          class="mx-2"
          @click="clickExcludeBtn(dataField, item.key)"
          ><v-icon>mdi-minus</v-icon></v-btn
        >
      </span>
      <!-- grey -->
      <span v-else-if="dataList.includes(item.key) && btnState">
        <v-btn
          icon
          x-small
          style="display: none"
          color="red"
          class="mx-2"
          @click="clickExcludeBtn(dataField, item.key)"
          ><v-icon>mdi-minus</v-icon></v-btn
        >
      </span>
      <!-- yellow -->
      <span v-else>
        <v-btn
          icon
          x-small
          style="display: none"
          color="red"
          class="mx-2"
          @click="clickExcludeBtn(dataField, item.key)"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </span>
    </template>
  </u-table>
</template>

<script>
export default {
  data () {
    return {
      btnState: false,
      dataList: [],
      inputValue: '',
      dataForSearch_must: [],
      itemsData: [
        { id: 1, columnA: 'A1', columnB: 'B1' },
        { id: 2, columnA: 'A2', columnB: 'B2' },
        { id: 3, columnA: 'A3', columnB: 'B3' }
      ],
      dataListTemp: [] // สำหรับเก็บค่าที่ filter แล้วตรงกับ
    }
  },
  computed: {
    h () {
      const labels = this.headers.map((x) => {
        if (x.value === 'key') {
          return { ...x, ...{ width: '65%' } }
        } else return { ...x, ...{ width: '20%' } }
      })

      return [
        ...labels,
        ...[
          {
            text: 'Filter',
            align: 'center',
            width: '15%',
            value: 'filter',
            type: 'filter',
            sortable: false
          }
        ]
      ]
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: ''
    },
    dataField: {
      type: String,
      default: ''
    }
  },
  created () {
    this.$EventBus.$on('inputValueFromSearch', (val) => {
      // this.inputValue = inputValueTemp ใน widget-serch-bar
      this.inputValue = val
      this.chekCurrentBtn()
    })
    this.$EventBus.$on('clickSearchBtn', (val) => {
      const spiltSpace = this.inputValue.split(' ')
      if (this.inputValue === '') {
        this.dataList = []
        this.btnState = false
      } else if (spiltSpace.length > 0) {
        for (let i = 0; i < spiltSpace.length; i++) {
          this.checkData(spiltSpace[i])
        }
      }
    })
    this.$EventBus.$on('addBtnState', (val) => {
      this.btnState = val.btnState
    })
    this.$EventBus.$on('inputNull', () => {
      this.inputValue = ''
      this.dataList = []
      this.btnState = false
    })
  },
  methods: {
    // checkData สำหรับ เก็บค่า value
    checkData (elem) {
      if (/^([^-])(\w+):"(.*?)"/.test(elem)) {
        // เคส : (word)(:)(string)
        const getValue = /(:"(.*?)")/.exec(elem)
        // console.log(getValue)
        const filetrGetValue = getValue.pop().replaceAll('@SPACE@', ' ')
        this.checkDataMacth(filetrGetValue)
      }
    },
    // checkDataMacth เช็คว่า ตรงกับ dataListหรือไม่
    checkDataMacth (filetrGetValue) {
      // เช็คว่ามีค่า ใน dataLIstไหม
      if (this.dataList.includes(filetrGetValue)) {
        this.dataList = this.dataList.filter((i) => i === filetrGetValue)
        this.btnState = true
      } else {
        this.dataList.push(filetrGetValue)
      }
      // this.dataListTemp = this.dataList.filter((i) => i === filetrGetValue)
      // console.log('dataList ===> ')
      // console.log(this.dataListTemp)
      // // console.log(this.dataList)
    },
    chekCurrentBtn () {
      const spiltSpace = this.inputValue.split(' ')
      const temp = []
      // const temp2 = []
      for (let i = 0; i < spiltSpace.length; ++i) {
        temp[i] = spiltSpace[i].replace(this.dataField, '').replace(':', '')
      }
    },
    arraysEqual (a, b) {
      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false
      }
      return true
    },
    editItem (dataFieldKey, dataFieldVal) {
      this.dataList.push(dataFieldVal)
      this.clickIncludeBtn(dataFieldKey, dataFieldVal)
    },
    saveItem (dataFieldKey, dataFieldVal) {
      this.dataList = this.dataList.filter((i) => i !== dataFieldVal)
      this.clickIncludeBtn(dataFieldKey, dataFieldVal)
    },
    clickIncludeBtn (dataFieldKey, dataFieldVal) {
      const dataForFilteringMode =
        dataFieldKey + ':' + '"' + dataFieldVal + '"'
      this.$EventBus.$emit('addquery', dataForFilteringMode)
    },
    clickExcludeBtn (dataFieldKey, dataFieldVal) {
      const dataForFilteringMode =
        '-' + dataFieldKey + ':' + '"' + dataFieldVal + '"'
      this.$EventBus.$emit('addquery', dataForFilteringMode)
    }
  }

  // created () {
  //   this.$EventBus.$on('addquery', (val) => {
  //     console.log(val)
  //   })
  // }
}
</script>

<style lang="css" scoped>
.custom-border-tb {
  border-top: 1px solid #dedede;
  border-collapse: collapse;
}

.header-expanded {
  font-size: 18px;
  font-weight: 400;
}

::v-deep
  .v-data-table.theme--light
  > .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th {
  background-color: #fff !important ;
}
::v-deep
  .v-data-table.theme--dark
  > .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th {
  background-color: #121212 !important ;
}
</style>
