import Vue from 'vue'
import VueRouter from 'vue-router'
// import LandingPage from '@/views/LandingPage.vue'
import LoginPage from '@/views/LoginPage.vue'
import RegisterPage from '@/views/RegisterPage.vue'
import HomePage from '@/views/HomePage.vue'
import DashboardPage from '@/views/HomePages/DashboardPage.vue'
import ProjectPage from '@/views/HomePages/ProjectPage.vue'
import Projects from '@/views/HomePages/ProjectPages/Projects.vue'
import Project from '@/views/HomePages/ProjectPages/Project.vue'
import UsersPage from '@/views/HomePages/UsersPage.vue'
import AnnouncePage from '@/views/HomePages/AnnouncePage.vue'
import SettingPage from '@/views/HomePages/SettingPage.vue'
import PermissionPage from '@/views/HomePages/SettingPages/PermissionPage.vue'
import MenuPage from '@/views/HomePages/SettingPages/MenuPage.vue'
import PreferencePage from '@/views/HomePages/SettingPages/PreferencePage.vue'
import ProfilePage from '@/views/HomePages/ProfilePage.vue'
import ResetPasswordOnEmail from '@/views/ResetPasswordOnEmail.vue'
import NotificationPage from '../views/HomePages/NotificationPage.vue'
import DatasourcePage from '@/views/HomePages/Datasource.vue'
Vue.use(VueRouter)

const routes = [
  { path: '*', redirect: { name: 'loginPage' } },
  // {
  //   path: '/',
  //   name: 'landingPage',
  //   meta: { requiresLogin: false },
  //   component: LandingPage
  // },
  {
    path: '/login/:provider',
    name: 'loginPage',
    meta: { requiresLogin: false },
    component: LoginPage
  },
  {
    path: '/register',
    name: 'registerPage',
    meta: { requiresLogin: false },
    component: RegisterPage
  },
  {
    path: '/password/new',
    name: 'ResetPasswordOnEmail',
    meta: { requiresLogin: false },
    component: ResetPasswordOnEmail
  },
  {
    path: '/home',
    name: 'homePage',
    meta: { requiresLogin: true },
    component: HomePage,
    children: [
      {
        path: 'dashboard',
        name: 'dashboardPage',
        meta: { requiresLogin: true },
        component: DashboardPage
      },

      {
        path: 'project',
        name: 'projectPage',
        redirect: { name: 'projectList' },
        meta: { requiresLogin: true },
        component: ProjectPage,
        children: [
          {
            path: '',
            name: 'projectList',
            meta: { requiresLogin: true },
            component: Projects
          },
          {
            path: ':project_id',
            name: 'projectDashboard',
            meta: { requiresLogin: true },
            component: Project
          }
        ]
      },
      {
        path: 'users',
        name: 'userPage',
        meta: { requiresLogin: true },
        component: UsersPage
      },
      {
        path: 'announce',
        name: 'announcePage',
        meta: { requiresLogin: true },
        component: AnnouncePage
      },
      {
        path: 'profile',
        name: 'profilePage',
        meta: { requiresLogin: true },
        component: ProfilePage
      },
      {
        path: 'profile/:provider',
        meta: { requiresLogin: true },
        component: ProfilePage
      },
      {
        path: 'notification',
        name: 'notificationPage',
        meta: { requiresLogin: true },
        component: NotificationPage
      },
      {
        path: 'datasource',
        name: 'datasourcePage',
        meta: { requiresLogin: true },
        component: DatasourcePage
      },
      {
        path: 'setting',
        name: 'settingPage',
        redirect: { name: 'permissionPage' },
        meta: { requiresLogin: true },
        component: SettingPage,
        children: [
          {
            path: 'permissions',
            name: 'permissionPage',
            meta: { requiresLogin: true },
            component: PermissionPage
          },
          {
            path: 'menus',
            name: 'menuPage',
            meta: { requiresLogin: true },
            component: MenuPage
          },
          {
            path: 'preference',
            name: 'preferencePage',
            meta: { requiresLogin: true },
            component: PreferencePage
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = 'Dashboard System'
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

// # UPDATE 2021-10-31
router.beforeEach((to, from, next) => {
  const ls = localStorage.getItem('Accesstoken')
  if (to.matched.some((x) => x.meta.requiresLogin)) {
    if (ls === undefined || ls === null) {
      // # เป็นหน้าที่ต้อง Authen และไม่มี Authen
      next({ name: 'loginPage' })
    } else {
      // # เป็นหน้าที่ต้อง Authen และมี Authen
      next()
    }
  } else {
    if (ls === undefined || ls === null) {
      // # เป็นหน้าที่ไม่ต้องต้อง Authen และไม่มี Authen
      next()
    } else {
      // # เป็นหน้าที่ไม่ต้อง Authen และมี Authen และไม่ใช่ login
      if (to.name === 'loginPage') next({ name: 'projectList' })
      else next()
    }
  }
})

export default router
