<template>
  <div>
    <div>
      <span v-if="required" class="required">*</span><span>{{ title }}</span>
    </div>
    <v-text-field
      v-mask="mask"
      v-model="data"
      :rules="rules"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      outlined
      dense
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '' | 0
    },
    rules: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String,
      default: ''
    }
  },

  computed: {
    data: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        return val
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
