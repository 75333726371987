<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card class="pa-5">
      <v-img
        class="img-header mx-auto"
        src="../../../public/img/resetpassword.jpg"
      />
      <div class="text-header">เปลี่ยนรหัสผ่าน</div>
      <v-form ref="formResetPassword">
        <v-text-field
          v-model="passwordData.oldPassword"
          :rules="[Rule.noInPut, Rule.noSpace]"
          :append-icon="show_oldPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show_oldPassword ? 'text' : 'password'"
          @click:append="show_oldPassword = !show_oldPassword"
          placeholder="กรอกรหัสผ่านเก่า"
          label="รหัสผ่านเก่า"
          dense
          outlined
          class="px-8"
        ></v-text-field>
        <v-text-field
          v-model="passwordData.newPassword"
          :rules="[Rule.noInPut, Rule.noSpace]"
          placeholder="กรอกรหัสผ่านใหม่"
          label="รหัสผ่านใหม่"
          :append-icon="show_newPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show_newPassword ? 'text' : 'password'"
          @click:append="show_newPassword = !show_newPassword"
          dense
          outlined
          class="px-8"
        >
        </v-text-field>
        <v-text-field
          v-model="passwordData.confirmnewPassword"
          :rules="[Rule.noInPut, Rule.noSpace]"
          placeholder="กรอกรหัสผ่านใหม่อีกครั้ง"
          label="ยืนยันรหัสผ่านใหม่อีกครั้ง"
          type="password"
          dense
          outlined
          class="px-8"
        >
        </v-text-field>
      </v-form>
      <v-row class="pa-4">
        <v-col>
          <v-btn color="primary" @click="save" block>ตกลง</v-btn>
        </v-col>
        <v-col>
          <v-btn block color="error" @click="(dialog = false), reset()"
            >ยกเลิก</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      show_oldPassword: false,
      show_newPassword: false,
      passwordData: {
        oldPassword: '',
        newPassword: '',
        confirmnewPassword: ''
      },
      NameComponent: 'เปลี่ยนรหัสผ่าน'
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    reset () {
      this.$refs.formResetPassword.reset()
    },
    save () {
      if (this.$refs.formResetPassword.validate()) {
        this.$swal({
          allowEnterKey: false,
          title: `${this.NameComponent}`,
          text: `คุณต้องการ${this.NameComponent}ใช่หรือไม่`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$emit('resetPasswordUser', {
              oldPassword: this.passwordData.oldPassword,
              newPassword: this.passwordData.newPassword,
              confirmnewPassword: this.passwordData.confirmnewPassword
            })
          }
        })
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>
.img-header {
  max-width: 30%;
}
.text-header {
  font-weight: 500;
  font-size: 30px;
  padding-top: 30px;
  text-align: center;
  color: var(--v-primary-base);
}
.text-password {
  padding-top: 20px;
  padding-bottom: 30px;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-weight: 300;
  font-size: 18px;
}
</style>
