import { mapGetters } from 'vuex'
export default {
  methods: {
    checkPermission (tags = []) {
      // NOTE เป็น function สำหรับ check ว่าเป็น super admin
      // NOTE หรือ Tag ที่กำหนดหรือไม่

      // TODO เมื่อไม่ส่ง tag
      if (tags.length === 0) return true

      // TODO เช็คว่าเป็น Super Admin ของระบบหรือไม่
      for (const ele of this.storeGetUserInfo.permissions) {
        if (ele.priority === 0) return true
      }

      // TODO เช็คว่า Current Tag ใช่ที่ต้องการหรือไม่
      if (this.arrayChecker(this.storeGetCurrentTag, tags)) return true

      // TODO ไม่เข้าเงื่อนไขใดเลย
      return false
    },

    arrayChecker (arr, target) {
      // NOTE เช็คว่า arr มีอยู่ใน Target ไหม
      // NOTE REF https://stackoverflow.com/questions/53606337/check-if-array-contains-all-elements-of-another-array
      return target.every((v) => arr.includes(v))
    }
  },
  computed: {
    ...mapGetters(['storeGetUserInfo', 'storeGetCurrentTag'])
  }
}
