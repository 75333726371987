<template>
    <iframe
      :src="url"
      width="100%"
      height="100%"
      style="border: none;"
      allow="camera;microphone;fullscreen;display-capture;"
    ></iframe>
  </template>
<script>
export default {
  props: {
    url: {
      type: String,
      default: ''
    }
  }
}
</script>
