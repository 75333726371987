<template>
  <div>
    <div>
      <span v-if="required" class="required">*</span><span>{{ title }}</span>
    </div>
    <v-select
      :items="items"
      v-model="data"
      :rules="rules"
      :placeholder="placeholder"
      :required="required"
      outlined
      dense
      :multiple="multiple"
      :item-value="itemValue"
      :item-text="itemText"
      :return-object="returnObject"
      @change="$emit('change', $event)"
      @input="$emit('input', $event)"
      :disabled="disabled"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip v-if="index < 2">
          <span class="ellipsis">{{ item[itemText] }}</span>
        </v-chip>
        <span
          v-if="index === 2"
          class="text-grey text-caption align-self-center"
        >
          (+{{ value.length - 2 }} others)
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    rules: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemText: {
      // type: String,
      // default: ''
    },
    itemValue: {
      // type: String,
      // default: ''
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    data: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        return val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ellipsis {
  white-space: nowrap;       /* ห้ามข้อความหักบรรทัด */
  overflow: hidden;          /* ซ่อนข้อความที่เกินขอบเขต */
  text-overflow: ellipsis;   /* แสดง ... แทนข้อความที่ถูกตัด */
  display: block;            /* หรือ inline-block ตามการใช้งาน */
  width: 100%;               /* กำหนดให้ความกว้างตามพาเรนต์ */
}</style>
