export default {
  methods: {
    delay (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    async CreateAlertRule (payload) {
      // TODO เป็น Function สำหรับ Create Rule Alert
      // NOTE New Object
      let data = JSON.parse(JSON.stringify(payload))

      // NOTE Remove Field not use
      delete data.project_id

      // NOTE Data Tranfer Object
      data = {
        ...{
          // TODO Edit Constructure here
          rule_mode: '',
          title: '',
          rule: '',
          scheduled: '',
          rate: 0,
          description: '',
          black_list_ips: [],
          alert_methods: []
        },
        ...data
      }
      // NOTE Calling To Server
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/alert/rule`,
        method: 'post',
        data: data
      }
      // // NOTE Return Data to Caller
      return await this.awaitAxios(options)

      // NOTE Return mock data
      // console.log(options)
      // await this.delay(1000)
      // return {
      //   status: true,
      //   code: 201,
      //   message: 'create data success',
      //   data: this.mock_alert_rule_obj
      // }
    },
    async GetAlertRules (payload) {
      // TODO เป็น Function สำหรับ Get Rule Alert มาใส่ในตราง
      // NOTE New Object
      let params = JSON.parse(JSON.stringify(payload))

      // NOTE Remove Field not use
      delete params.project_id

      // NOTE Data Tranfer Object
      params = {
        ...{
          // TODO Edit Constructure here
          page: 1,
          row: 10,
          keyword: ''
        },
        ...params
      }

      // NOTE Calling To Server
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/alert/rules`,
        method: 'get',
        params: params
      }

      // NOTE Return Data to Caller
      return await this.awaitAxios(options)

      // NOTE Return Mock Data
      // console.log(options)
      // const size = 9
      // const items = []
      // for (let i = 0; i < size; i++) {
      //   items.push(JSON.parse(JSON.stringify(this.mock_alert_rule_obj)))
      // }
      // await this.delay(2000)
      // return {
      //   status: true,
      //   code: 200,
      //   message: 'get data success',
      //   data: { pagination: { page: 1, row: 10, total: size }, data: items }
      // }
    },
    async UpdateAlertRules (payload) {
      // TODO เป็น Function สำหรับ Update Rule Alert
      // NOTE New Object
      let data = JSON.parse(JSON.stringify(payload))

      // NOTE Remove Field not use
      delete data.project_id
      delete data.rule_id

      // NOTE Data Tranfer Object
      data = {
        ...{
          // TODO Edit Constructure here
          rule_mode: '',
          title: '',
          rule: '',
          scheduled: '',
          rate: 0,
          description: '',
          black_list_ips: [],
          alert_methods: []
        },
        ...data
      }

      // FIX Type of Rate Aert String to Int ---
      data.rate = parseInt(data.rate)
      // FIX -----------------------------------
      // NOTE Calling To Server
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/alert/rule/${payload.rule_id}`,
        method: 'put',
        data: data
      }
      // NOTE Return Data to Caller
      return await this.awaitAxios(options)

      // NOTE Return mock data
      // console.log(options)
      // await this.delay(1000)
      // return {
      //   status: true,
      //   code: 201,
      //   message: 'create data success',
      //   data: this.mock_alert_rule_obj
      // }
    },
    async DeleteAlertRules (payload) {
      // TODO เป็น Function สำหรับ Delete Rule Alert
      // NOTE New Object
      let path = JSON.parse(JSON.stringify(payload))

      // NOTE Data Tranfer Object
      path = {
        ...{
          // TODO Edit Constructure here
          project_id: '',
          rule_id: ''
        },
        ...payload
      }

      // NOTE Calling To Server
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${path.project_id}/alert/rule/${path.rule_id}`,
        method: 'delete'
      }

      // NOTE Return Data to Caller
      return await this.awaitAxios(options)

      // NOTE Return mock data
      // console.log(options)
      // await this.delay(1000)
      // return {
      //   status: true,
      //   code: 201,
      //   message: 'create data success',
      //   data: this.mock_alert_rule_obj
      // }
    },
    async GetAlertHistories (payload) {
      // TODO เป็น Function สำหรับ Get Rule Alert มาใส่ในตราง
      // NOTE New Object
      let params = JSON.parse(JSON.stringify(payload))

      // NOTE Remove Field not use
      delete params.project_id

      // NOTE Data Tranfer Object
      params = {
        ...{
          // TODO Edit Constructure here
          page: 1,
          row: 10,
          keyword: ''
        },
        ...params
      }

      // NOTE Calling To Server
      const options = {
        url: `${process.env.VUE_APP_API}/api/v1/project/${payload.project_id}/alert/histories`,
        method: 'get',
        params: params
      }

      // NOTE Return Data to Caller
      return await this.awaitAxios(options)

      // NOTE Return Mock Data
      // console.log(options)
      // const size = 9
      // const items = []
      // for (let i = 0; i < size; i++) {
      //   items.push(JSON.parse(JSON.stringify(this.mock_alert_history_obj)))
      // }
      // await this.delay(2000)
      // return {
      //   status: true,
      //   code: 200,
      //   message: 'get data success',
      //   data: { pagination: { page: 1, row: 10, total: size }, data: items }
      // }
    }
  },
  data () {
    return {
      mock_alert_rule_obj: {
        rule_id: '000',
        rule_mode: 'normal',
        title: 'Test Alert Rule 000',
        rule: 'regex rule to filter format',
        scheduled: '*/1 * * * *',
        rate: 0,
        description: 'Description Of Alert',
        black_list_ips: [],
        alert_methods: [
          {
            method_id: 'unique key of provider Alert',
            method: 'email',
            authorization:
              'Bearer b436c0fb0abd0d1012fce7a2452ffe111484dddea1e4ede1252f0689edf76803',
            payload:
              'Example payload Alert AT : $$TIME\nFrom : $$PROJECT_NAME\nMessage : Error',
            endpoint: 'https://example.com:8080/api/v1/webhook',
            ststus: true,
            create_date: 'YYYY-MM-DD HH:mm:ss',
            update_date: '',
            create_by: '',
            update_by: ''
          }
        ],
        ststus: true,
        create_date: '',
        update_date: '',
        create_by: '',
        update_by: ''
      },
      mock_alert_history_obj: {
        history_id: '1234',
        rule_id: '1234',
        rule_mode: 'normal',
        rule: 'a&b&c',
        rate: 0,
        scheduled: '*/1 * * * *',
        title: 'Test Res Rule',
        method: 'res',
        method_id: '1234',
        endpoint: 'https://dashboard.manageai.co.th',
        payload: 'Hello World !!',
        create_date: '2023-12-12 00:00:00',
        status_code: 400
      }
    }
  }
}
