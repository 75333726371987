<template>
  <v-tabs v-model="tab" center-active show-arrows>
    <v-tab
      v-for="item in items"
      :key="item.name"
      :href="`#${item.name}`"
      @click="$emit('click', item)"
    >
      <span v-if="!item.disableTooltip">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.text }}</span>
          </template>
          <span>{{ item.description }}</span>
        </v-tooltip>
      </span>
      <span v-else>
        {{ item.text }}
      </span>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    tab: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      offsetHeight: 0,
      windowSize: {
        x: 0,
        y: 0
      }
    }
  },
  methods: {}
}
</script>

<style lang="css" scoped>
>>>.v-tab {
  text-transform: unset;
}
/* .v-item-group {
  background: red;
} */

.v-window-item {
  height: 100% !important;
}

>>> .v-tabs-bar {
  background: none !important;
}
</style>
