<template>
  <u-table
    download
    :per-page-list="[10, 20, 30, 50, 100, 500]"
    :headers="headers"
    :items="items"
    :pagination.sync="p"
    :item-key="itemKey"
    :showExpand="showExpand"
    :singleExpand="singleExpand"
    @onChangePagination="$emit('onChangePagination', $event)"
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="py-5">
        <div class="header-expanded mb-5">
          <v-icon>mdi-folder-open-outline</v-icon> Expanded document
        </div>
        <table style="width: 100%">
          <tr
            v-for="(value, key, i) in item"
            :key="key"
            :class="i !== 0 ? 'custom-border-tb' : ''"
          >
            <td class="pa-4">{{ key }}</td>
            <td class="pa-4">{{ value }}</td>
          </tr>
        </table>
      </td>
    </template>
    <!-- <template v-slot:[`item.number`]="{ item }">
        {{ p.row * p.page - p.row + (items.indexOf(item) + 1) }}
      </template>
      <template v-slot:[`item.@timestamp`]="{ item }">
        {{ item["@timestamp"] | BE_DD_MM_YYYY_HH_MM_SS }}
      </template> -->
    <template v-for="h in headers" v-slot:[`item.${h.value}`]="{ item }">
      <!-- // TODO เช็คว่าเป็น Field number ไหม -->
      <span v-if="h.value == 'number'" :key="`${h.value}-001`">
        {{ p.row * p.page - p.row + (items.indexOf(item) + 1) }}
      </span>

      <!-- // TODO เช็คว่าเป็น Field Time Stamp ไหม  -->
      <span v-if="h.value == '@timestamp'" :key="`${h.value}-002`">
        {{ item["@timestamp"] | BE_DD_MM_YYYY_HH_MM_SS }}
      </span>

      <span v-else :key="`${h.value}-003`">
        <!-- // TODO เช็คว่าค่าเป็น Array ไหม -->
        <span v-if="Array.isArray(item[h.value])">{{
          JSON.stringify(item[h.value])
        }}</span>

        <!-- // TODO เช็คว่าค่าเป็น Object ไหม -->
        <span v-else-if="typeof item[h.value] == 'object'">
          {{ JSON.stringify(item[h.value]) }}
        </span>

        <!-- // TODO เช็คว่าค่าไม่เป็นอะไรเลย -->
        <span v-else>{{ item[h.value] }}</span>
      </span>
    </template>
  </u-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: ''
    },
    pagination: {
      type: Object,
      default: () => {
        return { page: 1, row: 10, total: 0 }
      }
    },
    showExpand: {
      type: Boolean,
      default: false
    },
    singleExpand: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    p: {
      get () {
        return this.pagination
      },
      set (val) {
        this.$emit('update:pagination', val)
        return val
      }
    }
  }
}
</script>

<style lang="css" scoped>
.custom-border-tb {
  border-top: 1px solid #dedede;
  border-collapse: collapse;
}

.header-expanded {
  font-size: 18px;
  font-weight: 400;
}

::v-deep
  .v-data-table.theme--light
  > .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th {
  background-color: #fff !important ;
}
::v-deep
  .v-data-table.theme--dark
  > .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th {
  background-color: #121212 !important ;
}
</style>
