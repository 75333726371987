<template>
  <div class="main-content">
    <div class="content-item" v-for="item in localList" :key="item.key">
      <div class="item">
        <!-- TODO ส่วนสำหรับแสดงข้อมูลตัวเลข -->
        <div class="value">{{ item.doc_count.toLocaleString() }}</div>

        <!-- TODO ส่วนสำหรับแสดงขื่อ Key ของ Value นั้นๆ -->
        <div class="label">
          <!-- TODO ส่วนสำหรับแสดง สีของ Key นั้นๆ -->
          <div
            v-if="showColor && item.color"
            class="color"
            :style="`background-color:${item.color};`"
          />
          <!-- TODO ส่วนสำหรับแสดง Text Key ของ Value นั้นๆ -->
          <div class="text">
            {{ item.key }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => {}
    },
    showTotal: {
      type: Boolean,
      default: false
    },
    showColor: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localList: []
    }
  },

  watch: {
    data: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        if (this.showTotal) {
          const list = []
          const total = this.total
          list.push({ doc_count: total, key: 'Total' })
          this.localList = [...list, ...this.data]
        } else {
          this.localList = this.data
        }
      }
    }
  },

  computed: {
    total () {
      return this.data.reduce((acc, cur) => acc + cur.doc_count, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  overflow: auto;
}

::v-deep .row-content {
  // background-color: yellow;
  height: 100% !important;
  width: 100% !important;
  padding: 0;
  margin: 0;
  overflow-x: auto;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.row-content::-webkit-scrollbar {
  display: none;
}

.content-item {
  height: 100%;
  width: 100%;
  border-left: 1px solid rgba(140, 140, 140, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.content-item:first-child {
  border-left: none;
}
.item {
  line-height: 1.2;
}

.item .value {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  width: 100%;
}
.item .label {
  font-size: 18px;
  width: 100%;
  text-transform: capitalize;

  display: flex;
  justify-content: center;
  align-items: center;
}

.item .label .color {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  margin-right: 0.5rem;
}

.item .label .text {
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
</style>
