import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import { Chart, registerables, Tooltip } from 'chart.js'
// import moment from 'moment'
import moment from 'moment-timezone'
// import { Tooltip, message } from 'ant-design-vue'
import { message, notification, AutoComplete } from 'ant-design-vue'

import SwitchTheme from './components/SwitchTheme.vue'
import SwitchLang from './components/SwitchLang.vue'
import TitlePage from './components/TitlePage.vue'
import Table from './components/Table.vue'
import Tab from './components/Tab.vue'
import CardForm from './components/Forms/DialogForm.vue'
import ChartBar from './components/ChartBar.vue'
import ChartDoughnut from './components/ChartDoughnut.vue'
import ProfilePopUp from './components/Layout/ProfilePopUp.vue'
import NotificationPopUp from './components/Layout/NotificationPopUp.vue'
import TabItems from './components/TabItems.vue'
import { generateUUID } from './assets/script.js'
import VueSweetalert2 from 'vue-sweetalert2'
import DatePicker from './components/DatePicker.vue'
import DateTimePicker from '@/components/DateTimePicker.vue'
import TextEditor from './components/TextEditor.vue'
import UpdoadAvata from './components/UploadAvata.vue'
import TextField from '@/components/TextField.vue'
import TextArea from '@/components/TextArea.vue'
import Selectors from '@/components/Selector.vue'
import Autocomplete from '@/components/Autocomplete.vue'

// NOTE import External Style Sheet
import 'sweetalert2/dist/sweetalert2.min.css'
import 'ant-design-vue/dist/antd.css'
import mixin from './scripts/mixin.js' // NOTE import Mixin file to main.js
import aesMixin from './scripts/aes-mixin'
import datasourceMixin from './scripts/elasticsearch-mixin.js'
import alertMixin from './scripts/alert-mixin.js'
import providerIdMixin from '@/scripts/provider-mixin.js'
import helper from '@/scripts/helper.js'
import websocket from './plugins/websocket.js'

import '@/scripts/eventbus'
// import { VueMaskDirective } from 'v-mask'
// Vue.directive('mask', VueMaskDirective)

import VueMask from 'v-mask'
Vue.use(VueMask, {
  // (!) custom placeholders support requires registration as a plugin to
  placeholders: {
    U: /^(\*|\*\/\d|\d)+$/
  }
})

// NOTE Add Mixin to Global Vue.js
// สำหรับใช้งาน Function ในการ Calling API ตาม API Spect
Vue.mixin(mixin)
Vue.mixin(aesMixin)
Vue.mixin(datasourceMixin)
Vue.mixin(alertMixin)
Vue.mixin(helper)
Vue.mixin(providerIdMixin)

// สำหรับใช้ในการ Connect Centrifugo เพื่อใช้ในการต่อ Websocket
Vue.use(websocket, {
  host: process.env.VUE_APP_WS
})

Tooltip.positioners.custom = function (elements, eventPosition) {
  // const tooltip = this

  /* ... */

  return {
    x: eventPosition.x,
    y: eventPosition.y
  }
}
Chart.register(...registerables)

// NOTE Add Javascriot to Global Vue.js
Vue.prototype.$moment = moment
Vue.prototype.antmessage = message
Vue.prototype.$notification = notification

Vue.prototype.Chart = Chart
Vue.prototype.generateUUID = generateUUID

Vue.component('u-switch-theme', SwitchTheme)
Vue.component('u-switch-lang', SwitchLang)
Vue.component('u-title-page', TitlePage)
Vue.component('u-table', Table)
Vue.component('u-tab', Tab)
Vue.component('u-tab-items', TabItems)
Vue.component('u-card-form', CardForm)
Vue.component('u-chart-bar', ChartBar)
Vue.component('u-chart-doughnut', ChartDoughnut)
Vue.component('u-profile-popup', ProfilePopUp)
Vue.component('u-notification-popup', NotificationPopUp)
Vue.component('u-date-picker', DatePicker)
Vue.component('u-text-deitor', TextEditor)
Vue.component('u-upload-avata', UpdoadAvata)
Vue.component('u-text-field', TextField)
Vue.component('u-text-area', TextArea)
Vue.component('u-selector', Selectors)
Vue.component('u-date-time-picker', DateTimePicker)
Vue.component('u-autocomplete', Autocomplete)

Vue.use(VueAxios, axios)
// Vue.use(Tooltip)
Vue.use(VueCookies, { expires: '1d' })
Vue.use(VueSweetalert2)
Vue.use(AutoComplete)

// # สำหับ Config Axios
axios.interceptors.request.use((config) => {
  config.timeout = 10000 // Wait for 5 seconds before timing out
  return config
})

// # สำหรับแปะ Authorization ใน Request Header
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('Accesstoken')
    if (token) config.headers.common.Authorization = `Bearer ${token}`
    return config
  },
  (error) => Promise.reject(error)
)

// # สำหรับดัก Error Response
axios.interceptors.response.use(
  (response) => {
    // message.success(response.data.message)
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  (error) => {
    if (error.message !== 'Network Error') {
      if (error.response.status) {
        const redirectUri = `${window.location.origin}${process.env.VUE_APP_BASE_URL}login`
        const uri = `${process.env.VUE_APP_ONEID_URL}/api/oauth/logout?redirect_uri=${redirectUri}`
        switch (error.response.status) {
          case 401:
            localStorage.clear()
            location.replace(uri)
            // router.push({ name: 'loginPage' }).catch(() => {})
            Vue.$websocket.Disconnect()
            break
        }
        return Promise.reject(error.response)
      }
    } else message.error('Not connect to server')
  }
)

Vue.config.productionTip = false
new Vue({
  // mixins: [mixin],
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
