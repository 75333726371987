<template>
  <div class="layout-content">
    <!-- <div>
      <QRCodeGeneratorVue/>
    </div> -->
    <u-table
      v-model="projectSelected"
      :headers="headers"
      :items="items"
      :mobileBreakpoint="900"
      :showSelect="false"
      itemKey="project_id"
      :loading="loading"
      @dbclickRow="click"
      :pagination="pagination"
      @onChangePagination="onChangePagination"
    >
      <!-- // # Header Table -->
      <template v-slot:header>
        <v-row
          :justify="priorityUser === 0 ? 'space-between' : 'end'"
          align="center"
        >
          <!-- // # Action Group -->
          <v-col cols="12" md="2" v-if="priorityUser === 0">
            <v-btn color="primary" block dark @click="dialog = !dialog">
              <v-icon>mdi-home-plus</v-icon>เพิ่ม{{ NameComponent }}
            </v-btn>
          </v-col>

          <!-- // # Search Group -->
          <v-col cols="12" md="4">
            <v-row justify="center" align="center">
              <v-col>
                <v-text-field
                  v-model="keyword"
                  name="name"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  placeholder="ค้นหา"
                  label="ค้นหา"
                  id="id"
                  hide-details
                  @keyup.enter="(pagination.page = 1), init()"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn
                  color="primary"
                  block
                  dark
                  @click="(pagination.page = 1), init()"
                  >ค้นหา</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.number`]="{ item }">
        {{
          pagination.row * pagination.page -
          pagination.row +
          (items.indexOf(item) + 1)
        }}
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <span class="fix-overflow-ellipsis">
          {{
            item.description !== "" && item.description !== null
              ? item.description
              : "-"
          }}
        </span>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-avatar color="primary" size="35">
          <span
            v-if="item.image === ''"
            class="white--text text-h7 text-uppercase"
          >
            {{ item.name.charAt(0) }}{{ item.name.charAt(0) }}
          </span>
          <v-img v-else :src="getFileUrl(item.image)"></v-img>
        </v-avatar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="action-nowrap">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                fab
                x-small
                dark
                color="success"
                @click="openDialogInvite(item)"
              >
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </template>
            <span>Invite Member</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                fab
                x-small
                dark
                color="primary"
                @click="click(item)"
              >
                <v-icon>mdi-chart-areaspline</v-icon>
              </v-btn>
            </template>
            <span>ดูรายละเอียด</span>
          </v-tooltip>

          <v-tooltip top v-if="priorityUser === 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                fab
                x-small
                dark
                color="primary"
                @click="editItem(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>แก้ไขข้อมูล</span>
          </v-tooltip>

          <v-tooltip top v-if="priorityUser === 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                fab
                x-small
                dark
                color="error"
                @click="deleteItem(item)"
              >
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </template>
            <span>ลบ</span>
          </v-tooltip>
        </div>
      </template>
    </u-table>

    <u-card-form
      :title="formTitle"
      :subtitle="formSubTitle"
      ref="form"
      v-model="dialog"
    >
      <template v-slot:form>
        <v-row>
          <v-col cols="12">
            <div class="profile-image">
              <u-upload-avata
                v-model="files"
                :src="
                  editedItem.image != '' ? getFileUrl(editedItem.image) : ''
                "
                :firstName="editedItem.name"
                :lastName="editedItem.name"
                @change="uploadImage"
              ></u-upload-avata>
            </div>
          </v-col>

          <v-col cols="8">
            <div>
              <span class="required">*</span><span>{{ NameComponent }}</span>
            </div>
            <v-text-field
              v-model="editedItem.name"
              :items="projects"
              :rules="[Rule.noInPut]"
              :placeholder="`กรุณากรอก${NameComponent}`"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <u-selector
              title="Limit Date"
              placeholder="Limit Date"
              item-value="value"
              item-text="text"
              :items="limitDates"
              v-model="editedItem.limit_date"
              :rules="[Rule.noInPut]"
            />
          </v-col>

          <v-col cols="12">
            <div><span>บันทึกรายละเอียด</span></div>
            <v-textarea
              v-model="editedItem.description"
              outlined
              placeholder="รายละเอียด"
              value=""
            ></v-textarea>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions="{ valid }">
        <v-row>
          <v-col>
            <v-btn
              @click="save"
              :disabled="!valid"
              block
              color="primary"
              :loading="loadingSave"
            >
              บันทึก
            </v-btn>
          </v-col>
          <v-col>
            <v-btn block color="error" @click="close">ยกเลิก</v-btn>
          </v-col>
        </v-row>
      </template>
    </u-card-form>

    <!-- // TODO เป็น Dialog สำหรับ Invite Member สำหรับ Feature Provider ID -->
    <u-card-form
      title="Invite"
      subtitle="เลือกชื่อสิทธิการใช้งาน"
      v-model="statusDialogInvite"
    >
      <template v-slot:content>
        <!-- // TODO สำหรับเลือก Permissionในแต่ละ Project -->
        <div class="d-flex align-center">
          <v-row>
            <!-- // NOTE Select Role of project -->
            <v-col cols="12">
              <v-select
                v-model="currentPermissionID"
                label="เลือกชื่อสิทธิการใช้งาน"
                :items="permissions"
                item-text="name"
                item-value="permission_id"
                dense
                hide-details
                outlined
                @change="
                  generateQRcodeInvite(
                    'canvas-xx',
                    generateURLInvite(currentProjectID, currentPermissionID)
                  )
                "
              >
              </v-select>
            </v-col>
            <!-- // NOTE QRCode for invite members -->
            <v-col
              cols="12"
              v-show="currentPermissionID != ''"
              style="display: flex; justify-content: center"
            >
              <canvas
                v-show="currentPermissionID != ''"
                style=""
                id="canvas-xx"
              ></canvas>
            </v-col>
            <v-col v-show="currentPermissionID != ''">
              <v-row>
                <v-col class="pl-0">
                  <v-btn
                    block
                    color="primary"
                    @click="downloadQRcodeInvite('canvas-xx')"
                    >Download</v-btn
                  >
                </v-col>
                <v-col class="pr-0">
                  <v-btn
                    block
                    color="success"
                    @click="
                      copyTextToClipboard(
                        generateURLInvite(currentProjectID, currentPermissionID)
                      )
                    "
                    >Copy</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>

            <!-- <v-col cols="12"> Project ID : {{ currentProjectID }} </v-col> -->
            <!-- <v-col cols="12"> Permission ID : {{ currentPermissionID }} </v-col> -->
          </v-row>
        </div>
      </template>
      <!-- // TODO ปุ่มสำหรับปิด Dialog Invite -->
      <template v-slot:actions>
        <v-row>
          <v-col>
            <v-btn block color="error" @click="closeDialogInvite">ปิด</v-btn>
          </v-col>
        </v-row>
      </template>
    </u-card-form>
  </div>
</template>

<script>
import QRCode from 'qrcode'
export default {
  data () {
    return {
      // value: 'https://example.com',
      // size: 300,
      userInfo: {},
      priorityUser: '',
      NameComponent: 'โปรเจค',
      dialog: false, // สำหรับใช้เปิดปิด dialog add/edit
      rules: [(v) => !!v || 'กรุณากรอกข้อมูล'],
      bizIdEdit: '',
      keyword: '',
      limitDates: [
        {
          text: '1 Year',
          value: 365
        },
        {
          text: '90 Day',
          value: 90
        },
        {
          text: '30 Day',
          value: 30
        },
        {
          text: '7 Day',
          value: 7
        }
      ],
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number',
          width: '60px'
        },
        {
          text: 'โลโก้',
          align: 'center',
          sortable: false,
          value: 'image',
          width: '60px'
        },
        {
          text: 'ชื่อโปรเจค',
          align: 'start',
          value: 'name',
          sortable: false
        },
        // {
        //   text: 'หมายเลขโทรศัพท์ผู้ดูแลโปรเจค',
        //   value: 'phone',
        //   align: 'start',
        //   sortable: false
        // },
        {
          text: 'รายละเอียด',
          value: 'description',
          align: 'start',
          sortable: false
        },
        {
          text: 'จัดการ',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '200px'
        }
      ],
      projectSelected: [],
      items: [],
      projects: [],
      editedIndex: -1,
      editedItem: {
        project_id: '',
        image: '',
        name: '',
        biz_id: '',
        description: '',
        limit_date: 7
      },
      permissionItem: {
        project_id: '',
        image: '',
        name: '',
        biz_id: '',
        description: '',
        limit_date: 7
      },
      defaultItem: {
        project_id: '',
        image: '',
        name: '',
        biz_id: '',
        description: '',
        limit_date: 7
      },
      pagination: {
        total: 0,
        page: 1,
        row: 10
      },
      loading: false,
      loadingSave: false,
      files: null,
      dialogForAdminInvite: false,
      currentProjectID: '',
      permissions: [],
      currentPermissionID: '',
      // coppy Text and QR
      statusDialogInvite: false,
      QRValue: 'ww.sme.com',
      title: 'QR code generator',
      text: '',
      qrcode: {
        value: '',
        size: 300
      }
      // image: ''
    }
  },
  components: {
    // QrcodeVue
    // QRCodeGeneratorVue
  },
  // computed: {
  //   newQRCode () {
  //     // this.qrcode.value = this.text
  //     // return this.qrcode.toDataURL()
  //   }
  // },
  async created () {
    await this.init()
    this.initUserInfo()
    this.$store.commit('setProjectID', '')
    this.$store.commit('setProjectNmae', '')
    this.$store.commit('storeSetProjectLimitDate', 0)
  },
  methods: {
    // NOTE เป็น Function ที่จะทำงานเมื่อมีการ เปิด Dialog invite
    openDialogInvite (item) {
      // TODO Clear Data

      // TODO initial data
      this.currentProjectID = item.project_id

      // TODO เป็น Function สำหรับดึงข้อมูล
      this.initPermissionDropdown()

      // TODO Open Dialog
      this.statusDialogInvite = true
    },
    // NOTE เป็น Function ที่จะทำงานเมื่อมีการ ปิด Dialog invite
    closeDialogInvite () {
      // TODO Close Dialog
      this.statusDialogInvite = false

      // TODO Clear Data
      this.currentProjectID = ''
      this.currentPermissionID = ''
    },

    // NOTE เป็น Function สำหรับ Generate URL invite
    generateURLInvite (projectID = '', permissionID = '') {
      const payload = btoa(JSON.stringify({ project_id: projectID, permission_id: permissionID }))
      return `${window.location.origin}?action=invite&invite_id=${payload}`
      // return `${window.location.origin}?action=invite&project_id=${projectID}&permission_id=${permissionID}`
    },

    // NOTE เป็น function สำหรับ Download QRcode invite
    downloadQRcodeInvite (element) {
      const link = document.createElement('a')
      link.download = 'invite-qrcode.png'
      link.href = document.getElementById(element).toDataURL()
      link.click()
      this.$notification.success({
        message: 'Download Success',
        description: 'Download QR Code invite success',
        placement: 'bottomRight',
        duration: 2
      })
    },

    // NOTE เป็น Function สำหรับ Copy URL TO Clipboard
    copyTextToClipboard (msg = '') {
      // TODO Copy the text inside the text field
      navigator.clipboard.writeText(msg)
      this.$notification.success({
        message: 'Copy Success',
        description: 'Copy invite URL success',
        placement: 'bottomRight',
        duration: 2
      })
    },

    // NOTE เป็น Function สำหรับ Generate QR Code
    generateQRcodeInvite (element, msg) {
      const canvas = document.getElementById(element)
      const options = {
        errorCorrectionLevel: 'H',
        quality: 0.3,
        margin: 0,
        version: 15,
        scale: 3,
        // maskPattern: 1,
        color: {
          // dark: this.$vuetify.theme.currentTheme.primary
          // light: '#FFBF60FF'
        }
      }
      QRCode.toCanvas(canvas, msg, options, (error) => {
        if (error) console.error(error)
      })
    },
    async initUserInfo () {
      this.loading = true

      const res = await this.getUserInfo()
      this.loading = false
      if (res.status) {
        this.userInfo = res.data
        for (let index = 0; index < this.userInfo.permissions.length; index++) {
          if (this.userInfo.permissions[index].priority === 0) {
            this.priorityUser = this.userInfo.permissions[index].priority
          }
        }
      } else {
        this.antmessage.error(res.message)
      }
    },
    async init () {
      this.items = []
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        keyword: this.keyword,
        page: this.pagination.page,
        row: this.pagination.row
      }
      const res = await this.getProjectForTable(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination = res.data.pagination
      } else {
        this.antmessage.error(res.message)
      }
    },
    onChangePagination (val) {
      this.pagination.page = val.page
      this.pagination.row = val.row
      this.init()
    },
    click (e) {
      this.$router
        .push({
          name: 'projectDashboard',
          params: {
            project_id: e.project_id
          }
        })
        .catch(() => {})
    },
    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    // NOTE เป็น Functional เมื่อมีการกด invite members to project
    // selectProjectId (item) {
    //   this.permissionItem = Object.assign({}, item)
    //   this.currentProjectID = this.permissionItem.project_id

    //   // NOTE เป็น Function สำหรับดึงข้อมูล
    //   this.initPermissionDropdown()
    //   this.dialogForAdminInvite = true
    // },
    // async copyText () {
    //   // Get the text field
    //   const textToCopy = document.getElementById('copy-shared-url')

    //   // Select the text field
    //   await textToCopy.select()
    //   await textToCopy.setSelectionRange(0, 99999) // For mobile devices

    //   // Copy the text inside the text field
    //   await navigator.clipboard.writeText(textToCopy.value)
    // },
    deleteItem (item) {
      this.$swal({
        allowEnterKey: false,
        title: `ลบ${this.NameComponent}`,
        text: `คุณต้องการลบ${this.NameComponent}นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = {
            project_id: item.project_id
          }
          const res = await this.deleteProject(payload)
          this.antmessage.destroy()
          if (res.status) {
            this.init()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `ลบ${this.NameComponent}สำเร็จ`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },
    // สำหรับ get User
    async initPermissionDropdown () {
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        project_id: this.currentProjectID
      }
      const res = await this.getProjectPermissionForDropdown(payload)
      if (res.status) {
        // TODO when get data success
        this.permissions = res.data
      } else {
        // TODO when get data fail
        this.antmessage.error(res.message)
      }
    },
    close () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.bizIdEdit = ''
        this.image = ''
      })
    },
    // closeDialog () {
    //   this.dialogForAdminInvite = false
    //   this.statusDialogInvite = false
    //   this.currentPermissionID = ''
    //   this.permissions = Object.assign({})
    //   this.$nextTick(() => {
    //     this.permissionItem = Object.assign({}, this.defaultItem)
    //   })
    // },
    save () {
      if (this.editedIndex > -1) {
        // TODO Update Data
        this.$swal({
          allowEnterKey: false,
          title: `แก้ไข${this.NameComponent}`,
          text: `คุณต้องการแก้ไข${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = {
              project_id: this.editedItem.project_id,
              name: this.editedItem.name,
              image: this.editedItem.image,
              biz_id: this.editedItem.biz_id,
              description: this.editedItem.description,
              limit_date: parseInt(this.editedItem.limit_date)
            }
            const res = await this.updateProject(payload)
            this.loadingSave = false
            if (res.status) {
              this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `แก้ไข${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else {
        // TODO Create Data
        this.$swal({
          allowEnterKey: false,
          title: `เพิ่ม${this.NameComponent}`,
          text: `คุณต้องการเพิ่ม${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const res = await this.createProject(this.editedItem)
            this.loadingSave = false
            if (res.status) {
              // TODO when get data success
              this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `เพิ่ม${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.close()
            } else {
              // TODO when get data fail
              this.antmessage.error(res.message)
            }
          }
        })
      }
    },
    async uploadImage (val) {
      this.loading = true
      const formData = new FormData()
      for (const i of val) {
        formData.append('file', i)
      }
      const res = await this.UploadFile(formData)
      this.loading = false
      if (res.status) {
        this.editedItem.image = res.data.filename
        // this.antmessage.success(res.message)
      } else {
        this.antmessage.error(res.message)
      }
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? `เพิ่ม${this.NameComponent}`
        : `แก้ไข${this.NameComponent}`
    },
    formSubTitle () {
      return this.editedIndex === -1
        ? `กรอกข้อมูลเพื่อสร้าง${this.NameComponent}`
        : `กรอกข้อมูลเพื่อแก้ไข${this.NameComponent}`
    },
    link () {
      return `${window.location.origin}?action=invite&project_ID=${this.currentPermissionID.permission_id}`
    }
  }
}
</script>

<style lang="css" scoped>
/* // TODO สำหรับจัดการ Content ของเพจ */
.layout-content {
  height: calc(100% - 58px);
  padding: 0;
  margin: 0;
  /* background: green; */
}

.header-custom-table .header-custom-search {
  display: flex;
  gap: 1rem;

  /* background: gray; */
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.header-custom-table .header-custom-actions {
  display: flex;
  gap: 1rem;
  /* background: gray; */
  align-items: center;
  height: 100%;
  min-height: 70px;

  /* justify-content: flex-end; */
}

.main-layout {
  height: calc(100% - 58px);
}

.profile-image {
  /* background-color: red; */
  text-align: center;
  width: 100%;
  height: 100%;
}

.dialog-admin-invite {
  background: white;
}
</style>
