<template>
  <v-text-field
    v-model="color"
    v-mask="mask"
    hide-details
    class="ma-0 pa-0"
    solo
    readonly
  >
    <template v-slot:append>
      <v-menu
        v-model="menu"
        top
        nudge-bottom="105"
        nudge-left="16"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="color" flat mode="hexa" />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  computed: {
    swatchStyle () {
      const { color, menu } = this
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    color: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        return val
      }
    }
  },
  props: {
    value: {
      type: String,
      default: '#1976D2FF'
    }
  },

  data () {
    return {
      // color: '#1976D2FF',
      mask: [
        '?#',
        /^[0-9a-fA-F]$/,
        /^[0-9a-fA-F]+$/,
        /^[0-9a-fA-F]+$/,
        /^[0-9a-fA-F]+$/,
        /^[0-9a-fA-F]+$/,
        /^[0-9a-fA-F]+$/,
        /^[0-9a-fA-F]+$/,
        /^[0-9a-fA-F]+$/
      ],
      menu: false
    }
  }
}
</script>

<style lang="scss" scoped></style>
