<template>
<div class="main-login-page">
    <!-- image -->
    <div class="img-login">
        <div class="text-welcome">
            <div class="text-DashboardManageAI">
                ยินดีต้อนรับเข้าสู่ระบบ
            </div>
            <div class="text-DashboardManageAI">
                Dashboard
            </div>
            <span class="text-DashboardManageAI">
                <p class="text-ManageAI">
                    Manage
                </p>
                <p class="text-ManageAI">
                    AI
                </p>
            </span>
            <!-- <div class="text-DashboardManageAI">
                <p class="text-ManageAI">
                    ถ้าคุณยังไม่มีบัญชีผู้ใช้งาน
                </p>
                <p class="text-ManageAI">
                    <a style="color: #50A7DC;" href="#" @click="$emit('click:register')">ลงทะเบียนที่นี่ !</a>
                </p>
            </div> -->
        </div>
        <!-- image -->
        <img src="@/../public/img/ImageLogin.png">
    </div>
    <div class="login-content">
        <v-form ref="form" v-model="valid">
            <div class="ma-2 form-box">
                <div class="title-ManagAI">
                    <img src="@/../public/img/logoManageAIWithOutText.png">
                    ManageAI
                </div>
                <!-- <div class="blue d-flex align-content-start"> -->
                <div class="title-loginForm">
                    ลงทะเบียน
                </div>
                <!-- </div> -->
                <div>
                    <div class="form-Input1">
                        <!-- เพศ -->
                        <v-select v-model="signUpData.gender" :items="gender" item-text="text" item-value="val" :rules="[Rule.notNull]" outlined dense rounded label="เพศ" @change="returnNameTitle(signUpData.gender)" placeholder="กรุณาเพศ" :loading="loading"></v-select>

                    </div>
                    <div class="form-Input2">
                        <!-- // # ชื่อ -->
                        <v-text-field required v-model="signUpData.first_name" :rules="[Rule.noInPut, Rule.noSpace]" outlined dense rounded label="ชื่อ" placeholder="กรุณากรอกชื่อ" :loading="loading"></v-text-field>
                        <!-- //# นามสกุล -->
                        <v-text-field required v-model="signUpData.last_name" :rules="[Rule.noInPut, Rule.noSpace]" outlined dense rounded label="นามสกุล" placeholder="กรุณากรอกนามสกุล" :loading="loading"></v-text-field>
                    </div>
                    <div class="form-Input1">
                        <!-- // # วันเกิด -->
                        <!-- <v-dialog
                  ref="dialog"
                  v-model="modalDatePicker"
                  :return-value.sync="signUpData.date_of_birth"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="returnFormateDate"
                      label="วันเกิด"
                      placeholder="กรุณาเลือกวันเกิด"
                      :rules="[Rule.noInPut]"
                      readonly
                      outlined
                      dense
                      rounded
                      v-bind="attrs"
                      v-on="on"
                      :loading="loading"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="signUpData.date_of_birth"
                    color="primary"
                    no-title
                    scrollable
                    locale="th"
                    :max="DateNow"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(signUpData.date_of_birth)"
                    >
                      ตกลง
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="modalDatePicker = false"
                    >
                      ยกเลิก
                    </v-btn>
                  </v-date-picker>
                </v-dialog> -->
                        <!-- // #  หมายเลขโทรศัพท์ -->
                        <v-text-field required v-model="signUpData.phone" :rules="[Rule.noInPut,Rule.noSpace,Rule.numberOnly, Rule.phoneNumber]" maxlength="10" counter outlined dense rounded label="หมายเลขโทรศัพท์" placeholder="กรุณากรอกหมายเลขโทรศัพท์" :loading="loading"></v-text-field>
                    </div>
                    <div class="form-Input2">
                        <!-- //# ชื่อผู้ใช้งาน  Username -->
                        <v-text-field required v-model="signUpData.username" :rules="[Rule.noInPut, Rule.englishOnly, Rule.emailOnly]" outlined dense rounded label="ชื่อผู้ใช้งาน(อีเมล)" placeholder="กรุณากรอกชื่อผู้ใช้งาน" :loading="loading"></v-text-field>
                    </div>
                </div>
                <!-- เข้าสู่ระบบด้วย -->
                <!-- // TODO 2023-07-11 เพิ่ม Oauth OneID -->
                <!-- <div class="oauth-sign-in">
                    <div class="mr-5">
                        <OauthBtn disabled block rounded color="white" :image="require('@/assets/images/google.png')" text="Google" @click="$emit('click:google')">
                        </OauthBtn>
                    </div>
                    <div>
                        <OauthBtn block rounded color="primary" :image="require('@/assets/images/onePlatform.png')" text="OneID" @click="$emit('click:oneid')">
                        </OauthBtn>
                    </div>
                </div> -->
                <div class="forget-password">
                    <!-- <v-btn @click="save" class="my-3 sign-in-btn" color="primary" :loading="loading" block rounded :disabled="!valid">ลงทะเบียน</v-btn> -->
                    <v-btn @click="openDialogForm" class="my-3 sign-in-btn" style="height: 48px;" color="primary" :loading="loading" block rounded :disabled="!valid">ลงทะเบียน</v-btn>
                </div>
                <!-- <h2 class="hr-lines">ลงทะเบียนด้วย</h2> -->
                <!-- // TODO -------------------------- -->
                <div class="pa-5">
                    มีบัญชีผู้ใช้งานแล้ว
                    <a style="color: #50A7DC;" href="#" @click="$router.push({ name: 'loginPage' })">เข้าสู่ระบบ</a>
                </div>
                <DialogConfirm :value="dialogForCheck" :title="titleForConfirm.formTitle" :subtitle="titleForConfirm.subtitle" :imageDialog="require('../../public/img/createForDialog.png')" ref="form">
                    <!--  TODO Actions -->
                    <template v-slot:actionsCloseBtn>
                        <div class="btn-close">
                            <v-icon @click="(dialogForCheck = false)">mdi-close</v-icon>
                        </div>
                    </template>
                    <template v-slot:actions>
                        <!-- <div class="btn-ok-cancel"> -->
                        <div class="btn-cancel mr-2" @click="(dialogForCheck = false)">ยกเลิก</div>
                        <div class="btn-ok" @click="saveDiglog">บันทึก</div>
                        <!-- </div> -->
                    </template>
                </DialogConfirm>
                <DialogConfirm :value="dialogForSave" :title="titleDialogConfirm.formTitle" :subtitle="titleDialogConfirm.subtitle" :colorForMainTitle="titleDialogConfirm.colorForTitle" :imageDialog="require('../../public/img/createAccountSuccess.png')">
                    <!--  TODO Actions -->
                    <template v-slot:actionsCloseBtn>
                        <div class="btn-close">
                            <v-icon @click="(dialogForSave = false),reset()">mdi-close</v-icon>
                        </div>
                    </template>
                </DialogConfirm>
            </div>
        </v-form>

    </div>

    <div class="img-login-iphon">
        <img src="@/../public/img/ImageLogin.png">
    </div>

</div>
</template>

<script>
// import OauthBtn from '@/components/Button/OauthBtn.vue'
import DialogConfirm from '@/components/Forms/DialogConfirm.vue'
export default {
  components: {
    // DialogAnnounce,
    // DialogForgetPassword,
    // OauthBtn
    DialogConfirm
  },
  data () {
    return {
      DateNow: this.$moment().format('YYYY-MM-DD'),
      valid: true,
      loading: false,
      dialogForCheck: false,
      dialogForSave: false,
      NameComponent: 'บัญชีผู้ใช้',
      signUpData: {
        user_id: '',
        first_name: '',
        last_name: '',
        username: '',
        phone: '',
        gender: '',
        name_title: '',
        image: '',
        // date_of_birth: '',
        verify: 0,
        street: '',
        zip_code: '',
        city: '',
        country_code: '',
        country: '',
        time_zone: 'UTC',
        permissions: [],
        email_verify: '',
        description: ''
      },
      modalDatePicker: false,
      nameTitle: [{
        text: 'นางสาว',
        val: 'นางสาว'
      },
      {
        text: 'นาง',
        val: 'นาง'
      },
      {
        text: 'นาย',
        val: 'นาย'
      },
      {
        text: 'ไม่ระบุ',
        val: ''
      }
      ],
      gender: [{
        text: 'หญิง',
        val: 'หญิง'
      },
      {
        text: 'ชาย',
        val: 'ชาย'
      },
      {
        text: 'ไม่ระบุ',
        val: ''
      }
      ],
      titleForConfirm: {
        formTitle: 'สร้างบัญชีผู้ใช้',
        subtitle: 'คุณต้องการสร้างบัญชีผู้ใช้นี้ใช่หรือไม่ ?',
        colorForTitle: 'green'
      },
      titleDialogConfirm: {
        formTitle: 'สำเร็จ',
        subtitle: 'ลงทะเบียนบัญชีผู้ใช้งานสำเร็จรหัสผ่านของคุณจะถูกส่งไปที่ อีเมลที่ ทำการสมัครหรือกรุณาติดต่อเจ้าหน้าที่ ',
        colorForTitle: 'green'
      }
      // formTitle: 'สร้างบัญชีผู้ใช้',
      // subtitle: 'คุณต้องการสร้างบัญชีผู้ใช้นี้ใช่หรือไม่ ?'
    }
  },
  methods: {
    reset () {
      this.$refs.form.reset()
      this.$router.push({
        name: 'loginPage'
      }).catch(() => {})
    },
    returnNameTitle (gender) {
      this.signUpData.name_title = ''
      if (gender === '') {
        this.nameTitle = [{
          text: 'นางสาว',
          val: 'นางสาว'
        },
        {
          text: 'นาง',
          val: 'นาง'
        },
        {
          text: 'นาย',
          val: 'นาย'
        },
        {
          text: 'ไม่ระบุ',
          val: ''
        }
        ]
      } else if (gender === 'ชาย') {
        this.nameTitle = [{
          text: 'นาย',
          val: 'นาย'
        },
        {
          text: 'ไม่ระบุ',
          val: ''
        }
        ]
      } else if (gender === 'หญิง') {
        this.nameTitle = [{
          text: 'นางสาว',
          val: 'นางสาว'
        },
        {
          text: 'นาง',
          val: 'นาง'
        },
        {
          text: 'ไม่ระบุ',
          val: ''
        }
        ]
      }
    },
    openDialogForm () {
      this.dialogForCheck = true
    },
    async saveDiglog () {
      // ปิด diglog form แรก
      this.dialogForCheck = false
      // เปิด confirm
      this.loading = true
      const res = await this.registerPageUser(this.signUpData)
      this.loading = false
      if (res.status) {
        // TODO when get data success
        this.dialogForSave = true
        // this.$router.push({
        //   name: 'loginPage'
        // }).catch(() => {})
        // TODO when get data success
      } else {
        // TODO when get data fail
        this.antmessage.error(res.message)
      }
    },
    save () {
      // TODO Create Data
      this.$swal({
        allowOutsideClick: false,
        allowEnterKey: false,
        title: `สร้าง${this.NameComponent}`,
        text: `คุณต้องการสร้าง${this.NameComponent}นี้ใช่หรือไม่`,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true
          const res = await this.registerPageUser(this.signUpData)
          this.loading = false
          if (res.status) {
            // TODO when get data success
            this.$swal({
              allowOutsideClick: false,
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `สร้าง${this.NameComponent}สำเร็จ กรุณารออนุมัติ${this.NameComponent}`,
              icon: 'success',
              showConfirmButton: true,
              showCancelButton: false
            }).then(async (result) => {
              if (result.isConfirmed) {
                this.$router.push({
                  name: 'loginPage'
                }).catch(() => {})
              }
            })
          } else {
            // TODO when get data fail
            this.antmessage.error(res.message)
          }
        }
      })
    },
    close () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      // this.$emit('close')
      this.dialogForCheck = false
    }
  },
  computed: {
    // returnFormateDate: {
    //   set (val) {
    //     return val
    //   }
    //   get () {
    //     return this.signUpData.date_of_birth !== ''
    //       ? this.$moment(this.signUpData.date_of_birth)
    //         .add(543, 'year')
    //         .format('DD-MM-YYYY')
    //       : ''
    //   }
    // }
  }
}
</script>

<style lang="css" scoped>
.main-login-page {
    /* width: 100vw; */
    /* height: 100vh; */
    height: 100%;
    width: 100%;
    background-image: url("@/../public/img/BGlogin.svg");
    background-size: cover;
    display: grid;
    /* สร้าง 2 คอลัมภ์ แต่ละคอลัมภ์กว้าง 1fr */
    grid-template-columns: repeat(2, 1fr);
    /* grid-gap: 6rem; */
    grid-gap: 3rem;
    padding: 5px;
}

.img-login-iphon {
    display: none;
}

.img-login {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: flex-end; */
    justify-content: center;
    /* align-items: center; */
    /* background: pink; */
}

.img-login img {
    /* background: darksalmon; */
    bottom: 0;
    width: 100%;
    /* max-height: 618.93px;
    max-width: 543.36px; */
    max-height: 500px;
    max-width: 400px;

}

.text-welcome {
    width: 100%;
    margin-top: 80px;
    margin-left: 100px;
    /* background:  yellow; */
}

.text-DashboardManageAI {
    /* background: red; */
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;

    color: #333333;
}

.text-DashboardManageAI:nth-child(2) {
    /* background: #38d39f; */
    margin-top: 16px;

}

.text-DashboardManageAI:nth-child(3) {
    padding: 0;
    /* background: plum; */
    margin-top: 0px;
    font-size: 56px;
    display: flex;
    top: -10px;
}

.text-DashboardManageAI:nth-child(4) {
    /* background: lightgreen; */
    margin-top: 0px;
    font-weight: 500;
    font-size: 18px;
    display: flex;
}

.text-ManageAI {
    /* background: red; */
}

.text-ManageAI:nth-child(2) {
    margin-left: 10px;
    color: #50A7DC;
}

.ImageLogin {
    /* width: 50%; */
    /* padding: 5px; */
    max-height: 618.93px;
    max-width: 543.36px;
    /* width: calc(100% - 10rem); */
    /* width: 543.36px;
    height: 618.93px; */
    position: absolute;
    /* margin-left: 12.75rem; */
    margin-left: 5%;
    /* background: red; */
    /* left: 0; */
    bottom: 0;
}

/*-----------------ไส้ในlogin form-------------------*/
.login-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

form {
    width: 100%;
    /* width: fit-content; */
    height: 100%;
    max-height: 730px;
    max-width: 560px;
    /* top: calc(50% - 726px/2); */

    background: #FFFFFF;
    /* background: lightpink; */

    box-shadow: 0px 8px 24px -4px rgba(27, 46, 94, 0.08);
    border-radius: 32px;
    /* padding: 20px; */
}

.title-ManagAI {
    /* background: lightgoldenrodyellow; */

    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: #333333;
    margin-top: 20px;
}

.title-ManagAI img {
    width: 82px;
    height: 82px;
}

.title-loginForm {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;

    /* width: 480px;
  height: 48px; */
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    /* line-height: 48px; */
    color: #333333;
    margin-top: 10px;
    margin-bottom: 30px;
}

.forget-password {
    text-align: right;
    /* background: yellow; */
    /* height: 48px; */
}

.forget-password-btn {
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #50A7DC;
    /* height: 48px; */
}

.sign-in-btn {
    margin-top: 32px;
    margin-bottom: 32px;
    /* height: 200px; */
    height: 48px;
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #50A7DC;

}

.oauth-sign-in {
    /* width: 100%;
  height: 100%; */
    /* background: powderblue; */
    display: flex;
    justify-content: center;
}

.form-box {
    /* background: lightpink; */
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    /* display: flex;
    flex-direction: column;
    align-content: center; */
}

.hr-lines {
    position: relative;
    /*  new lines  */
    width: 100%;
    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #636363;

    /* margin-top: 10px; */
    /* margin-top: 32px;
    margin-bottom: 32px; */
    /* background: lightskyblue; */
}

.hr-lines:before {
    content: " ";
    display: block;
    height: 1px;
    /* width: 130px; */
    /* width: 20%; */
    width: 35%;
    position: absolute;
    top: 50%;
    left: 0;
    /* background: red; */
    background: #636363;
}

.hr-lines:after {
    content: " ";
    height: 1px;
    width: 35%;
    /* background: red; */
    background: #636363;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
}

/* btn for dialogConfirm */
.btn-ok-cancel {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    /* background: plum; */
}

.btn-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
    color: #989898;
}

.btn-ok {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    /* gap: 10px; */

    width: 148px;
    height: 42px;
    background: #50A7DC;
    border-radius: 32px;

    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* identical to box height, or 144% */
    color: #FFFFFF;
    cursor: pointer;
}

.btn-cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    /* gap: 10px; */

    width: 148px;
    height: 42px;
    border: 1px solid #C4C4C4;
    border-radius: 32px;

    font-family: 'Noto Sans Thai';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* identical to box height, or 144% */
    color: #989898;
    margin-right: 12px;
    cursor: pointer;
}

@media screen and (max-width: 1050px) {
    .main-login-page {
        grid-gap: 2rem;
    }

    .hr-lines:before {
        width: 25%;
    }

    .hr-lines::after {
        width: 25%;
    }

    .title-ManagAI {
        font-size: 28px;
    }

    .form-box {
        padding-top: 10px;
    }
}

@media screen and (max-width: 700px) {

    /* form {
        width: 290px;
    } */
    .main-login-page {
        grid-gap: 0rem;
    }

    .ImageLogin {
        /* width: 50%; */
        /* padding: 5px; */
        width: 70%;
        height: 70%;
        /* width: 543.36px;
        height: 618.93px; */
        position: absolute;
        /* margin-left: 12.75rem; */
        margin-left: 10px;
        /* background: red; */
        /* left: 0; */
        bottom: 0;
    }

    .text-welcome {
        margin-top: 40px;
        margin-left: 10px;
        /* background:  yellow; */
    }

    .text-DashboardManageAI {
        /* background: red; */
        font-size: 20px;
    }

    .text-DashboardManageAI:nth-child(2) {
        /* background: #38d39f; */
        margin-top: 14px;

    }

    .text-DashboardManageAI:nth-child(3) {
        padding: 0;
        /* background: plum; */
        margin-top: 0px;
        font-size: 48px;
        display: flex;
        top: -10px;
    }

    .text-DashboardManageAI:nth-child(4) {
        /* background: lightgreen; */
        margin-top: 0px;
        font-weight: 500;
        font-size: 12px;
        display: flex;
    }

    .text-ManageAI {
        /* background: red; */
    }

    .text-ManageAI:nth-child(2) {
        margin-left: 10px;
        color: #50A7DC;
    }

    /* .img-login img {
        max-height: 618.93px;
        max-width: 543.36px;
        width: 100%;
        height: 100%;
    } */
}

@media screen and (max-width: 554px) {
    .main-login-page {
        background-image: url("@/../public/img/BgLoginForiPhone.svg");
        background-size: cover;
        grid-template-columns: 1fr;
        grid-template-rows: 2fr 1fr;
        /* grid-gap: 1rem; */
        grid-gap: auto;
    }

    .login-content {
        align-items: flex-start;
    }

    form {
        width: 100%;
        height: 100%;
        max-width: 390px;
        max-height: 736px;
        background: none;
        border: none;
        /* background: pink; */
    }

    .img-login {
        display: none;
    }

    .img-login-iphon {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        bottom: 0;
    }

    .img-login-iphon img {
        /* background: #50A7DC; */
        max-width: 175px;
        max-height: 199px;
        width: 100%;
        height: 100%;
    }

    .wave {
        display: none;
    }
}
</style>
