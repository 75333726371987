<template>
  <div class="main-layout">
    <div class="content">
      <div class="item item-left">
        <!-- <div class="title overflow-elips">
          <span class="title-text">{{ title }}</span>
        </div> -->
        <div class="logo">
          <img class="logo-img" :src="logoImage" alt="ภาพโลโก้" />
        </div>
        <div class="description">
          <div class="about">
            <div class="description-title">เกี่ยวกับ</div>
            <div class="description-detail">
              Centralized Log Management <br />
              บริการจัดเก็บข้อมูลจราจรทางคอมพิวเตอร์ที่สอดคล้องตาม พ.ร.บ
              ว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์ 2560
              และได้ผ่านการรับรองตามมาตรฐาน NTS 4003.1 - 2560 จาก NECTEC
            </div>
          </div>
          <div class="contact">
            <div class="description-title">ติดต่อเรา</div>
            <div class="description-detail">
              บริษัท วัน ออเทน จำกัด <br />
              1768 อาคารไทยซัมมิท ทาวเวอร์ ชั้นที่ 16 ถนนเพชรบุรีตัดใหม่
              แขวงบางกะปิ เขตห้วยขวาง กรุงเทพฯ 10310
            </div>

            <div class="contact-item contact-calling">
              <div class="icon">
                <v-icon color="#d60304" size="30">mdi-phone-outline</v-icon>
              </div>
              <div class="text">02-257-7111</div>
            </div>
            <div class="contact-item contact-email">
              <div class="icon">
                <v-icon color="#d60304" size="30"
                  >mdi-email-open-outline</v-icon
                >
              </div>
              <div class="text">noc@inet.co.th<br />team@oneauthen.in.th</div>
            </div>
          </div>
        </div>
      </div>
      <div class="item item-right">
        <div class="title overflow-elips">
          <span class="title-text">เข้าสู่ระบบ</span>
          <br />
        </div>
        <div class="title-description">
          <span>เข้าสู่ระบบด้วยบัญชีผู้ใช้งานของคุณ</span>
        </div>
        <div class="form-login">
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="signInData.username"
                  class="custom-text-field"
                  color="primary"
                  placeholder="ผู้ใช้งาน"
                  dense
                  outlined
                  rounded
                  :rules="[
                    Rule.noInPut,
                    Rule.noSpace,
                    Rule.englishOnly,
                    Rule.emailOnly,
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model="signInData.password"
                  class="custom-text-field"
                  color="primary"
                  placeholder="รหัสผ่าน"
                  dense
                  outlined
                  rounded
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  :rules="[Rule.noInPut, Rule.noSpace, Rule.noSpace]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0" style="text-align: end">
                <v-btn text rounded @click="$emit('click:forget')">
                  ลืมรหัสผ่าน ?
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  :disabled="!valid"
                  type="submit"
                  class="my-3 sign-in-btn"
                  color="#595959"
                  :loading="loading"
                  block
                  rounded
                  @click.prevent="
                    $emit('click:login', {
                      username: signInData.username,
                      password: signInData.password,
                    })
                  "
                >
                  <span style="color: #fff">เข้าสู่ระบบ</span>
                </v-btn>
              </v-col>
            </v-row>
            <div class="oauth-sign-in pt-6">
              <div>
                <OauthBtn
                  block
                  rounded
                  color="primary"
                  :image="require('@/assets/images/oneid.png')"
                  text="OneID"
                  @click="$emit('click:oneid')"
                >
                </OauthBtn>
                <OauthBtn
                  block
                  rounded
                  color="primary"
                  :image="require('@/assets/images/logo-provider-id.png')"
                  text="ProviderID"
                  @click="$emit('click:providerid')"
                  class="mt-2"
                >
                </OauthBtn>
              </div>
            </div>
          </v-form>
        </div>
        <div class="version-text">
          <span>version &nbsp;{{ version }}</span>
        </div>
      </div>
    </div>
    <div class="triangle-up"></div>
  </div>
</template>

<script>
import OauthBtn from '@/components/Button/OauthBtn-Old.vue'

export default {
  components: {
    OauthBtn
  },
  data () {
    return {
      show: false,
      valid: false,
      signInData: {
        username: '',
        password: '',
        show: false
      }
    }
  },
  props: {
    title: {
      type: String,
      default: 'Centralized Log Management'
    },
    logoImage: {
      type: String,
      default: require('@/assets/images/logo-oneauthen2.jpg')
    },
    loading: {
      type: Boolean,
      default: false
    },
    version: {
      type: String,
      default: process.env.VUE_APP_VERSION
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
.version-text {
  display: flex;
  font-size: 11px;
  font-weight: 300;
  justify-content: flex-end;
  align-content: center;
}
.main-layout {
  width: 100vw;
  height: 100vh;
  // background-color: red;
  padding: 0;
  margin: 0;
  display: block;
  position: absolute;
}

.main-layout .content {
  position: relative;
  top: 50%;
  left: 50%;
  width: 50%;
  // height: 55%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  display: flex;
  border-radius: 20px;
  max-width: 900px;

  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.main-layout .content .item {
  width: 50%;
  padding: 1rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.main-layout .content .item-left {
  // background-color: pink;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.main-layout .content .item-right {
  background: linear-gradient(180deg, #d60102, 80%, #fff);
}

.main-layout .content .item-right {
  border-radius: inherit;
  .title {
    color: #fff;
  }

  .title-description {
    color: #fff;
  }
}

// .triangle-up {
//   top: 0;
//   left: 0;
//   width: 0;
//   height: 0;
//   // border-left: 80% solid transparent;
//   // border-right: 40% solid transparent;
//   // border-bottom: 80% solid #555;
//   position: relative;
//   // background-color: red;
//   z-index: 0;
// }

// NOTE Logo ---------------
.logo {
  // background-color: orange;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo .logo-img {
  max-width: 100% !important;
  margin-top: 25px;
  margin-bottom: 25px;
  max-height: 80px;
  object-fit: cover;
}

.overflow-elips {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

// NOTE TItle --------------
.title {
  text-align: center;
  width: 100%;
  color: grey;
}

.title-description {
  // background-color: yellow;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.title .title-text {
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
}

// NOTE Description -------------
.description {
  overflow: hidden;
  // background-color: red;
  width: 100%;
  height: calc(100% - 40px - 130px);
}

.description-title {
  padding-top: 10px;
  font-size: 20px;
  font-weight: 600;
  color: grey;
}

.description-detail {
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

// NOTE Contact ----------------
.contact-item {
  // background: orange;
  display: flex;
  font-size: 12px;
  font-weight: 300;
}
.contact-item .icon {
  // background: green;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.contact-item .text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
}
// NOTE Form login ----------------
.form-login {
  padding: 2rem;
  margin-top: 20px;
  height: calc(100% - 60px - 20px);
  // background-color: orange;
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

::v-deep .custom-text-field {
  fieldset {
    background-color: #fff !important;
  }
}
// TODO RESPONSIVE ---------------------------
// NOTE XS
@media only screen and (max-width: 600px) {
  .main-layout {
    // background-color: lightblue !important;
  }
  .main-layout .content {
    width: 95%;
    height: 95%;
  }

  .item {
    width: 100% !important;
    height: 100% !important;
  }

  .main-layout .content .item-left {
    display: none;
  }

  .main-layout .content .item-right {
    width: 100%;
    padding: 10px;
  }

  .item.item-right {
    width: 100%;
  }
}

// NOTE sm
@media (min-width: 600px) and (max-width: 960px) {
  .main-layout {
    // background-color: purple !important;
  }
  .main-layout .content {
    width: 90%;
  }
  .main-layout .content .item {
    padding: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

// NOTE md
@media (min-width: 960px) and (max-width: 1280px) {
  .main-layout {
    // background-color: green !important;
  }

  .main-layout .content {
    width: 70%;
  }

  .main-layout .content .item {
    padding: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

// NOTE lg
@media (min-width: 1280px) and (max-width: 2560px) {
  .main-layout {
    // background-color: grey !important;
  }
}
</style>
