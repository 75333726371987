<template>
  <div class="main-layout">
    <div class="layout-tab d-flex" v-if="!loading">
      <div>
        <v-btn class="mt-2 mx-3" icon @click="$EventBus.$emit('set-nav')">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>
      <div class="width-tab">
        <u-tab v-model="menu" :items="menuItems" @click="eventClick"></u-tab>
      </div>
    </div>

    <v-progress-linear indeterminate color="primary" v-else></v-progress-linear>
    <div class="layout-content">
      <u-tab-items v-model="menu">
        <!-- # Tab Project Users -->
        <template v-slot:menuUsers>
          <menuUsers
            ref="menuUsers"
            :userInfo="userInfo"
            :project_id="project_id"
          />
        </template>
        <!-- # Tab Project Feature  -->
        <template v-slot:menuMenu>
          <menuMenu
            ref="menuMenu"
            :project_id="project_id"
            :menu-system="menuSystem"
            :priorityUser="priorityUser"
            @changeMenu="init()"
          />
        </template>

        <!-- # Tab Project Permission  -->
        <template v-slot:menuPermission>
          <menuPermission ref="menuPermission" :project_id="project_id" />
        </template>

        <!-- # Tab Project User Approve -->
        <template v-slot:menuUsersApprove>
          <menuUsersApprove ref="menuUsersApprove" :project_id="project_id" />
        </template>

        <!-- TODO Tab สำหรับ Alert -->
        <template v-slot:menuAlert>
          <ModuleAlert ref="menuAlert" :project_id="project_id" />
        </template>

        <!-- # Tab Project Dashboard -->
        <template v-slot:customDashbord>
          <v-tab-item
            v-for="Dashbord in itemsDashbord"
            :key="Dashbord.name"
            :value="Dashbord.name"
          >
            <!-- //TODO IFRAME -->
            <iframeDashbord
              v-if="Dashbord.type == 'iframe'"
              :url="Dashbord.url"
            />

            <!-- //TODO WIDGET -->
            <Widget
              v-if="Dashbord.type == 'datasource'"
              :menu-id="Dashbord.menu_id"
              :project-id="project_id"
            ></Widget>
          </v-tab-item>
        </template>
      </u-tab-items>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import menuUsers from '@/components/Project/Users.vue'
import menuUsersApprove from '@/components/Project/UsersApprove.vue'
import menuPermission from '@/components/Project/Permission.vue'
import menuMenu from '@/components/Project/MenuProject.vue'
import iframeDashbord from '@/components/Project/DashboardByIframe.vue'
import Widget from '@/views/HomePages/ProjectPages/widget.vue'
import ModuleAlert from '@/views/HomePages/ProjectPages/Alert.vue'
export default {
  components: {
    menuUsers,
    menuUsersApprove,
    menuPermission,
    menuMenu,
    iframeDashbord,
    Widget,
    ModuleAlert
  },

  data () {
    return {
      userInfo: '',
      priorityUser: '',
      loading: false,
      project_id: '',
      menu: '',
      menuItems: [],

      // TODO Default Menu Selector in project menu
      menuSystem: [
        {
          text: 'บัญชีผู้ใช้',
          name: 'menuUsers',
          description: 'จัดการบัญชีผู้ใช้',
          is_iframe: false,
          type: 'none',
          url: '',
          layout: []
        },
        {
          text: 'รออนุมัติ',
          name: 'menuUsersApprove',
          description: 'คลิกเพื่อดูคำขอที่รออนุมัติ',
          is_iframe: false,
          type: 'none',
          url: '',
          layout: []
        },
        {
          text: 'สิทธิ',
          name: 'menuPermission',
          description: 'จัดการสิทธิ',
          is_iframe: false,
          type: 'none',
          url: '',
          layout: []
        },
        {
          text: 'เมนู',
          name: 'menuMenu',
          description: 'จัดการเมนู',
          is_iframe: false,
          type: 'none',
          url: '',
          layout: []
        },
        {
          text: 'การเตือน',
          name: 'menuAlert',
          description: 'การแจ้งเตือน',
          is_iframe: false,
          type: 'alert',
          url: '',
          layout: []
        },
        {
          text: 'Iframe',
          name: '',
          description: '',
          is_iframe: true,
          type: 'iframe',
          url: '',
          layout: []
        },
        {
          text: 'Datasource',
          name: '',
          description: '',
          is_iframe: false,
          type: 'datasource',
          url: '',
          layout: []
        }
      ],

      itemsDashbord: []
    }
  },
  async created () {
    // NOTE ดึง project_id จาก URL
    this.project_id = this.$route.params.project_id

    // NOTE ดึงรายละเอียดของ Project จาก project_id
    await this.initProjectInfo()

    // NOTE ดึงรายละเอียดของ User ที่กำลังใช้งานระบบ
    await this.initUserInfo()

    await this.init()

    // NOTE initial current tag with current project
    await this.initCurentTag()

    // FIX BUG First Menu is Data source
    // FIX เกิดปัญหาเมื่อ Tab ของ Menu นั้นเป็น Index แรกแล้วไม่เกิดการ Click จึงไม่มีการ Set Store ในส่วนของ Index และ Datasource_ID
    // FIX แก้ไขปัญหาโดยการเมื่อ menu index แรกเป็น Type Datasource ให้ทำการเก็บ index และ datasource_id ลงใน Store เลย
    if (this.menuItems.length > 0) {
      if (this.menuItems[0].type === 'datasource') {
        this.storeSetCurentDatasourceId(this.menuItems[0].datasource_id)
        this.storeSetCurentDatasourceIndex(this.menuItems[0].datasource_index)
      }
    }
  },
  methods: {
    ...mapMutations([
      'storeSetCurrentTag',
      'storeSetCurentDatasourceId',
      'storeSetCurentDatasourceIndex',
      'storeSetProjectMenus',
      'storeSetProjectLimitDate'
    ]),
    // ...mapActions({
    //   storeSetCurentDatasourceId: 'storeSetCurentDatasourceId',
    //   storeSetCurentDatasourceIndex: 'storeSetCurentDatasourceIndex'
    // }),

    async initUserInfo () {
      // TODO เป็น Function สำหรับดึงข้อมูลของ User จาก AccessToken
      this.loading = true
      const res = await this.getUserInfo()
      this.loading = false
      if (res.status) {
        this.userInfo = res.data
        // TODO loop เพื่อเช็คว่ามีสิทเป็น Super Admin ไหม
        // TODO ซึ่ง Super Admin ของระบบ จะมี priority เป็น 0
        // TODO เมื่อ เจอ piority ที่เป็น 0 จะทำการ set ค่า 0 ลงใน priorityUser
        for (let index = 0; index < this.userInfo.permissions.length; index++) {
          if (this.userInfo.permissions[index].priority === 0) {
            this.priorityUser = this.userInfo.permissions[index].priority
          }
        }
      } else {
        this.antmessage.error(res.message)
      }
    },

    async initProjectInfo () {
      // TODO เป็น function สำหรับดึงข้อมูล project จาก project_id
      // TODO แล้วนำข้อมูลของ project ไปใส่ใน Store
      this.loading = true
      const paylod = {
        project_id: this.project_id
      }
      const res = await this.getProjectInfo(paylod)
      this.loading = false
      if (res.status) {
        this.$store.commit('setProjectID', this.project_id)
        this.$store.commit('setProjectNmae', res.data.name)
        this.$store.commit('storeSetProjectLimitDate', res.data.limit_date)
        // this.storeSetProjectLimitDate(res.data.limit_date)
      } else {
        this.antmessage.error(res.message)
      }
    },

    async setURL () {
      // TODO เป็น function สำหรับ Replace string จาก URL
      // TODO โดยจะทำการ ใส่ค่าไปใน URL
      for (let index = 0; index < this.itemsDashbord.length; index++) {
        this.itemsDashbord[index].url = await this.checkCaseReplace(
          this.itemsDashbord[index].url
        )
      }
    },

    async init () {
      // TODO เป็น Function ที่ใช้ในการ initial menu ของ Project
      // TODO ที่ user สามารถใช้งานได้ตาม permission ใน project
      this.loading = true
      const payload = {
        // user_id: this.userInfo.user_id,
        project_id: this.project_id
      }
      const res = await this.getProjectMenuFromUser(payload)
      this.loading = false
      if (res.status) {
        this.menuItems = res.data
        this.storeSetProjectMenus(JSON.parse(JSON.stringify(res.data)))

        // TODO filter menu ที่ เป็น iframe แล้วใส่ใน menuIframe
        const menuIframe = this.menuItems.filter(
          // (val) => val.type === 'iframe' && val.type === 'datasource'
          (val) =>
            val.type !== 'none' && val.type !== '' && val.type !== 'alert'
        )
        // TODO Mock menu สำหรับทดสอบ
        // this.menuItems = [
        //   ...[
        //     {
        //       create_by_system: false,
        //       description: '',
        //       icon: '',
        //       index: '',
        //       is_iframe: false,
        //       items: [],
        //       menu_id: '999',
        //       name: 'editWidget',
        //       path: 'editWidget',
        //       text: 'editWidget',
        //       url: '',
        //       type: ''
        //     }
        //   ],
        //   ...this.menuItems
        // ]

        // TODO Set menu ที่เป็น iframe ลงใน itemsDashbord
        this.itemsDashbord = menuIframe
        this.setURL()
      } else {
        this.antmessage.error(res.message)
      }
    },

    async initCurentTag () {
      const res = await this.getProjectUserTag({ project_id: this.project_id })
      if (res.status) {
        this.storeSetCurrentTag(res.data)
      } else this.storeSetCurrentTag([])
    },

    eventClick (e) {
      this.storeSetCurentDatasourceId(e.datasource_id)
      this.storeSetCurentDatasourceIndex(e.datasource_index)

      this.$EventBus.$emit('close-nav')
      // e.name --> name component same ref
      if (this.$refs[e.name]) {
        this.$refs[e.name].init()
      }
    }
  }
}
</script>

<style lang="css" scoped>
.width-tab {
  width: calc(100% - 60px);
}
/* // TODO สำหรับจัดการหน้าเพจทั้งหมด */
.main-layout {
  height: 100%;
  /* background: blue; */
}

/* // TODO สำหรับจัดการ Tab ของ Page */
.layout-tab {
  height: 48px;
  overflow: hidden;
}

/* // TODO สำหรับจัดการ Content ของเพจ */
.layout-content {
  height: 100%;
  padding: 0;
  margin: 0;
}
.header-custom-table .header-custom-search {
  display: flex;
  gap: 1rem;

  /* background: gray; */
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.header-custom-table .header-custom-actions {
  display: flex;
  gap: 1rem;
  /* background: gray; */
  align-items: center;
  height: 100%;
  min-height: 70px;

  /* justify-content: flex-end; */
}
</style>
