<template>
  <v-card outlined elevation="0">
    <v-card-text>
      <canvas
        :id="name"
        class="chart-js"
        :aria-label="name"
        role="img"
        style="width: 100%; height: 100%"
      >
      </canvas>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Untitled'
    },
    labels: {
      type: Array,
      default: () => []
    },
    datasets: {
      type: Array,
      default: () => []
    }
    // name: {
    //   type: String,
    //   default: () => {
    //     return `ukrit-${this.generateUUID()}`
    //   }
    // }
  },
  data () {
    return {
      chart: null,
      data: {
        labels: [],
        datasets: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxis: {
            ticks: {
              font: {
                size: 10,
                family: "'Kanit', sans-serif",
                weight: '300'
              },
              color: this.$vuetify.theme.currentTheme.tprimary,
              display: true
            },
            grid: {
              borderColor: this.$vuetify.theme.currentTheme.tprimary,
              display: false,
              borderWidth: 0.2
            }
          },
          yAxis: {
            ticks: {
              font: {
                size: 10,
                family: "'Kanit', sans-serif",
                weight: '300'
              },
              color: this.$vuetify.theme.currentTheme.tprimary,
              display: true
            },
            grid: {
              borderColor: this.$vuetify.theme.currentTheme.tprimary,
              display: false,
              borderWidth: 0.2
            }
          }
        },

        plugins: {
          legend: {
            labels: {
              font: {
                size: 18,
                family: "'Kanit', sans-serif",
                weight: '100',
                boxWidth: 10
              }
            },
            display: false,
            position: 'bottom',
            align: 'center'
          },
          title: {
            display: true,
            text: this.title,
            position: 'top',
            align: 'start',
            padding: {
              top: 0,
              bottom: 15
            },

            color: this.$vuetify.theme.currentTheme.primary,
            font: {
              size: 24,
              family: "'Kanit', sans-serif",
              weight: '300'
            }
          }
        }
      }
    }
  },
  computed: {
    name () {
      return `ukrit-${this.generateUUID()}`
    }
  },
  watch: {
    '$vuetify.theme.dark' () {
      this.UpdateOptionsTheme()
    }
  },
  methods: {
    UpdateOptionsTheme () {
      const color = this.$vuetify.theme.currentTheme.tprimary
      //   const color = '#757575'

      //   Edit Options Here -------------
      // Font
      this.options.scales.xAxis.ticks.color = color
      this.options.scales.yAxis.ticks.color = color
      // line
      this.options.scales.xAxis.grid.borderColor = color
      this.options.scales.yAxis.grid.borderColor = color
      //   Edit Options Here -------------

      this.chart.options = this.options
      this.chart.update()
    },
    Update () {
      this.chart.update()
    }
  },
  mounted () {
    const options = this.options
    const name = this.name
    // Mapping Data ---------------
    this.data.labels = this.labels
    this.data.datasets = this.datasets

    const data = this.data
    const ctx = document.getElementById(name).getContext('2d')
    this.chart = new this.Chart(ctx, { type: 'bar', data, options })
  }
}
</script>

<style lang="css" scoped>
.v-card {
  height: 100%;
}
</style>
