<template>
  <u-card-form
    :title="formTitle"
    :subtitle="formSubTitle"
    ref="form"
    v-model="dialog"
  >
    <template v-slot:form>
      <v-row>
        <!--  TODO Title -->
        <v-col cols="12" class="pa-0 pr-1">
          <u-text-field
            title="Title"
            placeholder="Title of Alert"
            required
            v-model="item.title"
            :rules="[Rule.noInPut]"
          >
          </u-text-field>
        </v-col>

        <!-- TODO rule mode -->
        <v-col cols="12" md="6" class="pa-0 pr-1">
          <u-selector
            title="Alert Mode"
            placeholder="Mode of Alert"
            required
            v-model="item.rule_mode"
            item-value="value"
            item-text="name"
            :items="alertModeItems"
            :rules="[Rule.noInPut, Rule.noSpace, Rule.englishOnly]"
          />
        </v-col>

        <!-- TODO Scheduled -->
        <v-col cols="12" md="6" class="pa-0">
          <u-text-field
            title="Scheduled ( Cron format )"
            placeholder="Scheduled of Alert"
            required
            v-model="item.scheduled"
            :rules="[Rule.noInPut, Rule.cronFormat]"
          >
          </u-text-field>
        </v-col>
        <v-col
          cols="12"
          md="12"
          class="pa-0"
          v-if="['rate'].includes(item.rule_mode)"
        >
          <u-text-field
            title="Rate Number of Alert"
            placeholder="Rate Number of Alert"
            required
            v-model="item.rate"
            :rules="[Rule.noInPut, Rule.numberOnly]"
          >
          </u-text-field>
        </v-col>

        <!-- TODO Menu Active -->
        <v-col cols="12" class="px-0 pb-0">
          <TitleText
            title="Active Menu"
            subtitle="เลือก Menu ที่ต้องการ Alert"
            icon="mdi-menu"
          >
          </TitleText>
        </v-col>
        <!-- {{ alertMenuItems }} -->
        <!-- {{ item }} -->
        <!-- <v-col cols="12" class="pa-0">
          <u-selector
            title="Active Device"
            placeholder="Active Menu of Alert"
            required
            item-value="menu_id"
            item-text="name"
            :items="alertMenuItems"
            :rules="[Rule.noInPut]"
            v-model="item.menus"
            multiple
          />
        </v-col> -->

        <!-- <v-col cols="12" class="px-0 pb-0">
          <TitleText
            title="Rules Alert"
            subtitle="เลือก Menu ที่ต้องการ Alert"
            icon="mdi-list-status"
          ></TitleText>
        </v-col> -->

        <v-col
          cols="12"
          class="pa-0 mb-2"
          v-for="(i, idx) in item.rules"
          :key="`rule-${idx}`"
        >
          <div
            class="pa-2"
            style="
              width: 100%;
              height: 100%;
              border-radius: 4px;
              border: 1px solid grey;
            "
          >
            <!-- {{ i.menu_id }}| {{ alertMenuItems.map(e=> e.menu_id) }} -->
            <v-row style="width: 100%" class="ma-0">
              <v-col cols="12" class="pa-0">
                <u-selector
                  title="Active Device"
                  placeholder="Active Menu of Alert"
                  required
                  item-value="menu_id"
                  item-text="name"
                  :items="alertMenuItems"
                  :rules="[Rule.noInPut]"
                  v-model="i.menu_id"
                />
              </v-col>

              <v-col cols="12" class="pa-0">
                <!-- {{
                  alertMenuItems.filter((e) => e.menu_id == i.menu_id)[0]
                    ?.list_field
                }} -->
                <u-selector
                  title="Time Field"
                  placeholder="Time field of document to Alert"
                  required
                  item-value="text"
                  item-text="text"
                  :items="
                    alertMenuItems
                      .filter((e) => e.menu_id == i.menu_id)[0]
                      ?.list_field.filter((e) => e.type == 'date')
                  "
                  :rules="[Rule.noInPut]"
                  v-model="i.time_field"
                />
              </v-col>

              <v-col
                cols="12"
                class="pa-0"
                v-if="['rate'].includes(item.rule_mode)"
              >
                <!-- {{
                  alertMenuItems.filter((e) => e.menu_id == i.menu_id)[0]
                    ?.list_field
                }} -->
                <u-selector
                  title="Groups Field"
                  placeholder="Time field of document to Alert"
                  item-value="text"
                  item-text="field"
                  multiple
                  return-object
                  :items="
                    getFieldList(i.menu_id).map((e) => {
                      return { field: e.value, type: e.type };
                    })
                  "
                  v-model="i.group_fields"
                />
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-row style="width: 100%" class="ma-0">
                  <v-col
                    cols="12"
                    class="pa-0 mb-2"
                    v-for="(j, idj) in i.rules"
                    :key="`rule-rules-${idj}`"
                  >
                    <div
                      class="pa-2"
                      style="
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                        border: 1px solid grey;
                      "
                    >
                      <v-row style="width: 100%" class="ma-0">
                        <v-col cols="12" class="pa-0">
                          <u-selector
                            title="Field Data"
                            placeholder="Field Data Match regex to Alert"
                            required
                            v-model="j.field"
                            item-value="full_value"
                            item-text="value"
                            :items="getFieldList(i.menu_id)"
                            :rules="[
                              Rule.noInPut,
                              Rule.noSpace,
                              Rule.englishOnly,
                            ]"
                          />
                        </v-col>
                        <v-col cols="12" class="pa-0 mb-5">
                          <u-text-area
                            title="Rule ( Regular Expression )"
                            placeholder="Rule"
                            required
                            v-model="j.regex"
                            :rules="[Rule.noInPut]"
                          >
                          </u-text-area>
                        </v-col>
                        <v-col cols="12" class="px-0">
                          <v-row class="ma-0 pa-0" justify="end" align="center">
                            <v-col
                              class="pa-0"
                              cols="6"
                              md="1"
                              style="text-align: end"
                            >
                              <v-btn
                                color="error"
                                fab
                                x-small
                                @click="removeActiveMenuRules(idx, j)"
                                ><v-icon>mdi-minus</v-icon></v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-col cols="12" class="px-0" style="text-align: end">
                  <v-btn
                    color="success"
                    fab
                    x-small
                    @click="addActiveMenuRules(idx)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" class="px-0" style="text-align: end">
                  <v-btn color="error" fab x-small @click="removeActiveMenu(i)">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </v-col>

              <!-- TODO Rule -->
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" class="px-0" style="text-align: end">
          <v-btn color="success" fab x-small @click="addActiveMenu">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>

        <!-- TODO Loop To Print Alert Methods -->
        <v-col cols="12" class="px-0 pb-0">
          <TitleText
            title="Trigger Alert"
            subtitle="ตั่งค่าส่วนที่ใช้ในการ Alert"
            icon="mdi-alert-outline"
          ></TitleText>
        </v-col>
        <v-col
          cols="12"
          class="pa-0 mb-2"
          v-for="(i, idx) in item.alert_methods"
          :key="`method-${idx}`"
        >
          <div
            class="pa-2"
            style="
              width: 100%;
              height: 100%;
              border-radius: 4px;
              border: 1px solid grey;
            "
          >
            <v-row style="width: 100%" class="ma-0">
              <v-col cols="12" class="pa-0">
                <u-selector
                  title="Trigger Method"
                  placeholder="Trigger Method of Alert"
                  required
                  item-value="value"
                  item-text="name"
                  :items="alertTriggerItems"
                  :rules="[Rule.noInPut]"
                  v-model="i.method"
                >
                </u-selector>
              </v-col>
              <v-col cols="12" md="12" class="pa-0">
                <u-text-field
                  title="Endpoint ( https://example.com )"
                  placeholder="URI Endpoint of Alert Method"
                  required
                  v-model="i.endpoint"
                  :rules="[Rule.noInPut]"
                >
                </u-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
                class="pa-0"
                v-if="['line_notify', 'rest', 'email'].includes(i.method)"
              >
                <u-text-field
                  title="Authorization"
                  placeholder="Authorization of Alert Method"
                  v-model="i.authorization"
                >
                </u-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
                class="pa-0"
                v-if="['email'].includes(i.method)"
              >
                <u-text-field
                  title="From"
                  placeholder="Send E-mail from"
                  v-model="i.email_from"
                  :rules="[Rule.noInPut, Rule.emailOnly]"
                >
                </u-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
                class="pa-0"
                v-if="['email'].includes(i.method)"
              >
                <u-text-field
                  title="To"
                  placeholder="Send E-mail to"
                  v-model="i.email_to"
                  :rules="[Rule.noInPut, Rule.emailOnly]"
                >
                </u-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
                class="pa-0"
                v-if="['email'].includes(i.method)"
              >
                <u-text-field
                  title="Subject"
                  placeholder="Subject E-mail"
                  v-model="i.email_subject"
                >
                </u-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
                class="pa-0"
                v-if="['email'].includes(i.method)"
              >
                <v-checkbox v-model="i.html_format" label="Payload HTML Format">
                </v-checkbox>
              </v-col>

              <v-col cols="12" class="pa-0">
                <u-text-area
                  title="Message ( Payload to Methods )"
                  placeholder="Message"
                  required
                  :rules="[Rule.noInPut]"
                  v-model="i.payload"
                >
                </u-text-area>
              </v-col>
              <v-col cols="12" class="px-0">
                <v-row class="ma-0 pa-0" justify="end" align="center">
                  <v-col class="pa-0" cols="6" md="1" style="text-align: end">
                    <v-btn fab x-small color="warning"
                      ><v-icon>mdi-message-arrow-right-outline</v-icon></v-btn
                    >
                  </v-col>
                  <v-col class="pa-0" cols="6" md="1" style="text-align: end">
                    <v-btn color="error" fab x-small @click="removeMethod(i)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" class="px-0" style="text-align: end">
          <v-btn color="success" fab x-small @click="addMethod"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </template>

    <!--  TODO Actions -->
    <template v-slot:actions="{ valid }">
      <v-row>
        <v-col>
          <v-btn
            :disabled="!valid"
            block
            color="primary"
            :loading="loading"
            @click="$emit('save', item)"
          >
            บันทึก
          </v-btn>
        </v-col>
        <v-col>
          <v-btn block color="error" @click="close">ยกเลิก </v-btn>
        </v-col>
      </v-row>
    </template>
  </u-card-form>
</template>

<script>
// import LineSeparator from '@/components/LineSeparator.vue'
import TitleText from '@/components/TitleText.vue'
export default {
  components: {
    // LineSeparator
    TitleText
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    formTitle: {
      type: String,
      default: ''
    },
    formSubTitle: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      defautl: () => {
        return {
          rule_id: '',
          rule_mode: '',
          title: '',
          rule: '',
          scheduled: '',
          rate: 0,
          description: '',
          black_list_ips: [],
          alert_methods: [],
          ststus: false,
          create_date: '',
          update_date: '',
          create_by: '',
          update_by: ''
        }
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    alertModeItems: {
      type: Array,
      default: () => {
        return [
          { name: 'Normal Alert', value: 'normal' },
          { name: 'Rate Alert', value: 'rate' }
          // { name: 'Black List Alert', value: 'black_list' }
        ]
      }
    },
    alertMenuItems: {
      type: Array,
      default: () => {
        return [
          // {
          //   menu_id: '',
          //   datasource_id: '',
          //   datasource_index: '',
          //   text: '',
          //   name: '',
          //   path: ''
          // field_list : []
          // }
        ]
      }
    },
    alertTriggerItems: {
      type: Array,
      default: () => {
        return [
          { name: 'Rest', value: 'rest' },
          { name: 'Line Notify', value: 'line_notify' },
          { name: 'Discord', value: 'discord' },
          { name: 'Email', value: 'email' }
        ]
      }
    }
  },

  computed: {
    dialog: {
      set (val) {
        this.$emit('input', val)
        return val
      },
      get () {
        return this.value
      }
    },
    item: {
      set (val) {
        this.$emit('update:data', val)
      },
      get () {
        return this.data
      }
    }
  },
  methods: {
    getFieldList (menuId) {
      return this.alertMenuItems
        .filter((e) => e.menu_id === menuId)[0]
        ?.list_field.map((e2) => {
          return {
            ...e2,
            ...{
              full_value: e2.type === 'text' ? `${e2.value}.keyword` : e2.value
            }
          }
        })
    },
    close () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.$emit('close')
    },
    addMethod () {
      this.item.alert_methods.push({
        method: '',
        authorization: '',
        payload: '',
        endpoint: '',
        ststus: true
      })
    },
    removeMethod (val) {
      const index = this.item.alert_methods.indexOf(val)
      if (index > -1) {
        // only splice array when item is found
        this.item.alert_methods.splice(index, 1) // 2nd parameter means remove one item only
      }
    },
    addActiveMenu () {
      this.item.rules.push({
        menu_id: '',
        time_field: '',
        rules: []
      })
    },
    removeActiveMenu (val) {
      const index = this.item.rules.indexOf(val)
      if (index > -1) {
        // only splice array when item is found
        this.item.rules.splice(index, 1) // 2nd parameter means remove one item only
      }
    },
    addActiveMenuRules (idx) {
      this.item.rules[idx].rules.push({
        field: '',
        regex: ''
      })
    },
    removeActiveMenuRules (idx, val) {
      const index = this.item.rules[idx].rules.indexOf(val)
      if (index > -1) {
        // only splice array when item is found
        this.item.rules[idx].rules.splice(index, 1) // 2nd parameter means remove one item only
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
