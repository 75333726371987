import Vue from 'vue'
import { Centrifuge } from 'centrifuge'

export default {
  install: (app, options) => {
    // default option
    const defaultOptions = {
      host: '',
      channels: []
    }
    let EventBus = null
    let client = null
    const subs = []

    // delivery event to componnet with eventbus
    EventBus = new Vue()
    app.prototype.$websocket = EventBus

    // merge default options with arg options
    const userOptions = { ...defaultOptions, ...options }

    app.prototype.$websocket.Connect = (token = '') => {
      if (token.length) {
        // console.log(JSON.parse(atob(token.split('.')[1])).sub)
        client = new Centrifuge(userOptions.host, { token: token })

        client.on('connecting', (ctx) => {
          EventBus.$emit('connecting', ctx)
          // console.log(`connecting: ${ctx.code}, ${ctx.reason}`)
        })

        client.on('connected', (ctx) => {
          EventBus.$emit('connected', ctx)
          // console.log(`connected over ${ctx.transport}`)
        })

        client.on('disconnected', (ctx) => {
          EventBus.$emit('disconnected', ctx)
          // console.log(`disconnected: ${ctx.code}, ${ctx.reason}`)
        })

        client.connect()

        // for (const item of userOptions.channels) {
        // NOTE ใช้ในการ Subscribe Channel ที่เราต้องการข้อมูล
        const channel = JSON.parse(atob(token.split('.')[1])).sub
        const sub = client.newSubscription(channel)

        sub.on('publication', (ctx) => {
          // NOTE check action to event name
          if (!('action' in ctx.data)) {
            return console.warn('not found action in paload ws')
          }

          // NOTE check data to payload data
          if (!('data' in ctx.data)) {
            return console.warn('not found data in paload ws')
          }
          // NOTE Push Event to delivery data to component
          EventBus.$emit(ctx.data.action, ctx.data.data)
        })

        sub.on('subscribing', function (ctx) {
          EventBus.$emit('subscribing', ctx)
          // console.log(`subscribing: ${ctx.code}, ${ctx.reason}`)
        })
        sub.on('subscribed', function (ctx) {
          EventBus.$emit('subscribed', ctx)
          console.info('subscribed :', ctx.channel)
        })
        sub.on('unsubscribed', function (ctx) {
          EventBus.$emit('unsubscribed', ctx)
          // console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`)
        })
        sub.subscribe()
        subs.push(sub)
        // }
      } else console.error('not found token')
    }

    app.prototype.$websocket.Disconnect = () => {
      console.info('disconnect : websocket and clear event')
      EventBus.$off()
      for (const sub of subs) {
        sub.unsubscribe()
      }
      if (client != null) {
        client.disconnect()
      }
    }
  }
}
