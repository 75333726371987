<template>
  <div class="fieldset">
    <h1 class="legend">{{title}}</h1>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Untitled'
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fieldset {
  border: solid;
  border-width: 1px;
  // color: #353fff;
  border-radius: 4px;
  // margin: 2em 1em 1em;
  padding: 0 1em 0em;
  // padding: inherit;
  margin: inherit;
  border-color: inherit;
  margin-bottom:14px;
}

.legend {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  transform: translatey(-50%);
  width: max-content;
  background: white;
  padding: 0 0.15em;
}

.fieldset li {
  list-style-type: " - ";
}
</style>
