<template>
  <div class="main-layout">
    <div class="layout-title">
      <u-title-page :title="NameComponent" />
    </div>
    <div class="layout-content">
      <u-table
        :headers="headers"
        :items="items"
        :mobileBreakpoint="900"
        :showSelect="false"
        itemKey="permission_id"
        :pagination="pagination"
        @onChangePagination="onChangePagination"
        :loading="loading"
      >
        <template v-slot:header>
          <v-row
            justify="space-between"
            align="center"
            class="header-custom-table"
          >
            <!-- // # Action Group -->
            <v-col cols="12" md="4">
              <v-row justify="start" align="center">
                <v-col cols="12" md="6">
                  <v-btn color="primary" block dark @click="dialog = !dialog">
                    <v-icon>mdi-home-plus</v-icon>เพิ่ม{{ NameComponent }}
                  </v-btn></v-col
                >
              </v-row>
            </v-col>
            <!-- // # Search Group -->
            <v-col cols="12" md="4">
              <v-row justify="center" align="center">
                <v-col
                  ><v-text-field
                    v-model="keyword"
                    name="name"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    placeholder="ค้นหา"
                    label="ค้นหา"
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col cols="4">
                  <v-btn color="primary" block dark @click="init()"
                    >ค้นหา</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.number`]="{ item }">
          {{
            pagination.row * pagination.page -
            pagination.row +
            (items.indexOf(item) + 1)
          }}
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <span class="fix-overflow-ellipsis">
            {{
              item.description !== "" && item.description !== null
                ? item.description
                : "-"
            }}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="action-nowrap">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  color="primary"
                  @click="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>แก้ไขข้อมูล</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  fab
                  x-small
                  color="error"
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete-forever</v-icon>
                </v-btn>
              </template>
              <span>ลบ</span>
            </v-tooltip>
          </div>
        </template>
      </u-table>
    </div>

    <!-- // # Form Add/Edit -->
    <u-card-form
      :title="formTitle"
      :subtitle="formSubTitle"
      ref="form"
      v-model="dialog"
    >
      <template v-slot:form>
        <v-row no-gutters>
          <v-col cols="12">
            <u-text-field
              required
              title="ชื่อ Datasource"
              v-model="editedItem.name"
              :rules="[Rule.noInPut, Rule.noSpace]"
            ></u-text-field>
          </v-col>
          <v-col cols="12">
            <u-selector
              required
              v-model="editedItem.type"
              title="ชนิดของ Datasource"
              :items="datasourceType"
              item-text="name"
              item-value="value"
              :rules="[Rule.noInPut]"
            ></u-selector>
          </v-col>
          <v-col cols="12">
            <u-text-field
              required
              title="URI"
              v-model="editedItem.uri"
              :rules="[Rule.noInPut, Rule.noSpace]"
            ></u-text-field>
          </v-col>

          <v-col cols="12">
            <u-text-field
              title="Authorization"
              v-model="editedItem.authorization"
            ></u-text-field>
          </v-col>

          <v-col cols="12">
            <u-text-area
              title="รายละเอียด"
              v-model="editedItem.description"
            ></u-text-area>
          </v-col>
        </v-row>
      </template>

      <template v-slot:actions="{ valid }">
        <v-row>
          <v-col>
            <v-btn
              @click="save"
              :disabled="!valid"
              block
              color="primary"
              :loading="loadingSave"
            >
              บันทึก
            </v-btn>
          </v-col>
          <v-col>
            <v-btn block color="error" @click="close">ยกเลิก</v-btn>
          </v-col>
        </v-row>
      </template>
    </u-card-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      NameComponent: 'Datasource', //  ชื่อของ Modal
      dialog: false, // สำหรับใช้เปิดปิด dialog add/edit
      editedIndex: -1,
      editedItem: {
        datasource_id: '',
        name: '',
        uri: '',
        type: '',
        authorization: '',
        description: ''
      },
      defaultItem: {
        datasource_id: '',
        name: '',
        uri: '',
        type: '',
        authorization: '',
        description: ''
      },
      keyword: '',
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number',
          width: '100px'
        },
        {
          text: 'ชื่อ',
          align: 'start',
          value: 'name'
        },
        {
          text: 'ชนิด',
          align: 'center',
          value: 'type'
        },
        {
          text: 'รายละเอียด',
          value: 'description',
          align: 'start',
          sortable: false,
          width: '30%'
        },

        {
          text: 'จัดการ',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '150px'
        }
      ],

      datasourceType: [{ name: 'Elasticsearch', value: 'elasticsearch' }], // NOTE สำหรับเก็บข้อมูล Data Source ที่รองรับ
      items: [],
      pagination: {
        total: 0,
        page: 1,
        row: 10
      },
      loading: false,
      loadingSave: false,
      menus: []
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? `เพิ่ม${this.NameComponent}`
        : `แก้ไข${this.NameComponent}`
    },
    formSubTitle () {
      return this.editedIndex === -1
        ? `กรอกข้อมูลเพื่อสร้าง${this.NameComponent}`
        : `กรอกข้อมูลเพื่อแก้ไข${this.NameComponent}`
    }
  },
  created () {
    this.init()
  },

  methods: {
    async init () {
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        page: this.pagination.page,
        row: this.pagination.row,
        keyword: this.keyword
      }
      const res = await this.getDataSourceForTable(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination = res.data.pagination
      } else {
        this.antmessage.error(res.message)
      }
    },

    onChangePagination (val) {
      this.pagination.page = val.page
      this.pagination.row = val.row
      this.init()
    },

    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.$swal({
        allowEnterKey: false,
        title: `ลบ${this.NameComponent}`,
        text: `คุณต้องการลบ${this.NameComponent}นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = { datasource_id: item.datasource_id }
          const res = await this.deleteDatasource(payload)
          this.antmessage.destroy()
          if (res.status) {
            this.init()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `ลบ${this.NameComponent}สำเร็จ`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },

    close () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },

    save () {
      if (this.editedIndex > -1) {
        // TODO Update Data
        this.$swal({
          allowEnterKey: false,
          title: `แก้ไข${this.NameComponent}`,
          text: `คุณต้องการแก้ไข${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = this.editedItem
            const res = await this.updateDatasource(payload)
            this.loadingSave = false
            if (res.status) {
              this.init()
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `แก้ไข${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      } else {
        // TODO Create Data
        this.$swal({
          allowEnterKey: false,
          title: `เพิ่ม${this.NameComponent}`,
          text: `คุณต้องการเพิ่ม${this.NameComponent}นี้ใช่หรือไม่`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ตกลง'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSave = true
            const payload = this.editedItem
            const res = await this.createDatasource(payload)
            this.loadingSave = false
            if (res.status) {
              this.$swal({
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: `เพิ่ม${this.NameComponent}สำเร็จ`,
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000
              })
              this.init()
              this.close()
            } else {
              this.antmessage.error(res.message)
            }
          }
        })
      }
    }
    // getMenu (menuId) {
    //   const x = this.menus.filter((i) => i.menu_id === menuId)
    //   if (x.length > 0) return x[0].text
    //   else return menuId
    // }
  }
}
</script>

<style lang="css" scoped>
/* สำหรับจัดการหน้าเพจทั้งหมด */
.main-layout {
  height: 100%;
}

/* สำหรับ จัดการ title ของเพจ */
.layout-title {
  height: 58px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* สำหรับจัดการ Content ของเพจ */
.layout-content {
  height: calc(100% - 58px) !important;
  padding: 0;
  margin: 0;
}

.header-custom-table .header-custom-search {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.header-custom-table .header-custom-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
  min-height: 70px;
}
</style>
