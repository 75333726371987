import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import en from '../locale/en.ts'
import th from '../locale/th.ts'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { en, th },
    current: 'th'
  },
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#0075FF',
        secondary: '#b0bec5',
        third: '#0075FF',
        tableBgHeader: '#ECF5FF',
        tableCheckBox: '#0075FF',
        accent: '#8c9eff',

        error: '#FF1744',
        warning: '#F57C00',
        success: '#4CAF50',
        disable: '#C6C6C6',

        tprimary: '#4b4b4b',

        bgPrimary: '#ffffff',

        gradient1: '#786cff',
        gradient2: '#7dd8ff',
        gradient3: '#e8d8ff',
        gradient4: '#e0f6ff',
        gradient5: '#C5EDFF',
        gradient6: '#D3D1FF',

        swbg: '#eeeded',

        bgBtnLandingPage: '#EBF0FF',
        textLandingPage: '#1C3D70',
        contentTextLandingPage: '#4C4C4C',
        shadow: '#2b3b66',
        bgLandingPageMenu: '#F3F6FF',
        iconLandingPage: '#0C2E8F',
        footerLandingPage: '#EBF0FF',

        // BTN component
        bgBtnGoogle: '#51a1ff',
        bgBtnOneID: '#51a1ff',
        bgImgBtnComponent: '#EBF0FF',
        btnDisable: '#515151'

      },
      dark: {
        primary: '#0075FF',
        secondary: '#b0bec5',
        third: '#0075FF',
        tableBgHeader: '#0075FF',
        tableCheckBox: '#ffffff',
        accent: '#8c9eff',

        error: '#FF1744',
        warning: '#F57C00',
        success: '#4CAF50',
        disable: '#C6C6C6',

        tprimary: '#ffffff',

        bgPrimary: '#1f1f1f',

        gradient1: '#786cff',
        gradient2: '#7dd8ff',
        gradient3: '#121212',
        gradient4: '#121212',
        gradient5: '#C5EDFF',
        gradient6: '#D3D1FF',

        swbg: '#848484',

        bgBtnLandingPage: '#848484',
        iconLandingPage: '#0C2E8F',
        textLandingPage: '#f4f8ff',
        contentTextLandingPage: '#4C4C4C',
        shadow: '#2b3b66',
        // BTN component
        bgBtnGoogle: '#404040',
        bgBtnOneID: '#404040',
        bgImgBtnComponent: '#EBF0FF',
        btnDisable: '#515151'
      }
    }
  }
})
