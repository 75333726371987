<template>
  <div class="main-layout">
    <div class="layout-tab d-flex">
      <div class="width-tab">
        <u-tab v-model="menu" :items="menuItems"></u-tab>
      </div>
    </div>
    <div class="layout-content">
      <u-tab-items v-model="menu">
        <template v-slot:menuAlertConfig>
          <AlertConfigs :projectId="project_id" />
        </template>
        <template v-slot:menuAlertHistory>
          <AlertHistories :projectId="project_id" />
        </template>
      </u-tab-items>
    </div>
  </div>
</template>

<script>
import AlertConfigs from '@/views/HomePages/ProjectPages/AlertConfigs.vue'
import AlertHistories from '@/views/HomePages/ProjectPages/AlertHistories.vue'
export default {
  components: {
    AlertConfigs,
    AlertHistories
  },
  props: {
    project_id: {
      type: String
    }
  },
  data () {
    return {
      NameComponent: 'สิทธิ',
      menuItems: [
        { name: 'menuAlertConfig', text: 'Configs', description: 'Configs' },
        {
          name: 'menuAlertHistory',
          text: 'Histories',
          description: 'Histories'
        }
      ],
      menu: ''
    }
  },
  methods: {
    init () {}
  }
}
</script>

<style lang="scss" scoped>
.main-layout {
  height: 100%;
}
.layout-content {
  height: 100%;
  padding: 0;
  margin: 0;
  // background: red;
}

.width-tab {
  width: calc(100%);
  // background: green;
}
</style>
