<template>
  <div class="main-login-page">
    <!-- TODO NEW VERSION  -->

    <oneauthenLogin
      v-if="host == storeGetOneauthHost"
      ref="formSignIn"
      version="1.0.0"
      :loading="loading"
      @click:login="validateLogin"
       @click:providerid="SignInProviderIdAccount"
      @click:oneid="SignInOneIDAccount"
      @click:forget="dialogForget = true"
      @click:register="$router.push({ name: 'registerPage' })"
    >
    </oneauthenLogin>

    <manageaiLogin
      v-else
      ref="formSignIn"
      :loading="loading"
      @click:login="validateLogin"
      @click:providerid="SignInProviderIdAccount"
      @click:oneid="SignInOneIDAccount"
      @click:forget="dialogForget = true"
      @click:register="$router.push({ name: 'registerPage' })"
    >
    </manageaiLogin>
    <!-- TODO NEW VERSION  -->
    <DialogAnnounce :announce="announce" v-model="dialogCarousel" />
    <DialogForgetPassword
      ref="resetpassword"
      v-model="dialogForget"
      @emailUser="resetPassword"
    />
    <DialogConfirm
      :value="dialogForSave"
      :title="titleDialogConfirm.formTitle"
      :subtitle="titleDialogConfirm.subtitle"
      :colorForMainTitle="titleDialogConfirm.colorForTitle"
      :imageDialog="require('../../public/img/createAccountSuccess.png')"
    >
      <!--  TODO Actions -->
      <template v-slot:actionsCloseBtn>
        <div class="btn-close">
          <v-icon @click="dialogForSave = false">mdi-close</v-icon>
        </div>
      </template>
    </DialogConfirm>

    <!-- <oneauthenLogin :v-if="true"></oneauthenLogin> -->
  </div>
</template>

<script>
import DialogAnnounce from '../views/../components/Forms/DialogAnnounce.vue'
import DialogForgetPassword from '../views/../components/Forms/DialogForgetPassword.vue'
// import OauthBtn from '@/components/Button/OauthBtn.vue'
import oneauthenLogin from '@/components/Login/oneauthen.vue'
import manageaiLogin from '@/components/Login/manageai.vue'
import DialogConfirm from '@/components/Forms/DialogConfirm.vue'
import { mapGetters } from 'vuex'
// import CryptoJS from 'crypto-js'
export default {
  components: {
    DialogAnnounce,
    DialogForgetPassword,
    // OauthBtn
    oneauthenLogin,
    manageaiLogin,
    DialogConfirm
  },
  computed: {
    ...mapGetters(['storeGetOneauthHost'])
  },
  data () {
    return {
      host: '',
      mailUser: '',
      dialogCarousel: false,
      dialogForget: false,
      dialogForSave: false,
      resetEmail: [],
      textError: false,
      textFieldError: false,
      loading: false,
      signInData: {
        username: '',
        password: '',
        show: false
      },
      valid: false,
      firstname: '',
      lastname: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 10 || 'Name must be less than 10 characters'
      ],
      email: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      announce: [],
      signatureKey: '',
      dataEncryptionAES: '',
      accessTokenToLogin: '',
      titleDialogConfirm: {
        formTitle: 'สำเร็จ',
        subtitle: 'กรุณาตรวจเช็คอีเมลเพื่อเปลี่ยนรหัสผ่านในอีเมลของท่าน',
        colorForTitle: 'green'
      }
    }
  },
  async created () {
    // TODO ใช้สำหรับการดึงค่า hostname เพื่อใช้ในการเลือกเปิด หน้า Login
    this.host = window.location.hostname

    // console.log(this.$route.params.provider)
    if (this.$route.params.provider === 'providerid') {
      if (this.$route.query.code !== undefined) {
        this.loading = true

        const q = {
          ...{
            action: '',
            invite_id: ''
            // project_id: this.$route.query.project_id,
            // permission_id: this.$route.query.permission_id
          },
          ...this.$route.query
        }
        let p = { project_id: '', permission_id: '' }
        if (this.$route.query.invite_id && this.$route.query.action) {
          p = { ...p, ...JSON.parse(atob(this.$route.query.invite_id)) }
        }

        const payload = {
          authorization_code: this.$route.query.code,
          redirect_uri: this.addQueryString(`${window.location.origin}${process.env.VUE_APP_BASE_URL}login/providerid`, q),
          action: this.$route.query.action || '',
          project_id: p.project_id || '',
          permission_id: p.permission_id || ''
        }
        this.$router.replace({ query: null }).catch(() => {})
        const res = await this.signInProvidAuthorizationCode(payload)
        this.loading = false
        if (res.code === 200) {
          if (res.status === true) {
            localStorage.setItem('Accesstoken', res.data.accesstoken)
            this.$router.push({ name: 'homePage' }).catch(() => {})
          }
          if (res.status === false) {
            this.textError = true
            this.textFieldError = true
          }
        } else {
          this.antmessage.error(res.message)
        }
      }
    }
    if (this.$route.params.provider === 'oneid') {
      if (this.$route.query.code !== undefined) {
        this.loading = true

        // TODOเพื่อแก้ปัญหาไม่สามารถส่งข้อมูลผ่าน Query Sreing ของ OneID
        let inviteId = ''
        let action = ''
        let p = { project_id: '', permission_id: '' }
        if (localStorage.getItem('action') && localStorage.getItem('invite_id')) {
          inviteId = localStorage.getItem('invite_id')
          action = localStorage.getItem('action')
          p = { ...p, ...JSON.parse(atob(inviteId)) }
        }

        const payload = {
          authorization_code: this.$route.query.code,
          redirect_uri: `${window.location.origin}${process.env.VUE_APP_BASE_URL}login/oneid`,
          action: action || '',
          project_id: p.project_id || '',
          permission_id: p.permission_id || ''
        }

        this.$router.replace({ query: null }).catch(() => {})
        localStorage.clear()
        const res = await this.signInOneIDAuthorizationCode(payload)
        this.loading = false
        if (res.code === 200) {
          if (res.status === true) {
            localStorage.setItem('Accesstoken', res.data.accesstoken)
            this.$router.push({ name: 'homePage' }).catch(() => {})
          }
          if (res.status === false) {
            this.textError = true
            this.textFieldError = true
          }
        } else {
          this.antmessage.error(res.message)
        }
      }
    }

    this.initAnnouncerToday()
  },
  mounted () {
    setTimeout(() => {
      this.$vuetify.theme.dark = localStorage.getItem('darkMode') === 'true'
    }, 200)
  },
  methods: {
    async initAnnouncerToday () {
      const res = await this.getAnnouncerToday()
      if (res.status) {
        this.announce = res.data
        if (this.announce.length !== 0) {
          this.dialogCarousel = true
        }
      } else {
        this.antmessage.error(res.message)
      }
    },
    // ของเดิม
    async validateLogin (signInData) {
      if (this.$refs.formSignIn.validate()) {
        this.loading = true
        await this.getAESSignatureForLogin(signInData)
        // if (this.accessTokenToLogin != null) {
        const payload = {
          username: this.dataEncryptionAES.username,
          password: this.dataEncryptionAES.password
        }
        const res = await this.signIn(payload)
        this.loading = false
        if (res.code === 200) {
          if (res.status === true) {
            localStorage.setItem('Accesstoken', res.data.accesstoken)
            this.$router
              .push({
                name: 'homePage'
              })
              .catch(() => {})
          }
          if (res.status === false) {
            // this.textError = true
            // this.textFieldError = true
            this.antmessage.error('ผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
          }
        } else {
          this.antmessage.error(res.message)
        }
        // }
      }
    },
    async getAESSignatureForLogin (val) {
      // ยิง APi ไปเอา AESSignature
      this.dataEncryptionAES = {}
      const res = await this.GetAESSignature()
      if (res.status) {
        this.signatureKey = res.data.signature //
        const usernameEncryptionAES = this.AESEncryption(
          this.signatureKey,
          val.username
        )
        const passwordEncryptionAES = this.AESEncryption(
          this.signatureKey,
          val.password
        )
        this.dataEncryptionAES = {
          username: `encryption-${usernameEncryptionAES}-encryption`,
          password: `encryption-${passwordEncryptionAES}-encryption`
        }
      } else {
        this.antmessage.error(res.message)
      }
      // บวก UserName + Password
      // *username และ Password สามารส่งใน Format “encryption-AES(username)-encryption”
      // ทำการ Encryption
      // const usernameEncryptionAES = this.EncryptionAES(this.signatureKey, usernameFormatEncrytion)
      // const passwordEncryptionAES = this.EncryptionAES(this.signatureKey, passwordFormatEncrytion)

      // console.log('Encryption : ' + dataFromEncryptionAES)
      // console.log('DecryptionAES : ' + this.DecryptionAES(this.signatureKey, dataFromEncryptionAES))

      // ทำการ ส่ง Data ที่ ทำการ Encryption ไปApi ได้ accesstoken กลับมา
      // console.log('usernameEncryptionAES : ' + usernameEncryptionAES)
      // console.log('passwordEncryptionAES : ' + passwordEncryptionAES)
      // this.accessTokenToLogin = dataFromEncryptionAES
    },
    async resetPassword (val) {
      // Swal.fire("Good job!", "You clicked the button!", "success");
      const payload = {
        email: val
      }
      // const res = await this.sendEmailresetPasswordUser(payload)
      await this.sendEmailresetPasswordUser(payload)
      // if (res.status) {
      // เปิด dialog confirm
      this.dialogForSave = true
      // this.$swal({
      //   allowEnterKey: false,
      //   title: 'สำเร็จ',
      //   text: 'กรุณาตรวจเช็คอีเมลเพื่อเปลี่ยนรหัสผ่านในอีเมลของท่าน',
      //   icon: 'success',
      //   showConfirmButton: false,
      //   showCancelButton: false,
      //   timer: 3000
      // })
      this.dialogForget = false
      this.$refs.resetpassword.reset()
      // }
      // else {
      //   if (res.message === 'email is not exists') {
      //     this.$swal({
      //       allowEnterKey: false,
      //       title: 'ไม่สำเร็จ',
      //       text: 'ข้อมูลไม่ถูกต้องกรุณาเช็คอีเมล',
      //       icon: 'error',
      //       showConfirmButton: false,
      //       timer: 3000
      //     })
      //   } else {
      //     this.antmessage.error(res.message)
      //   }
      // }
    },

    async SignInProviderIdAccount () {
      // TODO Replace Url and Reload
      const res = await this.getProviderIDCredential()
      const clientID = res.data.client_id
      const healthId = res.data.url

      const q = {
        ...{
          action: '',
          invite_id: ''
          // project_id: this.$route.query.project_id,
          // permission_id: this.$route.query.permission_id
        },
        ...this.$route.query
      }
      // const redirectUri = `${window.location.origin}${process.env.VUE_APP_BASE_URL}login/providerid`
      const redirectUri = this.addQueryString(
        `${window.location.origin}${process.env.VUE_APP_BASE_URL}login/providerid`,
        q
      )
      // const url = `${process.env.VUE_APP_PROVIDER_ID_URL}/oauth/redirect?client_id=${clientID}&redirect_uri=${redirectUri}&response_type=code`
      const url = `${healthId}/oauth/redirect?client_id=${clientID}&redirect_uri=${redirectUri}&response_type=code`
      window.location.replace(url)
    },

    async SignInOneIDAccount () {
      // TODO เป็น function สำหรับการ signin ผ่าน Oauth ของ OneID แบบ SSO
      // TODO Edit Here --------------------------
      // COMMENT สำหรับ credential ได้มาจาก dpc.th
      // FIX ต้องแก้เป็นดึงมาจาก Backend แทนการรับ Environment
      const clientID = process.env.VUE_APP_ONEID_CLIENT_ID

      // TODO สำหรับ Oneid จะเป็นการเก็บข้อมูลลง localstorage เนื่องจาก หากว่า set params DPC จะไม่ใช่ผ่านเพราะ URL ไม่เหมือน
      if (
        this.$route.query.action !== undefined &&
        this.$route.query.invite_id !== undefined
      ) {
        localStorage.clear()
        localStorage.setItem('invite_id', this.$route.query.invite_id)
        localStorage.setItem('action', this.$route.query.action)
      }

      // COMMENT สำหรับสร้าง Url สำหรับสั่งใน Oneid Redirect Authorization Code กลับมา
      const redirectUri = `${window.location.origin}${process.env.VUE_APP_BASE_URL}login/oneid`

      // COMMENT สำหรับกำหนด Response type
      const responseType = 'code'

      // COMMENT สำหรับกำหนดของที่สามารถขอข้อมูลได้จาก OneID
      const scope = 'title+first_name+last_name+birth_date+email+tel+pic'
      // TODO Edit Here --------------------------
      const uri = `${process.env.VUE_APP_ONEID_URL}/oauth/authorize?client_id=${clientID}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`
      location.replace(encodeURI(uri))
    },

    // NOTE เป็น Function สำหรับการ Append query string parameters to URL
    addQueryString (url, query) {
      // TODO Parse the input URL
      const parsedUrl = new URL(url)

      // TODO Iterate over the JSON data and append each key-value pair to the query string
      for (const key of Object.keys(query)) {
        parsedUrl.searchParams.append(key, query[key])
      }

      // TODO Return the new URL with the appended query string
      return parsedUrl.toString()
    }
  }
}
</script>

<style lang="css" scoped>
.btn-close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 40px;
  padding-right: 40px;
  padding-left: 40px;
  color: #989898;
}

.title-login {
  font-size: 30px;
  color: var(--v-primary-base) !important;
}

/* >>> .custom-text-field input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
} */
>>> .textfieldsuccess.v-text-field--outlined fieldset {
  border: 1px solid var(--v-primary-base);
  color: var(--v-primary-base) !important;
}

>>> .textfielderror.v-text-field--outlined fieldset {
  border: 1px solid var(--v-error-base);
  color: var(--v-primary-base) !important;
}

>>> .textfieldsuccess.v-text-field--outlined.v-input--dense .v-label {
  color: var(--v-primary-base) !important;
}

>>> .textfielderror.v-text-field--outlined.v-input--dense .v-label {
  color: var(--v-error-base) !important;
}

>>> .textfieldsuccess .v-input__append-inner .v-input__icon .v-icon {
  color: var(--v-primary-base) !important;
}

>>> .textfielderror .v-input__append-inner .v-input__icon .v-icon {
  color: var(--v-error-base) !important;
}

.text-error {
  color: red;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: -10px;
  font-size: 13px;
}

.form-box {
  width: 60%;
}

.sign-in-btn {
  font-weight: 400;
  font-size: 18px;
  color: var(--v-bgPrimary-base);
}

.forget-password {
  text-align: right;
}

.forget-password-btn {
  font-weight: 300;
  font-size: 16px;
  color: var(--v-primary-base);
}

.logo-company {
  width: 95% !important;
  max-width: 260px !important;
}

.card-left-title {
  color: var(--v-tprimary-base);
  text-align: center;
  width: 100%;
  font-weight: 500;
  font-size: 30px;
}

.card-left {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  /* width: 45%; */
  width: 55%;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 20px !important;
}

.card-right {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 45%;
  background: linear-gradient(
    180deg,
    var(--v-gradient5-base) 0%,
    var(--v-gradient6-base) 100%
  );
  border-radius: 11px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 0 !important;
}

.card-right-img {
  max-width: 80% !important;
  /* background-color: red; */
}

.card-right-img-2 {
  width: 87px;
  margin-right: 30px;
  align-self: flex-end;
}

.card-right-title {
  color: var(--v-tprimary-base);
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 16px;
}

.main-login-page {
  /* height: calc(100vh - 64px) !important; */
  /* height: 100%; */
  max-width: 100vw;
  /* max-height: 100vh; */
  /* width: 100%; */
  height: 100%;
  overflow: auto;
  /* overflow: hidden; */
}

.login-form {
  max-width: 1200px;
  width: 60%;
  height: 70%;
  min-height: 420px;
}

.login-card {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 20px;
}

.img-outside {
  display: none;
}

@media only screen and (max-width: 1400px) {
  .login-form {
    width: 80%;
  }

  .form-box {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .login-form {
    width: 95%;
  }
}

@media only screen and (max-width: 750px) {
  .card-right {
    border-radius: 20px !important;
  }

  .login-form {
    height: fit-content;
  }

  .card-left {
    display: none;
  }

  .card-right {
    width: 100%;
    height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .card-right-img {
    max-width: 400px !important;
  }

  .login-card {
    max-width: 500px;
    flex-wrap: wrap;
    height: fit-content;
  }

  .img-outside {
    width: 100%;
    display: inline;
  }

  .login-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }

  /* .main-login-page {
    background: var(--v-bgPrimary-base);
  } */
  .logo-company {
    width: 65% !important;
    max-width: 200px !important;
  }
}

.find-user-comfirm {
  font-weight: 400;
  font-size: 18px;
  color: var(--v-bgPrimary-base);
}

.find-user-cancel {
  font-weight: 400;
  font-size: 18px;
  color: var(--v-bgPrimary-base);
}

.oauth-sign-in {
  width: 100%;
  height: 100%;
}
</style>
