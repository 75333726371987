<template>
  <!-- <div> -->
  <!-- <img class="wave" src="https://raw.githubusercontent.com/sefyudem/Responsive-Login-Form/master/img/wave.png"> -->
  <div class="main-login-page">
    <!-- image -->
    <div class="img-login">
      <div class="text-welcome">
        <div class="text-DashboardManageAI">ยินดีต้อนรับเข้าสู่ระบบ</div>
        <div class="text-DashboardManageAI">Dashboard</div>
        <span class="text-DashboardManageAI">
          <p class="text-ManageAI">Manage</p>
          <p class="text-ManageAI">AI</p>
        </span>
        <div class="text-DashboardManageAI">
          <p class="text-ManageAI">ถ้าคุณยังไม่มีบัญชีผู้ใช้งาน</p>
          <p class="text-ManageAI">
            <a style="color: #50a7dc" href="#" @click="$emit('click:register')"
              >ลงทะเบียนที่นี่ !</a
            >
          </p>
        </div>
      </div>
      <!-- image -->
      <img src="@/../public/img/ImageLogin.png" />
    </div>
    <div class="login-content">
      <v-form ref="form" v-model="valid">
        <div class="ma-2 form-box">
          <div class="title-ManagAI">
            <img src="@/../public/img/logoManageAIWithOutText.png" />
            ManageAI
          </div>
          <div class="title-loginForm">เข้าสู่ระบบ</div>
          <div>
            <v-text-field
              prepend-inner-icon="mdi-email"
              color="blue"
              class="custom-text-field"
              :class="textError ? 'textfielderror' : 'textfieldsuccess'"
              v-model="signInData.username"
              :rules="[
                Rule.noInPut,
                Rule.noSpace,
                Rule.englishOnly,
                Rule.emailOnly,
              ]"
              placeholder="อีเมล"
              label="ชื่อผู้ใช้(อีเมล)"
              dense
              outlined
              rounded
            ></v-text-field>
            <v-text-field
              prepend-inner-icon="mdi-lock"
              class="custom-text-field"
              :class="textError ? 'textfielderror' : 'textfieldsuccess'"
              v-model="signInData.password"
              :rules="[Rule.noInPut, Rule.noSpace, Rule.noSpace]"
              :append-icon="signInData.show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="signInData.show ? 'text' : 'password'"
              @click:append="signInData.show = !signInData.show"
              placeholder="รหัสผ่าน"
              label="รหัสผ่าน"
              dense
              outlined
              rounded
            >
            </v-text-field>
          </div>

          <div class="forget-password">
            <a
              href="#"
              class="forget-password-btn"
              @click="$emit('click:forget')"
              >ลืมรหัสผ่าน ?</a
            >
            <v-btn
              :disabled="!valid"
              type="submit"
              class="sign-in-btn"
              style="height: 48px"
              color="primary"
              :loading="loading"
              block
              rounded
              @click.prevent="
                $emit('click:login', {
                  username: signInData.username,
                  password: signInData.password,
                })
              "
              >เข้าสู่ระบบ</v-btn
            >
          </div>
          <!-- เข้าสู่ระบบด้วย -->
          <h2 class="hr-lines">เข้าสู่ระบบด้วย</h2>
          <!-- // TODO 2023-07-11 เพิ่ม Oauth OneID -->
          <div class="oauth-sign-in">
            <!-- <div class="mr-5">
              <OauthBtn
                disabled
                block
                rounded
                color="white"
                :image="require('@/assets/images/google.png')"
                text="Google"
                @click="$emit('click:google')"
              >
              </OauthBtn>
            </div> -->
            <div>
              <OauthBtn
                block
                rounded
                color="primary"
                :image="require('@/assets/images/onePlatform.png')"
                text="OneID"
                @click="$emit('click:oneid')"
              >
              </OauthBtn>
              <OauthBtn
                block
                rounded
                color="primary"
                class="ml-2"
                :image="require('@/assets/images/logo-provider-id.png')"
                text="ProviderID"
                @click="$emit('click:providerid')"
              >
              </OauthBtn>
            </div>
          </div>
          <!-- // TODO -------------------------- -->
          <div class="pt-5">
            ถ้าคุณยังไม่มีบัญชีผู้ใช้งาน
            <!-- <v-btn class="forget-password-btn" text rounded @click="$emit('click:register')">ลงทะเบียน</v-btn> -->
            <a style="color: #50a7dc" href="#" @click="$emit('click:register')"
              >ลงทะเบียนที่นี่ !</a
            >
          </div>
          <div class="">
            คู่มือ Dashboard FDH สำหรับผู้บริหาร
            <!-- <v-btn class="forget-password-btn" text rounded @click="$emit('click:register')">ลงทะเบียน</v-btn> -->
            <span style="color: #50a7dc; cursor: pointer" @click="openManual">
              คลิก
            </span>
          </div>
        </div>
      </v-form>
    </div>
    <div class="img-login-iphon">
      <img src="@/../public/img/ImageLogin.png" />
    </div>
  </div>

  <!-- </div> -->
</template>

<script>
// import DialogAnnounce from '@/components/Forms/DialogAnnounce.vue'
// import DialogForgetPassword from '@/components/Forms/DialogForgetPassword.vue'
import OauthBtn from '@/components/Button/OauthBtn.vue'

export default {
  components: {
    // DialogAnnounce,
    // DialogForgetPassword,
    OauthBtn
  },

  data () {
    return {
      mailUser: '',
      dialogCarousel: false,
      dialogForget: false,
      resetEmail: [],
      textError: false,
      textFieldError: false,
      // loading: false,
      signInData: {
        username: '',
        password: '',
        show: false
      },
      valid: false,
      firstname: '',
      lastname: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 10 || 'Name must be less than 10 characters'
      ],
      email: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      announce: []
    }
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    version: {
      type: String,
      default: process.env.VUE_APP_VERSION
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    openManual () {
      window.open(
        'https://docs.google.com/document/d/13m9LN7s7lWPOglU33rUS_2Yaq1oJSmg87vt6gGkxgq8/edit?usp=sharing',
        '_blank'
      )
    }
  }
}
</script>

<style lang="css" scoped>
.main-login-page {
  /* width: 100vw; */
  /* height: 100vh; */
  height: 100%;
  width: 100%;
  background-image: url("@/../public/img/BGlogin.svg");
  background-size: cover;
  display: grid;
  /* สร้าง 2 คอลัมภ์ แต่ละคอลัมภ์กว้าง 1fr */
  grid-template-columns: repeat(2, 1fr);
  /* grid-gap: 6rem; */
  grid-gap: 3rem;
  padding: 5px;
}

.img-login-iphon {
  display: none;
}

.img-login {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: flex-end; */
  justify-content: center;
  /* align-items: center; */
  /* background: pink; */
}

.img-login img {
  /* background: darksalmon; */
  bottom: 0;
  width: 100%;
  /* max-height: 618.93px;
    max-width: 543.36px; */
  max-height: 500px;
  max-width: 400px;
}

.text-welcome {
  width: 100%;
  margin-top: 80px;
  margin-left: 100px;
  /* background:  yellow; */
}

.text-DashboardManageAI {
  /* background: red; */
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;

  color: #333333;
}

.text-DashboardManageAI:nth-child(2) {
  /* background: #38d39f; */
  margin-top: 16px;
}

.text-DashboardManageAI:nth-child(3) {
  padding: 0;
  /* background: plum; */
  margin-top: 0px;
  font-size: 56px;
  display: flex;
  top: -10px;
}

.text-DashboardManageAI:nth-child(4) {
  /* background: lightgreen; */
  margin-top: 0px;
  font-weight: 500;
  font-size: 18px;
  display: flex;
}

.text-ManageAI {
  /* background: red; */
}

.text-ManageAI:nth-child(2) {
  margin-left: 10px;
  color: #50a7dc;
}

.ImageLogin {
  /* width: 50%; */
  /* padding: 5px; */
  max-height: 618.93px;
  max-width: 543.36px;
  /* width: calc(100% - 10rem); */
  /* width: 543.36px;
    height: 618.93px; */
  position: absolute;
  /* margin-left: 12.75rem; */
  margin-left: 5%;
  /* background: red; */
  /* left: 0; */
  bottom: 0;
}

/*-----------------ไส้ในlogin form-------------------*/
.login-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

form {
  width: 100%;
  height: 100%;
  max-height: 726px;
  max-width: 560px;
  /* top: calc(50% - 726px/2); */

  background: #ffffff;
  /* background: lightpink; */

  box-shadow: 0px 8px 24px -4px rgba(27, 46, 94, 0.08);
  border-radius: 32px;
  /* padding: 20px; */
}

.title-ManagAI {
  /* background: lightgoldenrodyellow; */

  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #333333;
  margin-top: 20px;
}

.title-ManagAI img {
  width: 82px;
  height: 82px;
}

.title-loginForm {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;

  /* width: 480px;
  height: 48px; */
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  /* line-height: 48px; */
  color: #333333;
  margin-top: 10px;
  margin-bottom: 30px;
}
.forget-password {
  text-align: right;
}
.forget-password-btn {
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #50a7dc;
}
.sign-in-btn {
  margin-top: 32px;
  margin-bottom: 32px;
  height: 200px;
}
.oauth-sign-in {
  /* width: 100%;
  height: 100%; */
  /* background: powderblue; */
  display: flex;
  justify-content: center;
}
.form-box {
  /* background: lightpink; */
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  /* display: flex;
    flex-direction: column;
    align-content: center; */
}
.hr-lines {
  position: relative;
  /*  new lines  */
  width: 100%;
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #636363;

  margin-top: 32px;
  margin-bottom: 32px;
}
.hr-lines:before {
  content: " ";
  display: block;
  height: 1px;
  /* width: 130px; */
  /* width: 20%; */
  width: 35%;
  position: absolute;
  top: 50%;
  left: 0;
  /* background: red; */
  background: #636363;
}
.hr-lines:after {
  content: " ";
  height: 1px;
  width: 35%;
  /* background: red; */
  background: #636363;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}
@media screen and (max-width: 1050px) {
  .main-login-page {
    grid-gap: 2rem;
  }
  .hr-lines:before {
    width: 25%;
  }
  .hr-lines::after {
    width: 25%;
  }
  .title-ManagAI {
    font-size: 28px;
  }
  .form-box {
    padding-top: 10px;
  }
}

@media screen and (max-width: 700px) {
  /* form {
        width: 290px;
    } */
  .main-login-page {
    grid-gap: 0rem;
  }

  .ImageLogin {
    /* width: 50%; */
    /* padding: 5px; */
    width: 70%;
    height: 70%;
    /* width: 543.36px;
        height: 618.93px; */
    position: absolute;
    /* margin-left: 12.75rem; */
    margin-left: 10px;
    /* background: red; */
    /* left: 0; */
    bottom: 0;
  }
  .text-welcome {
    margin-top: 40px;
    margin-left: 10px;
    /* background:  yellow; */
  }

  .text-DashboardManageAI {
    /* background: red; */
    font-size: 20px;
  }

  .text-DashboardManageAI:nth-child(2) {
    /* background: #38d39f; */
    margin-top: 14px;
  }

  .text-DashboardManageAI:nth-child(3) {
    padding: 0;
    /* background: plum; */
    margin-top: 0px;
    font-size: 48px;
    display: flex;
    top: -10px;
  }

  .text-DashboardManageAI:nth-child(4) {
    /* background: lightgreen; */
    margin-top: 0px;
    font-weight: 500;
    font-size: 12px;
    display: flex;
  }

  .text-ManageAI {
    /* background: red; */
  }

  .text-ManageAI:nth-child(2) {
    margin-left: 10px;
    color: #50a7dc;
  }

  /* .img-login img {
        max-height: 618.93px;
        max-width: 543.36px;
        width: 100%;
        height: 100%;
    } */
}

@media screen and (max-width: 554px) {
  .main-login-page {
    background-image: url("@/../public/img/BgLoginForiPhone.svg");
    background-size: cover;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
    /* grid-gap: 1rem; */
    grid-gap: auto;
  }

  .login-content {
    align-items: flex-start;
  }

  form {
    width: 100%;
    height: 100%;
    max-width: 390px;
    max-height: 636px;
    background: none;
    border: none;
    /* background: pink; */
  }

  .img-login {
    display: none;
  }

  .img-login-iphon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 0;
  }

  .img-login-iphon img {
    /* background: #50A7DC; */
    max-width: 175px;
    max-height: 199px;
    width: 100%;
    height: 100%;
  }

  .wave {
    display: none;
  }
}
</style>
