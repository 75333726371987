<template>
<div>
    <div class="search-bar d-flex align-center">
        <div class="search-icon mr-3">
            <v-icon>mdi-magnify</v-icon>
        </div>
        <div class="search-input">
            <!-- <input @change="pushData" type="text" placeholder="Filter your data" v-model="inputValue" autocomplete="off" /> -->
            <input @input="pushData" @change="pushData" type="text" placeholder="Filter your data" v-model="inputValue" autocomplete="off" />
            <div v-show="isOpen" class="autocomplete-results">
                <li class="loading" v-if="isLoading">Loading...</li>
                <div v-else v-for="(result, i) in results" :key="i" @click="setResult(result)" class="autocomplete-result d-flex">
                    <div class="icon-list mr-3">
                        <v-icon size="20px" icon>mdi-label-variant</v-icon>
                    </div>
                    {{ result }}
                </div>
            </div>
        </div>
        <v-btn v-if="inputValue!== ''" icon class="search-icon" color="grey" @click="searhClearAll">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </div>
</div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  created () {
    this.$EventBus.$on('addquery', (val) => {
      this.clickIncludeExclude(val)
    })
    this.$EventBus.$on('clickWidgetPieChart', (val) => {
      this.clickPieChart(val)
      this.handleClickOutside()
    })
    this.$EventBus.$on('clickWidgetBarChart', (val) => {
      this.clickBarChart(val)
      this.handleClickOutside()
    })
  },
  data () {
    return {
      inputValue: '',
      dataForSearch: [],
      // สร้าง dataSearch_must,dataSearch_must_not เพื่อแยกค่า
      dataForSearch_must: [],
      dataForSearch_must_not: [],
      dataForSearch_should: [],
      query: {
        bool: {
          must: [],
          must_not: [],
          should: []
        }
      },
      // suggestions
      isOpen: false,
      results: [],
      search: '',
      isLoading: false,
      arrowCounter: 0,
      BtnState: false
    }
  },

  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },

  methods: {
    replaceSpacesText (valInput) {
      let inputFiltering = ''
      // const re = /(:"(.*?)")/g
      const re = /((\w+):"(.*?)")/g
      let current
      let replaceSpaceBar
      inputFiltering = valInput
      while ((current = re.exec(inputFiltering))) {
        replaceSpaceBar = current[0].replaceAll(' ', '@SPACE@')
        inputFiltering = current.input.replace(current[0], replaceSpaceBar)
      }
      return inputFiltering
    },
    // กรอง ()bracket
    findBracket (valInput) {
      let inputFiltering = ''
      // const re = /(:"(.*?)")/g
      // const re = /((\w+):"(.*?)")/g
      const re = /\((.*?)?\)/g
      let current
      let replaceSpaceBar
      inputFiltering = valInput
      while ((current = re.exec(inputFiltering))) {
        // replaceSpaceBar = current[0].replaceAll(' OR ', '#OR#')
        replaceSpaceBar = current[0].replaceAll(/(( or )|( oR )|( Or )|( OR ))/g, '#OR#')
        // console.log('------------replaceSpaceBar----------')
        // replaceSpaceBar = current[0].replaceAll(' ', '@')
        inputFiltering = current.input.replace(current[0], replaceSpaceBar)
      }
      return inputFiltering
    },
    pushData () {
      // ล้างค่า
      this.dataForSearch = []
      this.query.bool.must = []
      this.query.bool.must_not = []
      this.dataForSearch_must = []
      this.dataForSearch_must_not = []
      this.dataForSearch_should = []

      // ได้ค่ามา การ spilt spaceออก
      this.inputValueTemp = this.replaceSpacesText(this.findBracket(this.inputValue))

      // this.$EventBus.$emit('inputValueFromSearch', this.inputValue)
      this.$EventBus.$emit('inputValueFromSearch', this.inputValueTemp)
      const spiltSpace = this.inputValueTemp.split(' ')
      if (spiltSpace.length > 0) {
        for (let i = 0; i < spiltSpace.length; i++) {
          this.queryData(spiltSpace[i])
        }
      }
      //   }
    },
    filterSemicolonPatter (elem) {
      // this.dataForSearch_should = []
      const findKey = elem.split(':"')
      const getValue = /(:"(.*?)")/.exec(elem)
      const objFilterVlaue = {
        key: findKey[0],
        value: getValue[2]
      }
      return objFilterVlaue
    },
    queryData (elem) {
      //  (key:"In et" OR usename:"Nattida") name:fa
      // list
      if (elem !== null) {
        this.filterResults(elem)
        this.isOpen = true
      }

      let splitVariableValue = []
      let indexOfoperator
      let settingOperator

      // เงื่อนไข regx แต่ละ Format
      const forMustCase = /^([^-])([A-Za-z-_\d.\w ^ !"(-)`'#%&,:;-@~$*+?[\]^.*]*)/
      const forMustNotCase = /^(?=.*(--|-)).+(?:[\w|^ !"`'#%&,:;<>=@{}~$*+?())(()[\]^.*]+)$/

      // Format : (ตัวเลข)(< หรือ >)(ตัวเลข)
      const GtandltOperatorsFormat = /^([^-])([A-Za-z-_\d.]*)(([>](?![=]))|([<](?![=])))([\d])/

      // Format : (ตัวเลข)(<= หรือ >=)(ตัวเลข)
      const GteandlteOperatorsFormat = /^([^-])([A-Za-z-_\d.]*)(([>][=])|([<][=]))([\d])/

      // Format (word)(=)(ตัวเลข)
      const ExactMatchNumeFormat = /^([^-])([A-Za-z-_\d.]*)([=])(([\w ^ !"(-)`'#%&,:;-@~$*+?[\]^.*]*))/

      // Format : Or group (key:"value" OR key:"value" OR ...)
      // const OrFormat = /\((.*?)?\)/
      const OrFormat = /\((.*)(#OR#)+(.*)\)/

      // Format : key:"string" กรองช่องว่าง
      const TextSpaceFormat = /^([^-])(\w+):"(.*?)"/

      if (forMustCase.test(elem)) {
        if (GtandltOperatorsFormat.test(elem)) {
          this.dataForSearch_must = []
          // เคส : (ตัวเลข)(< หรือ >)(ตัวเลข)
          splitVariableValue = elem.split(/[>|<]/)
          // indexOfoperator เก็บต่ำแหน่งเครื่อหมาย operator
          indexOfoperator = elem.search(/[>|<]/)
          // กำหนดค่าเครื่องหมาย
          if (elem[indexOfoperator] === '>') {
            settingOperator = 'gt'
          } else if (elem[indexOfoperator] === '<') {
            settingOperator = 'lt'
          }
          for (let i = 0; i < splitVariableValue.length - 1; i++) {
            this.dataForSearch_must.push({
              range: {
                [splitVariableValue[0]]: {
                  [settingOperator]: parseInt(splitVariableValue[i + 1])
                  // [settingOperator]: (splitVariableValue[i + 1])
                }
              }
            })
          }
          // console.log(this.dataForSearch_must)
          const object = Object.assign({}, ...this.dataForSearch_must)
          this.query.bool.must.push(object)
        } else if (GteandlteOperatorsFormat.test(elem)) {
          // เคส : (ตัวเลข)(<= หรือ >=)(ตัวเลข)
          this.dataForSearch_must = []
          splitVariableValue = elem.replace(/([=])/g, '').split(/[>|<]/)
          // indexOfoperator เก็บต่ำแหน่งเครื่อหมาย operator
          indexOfoperator = elem.search(/[>|<]/)
          // กำหนดค่าเครื่องหมาย
          if (elem[indexOfoperator] === '>') {
            settingOperator = 'gte'
          } else if (elem[indexOfoperator] === '<') {
            settingOperator = 'lte'
          }
          // else {
          //   console.log('settingOperator : ' + settingOperator)
          // }
          for (let i = 0; i < splitVariableValue.length - 1; i++) {
            this.dataForSearch_must.push({
              range: {
                [splitVariableValue[0]]: {
                  [settingOperator]: parseInt(splitVariableValue[i + 1])
                }
              }
            })
          }
          const object = Object.assign({}, ...this.dataForSearch_must)
          this.query.bool.must.push(object)
        } else if (ExactMatchNumeFormat.test(elem)) {
          this.dataForSearch_must = []
          // เคส (word)(=)(ตัวเลข)
          splitVariableValue = elem.split(/[=]/)
          for (let i = 0; i < splitVariableValue.length - 1; i++) {
            this.dataForSearch_must.push({
              match: {
                [splitVariableValue[0]]: {
                  // query: parseInt(splitVariableValue[i + 1]),
                  // query: splitVariableValue[i + 1],
                  query: `${splitVariableValue[i + 1]}`,
                  operator: 'and'
                }
              }
            })
          }
          // this.query.bool.must.push(this.dataForSearch_must)
          const object = Object.assign({}, ...this.dataForSearch_must)
          this.query.bool.must.push(object)
        } else if (OrFormat.test(elem)) {
          // เคส : Or group
          this.dataForSearch_should = []
          // step1 : กรอง ()
          const re = /\((.*?)?\)/g
          const noBracket = re.exec(elem)[1].replaceAll('@SPACE@', ' ')
          // step 2 split แต่ละคำสั่ง Key:"value" จาก #OR# แล้วเก็บ Array
          const arraySpiltOr = noBracket.split('#OR#')

          // step 3 วนสร้างค่า Array
          let tempObjFilterSemicolonPatter = {}// สำหรับเก็บค่าที่กรองได้จาก filterSemicolonPatter() รูปแบบ Obj
          for (let i = 0; i < arraySpiltOr.length; i++) {
            // step 4 วนเข้า ฟังก์ชัน filterSemicolonPatter เพื่อแยก key กับ value ของ Key:"value"
            tempObjFilterSemicolonPatter = this.filterSemicolonPatter(arraySpiltOr[i])
            this.dataForSearch_should.push({
              bool: {
                must: [
                  {
                    match: {
                      [tempObjFilterSemicolonPatter.key]: {
                        query: tempObjFilterSemicolonPatter.value,
                        operator: 'and'
                      }
                    }
                  }
                ]
              }
            })
          }
          // console.log(this.dataForSearch_should)
          // step 5 : ทำการ push ก้อน should [](this.datForSearch) เข้า must
          this.query.bool.must.push({
            bool: {
              should: this.dataForSearch_should
            }
          })
        } else if (TextSpaceFormat.test(elem)) {
          // เคส : key:"string" กรองช่องว่าง
          this.dataForSearch_must = []
          const findKey = elem.split(':"')
          const getValue = /(:"(.*?)")/.exec(elem)
          const filetrGetValue = getValue.pop().replaceAll('@SPACE@', ' ')
          for (let i = 0; i < findKey.length - 1; i++) {
            this.dataForSearch_must.push({
              match: {
                [findKey[0]]: {
                  query: filetrGetValue,
                  operator: 'and'
                }
              }
            })
          }
          // this.query.bool.must.push(this.dataForSearch_must)
          const object = Object.assign({}, ...this.dataForSearch_must)
          this.query.bool.must.push(object)
        } else if (/^([^-])([A-Za-z-_\d.]*)([=])([(]([A-Za-z]*)(\|(?!\|)).*[)])/.test(elem)) {
          splitVariableValue = elem
            .replace(/([(]|[)])/g, '')
            .replace(/(=)|(\|)/g, ' ')
            .split(' ')
          // tempData = []

          for (let i = 0; i < splitVariableValue.length - 1; i++) {
            this.dataForSearch_must.push({
              range: {
                [splitVariableValue[0]]: {
                  exact: splitVariableValue[i + 1]
                }
              }
            })
          }
          //* *แก้อีกที
          this.queryShould.bool.should = this.dataForSearch_must
          this.arrayForShould.push(this.queryShould)
          this.query.bool.must = this.arrayForShould
          // this.query.bool.must.push(this.dataForSearch_must)
          // query.bool.must = this.dataForSearch
        } else {
          splitVariableValue = elem
          this.dataForSearch_must.push({
            simple_query_string: {
              query: '+' + splitVariableValue
            }
          })
          // this.query.bool.must.push(this.dataForSearch_must)
          const object = Object.assign({}, ...this.dataForSearch_must)
          this.query.bool.must.push(object)
        }
      } else if (forMustNotCase.test(elem)) {
        const splitNegation = elem.replace('-', '')
        if (GtandltOperatorsFormat.test(splitNegation)) {
          this.dataForSearch_must_not = []
          // เคส : (ตัวเลข)(< หรือ >)(ตัวเลข)
          splitVariableValue = splitNegation.split(/[>|<]/)
          // indexOfoperator เก็บต่ำแหน่งเครื่อหมาย operator
          indexOfoperator = splitNegation.search(/[>|<]/)
          // กำหนดค่าเครื่องหมาย
          if (splitNegation[indexOfoperator] === '>') {
            settingOperator = 'gt'
          } else if (splitNegation[indexOfoperator] === '<') {
            settingOperator = 'lt'
          }
          for (let i = 0; i < splitVariableValue.length - 1; i++) {
            this.dataForSearch_must_not.push({
              range: {
                [splitVariableValue[0]]: {
                  [settingOperator]: parseInt(splitVariableValue[i + 1])
                }
              }
            })
          }
          // this.query.bool.must_not.push(this.dataForSearch_must_not)
          const object = Object.assign({}, ...this.dataForSearch_must_not)
          this.query.bool.must_not.push(object)
        } else if (GteandlteOperatorsFormat.test(splitNegation)) {
          // เคส : (ตัวเลข)(<= หรือ >=)(ตัวเลข)
          this.dataForSearch_must_not = []
          splitVariableValue = splitNegation
            .replace(/([=])/g, '')
            .split(/[>|<]/)
          // indexOfoperator เก็บต่ำแหน่งเครื่อหมาย operator
          indexOfoperator = splitNegation.search(/[>|<]/)
          // กำหนดค่าเครื่องหมาย
          if (splitNegation[indexOfoperator] === '>') {
            settingOperator = 'gte'
          } else if (splitNegation[indexOfoperator] === '<') {
            settingOperator = 'lte'
          }
          for (let i = 0; i < splitVariableValue.length - 1; i++) {
            this.dataForSearch_must_not.push({
              range: {
                [splitVariableValue[0]]: {
                  [settingOperator]: parseInt(splitVariableValue[i + 1])
                }
              }
            })
          }
          // this.query.bool.must_not.push(this.dataForSearch_must_not)
          const object = Object.assign({}, ...this.dataForSearch_must_not)
          this.query.bool.must_not.push(object)
        } else if (ExactMatchNumeFormat.test(splitNegation)) {
          this.dataForSearch_must_not = []
          // เคส (word)(=)(ตัวเลข)
          splitVariableValue = splitNegation.split(/[=]/)
          for (let i = 0; i < splitVariableValue.length - 1; i++) {
            this.dataForSearch_must_not.push({
              match: {
                [splitVariableValue[0]]: {
                  query: `${splitVariableValue[i + 1]}`,
                  operator: 'and'
                }
              }
            })
          }
          const object = Object.assign({}, ...this.dataForSearch_must_not)
          this.query.bool.must_not.push(object)
        } else if (OrFormat.test(splitNegation)) {
          // เคส : Or group
          this.dataForSearch_should = []
          // step1 : กรอง ()
          const re = /\((.*?)?\)/g
          const noBracket = re.exec(splitNegation)[1].replaceAll('@SPACE@', ' ')
          // step 2 split แต่ละคำสั่ง Key:"value" จาก #OR# แล้วเก็บ Array
          const arraySpiltOr = noBracket.split('#OR#')

          // step 3 วนสร้างค่า Array
          let tempObjFilterSemicolonPatter = {}// สำหรับเก็บค่าที่กรองได้จาก filterSemicolonPatter() รูปแบบ Obj
          for (let i = 0; i < arraySpiltOr.length; i++) {
            // step 4 วนเข้า ฟังก์ชัน filterSemicolonPatter เพื่อแยก key กับ value ของ Key:"value"
            tempObjFilterSemicolonPatter = this.filterSemicolonPatter(arraySpiltOr[i])
            this.dataForSearch_should.push({
              bool: {
                must: [
                  {
                    match: {
                      [tempObjFilterSemicolonPatter.key]: {
                        query: tempObjFilterSemicolonPatter.value,
                        operator: 'and'
                      }
                    }
                  }
                ]
              }
            })
          }
          // console.log(this.dataForSearch_should)
          // step 5 : ทำการ push ก้อน should [](this.datForSearch) เข้า must
          this.query.bool.must_not.push({
            bool: {
              should: this.dataForSearch_should
            }
          })
        } else if (TextSpaceFormat.test(splitNegation)) {
          // เคส : key:"string" กรองช่องว่าง
          this.dataForSearch_must_not = []
          const findKey = splitNegation.split(':"')
          const getValue = /(:"(.*?)")/.exec(splitNegation)
          const filetrGetValue = getValue.pop().replaceAll('@SPACE@', ' ')
          for (let i = 0; i < findKey.length - 1; i++) {
            this.dataForSearch_must_not.push({
              match: {
                [findKey[0]]: {
                  query: filetrGetValue,
                  operator: 'and'
                }
              }
            })
          }
          const object = Object.assign({}, ...this.dataForSearch_must_not)
          this.query.bool.must_not.push(object)
        } else if (/^([^-])([A-Za-z-_\d.]*)([=])([(]([A-Za-z]*)(\|(?!\|)).*[)])/.test(splitNegation)) {
          splitVariableValue = splitNegation
            .replace(/([(]|[)])/g, '')
            .replace(/(=)|(\|)/g, ' ')
            .split(' ')
          // tempData = []

          for (let i = 0; i < splitVariableValue.length - 1; i++) {
            this.dataForSearch_must_not.push({
              range: {
                [splitVariableValue[0]]: {
                  exact: splitVariableValue[i + 1]
                }
              }
            })
          }
          this.queryShould.bool.should = this.dataForSearch_must_not
          this.arrayForShould.push(this.queryShould)
          this.query.bool.must_not = this.arrayForShould
        } else {
          splitVariableValue = splitNegation
          this.dataForSearch_must.push({
            simple_query_string: {
              query: '+' + splitVariableValue
            }
          })
          // this.query.bool.must.push(this.dataForSearch_must)
          const object = Object.assign({}, ...this.dataForSearch_must)
          this.query.bool.must_not.push(object)
        }
      }
      this.$emit('getDataForSearch', this.query)
      // this.$emit('getDataForSearch', this.dataForSearch)
      // console.log(JSON.stringify(this.query, null, 2))
    },
    filterResults (elem1) {
      const itemsTemp = []
      for (const i in this.items) {
        itemsTemp[i] = this.items[i].text
      }
      // this.results = this.items.filter((item) => {
      this.results = itemsTemp.filter((item) => {
        // return item.toLowerCase().indexOf(elem1.toLowerCase()) > -1
        return item.toLocaleLowerCase().startsWith(elem1.toLocaleLowerCase())
      })
    },
    setResult (result) {
      if (/^[A-Za-z0-9(),-_., ]*( )$/.test(this.inputValue)) {
        this.inputValue += result
      } else {
        const lastIndex = this.inputValue.lastIndexOf(' ')
        this.inputValue = this.inputValue.substring(0, lastIndex + 1)
        this.inputValue += result
      }
    },
    searhClearAll () {
      this.inputValue = ''
      this.query = {
        bool: {
          must: [],
          must_not: [],
          should: []
        }
      }
      this.$emit('getDataForSearch', this.query)
      this.$EventBus.$emit('inputNull', this.inputValue)
    },
    handleClickOutside () {
      // if (!this.$el.contains(evt.target)) {
      // console.log(evt)
      this.isOpen = false
      this.arrowCounter = -1
      // }
    },
    clickIncludeExclude (val) {
      const valReplaceSpaces = this.replaceSpacesText(val) // สร้าง valReplaceSpaces(@แทนspace) ใหม่ เพราะต้องส่ง val ปกติ ที่ .$emit addBtnState
      this.inputValueTemp = this.replaceSpacesText(this.inputValue)
      const spiltSpace = this.inputValueTemp.split(' ')
      if (spiltSpace.includes(valReplaceSpaces)) {
        // 'สำหรับลบค่าออกถ้ามีการตรวจเจอคำ [กดซ้ำ]'
        spiltSpace.splice(spiltSpace.indexOf(valReplaceSpaces), 1)
        // spiltSpace.replaceAll('@', ' ')
        this.inputValue = spiltSpace.join(' ')
        this.inputValue = this.inputValue.replaceAll('@SPACE@', ' ')
        this.pushData()
        // ลบค่าที่ซ้ำออกและเปลี่ยนสถานะปุ่มเป็นพร้อมกด
        this.BtnState = false
        this.$EventBus.$emit('addBtnState', {
          btnState: this.BtnState,
          value: val
        })
        // this.inputValue = this.inputValue.replace(val, '')
      } else {
        // console.log('ไม่เคยมีค่านี้ (ไม่เคยถูกกดให้แอดค่า)')
        // Note : /^[A-Za-z0-9(),-_., ]*( )$/.test(this.inputValue) => กรณีมีคำข้างหน้าและเว้นวรรค
        if (this.inputValue === '' || /^[A-Za-z0-9(),-_., ]*( )$/.test(this.inputValue)) {
          this.inputValue += val
          this.pushData()
        } else {
          this.inputValue += ' ' + val
          this.pushData()
        }
        // แอดค่าเสร็จปุ่มต้องเป็นสีเทา
        this.BtnState = true
        this.$EventBus.$emit('addBtnState', {
          btnState: this.BtnState,
          value: val
        })
      }
    },
    clickPieChart (val) {
      const valReplaceSpaces = this.replaceSpacesText(val) // สร้าง valReplaceSpaces(@แทนspace) ใหม่ เพราะต้องส่ง val ปกติ ที่ .$emit addBtnState
      this.inputValueTemp = this.replaceSpacesText(this.inputValue)
      const spiltSpace = this.inputValueTemp.split(' ')
      if (spiltSpace.includes(valReplaceSpaces)) {
        // 'สำหรับลบค่าออกถ้ามีการตรวจเจอคำ [กดซ้ำ]'
        spiltSpace.splice(spiltSpace.indexOf(valReplaceSpaces), 1)
        this.inputValue = spiltSpace.join(' ')
        this.inputValue = this.inputValue.replaceAll('@SPACE@', ' ')
        this.pushData()
      } else {
        // console.log('ไม่เคยมีค่านี้ (ไม่เคยถูกกดให้แอดค่า)')
        // Note : /^[A-Za-z0-9(),-_., ]*( )$/.test(this.inputValue) => กรณีมีคำข้างหน้าและเว้นวรรค
        if (this.inputValue === '' || /^[A-Za-z0-9(),-_., ]*( )$/.test(this.inputValue)) {
          this.inputValue += val
          this.pushData()
        } else {
          this.inputValue += ' ' + val
          this.pushData()
        }
      }
    },
    clickBarChart (val) {
      const valReplaceSpaces = this.replaceSpacesText(val) // สร้าง valReplaceSpaces(@แทนspace) ใหม่ เพราะต้องส่ง val ปกติ ที่ .$emit addBtnState
      this.inputValueTemp = this.replaceSpacesText(this.inputValue)
      const spiltSpace = this.inputValueTemp.split(' ')
      if (spiltSpace.includes(valReplaceSpaces)) {
        // 'สำหรับลบค่าออกถ้ามีการตรวจเจอคำ [กดซ้ำ]'
        spiltSpace.splice(spiltSpace.indexOf(valReplaceSpaces), 1)
        this.inputValue = spiltSpace.join(' ')
        this.inputValue = this.inputValue.replaceAll('@SPACE@', ' ')
        this.pushData()
      } else {
        // console.log('ไม่เคยมีค่านี้ (ไม่เคยถูกกดให้แอดค่า)')
        // Note : /^[A-Za-z0-9(),-_., ]*( )$/.test(this.inputValue) => กรณีมีคำข้างหน้าและเว้นวรรค
        if (this.inputValue === '' || /^[A-Za-z0-9(),-_., ]*( )$/.test(this.inputValue)) {
          this.inputValue += val
          this.pushData()
        } else {
          this.inputValue += ' ' + val
          this.pushData()
        }
      }
    }
  }
}
</script>

<style>
input[type="text"] {
    /* background-color: red; */
    width: 100%;
    border: none;
    outline: none;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 16px;
}

.search-bar {
    /* max-width: 1089px; */
    width: 100%;
    height: 40px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid var(--forms-eui-form-border-color, rgba(17, 43, 134, 0.1));
    background: #fbfcfd;
    /* background: red; */
}

.search-icon {
    /* background-color: aqua; */
    /* z-index: 2; */
    height: 100%;
    text-align: center;
    color: #aaa;
}

.search-input {
    width: 100%;
}

/* suggestions */

.autocomplete {
    position: relative;
    /* width: 90%; */
    margin-top: 500px;
}

.icon-list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #62a5f1;
}

.autocomplete-results {
    padding: 0;
    margin-top: 2px;
    margin-left: -40px;
    border-radius: 0px 0px 3px 3px;
    max-height: 254.4px;
    height: 100%;
    overflow: auto;
    /* max-width: 920px; */
    width: 40.75375%;

    position: absolute;
    z-index: 99;
}

.autocomplete-result {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-align: center;
    background-color: #ffffff;
    border-bottom: 3px solid #d3dae6;
    cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
    background-color: #c8d6ef;
    color: #69707d;
}
</style>
