<template>
  <div style="width: 100%; height: 100%; position: relative ;">
    <div
      v-if="showDetail"
      style="width: 100%; position: relative; top: 50% ; left: 0; transform: translate(0 , -50%) ;"
    >
      <composition :data="composite" show-total show-color />
    </div>
  </div>
</template>

<script>
import composition from '@/components/widgets/widget-composite.vue'
export default {
  components: {
    composition
  },
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    indexAxis: {
      type: String,
      default: 'x'
    },
    labels: {
      type: Array,
      default: () => []
    },
    datasets: {
      type: Array,
      default: () => []
    },
    showDetail: {
      type: Boolean,
      default: false
    },
    composite: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    // TODO เมื่อมีการ Update Labels จะ Rerender Chart
    labels: {
      handler: function () {
        this.chart.data.labels = this.labels
        this.chart.update()
      },
      deep: true
    },
    // TODO เมื่อมีการ Update Datasets จะ Rerender Chart
    datasets: {
      handler: function () {
        this.chart.data.datasets = this.datasets
        this.chart.update()
      },
      deep: true
    }
  },
  data () {
    return {
      myID: 'my-id',
      chart: null,

      // TODO Config Chart.js
      DefaultOptions: {
        indexAxis: this.indexAxis,
        plugins: {
          legend: {
            display: false,
            labels: { font: { family: "'Kanit', sans-serif" } }
          },
          tooltip: {
            // intersect: true,
            position: 'custom'
          }
        },

        interaction: {
          // REF https://www.youtube.com/watch?v=OSWIefHdago เรื่อง show tooltip เมื่อไม่ตรง pointer
          // REF https://www.youtube.com/watch?v=24FVvn8IHQk เรื่อง Vertical line
          intersect: false,
          mode: 'index'
        },
        scales: {
          y: {
            beginAtZero: false,
            grid: {
              display: false
            },
            ticks: {
              font: {
                size: 10,
                weight: 500,
                family: "'Kanit', sans-serif"
              }
            }
          },
          x: {
            grid: {
              display: false
            },
            ticks: {
              font: {
                size: 10,
                weight: 500,
                family: "'Kanit', sans-serif"
              }

              // autoSkip: true
              // maxTicksLimit: 3,
              // maxRotation: 0,
              // stepSize: Math.round((Math.max.apply(Math, this.datasets.length) / 10) / 5) * 5,
              // beginAtZero: true,
              // precision: 0
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
        // aspectRatio: 1 | 1
      }
    }
  },
  created () {
    // TODO Create Unique id of component chart
    this.myID = 'my-id-' + this._uid
  },

  mounted () {
    // TODO initial Chart js
    // console.log(this.Chart.prototype.getElementsAtEvent)
  }
}
</script>

<style lang="scss" scoped></style>
