<template>
  <div style="height: 100%; width: 100%">
    <div class="label-title overflow-ellips">
      {{ title }}
    </div>
    <div class="label-description overflow-ellips">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.label-title {
  font-size: 30px;
  font-weight: 500;
//   background-color: red;
//   height: 50%;

  text-align: start;
  vertical-align: middle;
  line-height: 100%;
}

.label-description {
  font-size: 22px;
  font-weight: 100;
//   background: blue;
  text-align: start;
  vertical-align: middle;
  line-height: 100%;
}

.overflow-ellips {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
