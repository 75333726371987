export default {
  methods: {
    async getAllIndexsInDatasource (datasource_id = '') {
      // TODO สำหรับดึง Index ทั้งหมดของ Datasource โดยจะใช้การ Query แบบ Direct Access Elasticsearch
      const payload = {
        datasource_id: datasource_id,
        path: '/_cat/indices?format=json&pretty=true'
      }
      const res = await this.directQueryElasticsearch(payload)
      if (res.status) {
        return res.data
      }
      return []
    },

    async getAllFieldsFromIndex (datasourceId = '', datasourecIndex = '') {
      // TODO สร้าง Payload เพื่อที่จะไปดึง Field ทั้งหมดจาก Elasticsearch
      const payload = {
        datasource_id: datasourceId,
        path: `/${datasourecIndex}/_mapping?pretty`
      }

      // TODO Send Query To Elastisearch
      const res = await this.directQueryElasticsearch(payload)
      if (res.status) {
        // TODO ประกาศตัวเเปรเพื่อใช้งาน
        // NOTE สำหรับเก็บค่า Field ทั้งหมด
        const ListFields = [
          {
            text: '_id',
            align: 'start',
            width: '',
            value: '_id',
            type: 'text'
          },
          {
            text: '_index',
            align: 'start',
            width: '',
            value: '_index',
            type: 'text'
          },
          {
            text: 'Msg.raw',
            align: 'start',
            width: '',
            value: 'Msg.raw',
            type: 'text'
          }
        ]
        const tmp = {}
        // TODO Loop เพื่อดึง Index จากที่ส่งมา
        for (const property in res.data) {
          window._.merge(tmp, res.data[property])
        }
        // TODO ประกาศ LF เพื่อเก็บข้อมูลเเละเพื่อเรียกใช้ใน Function
        const FL = Object.assign({}, tmp.mappings.properties)
        // TODO สร้าง Array ที่เก็บ list field ของ Index
        for (const key in FL) {
          await ListFields.push({
            text: key,
            align: 'start',
            width: '',
            value: key,
            type: FL[key].type
          })
        }
        return ListFields
      }
      return []
    },

    async getAllValueFromField (
      datasourceId = '',
      datasourecIndex = '',
      field = ''
    ) {
      const payload = {
        datasource_id: datasourceId,
        path: `/${datasourecIndex}/_search`,
        query: {
          aggs: {
            result: {
              terms: {
                field: field,
                order: {
                  _count: 'desc'
                }
              }
            }
          },
          size: '0'
        }
      }

      const res = await this.directQueryElasticsearch(payload)
      if (res.status) {
        return res.data.aggregations.result.buckets
      } else return []
    }
  }
}
