<template>
  <div class="main-layout">
    <div class="layout-content">
      <u-table
        :headers="headers"
        :items="items"
        :mobileBreakpoint="900"
        :offsetHeight="48"
        itemKey="user_id"
        :pagination="pagination"
        @onChangePagination="onChangePagination"
        :loading="loading"
      >
        <!-- // # Header Table -->
        <template v-slot:header>
          <v-row justify="end" align="center">
            <v-col cols="12" md="4">
              <v-row justify="center" align="center">
                <v-col
                  ><v-text-field
                    v-model="keyword"
                    name="name"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    placeholder="ค้นหา"
                    label="ค้นหา"
                    id="id"
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col cols="4">
                  <v-btn color="primary" block dark @click="init()"
                    >ค้นหา</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.number`]="{ item }">
          {{
            pagination.row * pagination.page -
            pagination.row +
            (items.indexOf(item) + 1)
          }}
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <v-avatar color="primary" size="35">
            <span
              v-if="item.image === ''"
              class="white--text text-h7 text-uppercase"
            >
              {{ item.first_name.charAt(0) }}{{ item.last_name.charAt(0) }}
            </span>
            <v-img v-else :src="profileUrl(item.image)"></v-img>
          </v-avatar>
        </template>
        <template v-slot:[`item.name_title`]="{ item }">
          {{ item.name_title != "" ? item.name_title : "-" }}
        </template>
        <template v-slot:[`item.gender`]="{ item }">
          {{ item.gender != "" ? item.gender : "-" }}
        </template>
        <template v-slot:[`item.permissions`]="{ item }">
          <v-chip
            outlined
            small
            v-for="t in item.permissions"
            :key="t.permission_id"
            color="primary"
            class="text-capitalize my-1"
          >
            {{ getPermission(t.permission_id) }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top v-if="checkPermission(['superadmin'])">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                fab
                icon
                x-small
                dark
                color="success"
                @click="approve(item)"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </template>
            <span>อนุมัติ</span>
          </v-tooltip>

          <v-tooltip top v-if="checkPermission(['superadmin'])">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                fab
                icon
                x-small
                dark
                color="error"
                @click="approve(item, false)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>ไม่อนุมัติ</span>
          </v-tooltip>
        </template>
      </u-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project_id: {
      type: String
    }
  },
  data () {
    return {
      NameComponent: 'บัญชีผู้ใช้',
      keyword: '',
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number'
        },
        {
          text: 'รูปโปรไฟล์',
          align: 'center',
          sortable: false,
          value: 'image'
        },
        {
          text: 'คำนำหน้า',
          value: 'name_title',
          sortable: false,
          align: 'start'
        },
        {
          text: 'ชื่อ',
          align: 'start',
          sortable: false,
          value: 'first_name'
        },
        { text: 'นามสกุล', value: 'last_name', sortable: false },
        { text: 'เบอร์โทร', value: 'phone', sortable: false, align: 'center' },
        { text: 'เพศ', value: 'gender', sortable: false, align: 'center' },
        {
          text: 'สิทธิ',
          value: 'permissions',
          sortable: false,
          align: 'center'
        }
        // { text: 'จัดการ', value: 'actions', sortable: false, align: 'center' }
      ],
      items: [],
      permissions: [],
      loading: false,
      loadingSave: false,
      pagination: {
        total: 0,
        page: 1,
        row: 10
      }
    }
  },
  created () {
    this.init()
    if (this.checkPermission(['superadmin'])) {
      this.headers.push({
        text: 'จัดการ',
        value: 'actions',
        sortable: false,
        align: 'center'
      })
    }
    // this.initPermissionDropdown() move in init()
  },
  methods: {
    async init () {
      this.initPermissionDropdown()
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        page: this.pagination.page,
        row: this.pagination.row,
        project_id: this.project_id,
        verify: '0',
        keyword: this.keyword
      }
      const res = await this.getProjectUserForTable(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination = res.data.pagination
      } else {
        this.antmessage.error(res.message)
      }
    },
    async initPermissionDropdown () {
      this.loading = true
      const payload = {
        project_id: this.project_id
      }
      const res = await this.getProjectPermissionForDropdown(payload)
      this.loading = false
      if (res.status) {
        // TODO when get data success
        this.permissions = res.data
      } else {
        // TODO when get data fail
        this.antmessage.error(res.message)
      }
    },
    onChangePagination (val) {
      this.pagination.page = val.page
      this.pagination.row = val.row
      this.init()
    },
    approve (item, action = true) {
      this.$swal({
        allowEnterKey: false,
        title: `${action ? '' : 'ไม่'}อนุมัติ${this.NameComponent}`,
        text: `คุณต้องการ${action ? 'อนุมัติ' : 'ไม่อนุมัติ'}${
          this.NameComponent
        }นี้ใช่หรือไม่`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.antmessage.loading('กำลังดำเนินการ', 0)
          const payload = {
            project_id: this.project_id,
            user_id: item.user_id,
            action: action ? 'approve' : 'reject'
          }
          const res = await this.verifyUserProject(payload)
          if (res.status) {
            this.antmessage.destroy()
            this.init()
            this.initPermissionDropdown()
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: `${
                action
                  ? `อนุมัติ${this.NameComponent}สำเร็จ`
                  : `ปฏิเสธ${this.NameComponent}สำเร็จ`
              }`,
              icon: 'success',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000
            })
            // this.close()
          } else {
            setTimeout(() => {
              this.antmessage.destroy()
              this.antmessage.error(res.message)
            }, 1000)
          }
        }
      })
    },
    getPermission (permissionId) {
      const x = this.permissions.filter(
        (i) => i.permission_id === permissionId
      )
      if (x.length > 0) return x[0].name
      else return permissionId
    }
  }
}
</script>

<style lang="scss" scoped>
.main-layout {
  height: 100%;
}
.layout-content {
  height: 100%;
  padding: 0;
  margin: 0;
}
.header-custom-table .header-custom-search {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.header-custom-table .header-custom-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 100%;
  min-height: 70px;
}
</style>
