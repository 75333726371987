<template>
  <div>
    <!-- min:{{ min }} |max:{{ max }} | date:{{ date }}| dateFormatted:{{
      dateFormatted
    }}| value:{{ value }} -->
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateFormatted"
          :placeholder="placeholder"
          persistent-hint
          v-bind="attrs"
          @update:picker-date="date = parseDate(dateFormatted)"
          v-on="on"
          outlined
          dense
          :rules="rules"
        ></v-text-field>
      </template>

      <v-date-picker
        color="primary"
        v-model="date"
        no-title
        scrollable
        @input="menu = false"
        :max="max"
        :min="min"
        locale="th"
        :show-current="true"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // Real Format Date
      // date: this.$moment().format(this.format),

      // Show Format Date
      dateFormatted: this.$moment().format(this.format),
      menu: false
    }
  },

  props: {
    format: {
      // TODO เป็น Format ที่ใช้ในการ Show ใน Text Field
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss'
    },
    format_output: {
      // TODO Format ของ Date เป็น Srtring
      // TODO โดยจะเป็น format ของ Input และ Output
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss'
    },
    value: {
      type: String,
      default: new Date().toISOString()
    },
    placeholder: {
      type: String,
      default: ''
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    isBE: {
      type: Boolean,
      default: false
    }
  },

  created () {
    this.dateFormatted = this.formatDate(this.date)
  },

  watch: {
    value (n) {
      this.dateFormatted = this.formatDate(n)
    }
  },

  computed: {
    date: {
      set (value) {
        // const d = this.formatDate(value)
        // this.dateFormatted = d
        this.$emit('input', value)
        return value
      },
      get () {
        return this.parseDate(this.value)
      }
    }
  },

  methods: {
    formatDate (date) {
      // NOTE สำหรับ format ข้อมูล Text Field ที่ใช้ในการ Show ข้อมูล
      if (!date) return null
      else if (this.isBE) { return this.$moment(date).add(543, 'year').format(this.format) } else return this.$moment(date).format(this.format)
    },
    parseDate (date) {
      // NOTE สำหรับส่งออกไปสู่ภายนอก
      if (!date) return null
      if (this.format_output.length > 0) {
        return this.$moment(date).format(this.format_output)
      } else {
        return this.$moment(date).format(this.format)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
