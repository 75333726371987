<template>
  <div style="width: 100%; height: calc(100% - 48px)">
    <u-table
      :headers="headers"
      :items="items"
      :mobileBreakpoint="900"
      :offsetHeight="48"
      :showSelect="false"
      itemKey="menu_id"
      :loading="loading"
      :pagination="pagination"
      @onChangePagination="onChangePagination"
    >
      <!-- TODO Header of Table -->
      <template v-slot:header>
        <v-row justify="end" align="center">
          <!-- NOTE Action Group -->

          <!-- NOTE Search Group -->
          <v-col cols="12" md="4">
            <v-row justify="center" align="center">
              <v-col
                ><v-text-field
                  v-model="keyword"
                  name="name"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  placeholder="ค้นหา"
                  label="ค้นหา"
                  id="id"
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn color="primary" block dark @click="init()">ค้นหา</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.create_date`]="{ item }">
        {{ item.create_date | YYYY_MM_DD_HH_MM_SS }}
      </template>
      <template v-slot:[`item.number`]="{ item }">
        {{
          pagination.row * pagination.page -
          pagination.row +
          (items.indexOf(item) + 1)
        }}
      </template>
      <!-- <template v-slot:[`item.status_code`]="{ item }">
        <v-chip
          v-for="i in item.alert_methods"
          :key="i.method_id"
          :color="`${/^2.+$/.test(i.status_code) ? 'success' : 'error'}`"
        >
          {{ i.status_code }}
        </v-chip>
      </template> -->

      <template v-slot:[`item.methods`]="{ item }">
        <v-chip
          v-for="i in item.alert_methods"
          :key="i.method_id"
          :color="`${/^2.+$/.test(i.status_code) ? 'success' : 'error'}`"
        >
          {{ i.method }} ({{ i.status_code }})
        </v-chip>
      </template>
    </u-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['storeGetProjectID', 'storeGetCurProjectMenus'])
  },
  data () {
    return {
      keyword: '',
      loading: false,
      items: [],
      headers: [
        {
          text: 'ลำดับ',
          align: 'center',
          sortable: false,
          value: 'number',
          width: '100px'
        },
        {
          text: 'เวลา',
          align: 'center',
          value: 'create_date',
          sortable: false,
          width: '200px'
        },
        {
          text: 'Title',
          align: 'center',
          value: 'title',
          sortable: false
        },
        {
          text: 'Scheduled',
          align: 'center',
          value: 'scheduled',
          sortable: false
        },
        {
          text: 'โหมกการเตือน',
          align: 'center',
          value: 'rule_mode',
          sortable: false
        },
        {
          text: 'Trigger Method',
          value: 'methods',
          align: 'center',
          sortable: false
        }
        // {
        //   text: 'Status Code',
        //   value: 'status_code',
        //   align: 'center',
        //   sortable: false,
        //   width: '150px'
        // }
      ],
      pagination: {
        total: 0,
        page: 1,
        row: 10
      }
    }
  },
  async created () {
    await this.init()
  },
  methods: {
    async init () {
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลที่จะเอามาใส่ในตราง
      const payload = {
        page: this.pagination.page,
        row: this.pagination.row,
        project_id: this.storeGetProjectID,
        timezone: 'Asia/Bangkok',
        keyword: this.keyword
      }
      const res = await this.GetAlertHistories(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination = res.data.pagination
      } else {
        this.antmessage.error(res.message)
      }
    },
    onChangePagination (val) {
      this.pagination.page = val.page
      this.pagination.row = val.row
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped></style>
